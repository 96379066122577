<snl-modal (modalClose)="closePopup()" [settings]="snlModalSettings" [type]="'medium'" [open]="isPreview"
    class="CenterModelCmn CommonPhoneCstm FuelProperties__modal">
    <div class="HeaderInner" slot="heading">
        <strong class="header-h2 capitalize-first">Fuel properties</strong>
        <span class="body-b">{{editData ? 'Edit' : 'Add'}} the emission factor and effective date for the fuel
            here.</span>
    </div>
    <div slot="modalContent">
        <div class="inputFieldsHolder">
            <div class="form-group" [ngClass]="{'error-message':sccModel.idSameFuelNameExist}">
                <ng-select #ngSelectControlName [items]="sccModel.fuelNameList"
                    class="TextSlectGroup HideForMobileSc no-scroll-drpdwn user-mng-drop select-space PlaceholderLft"
                    [searchable]="false" bindValue="fuelName" bindLabel="fuelName" placeholder="Fuel name"
                    [closeOnSelect]="false" (open)="sccModel.isClick = true" (close)="closeDropdown('name')" [(ngModel)]="sccModel.assignedFuelName"
                    [ngClass]="{'_FuelName': sccModel.assignedFuelName}" [disabled]="!sccModel.fuelNameList">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div>
                            <snl-radio-button [checked]="item$.selected">
                                <span class="RadioLabel body-b1 m-0 p-0">{{item}}</span>
                            </snl-radio-button>
                        </div>
                    </ng-template>
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search"
                            (input)="customSearch('name',$event)" />
                    </ng-template>
                    <ng-template ng-footer-tmp>
                        <button class="StoltBtnIconLarge position-relative overflow-hidden"
                            (click)="applyAssignFilter('name')" [disabled]="!sccModel.assignedFuelName">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly"
                            (click)="cancelAssignFilter('name')">Cancel</button>
                    </ng-template>
                </ng-select>
                <div class="caption error-message" *ngIf="sccModel.idSameFuelNameExist">
                    Fuel name & fuel type combination already exists.
                </div>
            </div>



            <div class="form-group">
                <ng-select #ngSelectControlType [items]="sccModel.fuelTypeList"
                    class="TextSlectGroup HideForMobileSc no-scroll-drpdwn user-mng-drop select-space PlaceholderLft"
                    [searchable]="false" bindValue="fuelType" bindLabel="fuelType" placeholder="Fuel type"
                    [closeOnSelect]="false" (open)="sccModel.isClick = true" (close)="closeDropdown('type')" [(ngModel)]="sccModel.assignedFuelType"
                    [ngClass]="{'_FuelType': sccModel.assignedFuelType}" [disabled]="!sccModel.fuelTypeList">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div>
                            <snl-radio-button [checked]="item$.selected">
                                <span class="RadioLabel body-b1 m-0 p-0">{{item}}</span>
                            </snl-radio-button>
                        </div>
                    </ng-template>
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search"
                            (input)="customSearch('type',$event)" />
                    </ng-template>
                    <ng-template ng-footer-tmp>
                        <button class="StoltBtnIconLarge position-relative overflow-hidden"
                            (click)="applyAssignFilter('type')" [disabled]="!sccModel.assignedFuelType">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly"
                            (click)="cancelAssignFilter('type')">Cancel</button>
                    </ng-template>
                </ng-select>
            </div>
            <!--Start Wireframe-->
            <div class="EmissionFctColMn">
                <span class="section-title">
                    Emission factor 
                </span>

                <div class="EmissionFctCol">
                    <ng-container *ngFor="let item of sccModel.formArray; let i = index">
                        <div class="EmissionFctColIn">
                            <app-effective-date-filter *ngIf="item.dateRange" [dateRange]="item.dateRange"
                                [savedDateRange]="item.savedDateRange" [resetAllCalled]="item.resetAllCalled"
                                (submitFilter)="applyDateFilter($event, i)" [minDate]="sccModel.minDate"
                                [maxDate]="sccModel.maxDate" [errorDateRange]="item.errorDateRange" [status]="item.status">
                            </app-effective-date-filter>

                            <snl-input [label]="'Emission factor'" [errorMessage]="item.errorMessage"
                                [isError]="item.isError" [type]="'text'" [value]="item.emissionFactor"
                                (onClearField)="onClearField(i)" (input)="onInput($event, i)"
                                [ngClass]="{'disabled__column':item.status == 'Inactive'}">
                            </snl-input>
                            <span class="trashldr" (click)="removeField(i)" *ngIf="sccModel.formArray.length > 1 && item.status != 'Inactive'">
                                <svg-icon name="trash-icon" class="m-0 df-c"></svg-icon>
                                <span class="MobileDel">Delete</span>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>

            <button class="AddMoreButton body-b1" (click)="addField()"
                [disabled]="isFormInvalid() || sccModel.hasErrors">
                <span><svg-icon name="plus-icon2" class="m-0 df-c"></svg-icon></span>
                Add More
            </button>

            <!--End wireframe changes-->
        </div>
    </div>
    <div slot="modalFooter">
        <snl-button size="large" type="primary" (click)="closePopup('save')"
            [disabled]="!sccModel.assignedFuelName || !sccModel.assignedFuelType || isFormInvalid() || sccModel.hasErrors || sccModel.isClick">Save
            properties</snl-button>
        <snl-button type="secondary" size="large" (click)="closePopup()">Cancel</snl-button>
    </div>
</snl-modal>