
<!-- Header Section: This section represents the header of the webpage -->
<app-about-header></app-about-header> 

<!-- Common Page Title Section -->
<div class="CommonHeroArea ContactTitleArea position-relative">
    <div class="LargeWindowWidth">
        <div class="CommonHeroContent">
            <h1 class="heading01">Terms of use</h1> 
        </div>
    </div>
</div> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2 class="font-24 fw-700">Thank you for visiting the Stolt Tankers Limited (“STL”) website.</h2>
            <p class="SubHeading font-24 fw-500">
                By using this site, you signify your agreement to all terms, conditions, and notices contained
                or referenced herein (&quot;Terms and Conditions of Use&quot;). If you do not agree to these Terms and
                Conditions of Use, please do not use this site.</p>
            <p class="CommonPara">
                STL reserves the right, in its sole discretion, to modify, alter or otherwise update
                these Terms and Conditions of Use at any time. By using this site after notice of
                such changes is posted, you agree to be bound by the modifications, alterations or
                updates.</p>
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2 class="font-24 fw-700">Disclaimer</h2> 
            <p class="CommonPara">This site is provided by STL on an &quot;as is&quot; basis. STL makes no representations or
                warranties of any kind, express or implied, as to the operation of the site or the
                information, content, materials, or products included on this site, including but not
                limited to any information or documentation retrieved or downloaded from the My
                Bookings section of the site (“Bookings Information”). To the full extent permissible
                by applicable law, STL disclaims all warranties, express or implied, including, but not
                limited to, implied warranties of merchantability and fitness for a particular purpose.
                STL will not be liable for any damages of any kind arising from the use of, or inability
                to use, this site, including, but not limited to direct, indirect, incidental, punitive, and
                consequential damages. You acknowledge that the terms of your contract with the
                relevant Stolt Tankers entity shall at all times and in all cases prevail over the
                Bookings Information.</p>
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2 class="font-24 fw-700">Indemnification</h2> 
            <p class="CommonPara">You agree to indemnify STL and its officers, directors, employees, agents,
                distributors, and affiliates from and against any and all third party claims, demands,
                liabilities, costs, or expenses, including reasonable attorney’s fees, that arise from
                your use or misuse of this site.</p>
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0 Applicablelaw">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2 class="font-24 fw-700">Applicable law</h2> 
            <p class="CommonPara">This site is created, operated and controlled by STL. STL makes no representation that material on STL is appropriate or available for use in other locations.</p>
            <p class="CommonPara">These Terms and Conditions of Use shall be governed by, construed and enforced in accordance with the laws of, England and Wales, without giving effect to any principles of conflicts of laws. Any disputes arising shall be referred for determination to the High Court of Justice in London, UK.</p>
       </div>
    </div> 
</section> 


<!-- Footer Section: This section represents the footer of the webpage -->
<app-footer></app-footer>
<app-scroll-to-top></app-scroll-to-top>
