<!-- Header For Public Pages -->
<div class="CommonContentSec">  
  <div class="LargeWindowWidth position-relative">
    <div class="topnav w-100 d-flex DarkHeader">
      <!-- Mobile Menu Opener -->
      <div class="public-menu-opener">
        <svg-icon name="menu-opener-public" (click)="mobileMenuToggler(true)"></svg-icon>
      </div>

      <!-- Site Logo -->
      <div class="StoldBrand">
        <a class="active" [routerLink]="['/']">
          <img width="185" height="40" src="assets/svg/public/front_logo.svg" alt="img"> 
        </a>
      </div>

      <!-- Desktop Navigation Links -->
      <div class="topnav-right">
        <a [routerLink]="['/about']" [routerLinkActive]="['active']">About</a>
        
        <!-- Fleet Information Dropdown -->
        <span class="SubMenuMain">
          <a [routerLink]="['/fleetinfo']" [routerLinkActive]="['active']">Fleet Information</a>
          <ul>
            <li>
              <a href="javascript:void(0);" [routerLink]="['/fleetinfo/snits']" rel="noopener">Europe Inland Tanker Service</a>
            </li> 
          </ul>
        </span>
        
        <a [routerLink]="['/contact']" [routerLinkActive]="['active']">Contact</a>
        
        <!-- My Bookings (Shown when user is logged in) -->
        <a [routerLink]="['/booking']" *ngIf="(isLogedin|async)" [routerLinkActive]="['active']">My bookings</a>
        
      
        <!-- Sign In Dropdown (Shown when user is logged out) -->
        <span class="SubMenuMain SubMenuMainLog" *ngIf="!(isLogedin|async)">
          <a class="SignInBtn">Sign in
            <svg class="ArrowDwn" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.46875 9.46875L7.4375 3.75C7.625 3.59375 7.8125 3.5 8 3.5C8.1875 3.5 8.34375 3.5625 8.5 3.6875L14.4688 9.40625C14.7812 9.71875 14.7812 10.1875 14.5 10.4688C14.2188 10.7812 13.75 10.7812 13.4375 10.5L8 5.3125L2.5 10.5625C2.21875 10.8438 1.71875 10.8438 1.4375 10.5312C1.15625 10.2188 1.15625 9.75 1.46875 9.46875Z" fill="#F2A900"/>
            </svg>  
          </a>
          <ul class="LoginSubMenu">
            <li>
              <a href="javascript:void(0);" (click)="login()">Sign in as customer <svg-icon src="/assets/svg/arrow-download.svg"></svg-icon></a>
            </li> 
            <li>
              <a href="{{poolingPortalUrl}}">Sign in as pooling partner <svg-icon src="/assets/svg/arrow-download.svg"></svg-icon></a>
            </li> 
          </ul>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Public Mobile menu Start Here -->
<div class="MobileMenuSidebar" [ngClass]="{'IsMobileMenuVisible': isMobileMenuVisible}"> 
  <!-- Sidebar close button cross -->
  <span class="CloseCross">
    <svg-icon name="close-nav-cross" (click)="mobileMenuToggler(false)"></svg-icon>                                
  </span> 

  <!-- Sidebar Menu Items -->
  <ul class="d-flex flex-column">
    <li><a [routerLink]="['/about']" [routerLinkActive]="['active']">About us</a></li>
    
    <!-- Fleet Information Dropdown in Mobile Menu -->
    <li class="SubMenu flex-column" [ngClass]="{'SubmenuActive' : fleetIngo}">
      <div class="SubMenuItem d-flex justify-content-between align-items-center w-100" >
        <a [routerLink]="['/fleetinfo']" [routerLinkActive]="['active']" >Fleet Information</a>
        <span class="ChevronIcon" (click)="fleetIngoChange()" >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.46875 9.46875L7.4375 3.75C7.625 3.59375 7.8125 3.5 8 3.5C8.1875 3.5 8.34375 3.5625 8.5 3.6875L14.4688 9.40625C14.7812 9.71875 14.7812 10.1875 14.5 10.4688C14.2188 10.7812 13.75 10.7812 13.4375 10.5L8 5.3125L2.5 10.5625C2.21875 10.8438 1.71875 10.8438 1.4375 10.5312C1.15625 10.2188 1.15625 9.75 1.46875 9.46875Z" fill="#F2A900"/>
          </svg>  
        </span> 
      </div>          
      <ul >
        <li><a [routerLink]="['/fleetinfo/snits']" [routerLinkActive]="['active']">Inland Tanker Service (SNITS)</a></li>
      </ul>
    </li>
    
    <li><a [routerLink]="['/contact']" [routerLinkActive]="['active']">Contact</a></li>
    
    <!-- My Bookings in Mobile Menu (Shown when user is logged in) -->
    <li><a [routerLink]="['/booking']" [routerLinkActive]="['active']" *ngIf="(isLogedin|async)">My bookings</a></li>

    <!-- Sign In Section in Mobile Menu (Not logged in) -->
    <li class="SubMenu LogInDrp flex-column" [ngClass]="{'SubmenuActive': isSignToggle}" *ngIf="!(isLogedin|async)">
      <div class="SubMenuItem d-flex justify-content-between align-items-center w-100" (click)="loginClick()">
        Sign in
        <span class="ChevronIcon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.46875 9.46875L7.4375 3.75C7.625 3.59375 7.8125 3.5 8 3.5C8.1875 3.5 8.34375 3.5625 8.5 3.6875L14.4688 9.40625C14.7812 9.71875 14.7812 10.1875 14.5 10.4688C14.2188 10.7812 13.75 10.7812 13.4375 10.5L8 5.3125L2.5 10.5625C2.21875 10.8438 1.71875 10.8438 1.4375 10.5312C1.15625 10.2188 1.15625 9.75 1.46875 9.46875Z" fill="#F2A900"/>
          </svg>  
        </span> 
      </div>          
      <ul class="ArrowColor" (click)="login()">
        <li>
          <a href="javascript:void(0);" (click)="login()">Sign in as customer <svg-icon src="/assets/svg/arrow-download.svg"></svg-icon></a>
        </li>
        <li>
          <a href="{{poolingPortalUrl}}" (click)="login()">Sign in as pooling partner <svg-icon src="/assets/svg/arrow-download.svg"></svg-icon></a>
        </li>
      </ul>
    </li>
  </ul>
  <!-- Sidebar Menu Items end -->
</div>
