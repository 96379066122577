export function getLatLong(lat : number, lng: number){
    let latitude = toDegreesMinutesAndSeconds(lat);
    let latitudeCardinal = lat >= 0 ? "N" : "S";

    let longitude = toDegreesMinutesAndSeconds(lng);
    let longitudeCardinal = lng >= 0 ? "E" : "W";

    return `${latitude}${latitudeCardinal} ${longitude}${longitudeCardinal}`;
  }

  function toDegreesMinutesAndSeconds(coordinate : number) {
    let absolute = Math.abs(coordinate);
    let degrees = Math.floor(absolute);
    let minutesNotTruncated = (absolute - degrees) * 60;
    let minutes = Math.floor(minutesNotTruncated);
    return `${degrees}°${minutes}'`;
}