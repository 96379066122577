import { Component, OnInit, Inject } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLogedin: Observable<boolean>;
  poolingPortalUrl = environment.POOLING_PORTAL_URL;
  navType = environment.navType;

  editorData: any;
  isShowAncmt: any;
  isAnnouncementClosed: any;
  showMonkeySurvey: boolean;
  userId:any;
  isPreview:boolean;
  title:any;
  activeSurvey:any;
  isStoltDomain: any;
  userEmail:any;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, public navigationService: NavigationService, private authService: MsalService) { }


  ngOnInit(): void {
    let isLogin = localStorage.getItem('userInfo');
    if (isLogin) {
      this.getUserInfo();
    }
    this.isLogedin = this.navigationService.isLoginGet();
  }

  getUserInfo() {
    this.navigationService.getUserInfo().subscribe((res: any) => {
      this.userEmail = res.data.email;
      this.isShowAncmt = localStorage.getItem('show_announcement');
      this.isAnnouncementClosed = res.data.isAnnouncementClosed;
      this.isStoltDomain = res.data.email.toLowerCase().includes('@stolt');
      this.open_NPS_CFQ_survey(res);
    })
  }

  open_NPS_CFQ_survey(res:any){
    if (res?.data?.npsSurveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isNpsSurveySubmitted && res.data.npsSurveyEnabled) { // NPS Survey
      let isSuperAdmin = res.data.role === 'super_admin';
      if (this.isStoltDomain && isSuperAdmin || !this.isStoltDomain) {
        this.navigationService.isSurveyMonkey = true;
        this.showMonkeySurvey = true;
        this.userId = res.data.user_id;
      } else {
        this.openAnnouncement();
      }
    } else if (res?.data?.surveyOpenCount == 0 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) { // CFQ Survey
      if (!this.isStoltDomain) {
        this.userId = res.data.user_id;
        this.getActiveSurveys();
      } else {
        this.openAnnouncement();
      }
    } else {
      this.openAnnouncement();
    }
  }

  openAnnouncement() {
    if (this.isShowAncmt || (!this.isShowAncmt && !this.isAnnouncementClosed)) {
      this.getActiveAnnouncement();
    }
  }

  onSurveyClosed(value: string) {
    if (value) {
      this.openAnnouncement();
    }
  }

  getActiveAnnouncement() {
    this.navigationService.getActiveAnnouncement().subscribe((res: any) => {
      if (res.status == 200) {
        this.title = res.data.title;
        this.editorData = res.data.content;
        this.isPreview = true;
      }
    })
  }

  getActiveSurveys() {
    this.navigationService.getActiveSurveys().subscribe((res: any) => {
      if(res.status == 200){
        if(res.data.surveyActive){
          this.activeSurvey = res.data;
          this.navigationService.isSurveyMonkey = true;
          this.showMonkeySurvey = true;
        } else {
          this.openAnnouncement();
        }
      }
    })
  }

  // login() {
  //   if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
  //     if (this.msalGuardConfig.authRequest) {
  //       this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //         });
  //     } else {
  //       this.authService.loginPopup()
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //         });
  //     }
  //   } else {
  //     localStorage.clear();
  //     if (this.msalGuardConfig.authRequest) {
  //       this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
  //     } else {
  //       this.authService.loginRedirect();
  //     }
  //   }
  // }
}

