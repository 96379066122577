<snl-modal class="CenterModelCmn ModelEtbOtp CommonPhoneCstm" [settings]="snlModalSettings" (modalClose)="openModel = false" [type]="'small'" [open]="openModel">
    <div slot="heading"><svg-icon name="brand-logo"></svg-icon></div>
    <div slot="modalContent" >
        <div class="skelton_port_agent" *ngIf="skeletonPortAgent">
            <div class="Stolt-Skeleton"></div>
        </div>
        <div class="AgentportHeader" *ngIf="isActive">
            <div class="OtpSent">
            
                <p class="body-b1">An OTP has been sent to: </p>
                <p class="sub-heading-s1">{{portAgentEmail}}</p>
                <p class="body-b1 TimeExpre">This OTP will expire in <span class="TimeTxt">{{formattedTime}}</span></p>
            </div> 
        </div> 
        <div *ngIf="isShowSection == 'active'">
            <div slot="modalContent">
                <form [formGroup]="OtpVerificationForm">
                    <div class="form-group" [ngClass]="{'errorField':invalidOTP}">
                        <label for="otp" class="InputLableCmn">Enter the OTP, then select ‘Verify’</label>
                        <snl-input [label]="'OTP'"
                            [errorMessage]="(f?.verifyotp?.touched && f?.verifyotp?.errors?.required) ? 'OTP is required' : f?.verifyotp?.errors?.incorrect ? 'OTP must be a 6-digit number' : f?.verifyotp?.errors?.notNumeric ? 'OTP must contain only numeric values' : '' "
                            [isError]="f?.verifyotp?.touched && (f.verifyotp.errors?.required || f?.verifyotp?.errors?.incorrect || f?.verifyotp?.errors?.notNumeric)"
                            (input)="onInput($event)"
                            formControlName="verifyotp" [type]="'text'" (keydown)="onKeyUp($event)"></snl-input>
                        <span class="error caption inp__helperText inp__helperText--error" *ngIf="invalidOTP">Incorrect OTP. Please check and try again</span>
                    </div>
                    <span class="OtpNotReceive">
                        Didn't receive the OTP? 
                        <span *ngIf="attemptsCount < 5 && attemptsCountLeft != 1" [ngClass]="{'diabledLink' : !isLinkEnabledAfterMin}">
                            <a href="javascript:void(0)" (click)="isNewLink = false;resendOtpByLink()">
                                <span class="reqNewOtp">Request new code </span> 
                                <span class="body-b">({{attemptsCountLeft == 6 ? 5 : attemptsCountLeft}} attempts)</span>
                            </a>
                        </span>
                        <span *ngIf="attemptsCountLeft == 1" class="diabledLink">Max attempts reached</span>
                    </span>
                    <div *ngIf="isrequestNewOtpLink"  class="OtpExpiredCard spaceToptobottom">
                        <div class="OtpExpiredCardInner">
                            <strong>New OTP Sent</strong> 
                            <div class="OtpExpiredTxt body-b1">
                                <p>Kindly check your spam folder and ensure your email address is entered correctly. Please contact your respective Stolt representative if you need further assistance.</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> 
   
    <div class="FooterCommonMDl" slot="modalFooter" *ngIf="!(timeExpired || isShowSection =='otpExpired' || isShowSection =='maxAttemptsReached' || isShowSection == 'isLinkExpFor30min' || isShowSection == 'isTokenExpire')">
        <snl-button size="large" [disabled]="OtpVerificationForm.invalid || invalidOTP" (click)="onSubmitOtp()">Verify</snl-button>
    </div>  
    <!-- ################## ALERT MESSAGE SECTION ################-->

    <!-- OTP expire when time is left -->
    <div slot="modalContent" class="OtpExpiredCard"  *ngIf="isShowSection =='otpExpired'">
        <div class="OtpExpiredCardInner">
            <strong>OTP expired</strong>
            <div class="OtpExpiredTxt body-b1">
                <p>Your OTP has expired. Please request for a new OTP.</p>
                <p>Please contact your respective Stolt representative if you need further assistance.</p>
            </div>
        </div>
        <div class="FooterCommonMDl" slot="modalFooter">
            <snl-button size="large" (click)="requestforNewOtp()">Request new OTP</snl-button>
        </div>
    </div>

    <div slot="modalContent" class="OtpExpiredCard" *ngIf="isShowSection =='maxAttemptsReached'">
        <div class="OtpExpiredCardInner bottom-inner32">
            <strong> Incorrect attempts reached</strong> 
            <div class="OtpExpiredTxt body-b1">
                <p>You have reached the maximum attempts of incorrect OTP entered. Please request for a new OTP.</p>
                <p>Please contact your respective Stolt representative if you need further assistance.</p>
            </div>
        </div>
        <div class="FooterCommonMDl" slot="modalFooter" >
            <snl-button size="large" (click)="isNewLink = true;resendOtpByLink()">Request new OTP</snl-button>
        </div>
    </div>
    

     <!--  OTP expired & maximum attempts reached Message -->
    <div slot="modalContent" class="OtpExpiredCard" *ngIf="isShowSection == 'isLinkExpFor30min'">
        <div class="OtpExpiredCardInner bottom-inner32">
            <strong>OTP expired and maximum attempts reached</strong> 
            <div class="OtpExpiredTxt body-b1">
                <p>Your OTP has expired and you have reached the maximum number of OTP attempts. 
                For security purposes, you can only access the link again in 30 minutes.</p>
                <p>Please contact your respective Stolt representative if you need further assistance.</p>
            </div>
        </div>
    </div> 

    <div slot="modalContent" class="OtpExpiredCard" *ngIf="isShowSection == 'isTokenExpire'">
        <div class="OtpExpiredCardInner bottom-inner32">
            <strong>Link expired</strong> 
            <div class="OtpExpiredTxt body-b1">
                <p>Please use the link provided in the latest email.</p>
            </div>
        </div>
    </div> 

</snl-modal>

<!-- Session: This area represents the Session ongoing -->
<div class="h-100 background-session" *ngIf="isLinkOpen">
    <div class="StoltWrapper">
        <main class="StoltMainContent w-100 d-flex justify-content-center align-items-center CommonPhoneContainer"> 
            <div class="CommonStaticPageText">
                <div class="header-h1">Active session ongoing</div>
                <div class="body-b1">
                    <p>An active session for this link is currently ongoing by another user.</p>
                    <p>To ensure the security and integrity of the data, only one user can access this page at a time.</p>
                    <p>Please try again later once the current session has ended.</p>
                </div> 
            </div>
        </main>

        <!-- Footer Section: This section represents the footer of the webpage -->
        <app-footer></app-footer>
        <app-scroll-to-top></app-scroll-to-top>
    </div>
</div> 