<ng-container *ngIf="onlineOffline; else offline">
  <app-side-nav [sidenavTemplateRef]="navContent" *ngIf="(showSideNav | async)" [isSideBarActive]="isSideBarActive"
    (emitSibeBar)="sideBarToggle($event)" ></app-side-nav> 

  <div class="cookie-bar" [ngClass]="{'active': isCookieActive}" *ngIf="!isCookies">
    <div class="cookie-bar__main">
      <div class="ChangeSettingsMain">
        <div class="cookie-bar__text">
          <p>This website uses cookies. Accept to continue or find out more in our <a
              [routerLink]="['/privacy-and-cookies']">cookie policy</a>.</p>
        </div>
        <div class="ChangeOption">
          <a href="javascript:void(0)" (click)="showSubSectionCookie()">Change settings</a>
          <a href="javascript:void(0)" (click)="addCookies()"> <strong>Accept &amp; Close</strong></a>
        </div>
      </div>

      <div class="cookie-bar__settings" [ngClass]="{'is-active': subSectionCookie}">
        <div class="cookie-bar__toggles">
          <ul>
            <li>
              <div class="cookie-bar__checkbox">
                <div class="checkboxHld"
                  title="Mandatory - can not be deselected. Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.">
                  <label class="containerCheckbx">
                    <input type="checkbox" checked disabled>
                    <span class="checkmark"></span>
                  </label>
                  <span class="disabled">Necessary</span>
                </div>
              </div>
            </li>
            <li>
              <div class="cookie-bar__checkbox">
                <div class="checkboxHld">
                  <label class="containerCheckbx">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                  </label>
                  <span>Performance</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="PreferencesSave">
          <a href="#"><span>Save preferences</span></a>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'SideSpaceRemover' : isCollaps,'main-container h-100':true,'ConnonOverlay': (showMobileNav| async )}">
     
    <section class="h-100">
      <router-outlet></router-outlet>
    </section>
  </div>

  <!-- Add popup globally -->
<div class="StickyShareBox ModelSafariBrwer" *ngIf="isPopup">
  <div class="CustomModelContent">
      <span class="btn-close" (click)="closePopup()"></span>
      <div class="StickyShareBoxInner"> 
          <div class="BodySafariModel">
              <div class="BodySafariModelInner">
                  <span class="error-icon"><img src="assets/img/error-icon.png" alt="img"></span>
                  <p class="CommonPara">Due to system maintenance today, the data on this portal will be refreshed after longer intervals than usual. You can still continue to use the portal. Regret the inconvenience caused.</p>
              </div> 
          </div>  
      </div>
  </div>
</div>

  <ng-template #navContent>
    <app-side-nav-content *ngIf="(showSideNav | async)" [navigationUrl]="navigationUrl"></app-side-nav-content>
  </ng-template>
</ng-container>
<ng-template #offline>
  <app-four-zero-four></app-four-zero-four>
</ng-template>