import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-model',
  templateUrl: './mobile-model.component.html',
  styleUrls: ['./mobile-model.component.scss']
})
export class MobileModelComponent {
  @Input() mobileCargos:any;
  @Input() modelType:any
  @Input() dataSource:any
  @Input() documentType:any
  @Output() mobileModelClassClose = new EventEmitter();
  cargoTooltipData: string = 'Please contact your local Stolt representative for further information.'

  mobileModelRemoveTooltip(event:any)
  {
    this.mobileModelClassClose.emit(event);
  }
}
