import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss']
})
export class UnsavedChangesComponent {
  @Input() isUnsavedPopup:boolean;
  @Output() closePopupEmit = new EventEmitter(); 
  snlModalSettings = { showCloseIcon: true, backdropClick: false }

  closeModel(type:any){
    this.removebodyClass();
    this.closePopupEmit.emit(type);
  }

  removebodyClass()
  {
    let ele:any = document.querySelector("body");
    if(ele && ele.style){
      ele.style.overflow = 'auto';
    }
  }
}
