import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MonitorBookingData } from '../models/booking';
import { catchError, map, retry } from 'rxjs/operators';
import { of, Observable, BehaviorSubject, forkJoin,lastValueFrom } from 'rxjs';
import { PublicPagesService } from './public-pages.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  baseUrl = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;
  baseUrlSustainanility = environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME;
  baseUrlVessel = environment.API_BASE_URL + environment.VESSELTRACKING_SERVICE_BASE_NAME;
  authVessel = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;
  baseUrl_auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;


  private tabMode: BehaviorSubject<string> = new BehaviorSubject<string>('Inprogress');
  public userAccessedmodule: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  isSurveyFeedback: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bookingEtaModel : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  // userAccessedmodule:any = []

  private _bookingCountSource = new BehaviorSubject<any>(0);
  currentBookingCount = this._bookingCountSource.asObservable();

  constructor(private http: HttpClient, private publicPagesService: PublicPagesService) { }

  updateUserAccessedmodule(modules: any) {
    this.userAccessedmodule.next(modules);
  }

    // this.userAccessedmodule = modules
  changeBookingCount(bookingType: any) {
    this._bookingCountSource.next(bookingType)
  }

  setTabMode(mode: string) {
    const jsonData = JSON.stringify(mode)
    localStorage.setItem('mode', jsonData)
    this.tabMode.next(mode);
  }
  getTabMode() {
    let data = localStorage.getItem('mode');
    if (data) {
      const vsdata = JSON.parse(data);
      this.tabMode.next(vsdata);
      return this.tabMode.getValue();
    }
    return 'Upcoming';
  }

  getHeader() {
    return this.publicPagesService.getHeader();
  }
  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }

  getMonitorbookingList(params: any): Observable<any> {
    let body = {bookingType :params.bookingType ,vesselCode :params.vessArra,LoadingPortNo:params.portArr,DischargePortNo :params.dportArr,idFilter:params.delayArr,chartererFilter:params.chart,tradeLOB:params.trade, brokerFilter: params.broker, is24:params.is24, pageNo:params.pageNo,complete : params.complete,id_fleet:params.bookingIdArr,dateRange:params.dateRange,loadPortSortingFlag: params.eta,dischargePortSortingFlag:params.dischargePortEta, userLocalTime: this.getCurrentDateTime()}

    return this.http.post<MonitorBookingData>(`${this.baseUrl}/api/fetchBookingListing`, body, this.getHeaderJson()).pipe(map(data => data));
  }


  getBookingFilter(bookingtype: any): Observable<any> {
    let body = { bookingType: bookingtype, userLocalTime: this.getCurrentDateTime() }
    return this.http.post<any>(`${this.baseUrl}/api/fetchBookingFilters`, body, this.getHeaderJson()).pipe(map(data => data))
  }

  getAutoCompleteData(bookingtype: any): Observable<any> {
    let body = {}
    return this.http.post<any>(`${this.baseUrl}/api/getGlobalSearch`, body, this.getHeader()).pipe(map(data => data))
  }



  getBookingDetail(bid: number, fleet: string, bookingType?: number): Observable<any> {
    let body = bookingType == 4 ? `bookingId=${bid}&fleet=${fleet}&viewMode=${bookingType}` : `bookingId=${bid}&fleet=${fleet}`;

    return this.http.post<MonitorBookingData>(`${this.baseUrl}/api/fetchBookingDetail`, body, this.getHeader()).pipe(map(data => data)/*catchError(e => of(e))*/);
  }

  saveFilter(reqBody: any) {
     let call1 = this.http.post(`${this.baseUrl}/api/postFilters`, reqBody, this.getHeaderJson()).pipe(map(data => data));
    let reqBodys = {chartererFilter:[]}

    let call2 = this.http.post(`${this.baseUrlSustainanility}/api/postFilterSustainability`, reqBodys, this.getHeaderJson()).pipe(map(data => data));

     return forkJoin([call1, call2]);
  }

  getSavedFilters(reqBody: any) {
    return this.http.post<MonitorBookingData>(`${this.baseUrl}/api/getSavedFilters`, reqBody, this.getHeaderJson()).pipe(map(data => data));
  }

  downloadAndShare(bookingIdArray: any[], cargoArray: any[]) {
    let body: { 'idBookings': any[], 'idCargoes'?: any[] } = { 'idBookings': bookingIdArray, 'idCargoes': cargoArray };
    return this.http.post<any[]>(`${this.baseUrl}/api/downloadBookings`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  SetdownloadPocZipFile(bodydata: any[], bookingId: any, singleFileDownload?: boolean) {
    let body = singleFileDownload ? { 'docArr': bodydata, bookingId: bookingId, singleFileDownload: true } : { 'docArr': bodydata, bookingId: bookingId };
    return this.http.post<any[]>(`${this.baseUrl}/api/documentationZipReq`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  SetdownloadPocPdfFile(bodydata: any[], bookingId: any) {
    let body = { 'docArr': bodydata, bookingId: bookingId };
    return this.http.post<any[]>(`${this.baseUrl}/api/downloadPdfDocumentation`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  downloadDocumentsZipFile(key: any) {
    let body: { 'fileName': any[] } = { 'fileName': key };
    return this.http.post<any[]>(`${this.baseUrl}/api/downloadZipDocumentation`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  downloadBookingFile(key: any) {
    let body: { 'filename': any[] } = { 'filename': key };
    return this.http.post<any[]>(`${this.baseUrl}/api/downloadBookingsPath`, body, this.getHeaderJson()).pipe(map(data => data));
  }
  markAsReadBookings(bookingIdArray: any[], isRead: any, calledFor?: any, bookingType?:any) {
    let body: { 'readBookingIds': any[], isRead: any, isDoc: any, isSelected:any, bookingType?:any } = { 'readBookingIds': bookingIdArray, 'isRead': isRead, 'isDoc': false, isSelected : calledFor, bookingType : bookingType };
    if (calledFor == 'Poc') {
      body.isDoc = true;
    }
    if(calledFor == 'Poc' || calledFor == 'Cargo' || calledFor == 'Sustainability'){
      body.isSelected = true;
    }
    return this.http.post<any[]>(`${this.baseUrl_auth}/api/readBookingsByUser`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  sendmail(mailContent: any) {
    let body = mailContent;
    return this.http.post<any[]>(`${this.baseUrl}/api/sendMail`, body, this.getHeaderJson()).pipe(map(data => data));
  }
  sendSustainabilityMail(mailContent: any) {
    let body = mailContent;
    return this.http.post<any[]>(`${this.baseUrlSustainanility}/api/shareSustainabilityBookingByMail`, body, this.getHeaderJson()).pipe(map(data => data));
  }
  sendDocumentEmail(mailContent: any) {
    let body = mailContent;
    return this.http.post<any[]>(`${this.baseUrl}/api/sendBDetailDocMail`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  getSustainabilityForBooking(bid: number, fleet: string) {
    let body = { 'bookingId': bid, 'fleet': fleet };
    return this.http.post<any>(`${this.baseUrlSustainanility}/api/getSustainabilityForBooking`, body, this.getHeaderJson()).pipe(map(data => data)/*catchError(e => of(e))*/);
  }
  downloadScc(downaload: any[]) {
    let body = { 'params': downaload };
    return this.http.post<any>(`${this.baseUrlSustainanility}/api/generateSCCReport`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  getVesselsLocation(imos: any[], start_date: any, end_date: any) {
    let body = { 'imos': imos, "start_date": start_date, "end_date": end_date };
    return this.http.post<any>(`${this.baseUrlVessel}/api/getVesselsLocationHistory`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  saveBookingVarient(bookingType: number, bookingVariance: string, tradelanes: boolean, charterer: boolean, broker: boolean): Observable<any> {
    let body = `bookingType=${bookingType}&booking_variance=${bookingVariance}&tradelanes=${tradelanes}&charterer=${charterer}&broker=${broker}`;
    return this.http.post<any>(`${this.baseUrl}/api/addBookingVariance`, body, this.getHeader()).pipe(map(data => data));
  }

  wayPointsPreviousPortAPiCall(startLat: string, startLong: string, deslat: string, deslong: string): Observable<any> {
    let body = `StartLon=${startLong}&StartLat=${startLat}&EndLon=${deslong}&EndLat=${deslat}`;
    return this.http.post<any>(`${this.baseUrlVessel}/api/seaMatrix`, body, this.getHeader()).pipe(map(data => data), catchError(e => of(e)));
  }

  feedBackSurveyQuestionApiCall(): Observable<any> {
    return this.http.get<any>(`${this.authVessel}/api/getSurveyQues`, this.getHeader()).pipe(map(data => data), retry(1));
  }

  feedbackSurveryPostpondedApiCall(): Observable<any> {
    let body = `isExpiry=true`;
    return this.http.post<any>(`${this.authVessel}/api/postSurveyExpiryDate`, body, this.getHeader()).pipe(map(data => data), retry(1));
  }

  feedbackSurverySubmitApiCall(body: any): Observable<any> {
    return this.http.post<any>(`${this.authVessel}/api/postFeedback`, body, this.getHeaderJson()).pipe(map(data => data), retry(1));
  }

   trackUser(val: number,countryName?: string): Promise<any> {
    let body = { isActive: val, countryName: countryName};
    return lastValueFrom(this.http.post<any>(`${this.authVessel}/api/trackUser`, body, this.getHeaderJson()));
  }

   wayPointsPreviousPortAPiCallPromise(it: any[]): Promise<any> {
    let body = { "latlongArr": it };
    return  lastValueFrom(this.http.post<any>(`${this.baseUrlVessel}/api/seaMatrix`, body, this.getHeaderJson()));
  }

  getEmailDistribution(): Observable<any> {
    return this.http.get(`${this.authVessel}/api/viewDistributionList`, this.getHeaderJson()).pipe(map(data => data));
  }
  createBookingsXLRangeWise(reqBody: any): Observable<any> {
    let body = reqBody
    return this.http.post<MonitorBookingData>(`${this.baseUrl}/api/createBookingsXLRangeWise`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  downloadBookingsXLRangeWise(reqBody: any): Observable<any> {
    let body = reqBody
    return this.http.post<MonitorBookingData>(`${this.baseUrl}/api/downloadBookingsXLRangeWise`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  getBookingDetailDocumentation(bid: number, fleet: string): Observable<any> {
    let body = `idBooking=${bid}&fleet=${fleet}`;
    return this.http.post<any>(`${this.baseUrl}/api/getBookingDetailDocumentation`, body, this.getHeader()).pipe(map(data => data));
  }

  getBookingDetaiNotification(bid: any, fleet: string) {
    let body = { "bookingNo_fleet": bid + "__" + fleet }
    return this.http.post(`${this.authVessel}/api/getBookingDetailNotifications`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  removeNotification(id: any) {
    let body = { id: id }
    return this.http.post<any>(`${this.authVessel}/api/deleteNotificationsSettings`, body, this.getHeaderJson()).pipe(map((data: any) => data))
  }
  getCargoDetailDocumentation(bid: number,fleet: string): Observable<any> {
    let body = `idBooking=${bid}&fleet=${fleet}`;
    return this.http.post<any>(`${this.baseUrl}/api/getCargoDetailDocumentation`, body, this.getHeader()).pipe(map(data => data));
  }
  getBookingDocCount(type:any): Observable<any> {
    let body = {"bookingType":type, userLocalTime: this.getCurrentDateTime()};
    return this.http.post<any>(`${this.baseUrl}/api/getCountUnreadBookingDoc`, body, this.getHeaderJson()).pipe(map(data => data));
  }

  // getBookingDetaiNotification(bid: any, fleet: string) {
  //   let body = { "bookingNo_fleet": bid + "__" + fleet }
  //   return this.http.post(`${this.authVessel}/api/getBookingDetailNotifications`, body, this.getHeaderJson()).pipe(map(data => data));
  // }

  // removeNotification(id: any) {
  //   let body = { id: id }
  //   return this.http.post<any>(`${this.authVessel}/api/deleteNotificationsSettings`, body, this.getHeaderJson()).pipe(map((data: any) => data))
  // }

   getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Adding 1 because January is 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
}
