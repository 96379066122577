<div class="form-row">
    <div class="form-group date-range-sustainability mobile-date-range SelectedDate" [ngClass]="{'disabled__column': status == 'Inactive'}">
        <form [formGroup]="dateRageFiltersForm">
            <mat-form-field appearance="fill" (click)="picker.open()" [ngClass]="{'error-message' : errorDateRange}">
                <span class="FloatingLbl" *ngIf="dateRange.fromDate && dateRange.fromDate != 'Invalid date'">Effective
                    date</span>
                <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate"
                    [ngClass]="{'separator': !dateRange.fromDate || dateRange.fromDate == 'Invalid date'}">
                    <input matStartDate readonly placeholder="Effective date" formControlName="fromDate">
                    <input matEndDate readonly formControlName="toDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                        <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn"
                            matDateRangePickerApply (click)="applyDateRangeFilter()">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn"
                            (click)="resetDateRangeFilter()" matDateRangePickerCancel>Reset</button>

                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </form>
        <div *ngIf="errorDateRange" class="caption error-message">
            {{errorDateRange}}
        </div>
    </div>
</div>