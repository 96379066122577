<!-- Page Content Holder -->
<div id="content" class="StoltBGDefault" [ngClass]="{'overFlowHidden-0':overFlowClass}" (click)="removeFilter($event)" #cargoClass>
    <div class="StoltCommonContentArea">
        <div class="booking-heading">
            <div class="booking-heading-left"> 
                <snl-page-title>
                    <div title-content>
                        <span class="header-h1">Global Search</span>
                    </div>
                </snl-page-title>
            </div>
        </div>

        <!-- Page Content Card -->
        <div class="CommonCardContent SettingPageHolder">
            <div class="GlobalSearchWrap">
                <div class="SearcSection"  [ngClass]="{'input-full-width' : searchTag}">
                    <app-search-bar [searchTag]="searchTag" [isDataStored]="isDataStored" [searchString]="searchString" (clearSearchTag)="clearSearchTag()"
                        (searchBoxCallBack)="getUserSearchFields($event)" [isHistory]="isHistory" (getSearchValue)="getSearchValue($event)" (callBackTag)="callBackTag($event)">

                    </app-search-bar>
                    <div *ngIf="tagError" class="error d-flex align-items-center tags-error">
                        <svg-icon name="Warning"></svg-icon>
                        Please select the tag
                    </div>
                </div>
                <!-- Search Tags Section -->
                <div class="SearchTags">
                    <span class="d-flex TitleTags body-b1">Search tags</span>
                    <div class="d-flex TagsHolder body-b1">
                        <button *ngFor="let tag of arrSearchTags" [ngClass]="{'active': tag.tagName == searchTag}"
                            (click)="selectTagType(tag.tagName)">
                            {{ tag.tagName }}
                        </button>
                    </div>
                </div>
                <!-- date filter start -->
                <div class="SearchTags" *ngIf="navType =='local'">
                    <div class="d-flex TagsHolder">
                        <div class="FiltersReset">
                           <label class="fw-700 font-14 d-flex align-items-center FiltersTxt">Filters</label>

                           <!-- Mobile Reset Text -->
                           <a href="javascript:void(0);" class="filter-reset" [ngClass]="{'anchor-disable': (!datePickerForm.value.fromDate && !datePickerForm.value.toDate)}" (click)="ResetDateFilter()">Reset Date</a>
                        </div>
                        <div class="date-range-sustainability globalSrcDateR" [ngClass]="{AllTextWidth:datePickerClass}">
                            <div [formGroup]="datePickerForm">
                            <mat-form-field class="mat-form-field" appearance="fill">
                                <span class="FloatingLbl">Load date</span>
                                <mat-label><strong class="All_Lablel">All</strong></mat-label>
                                <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
                                    <input matStartDate #dateRangeStart readonly placeholder="Start date"
                                        formControlName="fromDate" (focus)="picker.open()"
                                        (click)="picker.open()" (dateChange)="ChangeDate($event)">
                                    <input matEndDate readonly #dateRangeEnd placeholder="End date"
                                        formControlName="toDate" (focus)="picker.open()" (click)="picker.open()">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker (closed)="ClosePicker()"
                                >

                                </mat-date-range-picker>
                            </mat-form-field>
                            </div>
                        </div>
                        <a href="javascript:void(0);" class="filter-reset fw-700" [ngClass]="{'anchor-disable': (!datePickerForm.value.fromDate && !datePickerForm.value.toDate)}" (click)="ResetDateFilter()">Reset Date</a>
                    </div>
                    

                </div>
                <!-- date filter end -->
                <!-- Result -->
                <app-global-search-result *ngIf="searchListing.length > 0" [searchListing]="searchListing" [selectedTag]="selectedTag" [isSustainabilityAccess]="isSustainabilityAccess" [isDocumentationAccess]="isDocumentationAccess" (emitCargoData)="emitCargoData($event)" (emitDocumentoData)="emitDocumentoData($event)" (emitOverflowClass)="emitOverflowClass($event)"></app-global-search-result>

                <!-- Result -->  
                <div *ngIf="showNoResultMsg" class="DataNotAvailable d-flex align-items-center justify-content-center mb-0 mt-3">
                    <span>No Result Found.</span>
                </div>


                <!-- Showing Result Skelton Area -->
                <div class="ShowingResultMain" *ngIf="isLoading">
                    <p class="m-0 font-16 result-title"></p>
                    <ng-container>
                        <div class="ShowingResultIn">
                            <div class="BookingIdCol SkeletonSpace-b">
                                <span class="Stolt-Skeleton mb-1 w-50"></span>
                                <p class="m-0 Stolt-Skeleton w-75"></p>
                            </div>

                            <div class="RightColMain border-separator">

                                <div class="InnerRow">
                                    <div class="heading-main">
                                        <p class="m-0 Stolt-Skeleton"></p>
                                    </div>
                                    <div class="col-div-main">
                                        <div class="col-cmn column-first">
                                            <span class="font-14 Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 font-16 Stolt-Skeleton"></p>
                                        </div>
                                        <div class="col-cmn column-two"> 
                                                <span class="font-14 Stolt-Skeleton mb-1"></span>
                                                <p class="m-0 font-16 Stolt-Skeleton"></p> 
                                        </div>
                                        <div class="col-cmn column-three">
                                            <span class="font-14 Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 font-16 Stolt-Skeleton"></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="InnerRow">
                                    <div class="col-div-main">
                                        <div class="col-cmn column-first">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                        <div class="col-cmn column-two">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                        <div class="col-cmn column-three">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="InnerRow"> 
                                    <div class="col-div-main">
                                        <div class="col-cmn column-first">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                        <div class="col-cmn column-two"> 
                                                <span class="Stolt-Skeleton mb-1"></span>
                                                <p class="m-0 Stolt-Skeleton"></p> 
                                        </div>
                                        <div class="col-cmn column-three">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="InnerRow">
                                    <div class="col-div-main">
                                        <div class="col-cmn column-first">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                        <div class="col-cmn column-two">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                        <div class="col-cmn column-three">
                                            <span class="Stolt-Skeleton mb-1"></span>
                                            <p class="m-0 Stolt-Skeleton"></p>
                                        </div>
                                    </div>
                                </div>

                            </div> 
                        </div>
                    </ng-container>
                </div>
                <!-- Showing Result Skelton Area End -->

                <!-- Recent search Section -->
                <div class="RecentSearchSec" *ngIf="userHistoryRev.length > 0 && !searchListing.length && !showNoResultMsg && !isLoading">
                    <div class="RecentSearchIn">
                        <div class="RecentHeader d-flex justify-content-between align-items-center">
                            <p>Recent search</p>
                            <a class="body-b1" href="javascript:void(0)" (click)="clearAllHistory()">Clear all </a>
                        </div>
                        <div class="SearchHistory">
                            <div class="ItemRecent" *ngFor="let history of userHistoryRev">
                                <span class="body-b1" (click)="goToDetails(history)">
                                    {{history?.searchKeyword | titlecase}} 
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Model Cargo -->
 <div class="FiltersModelCustom LargeDeviceshide" #cargoModelClass>
    <div class="ModelInnerContent">
        <div class="F_ModelHeader __borderBold">
            <span class="model-dragger" (click)="mobileCargoesRemoveCall(false)"></span>
            <span>Cargoes</span>
        </div> 

        <div class="FilterItemsName" *ngIf="!isSetMobileCargoesData"> 
            <ul class="FilterListName">
                <li class="d-flex align-items-center justify-content-between col_gap_8 small-lst" *ngFor="let cargo of mobileCargoes">
                    <span>{{ cargo.name | titlecase }}</span> 
                </li>
            </ul>
        </div>
    </div>
</div>


<!-- Model Documentation -->
<div class="FiltersModelCustom LargeDeviceshide" #documentModelClass> 
    <div class="ModelInnerContent">

        <div class="F_ModelHeader __borderBold">
            <span class="model-dragger" (click)="mobileDocumentRemoveCall(false)"></span>
            <span>Documentation</span>
        </div> 
 
        <div class="FilterItemsName"> 
          <div class="HeaderData font-16">
            <ul>
                <li class="d-flex align-items-center justify-content-between col_gap_8 small-lst fw-700 border-0">
                    <span class="doc-name">Cargoes</span>
                    <span class="load-discharge">
                        <span></span>
                        <span>Docs</span>
                    </span>
                </li> 
            </ul>
         </div>

        <ul class="FilterListName font-16">
            <li class="d-flex align-items-center justify-content-between col_gap_8 small-lst" *ngFor="let docs of mobileDocument">
                <span class="doc-name">{{ docs.name |titlecase }}</span> 
                <span class="load-discharge" *ngIf="docType == 'load'">
                    <span ></span>
                    <span >{{ docs.loadDocCount }}</span>
                </span>
                <span class="load-discharge" *ngIf="docType == 'discharge'">
                    <span ></span>
                    <span >{{ docs.dischargeDocsCount }}</span>
                </span>
            </li> 
        </ul>
        </div>
    </div> 
</div>


