

<section class="CommonContentSec GreyBG OurFleetSec vh-100 position-relative">
    <div class="LargeWindowWidth SmallContainer">
         <div class="OurFleetSecBoxes d-flex flex-column justify-content-center vh-100">
            <h3 class="heading03 heading03-front text-center">Our Fleet</h3> 
            <div class="OurFleetSecBoxesHold">
                <div class="OurFleetSecBoxesInner d-flex justify-content-start position-relative"> 
                    <div class="OurFleetBxData">
                        <h3 class="heading03">Capacity</h3>
                        <div class="TableView">
                            <p class="TopHeadingTitle border-0 p-0 d-flex justify-content-between">
                                <span class="text-uppercase">Dead Weight Tonnes</span> 
                                <span class="text-uppercase">Number of vessels</span>
                            </p>
                            <p class="DataRow d-flex justify-content-between">
                                <span>Up to 10,000</span>
                                <span>{{totalFleets1}}</span>
                            </p>
                            <p class="DataRow d-flex justify-content-between">
                                <span>10,000 - 25,000</span>
                                <span>{{totalFleets2}}</span>
                            </p>
                            <p class="DataRow d-flex justify-content-between">
                                <span>Above 25,000</span>
                                <span>{{totalFleets3}}</span>
                            </p>
                        </div>
                    </div>
                    <hr>
                    <div class="OurFleetBxData FleetBxData-right">
                        <div class="FleetBxDataRight-in">
                            <h3 class="heading03">Cargo</h3>
                            <div class="TableView">
                                <p class="DataRow RowCargoTp">
                                    <span> 
                                        IMO Class 
                                        <ng-container *ngFor="let item of imo_class; let first =first">{{!first?',':''}}{{item}}</ng-container><br>
                                        liquid chemicals
                                    </span>
                                </p> 
                            </div>
                            <h3 class="heading03">Age</h3>
                            <div class="TableView">
                                <p class="DataRow">
                                    <span>{{average_year}} years (average)</span>
                                </p> 
                            </div>
                        </div> 
                    </div>                        
                </div>
                <div class="text-center">
                    <a class="BtnNoBgArrow" [routerLink]="['/fleetinfo-external']" >View fleet information<img src="assets/svg/login-arrow.svg" alt="img"></a>               
                </div>   
            </div>
         </div>
    </div>     
</section>  