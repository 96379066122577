import { Component, OnInit, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnaylticsService } from 'src/app/services/google-anayltics.service';

@Component({
  selector: 'app-about-header',
  templateUrl: './about-header.component.html',
  styleUrls: ['./about-header.component.scss']
})
export class AboutHeaderComponent implements OnInit {
  poolingPortalUrl = environment.POOLING_PORTAL_URL;
  navType = environment.navType;
  isIframe = false;
  loginDisplay = false;
  isCookies:boolean;
  isLogedin: Observable<boolean>;
  isCookieActive : boolean;
  isMobileMenuVisible : boolean = false;
  fleetIngo:boolean = false
  isSignToggle: boolean = false;
  profileData:any={}
  isUserLogin:boolean = false;
  publicUrl:any[] = ['track','terms-of-use','privacy-and-cookies','about','fleetinfo','contact'];
  isLogedinSubject: BehaviorSubject<boolean>;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private navService: NavigationService,
    private router: Router,
    private _googleAnaylticsService: GoogleAnaylticsService,
    private route: ActivatedRoute
  ) {
    this.isLogedin = this.navService.isLoginGet();
    this.getPopupCookies()
  }

  ngOnInit(): void {
   
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          (<AuthenticationResult>result.payload)
          this.setLoginDisplay();
        }
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        // Add your auth error handling logic here
      });
      this.checkUserLogin();
      if(this.isUserLogin){
        this.getUserInfo()
      }
  }

  setLoginDisplay() {
    let splitUrl:any = this.router.url.split('/')
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.isLogedinSubject = new BehaviorSubject<boolean>(this.loginDisplay);
    if(this.loginDisplay) {
      this.isLogedin = this.isLogedinSubject.asObservable();
    }
    if(!this.publicUrl.includes(splitUrl[1])){
      this.navService.isLoginSet(this.loginDisplay);
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  addPopupCookies() {
    localStorage.setItem('isPopCookies', JSON.stringify(true));
    this.getPopupCookies();
  }

  getPopupCookies(){
    setTimeout(()=> this.isCookieActive =true,1000);
    const isCookiesCheck = localStorage.getItem('isPopCookies');
      if (isCookiesCheck) {
        this.isCookies= JSON.parse(isCookiesCheck);
      }else{
        this.isCookies= false;
      }
  }

  mobileMenuToggler(isVisible:boolean){
    this.isMobileMenuVisible = isVisible;
  }

  fleetIngoChange()
  {
    this.fleetIngo = this.fleetIngo ? false : true;
  }

  loginClick(){
    this.isSignToggle = !this.isSignToggle;
  }

  getUserInfo(){
    this.navService.getUserInfo().subscribe((res: any) => {
      if (res && res.data) {
      
        this.profileData = res.data;
        let gaData = {
          eventCategory: "user_login",
          eventLabel: "page_load",
          eventValue: this.router.url,
          user_charterer: this.profileData?.isBroker ? this.profileData?.broker_short_name : this.profileData?.charterer,
          userType: this.getBrokerType(this.profileData),
          user_id: this.profileData.user_id ? this.profileData.user_id : ""
        }
        this._googleAnaylticsService.sendPageTrackingLog(gaData)
      }
    })
  }

  getBrokerType(data: any) {
    if (data.isBroker) {
      return 'B'
    } else {
      if (data.charterer == "Stolt Tankers") {
        return 'S'
      } else {
        return 'C'
      }
    }
  }

  checkUserLogin(){
    this.navService.isLoginGet().subscribe((isLogin) => this.isUserLogin = isLogin);
  }
}
