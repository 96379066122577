import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import moment from 'moment';
import { NavigationService } from 'src/app/services/navigation.service';
import { SccModel } from '../model/scc-model';
import { SustainabilityService } from 'src/app/services/sustainability.service';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-add-new-fuel',
  templateUrl: './add-new-fuel.component.html',
  styleUrls: ['./add-new-fuel.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class AddNewFuelComponent {
  @ViewChild('ngSelectControlName') ngSelectControlName: NgSelectComponent;
  @ViewChild('ngSelectControlType') ngSelectControlType: NgSelectComponent;
  @Output() closePopupEmit = new EventEmitter();
  @Input() isPreview: any;
  @Input() editData: any;
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  sccModel = new SccModel();
  constructor(private navigationService: NavigationService, private sustainabilityService: SustainabilityService) {
    this.getFuelList();
  }
  
  getFuelList(){
    this.sustainabilityService.getFuelList().subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.sccModel.fuelNameList = res.data.fuelNames;
          this.sccModel.fuelTypeList = res.data.fuelTypes;
          this.sccModel.fuelNameListMini = this.sccModel.fuelNameList;
          this.sccModel.fuelTypeListMini = this.sccModel.fuelTypeList;
        }
      }, error: err => {
      }
    })
  }

  ngOnInit() {
    const currentYear = moment().year();
    const earliestYear = currentYear - 2;
    const latestYear = currentYear + 2;
    this.sccModel.minDate = new Date(earliestYear, 0, 1);
    this.sccModel.maxDate = new Date(latestYear, 11, 31);
  }

  ngOnChanges() {
    if (this.editData) {
      this.sccModel.formArray = []
      this.sccModel.assignedFuelName = this.editData.fuelName;
      this.sccModel.assignedFuelNameApply = this.editData.fuelName;
      this.sccModel.assignedFuelType = this.editData.fuelType;
      this.sccModel.assignedFuelTypeApply = this.editData.fuelType;
      this.sccModel.formArray = this.editData.emissionFactors;
      this.sccModel.formArray = this.editData.emissionFactors.map((emission: any) => ({
        ...emission,  // Spread existing properties
        emissionFactor: emission.emissionFactor.toString(),  // Convert to string
        errorMessage: '',
        isError: false,
        savedDateRange: emission.dateRange,
        resetAllCalled: false,
        errorDateRange: ''
      }));
    } else {
      this.sccModel.formArray = [
        {
          emissionFactor: '',
          errorMessage: '',
          isError: false,
          dateRange: this.sccModel.dateRange,
          savedDateRange: null,
          resetAllCalled: false,
          errorDateRange: ''
        }
      ];
    }
  }

  closePopup(type?: any) {
    if (type == 'save') {
      this.sccModel.idSameFuelNameExist = false;
      if (this.editData) {
        this.updateAddNewFuel();
      } else {
        this.addNewFuel();
      }
    } else {
      this.removebodyClass();
      this.closePopupEmit.emit(type);
    }
  }

  addNewFuel() {
    const emissions = this.getEmissionsData('add');
    let payload = {
      fuelName: this.sccModel.assignedFuelName,
      fuelType: this.sccModel.assignedFuelType,
      emissions: emissions
    }
    this.sccModel.isClick = true;
    this.sustainabilityService.addEmissionFactor(payload).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.sccModel.isClick = false;
          this.navigationService.showSuccessAddTitle('The new fuel has been created successfully', '');
          this.removebodyClass();
          this.closePopupEmit.emit('save');
        }
      }, error: err => {
        this.sccModel.isClick = false;
        this.sccModel.idSameFuelNameExist = true;
      }
    })
  }

  getEmissionsData(type:any) {
    if(type == 'add'){
      return this.sccModel.formArray.map((item: any) => ({
        emissionFactor: item.emissionFactor,
        dateRange: item.dateRange
      }));
    }else if (type == 'update'){
      return this.sccModel.formArray.map((item: any) => ({
        emissionFactor: item.emissionFactor,
        dateRange: item.dateRange,
        id: item._id
      }));
    }
    
  }

  updateAddNewFuel() {
    const emissions = this.getEmissionsData('update');
    let payload = {
      fuelId: this.editData.fuelId,
      fuelName: this.sccModel.assignedFuelName,
      fuelType: this.sccModel.assignedFuelType,
      status: this.editData.status,
      emissions: emissions
    }
    this.sccModel.isClick = true;
    this.sustainabilityService.updateEmissionFactor(payload).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.sccModel.isClick = false;
          this.navigationService.showSuccessAddTitle('Changes have been saved successfully', '');
          this.removebodyClass();
          this.closePopupEmit.emit('save');
        }
      }, error: err => {
        this.sccModel.isClick = false;
        this.sccModel.idSameFuelNameExist = true;
      }
    })
  }

  removebodyClass() {
    let ele: any = document.querySelector("body");
    if (ele && ele.style) {
      ele.style.overflow = 'auto';
    }
  }

  applyAssignFilter(event: any) {
    if (event == 'name') {
      this.sccModel.idSameFuelNameExist = false;
      this.sccModel.assignedFuelNameApply = this.sccModel.assignedFuelName;
      this.ngSelectControlName.close();
    } else if (event == 'type') {
      this.sccModel.assignedFuelTypeApply = this.sccModel.assignedFuelType;
      this.ngSelectControlType.close();
    }

  }

  cancelAssignFilter(event: any) {
    if (event == 'name') {
      this.sccModel.assignedFuelName = this.sccModel.assignedFuelNameApply;
      this.ngSelectControlName.close();
    } else if (event == 'type') {
      this.sccModel.assignedFuelType = this.sccModel.assignedFuelTypeApply;
      this.ngSelectControlType.close();
    }
  }

  closeDropdown(event: any) {
    this.sccModel.isClick = false;
    if (event == 'name') {
      this.sccModel.fuelNameList = this.sccModel.fuelNameListMini;
      this.sccModel.assignedFuelName = this.sccModel.assignedFuelNameApply;
    } else if (event == 'type') {
      this.sccModel.fuelTypeList = this.sccModel.fuelTypeListMini;
      this.sccModel.assignedFuelType = this.sccModel.assignedFuelTypeApply;
    }

  }

  customSearch(type: any, event: any) {
    switch (type) {
      case 'name':
        let fuelName = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchFuelName(fuelName)
        return;
      case 'type':
        let fuelType = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchByFuelType(fuelType);
        return;
      default:
        return;
    }
  }

  refactorSearchFuelName(fuelName: any) {
    if (fuelName.length > 2) {
      this.sccModel.fuelNameList = this.sccModel.fuelNameListMini.filter((it: any) => it.toLowerCase().includes(fuelName.toLowerCase()));
    } else {
      this.sccModel.fuelNameList = this.sccModel.fuelNameListMini
    }
  }

  refactorSearchByFuelType(fuelType: any) {
    if (fuelType.length > 2) {
      this.sccModel.fuelTypeList = this.sccModel.fuelTypeListMini.filter((it: any) => it.toLowerCase().includes(fuelType.toLowerCase()));
    } else {
      this.sccModel.fuelTypeList = this.sccModel.fuelTypeListMini
    }
  }

  // wireframe changes
  addField() {
    this.sccModel.formArray.push({
      emissionFactor: '',
      errorMessage: '',
      isError: false,
      dateRange: this.sccModel.dateRange,
      savedDateRange: null,
      resetAllCalled: false,
      errorDateRange: ''
    });
  }

  removeField(index: number) {
    this.sccModel.formArray.splice(index, 1);
    this.sccModel.hasErrors = false;
    this.checkDateOrder(this.sccModel.formArray);
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/[^0-9.]/g, '');
    const decimalParts = value.split('.');
    if (decimalParts.length > 2) {
      value = decimalParts[0] + '.' + decimalParts.slice(1).join('');
    }
    if (decimalParts.length === 2) {
      value = decimalParts[0] + '.' + decimalParts[1].slice(0, 4);
    }

    input.value = value;
    this.sccModel.formArray[index].emissionFactor = value;

    if (value) {
      this.sccModel.formArray[index].errorMessage = '';
      this.sccModel.formArray[index].isError = false;
    }
  }

  onClearField(index: number) {
    this.sccModel.formArray[index].emissionFactor = '';
    this.sccModel.formArray[index].errorMessage = 'Emission factor is required.';
    this.sccModel.formArray[index].isError = true;
  }

  // overlapping
  applyDateFilter(eventFilter: any, index: number) {
    this.sccModel.hasErrors = false;
    if (!this.sccModel.formArray[index].resetAllCalled) {
      this.sccModel.formArray[index].resetAllCalled = false;

      const { fromDate, toDate } = eventFilter.data;
      this.sccModel.formArray[index].dateRange = eventFilter.data;
      // Validation for missing end date
      if (fromDate && fromDate !== 'Invalid date' && (!toDate || toDate === 'Invalid date')) {
        this.sccModel.formArray[index].errorDateRange = 'Please select end date.';
        this.sccModel.hasErrors = true;
        return;
      }
      this.sccModel.formArray.forEach((item: any, index: any) => {
        this.sccModel.formArray[index].errorDateRange = '';
      });
      if (this.sccModel.formArray[index].dateRange.fromDate === "Invalid date" && this.sccModel.formArray[index].dateRange.toDate === "Invalid date") {
        this.sccModel.formArray[index].errorDateRange = 'Effective date is required.';
        this.sccModel.hasErrors = true;
        return
      }
      this.checkOverlappingSequence(fromDate, toDate, index)
      setTimeout(() => {
        this.checkDateOrder(this.sccModel.formArray);
      }, 100)
    }
  }

  checkDateOrder(array: any) {
    if (!this.sccModel.hasErrors) {
      array.forEach((item: any, index: any) => {
        this.sccModel.formArray[index].errorDateRange = '';
        this.checkOverlappingSequence(item.dateRange.fromDate, item.dateRange.toDate, index); // Overlapping without sequence
        this.checkEmptyDateSingleSelection(item.dateRange.fromDate, item.dateRange.toDate, index);
      });
    }
  }

  checkEmptyDateSingleSelection(fromDate: string, toDate: string, currentIndex: number) {
    if (fromDate && fromDate !== 'Invalid date' && (!toDate || toDate === 'Invalid date')) {
      this.sccModel.formArray[currentIndex].errorDateRange = 'Please select end date.';
      this.sccModel.hasErrors = true;
    }
    if (this.sccModel.formArray[currentIndex].dateRange.fromDate === "Invalid date" && this.sccModel.formArray[currentIndex].dateRange.toDate === "Invalid date") {
      this.sccModel.formArray[currentIndex].errorDateRange = 'Effective date is required.';
      this.sccModel.hasErrors = true;
    }
  }

  checkOverlappingSequence(fromDate: string, toDate: string, currentIndex: number) {
    let checkAll = [...this.sccModel.formArray.slice(0, currentIndex), ...this.sccModel.formArray.slice(currentIndex + 1)];
    let isETABerthOccupiedOverlap = checkAll.some((item: any) => new Date(fromDate) > new Date(item.dateRange.fromDate) && new Date(fromDate) < new Date(item.dateRange.toDate));
    let isETABerthOccupiedOverlap1 = checkAll.some((item: any) => new Date(fromDate) < new Date(item.dateRange.fromDate) && new Date(toDate) > new Date(item.dateRange.fromDate));

    if (isETABerthOccupiedOverlap || isETABerthOccupiedOverlap1) {
      this.sccModel.formArray[currentIndex].errorDateRange = 'The selected date range overlaps with another existing entry for this fuel. Please choose a different range.';
      this.sccModel.hasErrors = true;
    }

    // New conditions to check if fromDate or toDate is the same as another fromDate or toDate
    let isFromDateSame = checkAll.some((item: any) =>
      new Date(fromDate).getTime() === new Date(item.dateRange.fromDate).getTime() ||
      new Date(fromDate).getTime() === new Date(item.dateRange.toDate).getTime()
    );

    let isToDateSame = checkAll.some((item: any) =>
      new Date(toDate).getTime() === new Date(item.dateRange.toDate).getTime() ||
      new Date(toDate).getTime() === new Date(item.dateRange.fromDate).getTime()
    );
    if (isFromDateSame || isToDateSame) {
      this.sccModel.formArray[currentIndex].errorDateRange = 'The selected date range overlaps with another existing entry for this fuel. Please choose a different range.';
      this.sccModel.hasErrors = true;
    }
  }

  // end overlapping

  // Validation Function
  isFormInvalid(): boolean {
    return this.sccModel.formArray.some((item: any) =>
      !item.emissionFactor ||
      !item.dateRange?.fromDate ||
      !item.dateRange?.toDate ||
      item.dateRange.fromDate === 'Invalid date' ||
      item.dateRange.toDate === 'Invalid date'
    );
  }

  // end wireframe changes

}
