<!-- Session: This area represents the Thank you! -->
<div class="h-100">
    <div class="StoltWrapper">
        <main class="StoltMainContent w-100 d-flex justify-content-center align-items-center CommonPhoneContainer"> 
            <div class="CommonStaticPageText">
                <div class="header-h1">Thank you!</div>
                <div class="body-b1">
                    <p>Your form has been successfully submitted.</p>
                    <p>You may now close this window or exit the page.</p>
                </div> 
            </div>
        </main>

        <!-- Footer Section: This section represents the footer of the webpage -->
        <app-footer></app-footer>
        <app-scroll-to-top></app-scroll-to-top>
    </div>
</div> 