import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'delayMessage'
})
export class DelayMessagePipe implements PipeTransform {
  transform(value: any): any {
    if(value > 0){
      return 'Delayed';
    } else {
      return (value < 0 ? 'Early Arrival' : 'On time');
    }
  }

}