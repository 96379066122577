import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'days'
})
export class DaysPipe implements PipeTransform {

  transform(value: any): any {
    const d =Math.round(value);
    if(d>1){
      return `${d} days`;
    } else {
      return (d < -1 ?`${d} days` : `${d} day`);
    }
  }

}

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  transform(value: any): any {
    if(value>1 || value < -1){
      return `${value} hours `;
    } else {
      return (value == 0 ?`${0} days` : `${value} hour`);
    }
  }

}
