import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {PublicPagesService} from './public-pages.service';
@Injectable({
  providedIn: 'root'
})
export class SustainabilityService {
  baseUrlSustainanility= environment.API_BASE_URL+environment.SUSTAINABILITY_SERVICE_BASE_NAME;
  constructor(private http :HttpClient,private publicPagesService :PublicPagesService) { }
  getHeader() {
    return this.publicPagesService.getHeader();
   }
   getHeaderJson(){
     return this.publicPagesService.getHeaderTypeJson();
   }

   getSustainability(){
    let body=  {};
    return  this.http.post<any>(`${this.baseUrlSustainanility}/api/getSustainabilityGraphs`,body,this.getHeaderJson()).pipe(map(data => data));
  }

  getSustainanilityChartData(mode:number) : Observable<any>{
    let body= `mode=${mode}`;
    return this.http.post<any>(`${this.baseUrlSustainanility}/api/fetchSusChartDashboardData`,body,this.getHeader()).pipe(map(data => data));
  }

  getSustainanilityFilteData() : Observable<any>{
    return this.http.post<any>(`${this.baseUrlSustainanility}/api/fetchSustanabilityFiltersOptions`,this.getHeader()).pipe(map(data => data));
  }
  

  getSustainabilityBookingListData(obj:any,cargo:any[], trade :any[], vessel:any[],charterer:any[],bookingid:any[],dateRangeObj:any) : Observable<any>{
    let body=  {"pageNo" :obj.pageNo,"cargoFilter":cargo,"tradeFilter":trade,"vesselFilter":vessel,"chartererFilter":charterer,"id_fleet":bookingid,dateRange:dateRangeObj,"isSetting":obj.isSetting};
    return this.http.post<any>(`${this.baseUrlSustainanility}/api/fetchSusBookingDashboardData`,body,this.getHeaderJson()).pipe(map(data => data));
  }

  filterSusChartData(datas:any){
    let body=  datas;
    return this.http.post<any>(`${this.baseUrlSustainanility}/api/filterSusChartData`,body,this.getHeaderJson()).pipe(map(data => data));
  }

  createBookingsXLRangeWise(reqBody: any): Observable<any> {
    let body = reqBody
    return  this.http.post<any>(`${this.baseUrlSustainanility}/api/createSustainBookingsXLRangeWise`,body,this.getHeaderJson()).pipe(map(data => data));
  }

  downloadBookingsXLRangeWise(reqBody: any): Observable<any> {
    let body = reqBody
    return  this.http.post<any>(`${this.baseUrlSustainanility}/api/downloadSustainBookingsXLRangeWise`,body,this.getHeaderJson()).pipe(map(data => data));
  }

  getSustainabilityGraphData(reqBody: any): Observable<any> {
    let body = reqBody
    return  this.http.post<any>(`${this.baseUrlSustainanility}/api/updateSustainabilityGraphFilter`,body,this.getHeaderJson()).pipe(map(data => data));
  }
}
