<!-- Header Section: This section represents the header of the webpage -->
<app-about-header></app-about-header>  

<!-- Common Page Title Section -->
<div class="CommonHeroArea ContactTitleArea position-relative">
    <div class="LargeWindowWidth">
        <div class="CommonHeroContent">
            <h1 class="heading01">Privacy and cookies</h1> 
        </div>
    </div>
</div> 


<!-- Common Page Title Section -->
<section class="CommonContentSec TermsUseContent">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2>Thank you for visiting the Stolt Tankers Limited (STL) website.</h2>
            <p class="SubHeading">This website is designed to make it easier and more efficient for businesses to
                interact with STL. STL recognises that it is critical for individuals and businesses to
                be confident that their privacy is protected when they visit the STL website.</p>

            <p class="CommonPara">Stolt Tankers Limited and its subsidiaries respect your privacy. Privacy and the
                protection of your personal data is important to us and we are committed to
                protecting the integrity, availability and confidentiality of your personal data. This
                Privacy Policy outlines your rights to privacy and our commitment to safeguarding
                personal data. It is meant to help you understand how we process and protect your
                personal data. ‘Personal data’ means any information relating to an identified or
                identifiable natural person (‘data subject’).</p>

                <p class="CommonPara">STL will only use personal data for legitimate purposes, and personal data will be
                    processed in accordance with applicable data protection law. STL is implementing
                    EU data protection law (GDPR). GDPR and local privacy law will apply to the process
                    of personal data by STL.</p>    
       </div>
    </div> 
</section> 


<!-- Common Page Title Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2>Information collected automatically when you visit this website</h2> 
            <p class="CommonPara">When visiting this website STL automatically collects and stores a limited amount of
                information about your visit. The information that is collected automatically is used
                to improve this website&#39;s content and to help STL understand how users are
                interacting with the website. This information is collected for statistical analysis, to
                determine what information is of most and least interest to our users, and to improve
                the utility of the material available on the website.</p>
       </div>
    </div> 
</section> 


<!-- Common Page Title Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2>Cookies</h2> 
            <p class="CommonPara">Like most websites, our website uses cookies. A cookie is a small file of letters and
                numbers that is saved in your browser. The cookies cannot identify you. In most
                browsers you can accept or reject all, or certain, cookies. If you continue to use our
                website, you accept our use of cookies. The software and hardware you use to
                access the website allows you to refuse new cookies or delete existing cookies.
                Refusing or deleting these cookies may limit your ability to take advantage of some
                features of this website.</p>

                <p class="CommonPara">Details of the cookies used on our website are provided below:</p>    
       </div>
    </div> 
</section> 

<!-- Common Page Title Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2>Necessary (3 cookies)</h2> 
            <p class="CommonPara">Necessary cookies help make a website usable by enabling basic functions like page
                navigation and access to secure areas of the website. The website cannot function
                properly without these cookies.</p> 
       </div>
    </div> 
</section> 

<!-- Common Page Title Section -->
<section class="CommonContentSec TableFleetData">
    <div class="LargeWindowWidth">
        <div class="table-data CommonTableContainer table-responsive"> 
                <snl-table class="snl-table-customized">
                    <thead>
                        <snl-row class="body-b1 fw-700 sticky-tbl-hdr"> 
                            <snl-th scope="col">Name</snl-th>
                            <snl-th scope="col">Provider</snl-th>                       
                            <snl-th scope="col">Purpose</snl-th> 
                            <snl-th scope="col">Expiry</snl-th> 
                            <snl-th scope="col">Type</snl-th> 
                        </snl-row>
                    </thead>
                    <tbody>
                        <snl-row> 
                            <snl-td><span class="text__primary">ARRAffinity</span></snl-td>
                            <snl-td><span class="text__primary"><a  target="_blank" href="{{siteUrl}}" rel="noopener" class="text-decoration-underline">{{hostName}}</a></span></snl-td> 
                            <snl-td><span class="text__primary">Used for load balancing to make sure the visitor page requests are routed to the same server in any browsing session.</span></snl-td>
                            <snl-td><span class="text__primary">session</span></snl-td>  
                            <snl-td><span class="text__primary">HTTP Cookie</span></snl-td> 
                        </snl-row> 
                        <snl-row> 
                            <snl-td><span class="text__primary">ARRAffinitySameSite</span></snl-td>
                            <snl-td><span class="text__primary"><a  target="_blank" rel="noopener" href="{{siteUrl}}"  class="text-decoration-underline">{{hostName}}</a></span></snl-td> 
                            <snl-td><span class="text__primary">It is used for load balancing to make sure the visitor page requests are routed to the same server in any browsing session.</span></snl-td>
                            <snl-td><span class="text__primary">session</span></snl-td>  
                            <snl-td><span class="text__primary">HTTP Cookie</span></snl-td> 
                        </snl-row> 
                        <snl-row> 
                            <snl-td><span class="text__primary">smConsent</span></snl-td>
                            <snl-td><span class="text__primary"><a  target="_blank" href="{{siteUrl}}" rel="noopener"  class="text-decoration-underline">{{hostName}}</a></span></snl-td> 
                            <snl-td><span class="text__primary">Stores the user’s cookie consent state for the current domain {{hostName}}</span></snl-td> 
                            <snl-td><span class="text__primary">session</span></snl-td>  
                            <snl-td><span class="text__primary">HTTP Cookie</span></snl-td>  
                        </snl-row> 
                        
                    </tbody>
                </snl-table> 
        </div> 
    </div>     
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0 mt-4">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2>Performance cookies (3 cookies)</h2> 
            <p class="CommonPara">Performance cookies help website owners to understand how visitors interact with
                websites by collecting and reporting information anonymously.</p> 
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TableFleetData">
    <div class="LargeWindowWidth">
        <div class="table-data CommonTableContainer table-responsive"> 
            <snl-table class="snl-table-customized">
                <thead class="thead-dark StickyThead">
                    <snl-row class="body-b1 fw-700 sticky-tbl-hdr">
                        <snl-th scope="col">Name</snl-th>
                        <snl-th scope="col">Provider</snl-th>                       
                        <snl-th scope="col">Purpose</snl-th> 
                        <snl-th scope="col">Expiry</snl-th> 
                        <snl-th scope="col">Type</snl-th>
                    </snl-row>
                </thead>
                <tbody>
                    <snl-row> 
                        <snl-td><span class="text__primary">_ga</span></snl-td>
                        <snl-td><span class="text__primary"><a  target="_blank" href="{{siteUrl}}" rel="noopener"  class="text-decoration-underline">{{hostName}}</a></span></snl-td> 
                        <snl-td><span class="text__primary">Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</span></snl-td>  
                        <snl-td><span class="text__primary">2 year</span></snl-td>  
                        <snl-td><span class="text__primary">HTTP Cookie</span></snl-td>
                    </snl-row> 
                    <snl-row> 
                        <snl-td><span class="text__primary">gat</span></snl-td>
                        <snl-td><span class="text__primary"><a  target="_blank" href="{{siteUrl}}" rel="noopener"  class="text-decoration-underline">{{hostName}}</a></span></snl-td> 
                        <snl-td><span class="text__primary">Used by Google Analytics to throttle request rate </span></snl-td>  
                        <snl-td><span class="text__primary">Session</span></snl-td>  
                        <snl-td><span class="text__primary">HTTP Cookie</span></snl-td>
                    </snl-row> 
                    <snl-row> 
                        <snl-td><span class="text__primary">gid</span></snl-td>
                        <snl-td><span class="text__primary"><a target="_blank" href="{{siteUrl}}" rel="noopener" class="text-decoration-underline">{{hostName}}</a></span></snl-td> 
                        <snl-td><span class="text__primary">Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</span></snl-td>  
                        <snl-td><span class="text__primary">Session</span></snl-td>  
                        <snl-td><span class="text__primary">HTTP Cookie</span></snl-td>
                    </snl-row> 
                </tbody>
            </snl-table> 
        </div> 
        <p class="CommonPara">Your consent to the use of cookies applies to <a  target="_blank" rel="noopener" href="{{siteUrl}}"  class="text-decoration-underline">{{hostName}}</a></p>
        <p class="CommonPara">When you first access the website, you are asked to consent to the use of cookies including performance cookies. You can view and change your cookie settings at any time here.</p>
    </div>     
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2>Information collected when you contact us or subscribe to our news alerts via this website</h2> 
            <p class="CommonPara">During your visit to this website you may send an e-mail to STL. Your e-mail address and the information included in your message will be used to respond to you, to address issues you identify, or to improve this website</p> 
            <p class="CommonPara">During your visit to this website you may initiate a transaction such as a survey or registration. The information, including personal information, volunteered by you in initiating the transaction is used by STL to operate STL programmes, which include the provision of goods, services, and information.</p>
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Controller</h2> 
            <p class="CommonPara">STL processes personal data as an employer, as a supplier of services, for marketing purposes and in connection with visits to our website. In the capacity as controller, we process personal data for the following main purposes:</p>
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Customer and supplier information</h2> 
            <p class="CommonPara">STL processes personal data regarding customers and suppliers as well as any third parties required for the performance of contractual obligations. This personal information may be exchanged between legal entities within STL but will not be disclosed unless otherwise stated in the relevant service contracts or required by law.</p>
            <p class="CommonPara">The information being processed is contact information for customers and suppliers, and the personal data will be securely stored.</p>
       </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Marketing</h2> 
            <p class="CommonPara">STL processes personal data for marketing purposes. This personal information may be exchanged between the various legal entities within STL but will not be disclosed to other businesses.</p>
            <p class="CommonPara">STL processes contact information of potential customers. The information is obtained from publicly available sources, such as the websites of companies, or from direct contact with the company in question. The purpose of this processing of personal data is to promote marketing for our services and coordinate this marketing effort.</p>
            <p class="CommonPara">STL has a legitimate interest in contacting potential customers to promote our services, and in our opinion this interest is not overridden by the interests of the data subject. Reference is here made to the fact that we only process contact information relating to the data subjects position at his/her employer. Personal data relating to potential customers will be deleted unless a customer relationship is established.</p>
        </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Human resources</h2> 
            <p class="CommonPara">STL processes personal information as part of human resources management.</p>
            <p class="CommonPara">Personal data relating to vacant positions and applications will be deleted one year after you applied for employment unless you are employed or explicit consent for further storage is given.</p>
        </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Exchange and transfer</h2> 
            <p class="CommonPara">We may share your information within STL as well as with third parties to the extent necessary for the purposes described above and permitted by law.</p>
        </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Data protection</h2> 
            <p class="CommonPara">We take data protection seriously and have adopted appropriate security measures to protect your personal data against loss, misuse, unauthorised access, theft, alteration, disclosure or destruction. We will restrict access to your personal data to those who have a legitimate need for such access.</p>
            <p class="CommonPara">The STL group complies with legal requirements and avoids unacceptable legal risks.</p>
        </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Retention of personal data</h2> 
            <p class="CommonPara">STL will only retain personal data if this is necessary for the purposes described above. When storage is no longer necessary, we will delete, destroy or anonymise the relevant personal data.</p>
         </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Exercising your rights and contacting us</h2> 
            <p class="CommonPara">You have the right to ask us for a copy of any personal data that you have provided to us or that we maintain about you and to request an explanation about the processing. In addition, you have the right to withdraw any consent previously granted or to request correction, amendment, restriction, anonymisation or deletion of your personal data; and to obtain the personal data you provide with your consent or in connection with a contract in a structured, machine readable format and to ask us to transfer this data to another data controller.</p>
            <p class="CommonPara">You also have the right to object to the processing of your personal data in some circumstances, in particular when we are using your data for direct marketing or to create a marketing profile</p>
            <p class="CommonPara">We may decline to process requests that are frivolous/vexatious, jeopardise the privacy of others, are extremely impractical, or for which access is not otherwise required by local law. Access, correction, or deletion requests can be made by sending an email to privacyoffice@stolt.com.</p>
        </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Changes to this privacy policy</h2> 
            <p class="CommonPara">We may update this privacy policy at any time. We encourage you to check our privacy policy regularly for any changes in order to stay informed about how STL protects and uses your personal data.</p>
          </div>
    </div> 
</section>


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0 Applicablelaw">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <h2>Disclaimer</h2> 
            <p class="CommonPara">The information provided in this privacy policy should not be construed as giving business, legal, or other advice, or warranting as fail proof, the security of information provided through this website.</p>
            <p class="CommonPara">This privacy policy was last reviewed on May 12, 2022</p>
          </div>
    </div> 
</section>


<!-- Footer Section: This section represents the footer of the webpage -->
<app-footer></app-footer>
<app-scroll-to-top></app-scroll-to-top>
