export class SccModel {
     public fuelNameList: any; 
     public fuelTypeList: any; 
     public fuelNameListMini: any;
     public assignedFuelName: any;
     public assignedFuelNameApply: any;
   
     public fuelTypeListMini: any;
     public assignedFuelType: any;
     public assignedFuelTypeApply: any;
   
     public errorMessage: any = '';
     public isError: boolean;
     public emissionFactor: any;
   
     public dateRange: any = {
       fromDate: '',
       toDate: ''
     };
     public minDate = new Date();
     public maxDate = new Date();
     public todayDate = new Date();
     public savedDateRange: any;
     public resetAllCalled = false;
     public isOpenEffectModel:boolean;
     public isOpenAddFuel: boolean;
     public userRole:any;
     public isSkeletonEnable:boolean;
     public editData:any;
     public fuelData:any = [];
     public formArray:any = [];
     public idSameFuelNameExist:boolean = false;
     public hasErrors:boolean;
     public isClick:boolean;
    constructor() {console.log('Model constructor called');}
    }