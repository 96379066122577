<div class="StoltWrapper"> 

    <!-- Header Section: This section represents the header of the webpage -->
    <app-about-header></app-about-header>  

    <!-- New Landing Page Design Start Here -->       
    <main class="StoltMainContent GreyBG NewWrpHome">
        <div class="HomeBannerArea">
            <div class="HereHome overflow-hidden position-relative"> 
                <div class="CommonContentSec __pr0__phone">
                    <div class="LargeWindowWidth">
                        <div class="HeroFormArea d-flex">
                            <div class="BannerContentMain w-100 d-flex justify-content-between"> 
                                <div class="ConetntColumn">
                                    <h1 class="heading01">
                                        <h1 class="heading01 BrandNameText">Stolt Tankers</h1>  
                                        We collaborate to create 
                                        flexible and personalised  
                                        solutions
                                    </h1>  
                                </div>
                                <div class="BannerBoxesMain d-flex flex-column">
                                    <div class="d-flex flex-column BoxesLargeDevice">
                                        <div class="BoxInner d-flex flex-column">
                                            <h3 class="heading03 font-24 fw-700">Customer portal</h3>
                                            <p class="fw-400 m-0">Track and manage all your bookings in MyStoltTankers portal.</p>
                                            <p class="m-0"><a class="text-decoration-none fw-700">To request access to the portal, please contact your Stolt Tankers Voyage co-ordinator.</a></p>
                                            <a class="BtnNoBgArrow border-0 position-relative m-0 fw-700" [routerLink]="['/booking']" *ngIf="(isLogedin|async)">My bookings<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a> 
                                            <a class="BtnNoBgArrow border-0 position-relative m-0 fw-700" (click)="login()" *ngIf="!(isLogedin|async)">Sign in<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a>
                                        </div>
        
                                        <div class="BoxInner d-flex flex-column">
                                            <h3 class="heading03 font-24 fw-700">Europe Inland Tanker Service</h3>
                                            <p class="m-0 fw-400">Your reliable barge partner for Chemicals, Bio-components, and Mineral Oil Products.</p>
                                            
                                            <a class="BtnNoBgArrow border-0 position-relative m-0 fw-700" [routerLink]="['/fleetinfo/snits']">Inland fleet info<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a> 
                                        </div>
                                    </div>
                                    
                                    <!-- Mobile Swiper Slider -->
                                    <div class="customizedSwiperMain">
                                        <owl-carousel-o [options]="swiperConfiguration">
                                            <ng-template carouselSlide>
                                                <div class="BoxInner d-flex flex-column">
                                                    <div class="BannerBoxesMain d-flex flex-column">
                                                    <h3 class="heading03 font-24 fw-700">Customer portal</h3>
                                                    <p class="fw-400 m-0">Track and manage all your bookings in MyStoltTankers portal.</p>
                                                    <p class="m-0"><a class="text-decoration-none fw-700">To request access to the portal, please contact your Stolt Tankers Voyage co-ordinator.</a></p>
                                                    <a class="BtnNoBgArrow border-0 position-relative m-0 fw-700" [routerLink]="['/booking']" *ngIf="(isLogedin|async)">My bookings<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a>
                                                    
                                                    <a class="BtnNoBgArrow border-0 position-relative m-0 fw-700" (click)="login()" *ngIf="!(isLogedin|async)">Sign in<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a>

                                                    </div>
                                                </div>
                                            </ng-template> 
                                            <ng-template carouselSlide>
                                                <div class="BoxInner d-flex flex-column">
                                                    <div class="BannerBoxesMain d-flex flex-column">
                                                    <h3 class="heading03 font-24 fw-700">Europe Inland Tanker Service</h3>
                                                    <p class="fw-400 m-0">Your reliable barge partner for Chemicals, Bio-components, and Mineral Oil Products.</p> 
                                                    <a class="BtnNoBgArrow border-0 position-relative m-0 fw-700" [routerLink]="['/fleetinfo/snits']">Inland fleet info<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a> 
                                                    </div>
                                                </div>
                                            </ng-template>   
                                        </owl-carousel-o>
                                    </div>
                                    <!-- Mobile Swiper Slider -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    
        <!-- Our Fleet Content Section -->
        <section class="CommonContentSec GreyBG OurFleetSec position-relative overflow-hidden">
            <div class="LargeWindowWidth">
                <div class="OurFleetSecBoxes MobileRelative">
                    <h3 class="heading03 heading03-front heading0M">Our Fleet</h3> 
                    <div class="OurFleetSecBoxesHold">
                        <div class="OurFleetSecBoxesInner d-grid justify-content-start position-relative"> 
                            <div class="OurFleetBxData">
                                <h3 class="heading03">Capacity</h3>
                                <div class="TableView d-flex flex-column">
                                    <p class="TopHeadingTitle"><span>Dead Weight Tonnes</span> <span>No. of vessels</span></p>
                                    <p><span>Up to 10,000</span> <span>{{totalFleets1}}</span></p>
                                    <p><span>10,000 - 25,000</span> <span>{{totalFleets2}}</span></p>
                                    <p><span>Above 25,000</span> <span>{{totalFleets3}}</span></p>
                                </div>
                            </div>
    
                            <div class="OurFleetBxData">
                                <h3 class="heading03">Cargo</h3>
                                <div class="TableView">
                                    <p><span> IMO Class <ng-container *ngFor="let item of imo_class; let first =first">{{!first?',':''}}{{item}}</ng-container> liquid chemicals</span></p>
                                </div>
                            </div>
    
                            <div class="OurFleetBxData">
                                <h3 class="heading03">Age</h3>
                                <div class="TableView">
                                    <p><span>{{average_year}} years (average)</span></p> 
                                </div>
                            </div>
                            <div class="OurFleetBxData WhiteBx d-flex justify-content-center align-items-center p-0">
                                <a class="BtnNoBgArrow position-relative fw-700" [routerLink]="['/fleetinfo']" >View fleet information<img src="assets/svg/login-arrow.svg" alt="img" width="20" height="20"></a>               
                            </div>                                              
                        </div>
                        <div class="HomeMapArea"> 
                            <img src="assets/img/public/home-map.png" alt="img" width="100%" height="100%">  
                        </div>
                    </div>
                </div>
            </div>     
        </section> 
    </main>  
    
    <!-- Footer Section: This section represents the footer of the webpage -->
    <app-footer></app-footer> 
    <app-scroll-to-top></app-scroll-to-top> 
    
</div>
<!-- Comment temporary <app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId"></app-nps-survey-monkey> -->