<form [formGroup]="notificationForm" class="CustomSlectTimeMain">
    <div class="CustomSlectTime" (click)="displayPicker(1,'on')" [ngClass]="{'active':isDisplayPicker,'inp__error': isNotValidate}">
        <span>
            <span class="FloatingLbl">Time #1</span>
            <strong class="fw-700">{{timer.HourMin}} {{timeFormate}}</strong>
        </span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z"
                fill="#788496" />
        </svg>

    </div>

    <div class="TimePickerMain" *ngIf="isDisplayPicker">
        <div class="BodyTimePic">
            <timepicker [hourStep]="hstep" [mousewheel]="false" [minuteStep]="mstep"
                formControlName="notificationTime" [meridians]="meridians" [(ngModel)]="notifyTime"></timepicker>
            <div class="TimeAmPm">
                <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormatPm}"
                    type="button" (click)="onFormateChange(1,'PM')" type="button">PM</button>
                <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormateAm}"
                    (click)="onFormateChange(1,'AM')" type="button"> AM </button>
            </div>
        </div>
        <div class="modal-footer snl-btn-applyCncl">
            <snl-button size="large" (click)="applyTime(1)">Apply</snl-button>
            <snl-button size="large" [type]="'secondary'" (click)="displayPicker(1,'off')"> Cancel </snl-button>
        </div>
    </div> 
    <span class="mt-1 error caption" *ngIf="isNotValidate">
        This selection overlaps with another timeslot selected. Please choose another timeslot.
    </span> 
    <div class="TimeoptColMn" *ngIf="activeSecondSlot || activeThirdSlot"> 
        <!-- ################## Timer section 2 start here #################### -->
        <ng-container *ngIf="activeSecondSlot">
            <div class="CustomSlectTimeHolder">
                <div class="CustomSlectHld">
                    <div class="CustomSlectTime" (click)="displayPicker(2,'on')" [ngClass]="{'active':isDisplayPicker_second,'inp__error': isNotValidate_1}">
                        <span>
                            <span class="FloatingLbl">Time #{{timerSlotLength_1}}</span>
                            <strong class="fw-700">{{timer_second.HourMin}} {{timeFormate_second}}</strong>
                        </span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z"
                                fill="#788496" />
                        </svg>
                    </div>
                    <a href="javascript:void(0)" (click)="removeSlot(1)" class="trash__list df-c fw-700 body-b1">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_18340_262)">
                            <path d="M13.8516 2.16406C14.2578 2.16406 14.6016 2.50781 14.6016 2.91406C14.6016 3.35156 14.2578 3.66406 13.8516 3.66406H13.4766L12.7266 13.8203C12.6328 14.8828 11.7891 15.6641 10.7266 15.6641H4.44531C3.38281 15.6641 2.53906 14.8828 2.44531 13.8203L1.69531 3.66406H1.35156C0.914062 3.66406 0.601562 3.35156 0.601562 2.91406C0.601562 2.50781 0.914062 2.16406 1.35156 2.16406H3.50781L4.66406 0.445312C4.97656 -0.0234375 5.53906 -0.335938 6.13281 -0.335938H9.03906C9.63281 -0.335938 10.1953 -0.0234375 10.5078 0.445312L11.6641 2.16406H13.8516ZM6.13281 1.16406C6.03906 1.16406 5.94531 1.22656 5.91406 1.28906L5.32031 2.16406H9.85156L9.25781 1.28906C9.22656 1.22656 9.13281 1.16406 9.03906 1.16406H6.13281ZM11.9766 3.66406H3.19531L3.94531 13.7266C3.97656 13.9766 4.19531 14.1641 4.44531 14.1641H10.7266C10.9766 14.1641 11.1953 13.9766 11.2266 13.7266L11.9766 3.66406Z" fill="#008ECF"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_18340_262">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </a>
                </div>
                <span class="mt-1 error caption d-flex" *ngIf="isNotValidate_1">
                    This selection overlaps with another timeslot selected. Please choose another timeslot.
                </span>
            </div>

            <div class="TimePickerMain" *ngIf="isDisplayPicker_second">
                <div class="BodyTimePic">
                    <timepicker [hourStep]="hstep" [mousewheel]="false" [minuteStep]="mstep"
                        formControlName="notificationTime_1" [meridians]="meridians" [(ngModel)]="notifyTime_second">
                    </timepicker>
                    <div class="TimeAmPm">
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700"
                            [ngClass]="{'active':isFormatPm_second}" type="button" (click)="onFormateChange(2,'PM')"
                            type="button">PM</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700"
                            [ngClass]="{'active':isFormateAm_second}" (click)="onFormateChange(2,'AM')" type="button"> AM
                        </button>
                    </div>
                </div>
                <div class="modal-footer snl-btn-applyCncl">
                    <snl-button size="large" (click)="applyTime(2)">Apply</snl-button>
                    <snl-button size="large" [type]="'secondary'" (click)="displayPicker(2,'off')"> Cancel </snl-button>
                </div>
            </div> 
        </ng-container> 

        <!-- ################## Timer section 3 start here #################### -->
        <ng-container *ngIf="activeThirdSlot">
            <div class="CustomSlectTimeHolder">
                <div class="CustomSlectHld">
                    <div class="CustomSlectTime" (click)="displayPicker(3,'on')" [ngClass]="{'active':isDisplayPicker_second, 'inp__error': isNotValidate_2}">
                        <span>
                            <span class="FloatingLbl">Time #{{timerSlotLength_2}}</span>
                            <strong class="fw-700">{{timer_third.HourMin}} {{timeFormate_third}}</strong>
                        </span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z"
                                fill="#788496" />
                        </svg>

                    </div>
                    <a href="javascript:void(0)" (click)="removeSlot(2)" class="trash__list df-c fw-700 body-b1">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_18340_262)">
                            <path d="M13.8516 2.16406C14.2578 2.16406 14.6016 2.50781 14.6016 2.91406C14.6016 3.35156 14.2578 3.66406 13.8516 3.66406H13.4766L12.7266 13.8203C12.6328 14.8828 11.7891 15.6641 10.7266 15.6641H4.44531C3.38281 15.6641 2.53906 14.8828 2.44531 13.8203L1.69531 3.66406H1.35156C0.914062 3.66406 0.601562 3.35156 0.601562 2.91406C0.601562 2.50781 0.914062 2.16406 1.35156 2.16406H3.50781L4.66406 0.445312C4.97656 -0.0234375 5.53906 -0.335938 6.13281 -0.335938H9.03906C9.63281 -0.335938 10.1953 -0.0234375 10.5078 0.445312L11.6641 2.16406H13.8516ZM6.13281 1.16406C6.03906 1.16406 5.94531 1.22656 5.91406 1.28906L5.32031 2.16406H9.85156L9.25781 1.28906C9.22656 1.22656 9.13281 1.16406 9.03906 1.16406H6.13281ZM11.9766 3.66406H3.19531L3.94531 13.7266C3.97656 13.9766 4.19531 14.1641 4.44531 14.1641H10.7266C10.9766 14.1641 11.1953 13.9766 11.2266 13.7266L11.9766 3.66406Z" fill="#008ECF"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_18340_262">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            
                    </a>
                </div>
                <span class="mt-1 error caption d-flex" *ngIf="isNotValidate_2">
                    This selection overlaps with another timeslot selected. Please choose another timeslot.
                </span> 
            </div>
            <div class="TimePickerMain" *ngIf="isDisplayPicker_third">
                <div class="BodyTimePic">
                    <timepicker [hourStep]="hstep" [mousewheel]="false" [minuteStep]="mstep"
                        formControlName="notificationTime_2" [meridians]="meridians" [(ngModel)]="notifyTime_third">
                    </timepicker>
                    <div class="TimeAmPm">
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700"
                            [ngClass]="{'active':isFormatPm_third}" type="button" (click)="onFormateChange(3,'PM')"
                            type="button">PM</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700"
                            [ngClass]="{'active':isFormateAm_third}" (click)="onFormateChange(3,'AM')" type="button"> AM
                        </button>
                    </div>
                </div>
                <div class="modal-footer snl-btn-applyCncl">
                    <snl-button size="large" (click)="applyTime(3)">Apply</snl-button>
                    <snl-button size="large" [type]="'secondary'" (click)="displayPicker(3,'off')"> Cancel </snl-button>
                </div>
            </div>
        </ng-container>
    </div>
    
    <span class="AddMoreDate"
        [ngClass]="{'resetdisabled' :(activeSecondSlot && activeThirdSlot) || (isNotValidate || isNotValidate_1 || isNotValidate_2)}">
        <a (click)="addMoreSlot()" class="section-title text-end w-100 text-decoration-none">
            <svg-icon name="plus-icon2"></svg-icon>
            Add another time
        </a>
    </span>
</form>