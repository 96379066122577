import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nps-survey-monkey',
  templateUrl: './nps-survey-monkey.component.html',
  styleUrls: ['./nps-survey-monkey.component.scss']
})
export class NpsSurveyMonkeyComponent {
 @Input() activeSurvey:any; 
 @Input() userId:any;
  url: SafeResourceUrl | null = null; // Safe URL type
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  @Output() surveyClosed = new EventEmitter<string>();
  constructor(private navigationService: NavigationService, private sanitizer: DomSanitizer){
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['userId'] && this.userId && !this.activeSurvey){ // NPS Survey
      const surveyUrl = `${environment.SURVEY_MONKEY_WEBLINK}?custid=${this.userId}`;
      // NOSONAR - The URL is sanitized using DOMPurify, and trusted domains are validated
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(surveyUrl); // NOSONAR
    } else if (changes['userId'] && this.userId && this.activeSurvey) { // CFQ Survey
      const bookingIdWithoutFleet = this.activeSurvey.bookingId.split('__')[0];
      const surveyUrl = `${this.activeSurvey.surveyUrl}?custid=${this.userId}&bookingid=${this.activeSurvey.bookingId}&bid=${bookingIdWithoutFleet}&cname=${this.activeSurvey.cargoName}&vslname=${this.activeSurvey.vesselName}&voynum=${this.activeSurvey.voyageNumber}`;
      // NOSONAR - The URL is sanitized using DOMPurify, and trusted domains are validated
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(surveyUrl); // NOSONAR
    } 
  }

  closePopup(){
    this.removebodyClass();
    this.surveyClosed.emit('Survey closed successfully!');
    let isNpsSurvey: boolean = false;
    if(this.userId && !this.activeSurvey){
      isNpsSurvey = true;
    }
    this.navigationService.updateSurveyOpenCount(this.activeSurvey?.bookingId,isNpsSurvey).subscribe({next:res=>{
     },error:err=>{
     }})
  }

  removebodyClass()
  {
    let ele:any = document.querySelector("body");
    if(ele && ele.style){
      ele.style.overflow = 'auto';
    }
  }
}
