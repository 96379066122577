import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InProgressFilter } from 'src/app/enums/inProgressFilter';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-settingfilter-poc',
  templateUrl: './settingfilter-poc.component.html',
  styleUrls: ['./settingfilter-poc.component.scss']
})
export class SettingfilterPocComponent {
  PortDisplay = 'block'
  dischargeDisplay = 'block'
  chartererDisplay = 'block'
  portFilterData: any[] = [];
  portDischargeData: any[] = []
  chartererFilterData: any[] = [];

  portFilterDataMani: any[] = [];
  portDischargeDataMani: any[] = []
  chartererFilterDataMani: any[] = []
  portFilterValueTemp: any[] = [];
  dportFilterValueTemp: any[] = [];
  chartererFilterValueTemp: any[] = []

  dportFilterValue: any[] = [];
  portFilterValue: any[] = [];
  hideAllPortSearch = true;
  DisabledBtn: boolean = true
  @Input() settingFilterData: any;
  @Output() filterCallBack: EventEmitter<any> = new EventEmitter();
  @Output() filterEventCallBack: EventEmitter<any> = new EventEmitter();

  @ViewChild('ngSelectControlPort') ngSelectControlPort: NgSelectComponent;
  @ViewChild('searchName') searchNameInput: any;
  enableFilter: boolean = false;
  saveFilter: any;
  isFilter = false;
  isLoading = true;
  constructor(private navigationService: NavigationService) {
    this.navigationService.setShowNav(true);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Setting');
  }



  ngOnChanges() {
    if(this.settingFilterData){
      this.getUserFilter()
    }
  }

  getUserFilter() {
    this.isFilter = true;
    this.saveFilter = this.settingFilterData.savedFilter;
    this.portFilterValueTemp = (this.saveFilter.loadPortNumber === null) ? [] : this.saveFilter.loadPortNumber;
    this.dportFilterValueTemp = (this.saveFilter.dischargePortNumber === null) ? [] : this.saveFilter.dischargePortNumber;
    this.chartererFilterValueTemp = (this.saveFilter.charterer === null) ? [] : this.saveFilter.charterer;
    let loadPortfilterdata = this.settingFilterData.LoadPortFilter
    this.portFilterData = loadPortfilterdata.map((it: any) => ({ ...it, port: 'All' }))
    this.portFilterDataMani = this.portFilterData;
   
    let dischargeFilter = this.settingFilterData.DischargePortsFilter
    this.portDischargeData = dischargeFilter.map((it: any) => ({ ...it, discharge: 'All'}))
    this.portDischargeDataMani = this.portDischargeData

    let chartererData = this.settingFilterData.ChartererFilter;
    this.chartererFilterData = chartererData.map((it: any) => ({ "chartererName": it.charterer,  charterer: 'All' }))
    this.chartererFilterDataMani = this.chartererFilterData;
    this.isLoading = false;
    this.refactorSavedFilter()

  }

  refactorSavedFilter() {

    if (this.portFilterValueTemp.includes('All')) {
      this.portFilterValueTemp = this.portFilterDataMani.map(it => it.loadPortNumber);
    } else {
      this.portFilterValueTemp = (this.portFilterValueTemp === null) ? [] : this.portFilterValueTemp;
    }

    if (this.dportFilterValueTemp.includes('All')) {
      this.dportFilterValueTemp = this.portDischargeDataMani.map(it => it.dischargePortNumber);
    } else {
      this.dportFilterValueTemp = (this.dportFilterValueTemp === null) ? [] : this.dportFilterValueTemp;
    }

    if (this.chartererFilterValueTemp.includes('All')) {
      this.chartererFilterValueTemp = this.chartererFilterDataMani.map(it => it.chartererName);
    } else {
      this.chartererFilterValueTemp = (this.chartererFilterValueTemp === null) ? [] : this.chartererFilterValueTemp
    }
  }

  customSearch(type: string, event: Event) {
    switch (type) {
      case InProgressFilter.port:
        let wordPort = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchPort(wordPort, event)
        return
      case InProgressFilter.dport:
        let wordDport = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchDport(wordDport)
        return
      case InProgressFilter.charterer:
        let wordCharterer = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchCharterer(wordCharterer)
        return

      default:
        let wordCharterer1 = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchCharterer(wordCharterer1)
        return
    }
  }


  refactorSearchPort(wordPort: any, event: any) {
    this.hideAllPortSearch = (event.target as HTMLInputElement).value == "" ? true : false;
    if ((wordPort != undefined && wordPort != "undefined" && wordPort != null && wordPort != "null" && wordPort != "") || wordPort.length > 2) {
      this.PortDisplay = "none";
      this.portFilterData = this.portFilterDataMani.filter((it: any) => it.loadPortName.toLowerCase().includes(wordPort.toLowerCase()));
    } else {
      this.PortDisplay = "block";
      this.portFilterData = this.portFilterDataMani
    }
  }

  refactorSearchDport(wordDport: any) {
    if ((wordDport != undefined && wordDport != "undefined" && wordDport != null && wordDport != "null" && wordDport != "") || wordDport.length > 2) {
      this.dischargeDisplay = "none";
      this.portDischargeData = this.portDischargeDataMani.filter((it: any) => it.dischargePortName.toLowerCase().includes(wordDport.toLowerCase()));
    } else {
      this.dischargeDisplay = "block";
      this.portDischargeData = this.portDischargeDataMani
    }
  }
  refactorSearchCharterer(wordCharterer: any) {
    if ((wordCharterer != undefined && wordCharterer != "undefined" && wordCharterer != null && wordCharterer != "null" && wordCharterer != "") || wordCharterer.length > 2) {
      this.chartererDisplay = "none";
      this.chartererFilterData = this.chartererFilterDataMani.filter((it: any) => it.chartererName.toLowerCase().includes(wordCharterer.toLowerCase()));
    } else {
      this.chartererDisplay = "block";
      this.chartererFilterData = this.chartererFilterDataMani
    }
  }
  SubmitFilter() {
    this.DisabledBtn = true;
    let param = { port: this.portFilterValueTemp, dport: this.dportFilterValueTemp, charterer: this.chartererFilterValueTemp }
    this.filterCallBack.emit(param)

  }


  ResetAllFilter() {
    this.PortDisplay = 'block'
    this.dischargeDisplay = 'block'
    this.chartererDisplay = 'block'
    this.DisabledBtn = false;
    this.enableFilter = false
    this.portFilterValueTemp = [];
    this.portFilterData = this.portFilterDataMani;
    this.dportFilterValueTemp = [];
    this.portDischargeData = this.portDischargeDataMani;
    this.chartererFilterValueTemp = [];
    this.chartererFilterData = this.chartererFilterDataMani;
    let param = { port: this.portFilterValueTemp, dport: this.dportFilterValueTemp, charterer: this.chartererFilterValueTemp }
    this.filterCallBack.emit(param)
  }

  clickEvent(event: any, type: any) {
    let userEvent = { event: event, type: type }
    this.filterEventCallBack.emit(userEvent)
    this.SubmitFilter()
  }

  clearInput()
  {
    this.displayFilterCount();
    if(this.searchNameInput){
      this.searchNameInput.nativeElement.value = '';
    }
    this.portFilterData = this.portFilterDataMani;
    this.chartererFilterData = this.chartererFilterDataMani;
    this.portDischargeData = this.portDischargeDataMani
  }
  displayFilterCount(){
    this.PortDisplay = 'block'
    this.dischargeDisplay = 'block'
    this.chartererDisplay = 'block'
  }

  emitDinamicAddClass(){
    this.filterEventCallBack.emit(true)
  }
  emitDinamicRemoveClass(){
    this.filterEventCallBack.emit(false)
  }
}
