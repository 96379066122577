<ng-container [ngSwitch]="mapType">
    <ng-container *ngSwitchCase="'booking'">
        <!--Loader Map-->
        <div class="MapLoaderHld MapLoaderHld-2" *ngIf="!isDisplayMap else disPlayMap1">
            <div class="MapLoaderHldInner blur-map-2"></div>
            <span>Loading...</span>
       </div>
       <!--Loader Map End-->
        <ng-template #disPlayMap1>
            <google-map class="MapModelFullCard MapMdlShareDwnd sebm-google-map-container MapViewMn" width="100%" [options]="options">
                <ng-container *ngFor="let item of bookingList">
                    <ng-container *ngFor='let li of item.arVessel;index as i'>
                        <map-marker #marker="mapMarker" class="vesselName" *ngIf="li.last_latitude && li.last_longitude" 
                        [position]="{lat:li.last_latitude,lng:li.last_longitude}"  [options]="{icon :locIcon}" [label]="{color: 'white',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        text:li.vesselName|titlecase}" (mapClick)="markerClick(marker,li,item.idBooking)">
                        <map-info-window>
                            <div class="CloseMdl"><svg-icon name="model-closer" (click)="closePopup()"></svg-icon></div>
                            <ng-container *ngIf="agmInfoWindow.length >1;else other">
                                <ng-select [searchable]="false" [clearable]="false" class="_Load tp_space"
                                    [(ngModel)]="changeBookingId" (change)="changeAgmInfoData()">
                                    <ng-option *ngFor="let m of agmInfoWindow" [value]="m.idBooking">
                                        {{m.idBooking}}</ng-option>
                                </ng-select>
                            </ng-container>
                            <ng-template #other>
                                <span class="SingleSelect">{{agmInfoWindow[0]?.idBooking}}</span>
                            </ng-template>
    
                            <ng-container *ngFor='let el of vessalAgmInfo'>
                             
                                <div class="agm-info-windowBX"
                                    *ngFor='let e of el.arVessel;index as k;let first = first'>
                                    <div *ngIf="first" class="VesselInfoWindowModelCls" id="VesselInfoWindowModel">
                                        <div class="modal-dialog modal-dialog-centered mt-15">
                                            <div class="modal-content border-0">
                                                <div class="modal-header ps-0 pe-0 pt-0 box-shadow-hide">
                                                    <div class="modal-header-inner">
                                                        <span class="etaModalData">{{el?.idBooking}}</span>
                                                        <h5 class="heading05">{{e?.vesselName|titlecase}}</h5>
                                                        <span>Voyage no: <span
                                                                class="VoyageNumber">{{e?.idVoyageNumber}}</span></span>
                                                    </div>
                                                    <div class="ButtonHolder">
                                                        <a   (click)="goToBooking(el)"><button
                                                                type="button" class="StoltBtnIconLarge btn-wdt-100">View
                                                                booking details</button></a>
                                                        <div class="btnGrp btnGrp2">
                                                            <button type="button"
                                                                class="StoltBtnIconLarge StoltBtnBorderOnly shareBooking M_CommonHidden"
                                                                (click)="agmShareDownloadFunc('agmShare',el)">
                                                                <svg-icon name="share-icon"></svg-icon> Share
                                                            </button>
                                                            <button type="button" (click)="agmShareDownloadFunc('agmShareMbl',el)"
                                                                class="StoltBtnIconLarge StoltBtnBorderOnly shareBooking LargeDeviceshide">
                                                                <svg-icon name="share-icon"></svg-icon> Share
                                                            </button>
                                                            <button type="button"
                                                                class="StoltBtnIconLarge StoltBtnBorderOnly downloadBooking"
                                                                (click)="agmShareDownloadFunc('agmDownload',el)">
                                                                <svg-icon name="download-blue"></svg-icon> Download
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-body ps-0 pe-0 pt-0 pb-m-0">
                                                    <div class="modal-body-info">
                                                        <div class="ColumBodyinner ColumBodyinnerLeft">
                                                            <ng-scrollbar class="M_CommonHidden">
                                                                <div class="scrollbar-holder">
                                                                    <span class="notifymessage"
                                                                        *ngIf="el.arVessel.length > 1">This booking has
                                                                        multiple load & discharge ports. View booking
                                                                        details for full info</span>
                                                                    <div class="ColuminnerInfo NameDataPrt">
                                                                        <span class="d-flex">Load port: <span
                                                                                class="boldlabel">
                                                                                {{e?.loadPortName|titlecase}}</span>
                                                                        </span>
                                                                        <span class="d-flex">Discharge port: <span
                                                                                class="boldlabel">
                                                                                {{e?.dischargePortName|titlecase}}</span>
                                                                        </span>
                                                                        <span class="d-flex">Vessel Status: <span
                                                                                class="boldlabel">
                                                                                {{e?.vesselStatus}}</span> </span>
                                                                        <span class="d-flex">Latest ETA: <span
                                                                                class="boldlabel"> {{e?.vesselStatus !==
                                                                                'Shipment complete' ?(e?.latestETA |date:'d MMM
                                                                                y,a':'UTC'):'-' }}</span> </span>
                                                                    </div>
    
                                                                    <div class="ColuminnerInfo ChangeETAHld">
                                                                        <span class="TitleEta">ETA CHANGE</span>
                                                                        <div class="innerchangeETA">
                                                                            <span class="spcebtm">
                                                                                <span class="txt-inside">vs vessel
                                                                                    nomination</span>
                                                                                <app-day-delay
                                                                                    [val]="e?.totalDelayInDays">
                                                                                </app-day-delay> 
                                                                            </span>
                                                                            <span class="spcebtm">
                                                                                <span class="txt-inside">vs last updated
                                                                                    ETA </span>
                                                                                <app-day-delay
                                                                                    [val]="e?.delayDaysInLast24Hrs">
                                                                                </app-day-delay> 
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="ColuminnerInfo">
                                                                        <div class="CargosInfo">
                                                                            <span>CARGOES</span>
                                                                            <span>{{(e?.vesselStatus == enroute ||
                                                                                e?.vesselStatus == loading) ? 'QTY
                                                                                (CP)':'QTY (BL)'}}</span>
                                                                        </div>
                                                                        <div class="InnerTxtCargo"
                                                                            *ngFor="let it of e.arCargoes|sort:'asc':'name'">
                                                                            <span
                                                                                class="CargoName">{{it.name|titlecase|limitTo:
                                                                                16}}</span>
                                                                            <span class="CargoName"><span
                                                                                    class="CargoName">{{(e?.vesselStatus
                                                                                    == enroute || e?.vesselStatus ==
                                                                                    loading) ? (it.cpQTY|
                                                                                    number:'1.0-3':'en-US') :(it.blQty|
                                                                                    number:'1.0-3':'en-US')}}</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-scrollbar>
    
                                                                <!-- MObile Code Column Start Here-->
                                                                <div class="scrollbar-holder  LargeDeviceshide flex-column">
                                                                    <span class="notifymessage"
                                                                        *ngIf="el.arVessel.length > 1">This booking has
                                                                        multiple load & discharge ports. View booking
                                                                        details for full info</span>
                                                                    <div class="ColuminnerInfo NameDataPrt">
                                                                        <span class="d-flex">Load port: <span
                                                                                class="boldlabel">
                                                                                {{e?.loadPortName|titlecase}}</span>
                                                                        </span>
                                                                        <span class="d-flex">Discharge port: <span
                                                                                class="boldlabel">
                                                                                {{e?.dischargePortName|titlecase}}</span>
                                                                        </span>
                                                                        <span class="d-flex">Vessel Status: <span
                                                                                class="boldlabel">
                                                                                {{e?.vesselStatus}}</span> </span>
                                                                        <span class="d-flex">Latest ETA: <span
                                                                                class="boldlabel"> {{e?.vesselStatus !==
                                                                                'Shipment complete' ?(e?.latestETA |date:'d MMM
                                                                                y,a':'UTC'):'-' }}</span> </span>
                                                                    </div>
    
                                                                    <div class="ColuminnerInfo ChangeETAHld">
                                                                        <span class="TitleEta">ETA CHANGE</span>
                                                                        <div class="innerchangeETA">
                                                                            <span class="spcebtm">
                                                                                <span class="txt-inside">vs vessel
                                                                                    nomination</span>
                                                                                <app-day-delay
                                                                                    [val]="e?.totalDelayInDays">
                                                                                </app-day-delay> 
                                                                            </span>
                                                                            <span class="spcebtm">
                                                                                <span class="txt-inside">vs last updated
                                                                                    ETA </span>
                                                                                <app-day-delay
                                                                                    [val]="e?.delayDaysInLast24Hrs">
                                                                                </app-day-delay> 
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="ColuminnerInfo">
                                                                        <div class="CargosInfo">
                                                                            <span>CARGOES</span>
                                                                            <span>{{(e?.vesselStatus == enroute ||
                                                                                e?.vesselStatus == loading) ? 'QTY
                                                                                (CP)':'QTY (BL)'}}</span>
                                                                        </div>
                                                                        <div class="InnerTxtCargo"
                                                                            *ngFor="let it of e.arCargoes|sort:'asc':'name'">
                                                                            <span
                                                                                class="CargoName">{{it.name|titlecase|limitTo:
                                                                                16}}</span>
                                                                            <span class="CargoName"><span
                                                                                    class="CargoName">{{(e?.vesselStatus
                                                                                    == enroute || e?.vesselStatus ==
                                                                                    loading) ? (it.cpQTY|
                                                                                    number:'1.0-3':'en-US') :(it.blQty|
                                                                                    number:'1.0-3':'en-US')}}</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- MObile Code Column End Here --> 
                                                        </div>
                                                        <div class="ColumBodyinner ColumBodyinnerRight">
                                                            <ng-scrollbar class="maps-ngcrollbar M_CommonHidden">
                                                                <div class="scrollbar-holder">
                                                                    <span class="LogTxt">ETA log</span>
                                                                    <div class="SectionInfoBdr"
                                                                        *ngFor="let item of e.delayReason; let first = first ;let last = last; let index = index">
                                                                        <span class="DateTxt"> {{item.LatestETA |date:'d
                                                                            MMM
                                                                            y, a':'UTC'}} <em
                                                                                *ngIf="last">Original</em></span>
                                                                        <small>Updated
                                                                            {{item.snapShotDate|timeAgo}}</small>
                                                                            <span class="DiscTxt">
                                                                                <span *ngIf="!last && item.reason!='TBD'">
                                                                                <ng-container
                                                                                    *ngFor="let it of item.reason; let last =last; let first = first">
                                                                                    {{it}}{{!last ?', ':''}}
                                                                                </ng-container>
                                                                                </span>
                                                                                <span class="d-flex" *ngIf="!last && item.reason=='TBD'">
                                                                                    <div class="tbd__txt"  (mouseover)="OnMouseOver(index)"
                                                                                    (mouseleave)="OnMouseleave(index)"> 
                                                                                    <ng-container
                                                                                        *ngFor="let it of item.reason; let last =last; let first = first; let i = index">
                                                                                        {{it}}{{!last ?', ':''}}
                                                                                    </ng-container>
                                                                                </div>
                                                                            </span>
                                                                                <div *ngIf="TBD_Flag && bookingIndex == index"
                                                                                        class="TootipDropDiv CustomScrollBarCls left__dir agmmap__tp">
                                                                                        <div class="AfetrBeforeAr">
                                                                                            <div class="BodyToot">
                                                                                                <span>Please contact your local Stolt representative for further information.</span>
                                                                                            </div>
                                                                                        </div>
                                                                                </div>
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </ng-scrollbar>
    
                                                                <!-- MObile Code Column Start Here-->
                                                                <div class="scrollbar-holder LargeDeviceshide flex-column">
                                                                    <span class="LogTxt mt-0">ETA log</span>
                                                                    <div class="SectionInfoBdr"
                                                                        *ngFor="let item of e.delayReason; let first = first ;let last = last; let index = index">
                                                                        <span class="DateTxt"> {{item.LatestETA |date:'d
                                                                            MMM
                                                                            y, a':'UTC'}} <em
                                                                                *ngIf="last">Original</em></span>
                                                                        <small>Updated
                                                                            {{item.snapShotDate|timeAgo}}</small>
                                                                            <span class="DiscTxt" *ngIf="!last && item.reason!='TBD'">
                                                                                <ng-container
                                                                                    *ngFor="let it of item.reason; let last =last; let first = first,let i =index">{{it}}{{!last ?', ':''}}
                                                                                </ng-container>
                                                                            </span>
                                                                            <span class="d-flex" *ngIf="!last && item.reason=='TBD'">
                                                                            <div class="tbd__txt"  (mouseover)="OnMouseOver(index)" (mouseleave)="OnMouseleave(index)"> 
                                                                                <ng-container
                                                                                    *ngFor="let it of item.reason; let last =last; let first = first; let i = index">
                                                                                    {{it}}{{!last ?', ':''}}
                                                                                </ng-container>
                                                                                <div *ngIf="TBD_Flag && bookingIndex == index"
                                                                                    class="TootipDropDiv CustomScrollBarCls left__dir agmmap__tp mt__mobile">
                                                                                    <div class="AfetrBeforeAr">
                                                                                        <div class="BodyToot">
                                                                                            <span>Please contact your local Stolt representative for further information.</span>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                            </div>
                                                                            
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- MObile Code Column End Here -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </map-info-window>
                        </map-marker>
                    </ng-container>
                </ng-container>
            </google-map>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'fleet'">
        <google-map class="g-mapHeight Mpfleetheight" width="100%" [options]="options">
            <map-marker
            [position]="sItem?.position" [options]="{icon :locIcon}"></map-marker>
        </google-map>
    </ng-container>

    <ng-container *ngSwitchCase="'snits'">
        <google-map class="g-mapHeight Mpfleetheight" width="100%" [options]="options">
            <map-marker
            [position]="snitsItem?.position" [options]="{icon :locIcon}"></map-marker>
        </google-map>
    </ng-container>

    <ng-container *ngSwitchCase="'fleetmultiple'">
        <google-map height="500px" width="100%" [options]="options">
            <map-marker *ngFor="let marker of fleetArray"
              [position]="{lat:marker.lat,lng:marker.lng}" [options]="{icon :marker.icon}"></map-marker>
        </google-map>
    </ng-container>

    <ng-container *ngSwitchCase="'snitsfleetmultiple'">
        <google-map class="snitsfleetmultiple-map" width="100%" [options]="options">
            <map-marker *ngFor="let marker of snitsfleetArray"
              [position]="{lat:marker.lat,lng:marker.lng}" [options]="{icon :marker.icon}"></map-marker>
        </google-map>
    </ng-container>

    <ng-container *ngSwitchCase="'bookingDetail'">
        <!--Loader Map-->
        <div class="MapLoaderHld" *ngIf="!isDisplayMap else disPlayMap">
             <div class="MapLoaderHldInner"></div>
             <span>Loading...</span>
        </div>
        <!--Loader Map End-->   
    <ng-template #disPlayMap>
        <google-map #googleMap class="g-mapHeight mapinfoHld" width="100%" [options]="options" (idle)="initClusterer($event)">
             
            <map-marker-clusterer   [imagePath]="markerClustererImagePath"> 
                <ng-container *ngFor='let v of vessalArray'>
                    <ng-container *ngFor='let p of v.arPorts;let first = first; let last = last ; let i = index'>
                        <map-marker  #marker="mapMarker" *ngIf="p.portName && !p.isPreviousPort" [position]="{lat:p.lat,lng:p.long}"  [options]="((p.isLoading || p.isDischarging)? {icon :activityPort}: {icon :passingPort})" (mapClick)="openInfoWindow(marker,infoWindow)">
                        <map-info-window #infoWindow="mapInfoWindow">{{p?.portName|titlecase}}({{p?.country|titlecase}})<svg-icon *ngIf="p && p.isLoading" class="Pills-loard" name="Pills-loard"></svg-icon><svg-icon *ngIf="p && p.isDischarging" name="Pills-discharge"></svg-icon>
                        </map-info-window>
                        </map-marker>
                    
                    </ng-container>
                </ng-container>
               </map-marker-clusterer> 

            <ng-container *ngFor='let locat of arVesselsLocation;let first = first; let last = last;index as i'>
                <ng-container *ngFor='let h of locat.history;let f = first; let l = last;index as j'>
                    <map-marker #marker="mapMarker" *ngIf="l" [position]="{lat:h.lat,lng:h.lng}" [options]="{icon :vessalLocationIcon}" (mapClick)="openInfoWindow(marker,infoWindow)">
                        <map-info-window #infoWindow="mapInfoWindow" class="brandS-label"> <svg-icon name="brand-s"></svg-icon>{{vsl_name|titlecase}}</map-info-window>
                 </map-marker>
                </ng-container>
            </ng-container>
       
         <!-- sea Matrix track route -->
            <ng-container *ngFor='let wp of waypointsArraySeaMatrix'>
                <map-polygon [options]="{editable:false}">
                    <map-polyline [path]="wp" [options]="{strokeColor:'#2bade9',editable:false,icons:[{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px'}]}">
                    </map-polyline>
                </map-polygon>
            </ng-container>
         
         <ng-container  *ngFor='let pt of missingLocationHistorySeaMatrix'>
            <map-polygon [options]="{editable:false}">
                <map-polyline  [path]="pt.waypoints" [options]="{strokeColor:'#008ECF',editable:false}">
                </map-polyline>
            </map-polygon>
        </ng-container>

         <!-- vessal track route -->
            <ng-container *ngFor='let locat of arVesselsLocation'>
                <map-polygon [options]="{editable:false}" > 
                   <map-polyline [path]="locat.history" [options]="{strokeColor:'#008ECF',editable:false}">
                   </map-polyline> 
              </map-polygon>
           </ng-container>
         
      
      </google-map>
    </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'track'">
        <google-map height="500px" width="100%" [options]="options">
            <ng-container *ngFor='let locat of arVesselsLocation;let first = first; let last = last;index as i'>
                <ng-container *ngFor='let loc of locat.history;let f = first; let l = last;index as j'>
                    <map-marker *ngIf="l" [position]="{lat:loc?.lat,lng:loc?.lng}" [options]="{icon :locIconCurrent}"></map-marker>
                </ng-container>
            </ng-container>
        </google-map>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <google-map height="900px" width="100%" [options]="options">
            <map-marker  [position]="{lat:latitude,lng:longitude}" ></map-marker>
        </google-map>
    </ng-container>
</ng-container>