import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-show-announcement',
  templateUrl: './show-announcement.component.html',
  styleUrls: ['./show-announcement.component.scss']
})
export class ShowAnnouncementComponent {
  @Output() closePopupEmit = new EventEmitter(); 
  @Input() isPreview: any;
  @Input() editorData:any;
  @Input() title:any;
  @Input() userEmail:any;
  // isShowBtn: boolean;
  public safeHtmlContent: SafeHtml;
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
   /* For future use
  snlModalSettings = { showCloseIcon: false, backdropClick: false }
  allowedEmails = [
    'HNP@STOLT.COM', 
    'NSA@STOLT.COM', 
    'ALW@STOLT.COM', 
    'GAI@STOLT.COM', 
    'VTY@STOLT.COM', 
    'HML@STOLT.COM',
    'ACL@STOLT.COM',
    'IRF@STOLT.COM',
    'ANANDJ@WHIZ-SOLUTIONS.COM',
    'OHO@STOLT.COM',
    'ABT@STOLT.COM',
    'TAK@STOLT.COM',
    'YLM@STOLT.COM',
    'IMK@STOLT.COM',
    'HIK@STOLT.COM',
    'NISHA@WHIZ-SOLUTIONS.COM',
    'HIMANSHU@WHIZ-SOLUTIONS.COM'
  ];
  */
  constructor(private sanitizer: DomSanitizer, private navigationService: NavigationService){
  }

  ngOnChanges(){
    if(this.editorData){
      /* For future use
      if (this.allowedEmails.includes(this.userEmail)) {
        this.snlModalSettings = { showCloseIcon: true, backdropClick: false }
        this.isShowBtn = true;
      }
      */
      
       // NOSONAR - The URL is sanitized using DOMPurify, and trusted domains are validated
      this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.editorData); // NOSONAR
    }
  }

  closePopup(){
    localStorage.removeItem('show_announcement');
    this.removebodyClass();
    this.closedAnnouncementByUser();
    this.closePopupEmit.emit();
  }

  closedAnnouncementByUser(){
    this.navigationService.closedAnnouncementByUser().subscribe((res: any) => {
      console.log(res);
    })
  }

  removebodyClass()
  {
    let ele:any = document.querySelector("body");
    if(ele && ele.style){
      ele.style.overflow = 'auto';
    }
  }


}