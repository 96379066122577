import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { otpValidator } from 'src/app/helpers/multipleEmail';
import { NavigationService } from 'src/app/services/navigation.service';
import { OtpService } from 'src/app/services/otp.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-etb-otp-verification',
  templateUrl: './etb-otp-verification.component.html',
  styleUrls: ['./etb-otp-verification.component.scss']
})
export class EtbOtpVerificationComponent implements OnInit, OnDestroy {
  OtpVerificationForm: UntypedFormGroup;
  openModel: boolean = true
  timerSubscription!: Subscription;
  isLinkEnabledAfterMin: boolean;
  isrequestNewOtpLink: boolean;
  userEmail: any;
  attemptsCount: number = 5;
  attemptsCountLeft: number = 5;
  invalidOTP: boolean = false;
  maxAttemptsExceeded: boolean = false;
  snlModalSettings = { showCloseIcon: false, backdropClick: false }
  isthreeAttempts:boolean = false;
  isNewLink:boolean = false;
  getToken:any
  wrongOTPCount: number;
  SECRETKEY= environment.secretKeyOtp
  portAgentEmail:any=null;
  isLinkOpen = false;
  isLinkExpFor30min:boolean=false;
  isTokenExpire:boolean=false;
  skeletonPortAgent = true;
  isOtpSuccess: any;
  isActive:boolean = false;
  otpExpired:boolean = false;
  isShowSection = "";
  timeLeft: number = 5 * 60; // 5 minutes
  endTime: any;
  timeExpired: boolean = false;
  dtTimeToGenerateNewOtp: any;
  constructor(
    private fb: UntypedFormBuilder,
    private navigationService: NavigationService,
    public otpService: OtpService,
    private router:Router,
    private route: ActivatedRoute
  ) {
    this.navigationService.setShowNav(false);
    this.navigationService.setPageTitle('Port-agent');
  }
  ngOnInit(): void {
    this.route.params.subscribe({next:(paramsId:any) => {
      this.getToken = paramsId.id;
      this.refctorOtpVeificationWithToken(this.getToken)
    }, error:err => {
      this.navigationService.errorOccure(err);
      this.router.navigate(['/error']);
    }
  })    
  }

  refctorOtpVeificationWithToken(token:any){
    let tokenData = {token:token,isNewLink:this.isNewLink,isResendOtp: false}
    this.otpService.portagentOtpVeificationWithToken(tokenData).subscribe({next:(res:any) => {
      this.skeletonPortAgent = false;
      this.isLinkOpen = res.data.isLinkOpen;
      this.openModel = this.isLinkOpen ? false : true;
      if (res && res.data) {
        this.dtTimeToGenerateNewOtp = res.data.dtTimeToGenerateNewOtp
        if(!res.data.isSuccess){
          this.isLinkExpFor30min = res.data?.isLinkExpFor30min;
          this.isTokenExpire = res.data?.isTokenExpire;
          this.getModalState();
        }
        else{
          this.attemptsCount = res.data.attemptCount-1
          this.attemptsCountLeft = 5 - this.attemptsCount;
          this.portAgentEmail = res.data.portAgentEmail
          this.startTimer();
          this.createOtpForm()
          this.enabledRequestLinkAfterMin();
          this.getModalState();
        }
      }else{
        this.router.navigate(['/error']);
      }
    }, error:err => {
      this.skeletonPortAgent = false;
      this.navigationService.errorOccure(err);
      this.router.navigate(['/error']);
    }})
  }

  async updateTokenInfo(token:any){
    sessionStorage.setItem('isMainTab', 'true');
    const tokenData = { 
      token:token, 
      data: { isLinkOpen:true, sessionStartDateTime: new Date().toISOString() ,otp: null , dtTimeToGenerateNewOtp:null, portAgentEtbUpdateCount:1 } 
    };
    const requestBody = this.navigationService.objectToRequestBody(tokenData);
    await this.otpService.updateTokenInfo(requestBody);
  }

  createOtpForm(){
    this.OtpVerificationForm = this.fb.group({
      verifyotp: ['', [Validators.required, otpValidator()]],
    });
  }


  enabledRequestLinkAfterMin() {
    setTimeout(() => {
      this.isLinkEnabledAfterMin = true; // Enable request new OTP link after 1 minute
    }, 60000);
  }



  startTimer() {
    const utcISOStringCurrentTime = new Date(Date.now()).toISOString();
    const result = this.getDateTimeDifference(utcISOStringCurrentTime, this.dtTimeToGenerateNewOtp);
      let min = result.minutes;
      let sec = result.seconds;
      this.timeLeft =(min*60)+sec // Default to 5 minutes if no endTime is stored
      this.endTime = Date.now() + this.timeLeft * 1000; // Calculate the new endTime
    this.timeExpired = false;
    if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    this.timerSubscription = interval(1000).subscribe(() => {
        const currentTime = Date.now();
        this.timeLeft = Math.max(0, Math.floor((this.endTime - currentTime) / 1000));
        if (this.timeLeft === 0) {
            this.timeExpired = true;
            this.timerSubscription?.unsubscribe(); // Stop the timer when it reaches 0
            this.getModalState(); // Call your modal state logic
        }
    });
  }

  
  getDateTimeDifference(date1Str:any, date2Str:any) {
    // Create Date objects from the input strings
    let date1:any = new Date(date1Str);
    let date2:any = new Date(date2Str);
    // Get the difference in milliseconds
    const differenceInMs = Math.abs(date1 - date2);
    // Calculate difference in various units
    const seconds = Math.floor((differenceInMs / 1000) % 60);
    const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);
    const hours = Math.floor((differenceInMs / (1000 * 60 * 60)) % 24);
    const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    return {
        days,
        hours,
        minutes,
        seconds
    };
}


 

  // Convert timeLeft to minutes and seconds
  get formattedTime(): string {
    const minutes: number = Math.floor(this.timeLeft / 60);
    const seconds: number = this.timeLeft % 60;
    return `${minutes > 9 ? minutes : '0' + minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  }
  


  get f() {
    return this.OtpVerificationForm.controls;
  }


  resendOtpByLink() {
    let tokenData = {token:this.getToken,isNewLink:this.isNewLink,isResendOtp: true}
    this.otpService.portagentOtpVeificationWithToken(tokenData).subscribe({next:res => {
      if (res && res.data) {
        this.dtTimeToGenerateNewOtp = res.data.dtTimeToGenerateNewOtp;
        if(!res.data.isSuccess){
          this.isLinkExpFor30min = res.data?.isLinkExpFor30min;
          this.isTokenExpire = res.data?.isTokenExpire;
          this.attemptsCount = res.data.attemptCount-1;
          this.attemptsCountLeft = 5 - this.attemptsCount;
          this.getModalState();
        }else{
          this.attemptsCount = res.data.attemptCount - 1;
          this.attemptsCountLeft = 5 - this.attemptsCount;
          this.isShowSection = 'active';
          this.isNewLink = true;
          this.invalidOTP = false;
          this.isrequestNewOtpLink = true;
          this.isLinkEnabledAfterMin = false;
          this.isActive = true;
          this.enabledRequestLinkAfterMin();
          this.startTimer()
          this.OtpVerificationForm.reset(this.OtpVerificationForm.value);
        }
      }
  }, error:err => {
    this.navigationService.errorOccure(err);
  }
})
  }

  onSubmitOtp() {
    this.invalidOTP = false
    if (this.OtpVerificationForm.invalid) {
      return;
    } else {
      this.isNewLink = false
      let otp = this.OtpVerificationForm.value.verifyotp;
      const encryptedOtp = CryptoJS.AES.encrypt(otp, this.SECRETKEY).toString();     
      let tokenData = {token:this.getToken,OTP:encryptedOtp,isNewLink:this.isNewLink}
      this.otpService.verifyPortAgentOTP(tokenData).subscribe({next:res => {
        console.log(res);
        if(res && res.data){
          if(res.data?.isSuccess){
          this.updateTokenInfo(this.getToken);
          this.router.navigate(['/port-agent-etb-detail-update', this.getToken]);
        }else{
          this.isOtpSuccess = res.data?.isSuccess;
          this.invalidOTP = true
          this.wrongOTPCount = res.data?.wrongOTPCount
          this.getModalState();
        }
      }
    }, error:err => {
      this.navigationService.errorOccure(err);
    }})
    }
  }


  requestforNewOtp() {
      this.invalidOTP = false;
      this.isNewLink = false
      this.timeExpired = false;
      this.isLinkEnabledAfterMin = false
      this.enabledRequestLinkAfterMin()
      this.createOtpForm()
      let tokenData = { token: this.getToken, isNewLink: this.isNewLink, isResendOtp: true }
      this.otpService.portagentOtpVeificationWithToken(tokenData).subscribe({
        next: res => {
          if (res && res.data) {
            this.dtTimeToGenerateNewOtp = res.data.dtTimeToGenerateNewOtp;
            this.startTimer()
            this.attemptsCount = res.data.attemptCount -1
            this.attemptsCountLeft = 5 - this.attemptsCount;
            console.log(res);
            this.getModalState();
          }
        }, error: err => {
          this.navigationService.errorOccure(err);
        }
      })
      this.getModalState();
  }

  onKeyUp(eve: any) {
    this.isrequestNewOtpLink =  false
    this.invalidOTP= false
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }


onInput(event: Event): void {
  const input = event.target as HTMLInputElement;
  // Remove spaces and non-numeric characters
  const sanitizedValue = input.value.replace(/\s+/g, '').replace(/\D/g, '');
  // Limit input to 6 characters
  const trimmedValue = sanitizedValue.slice(0, 6);
  // Update the input field and form control
  input.value = trimmedValue;
  this.OtpVerificationForm.get('verifyotp')?.setValue(trimmedValue, { emitEvent: false });
}


  getModalState() {
    if (this.timeExpired && this.attemptsCount > 5) {
      this.isShowSection = 'otpExpiredAndMaxAttempts';
    } else if (this.timeExpired) {
      this.isActive = false;
      this.otpExpired = true;
      this.isShowSection = 'otpExpired';
    } else if ((!this.timeExpired && this.attemptsCount > 5) || this.isLinkExpFor30min) {
      this.isShowSection = 'isLinkExpFor30min'
      this.isActive = false;
    } else if (this.wrongOTPCount >= 3 || this.attemptsCount > 5) {
      this.isActive = false;
      this.isShowSection = 'maxAttemptsReached';
    } else if (this.isTokenExpire) {
      this.isActive = false;
      this.isShowSection = 'isTokenExpire';
    } else {
      this.isActive = true
      this.isShowSection = 'active';
    }
  }
}
