import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { PublicPagesService } from './public-pages.service';
import { ToastService } from 'snl-complib';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  isSurveyMonkey:boolean;
  private showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mobileNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private islogedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public TableHeightOnSideBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isNavigationCollapse$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  baseUrl_auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;
  public  userAccessedmodule: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  constructor(public toastr: ToastrService,
    private http: HttpClient,
    private authService: MsalService,
    private titleService: Title,
    private publicPagesService: PublicPagesService,
    private toastService: ToastService) { }


  setUpdateUserAccessedmodule(apidoc: any) {
    this.userAccessedmodule.next(apidoc);
  }

  getUpdateUserAccessedmodule() {
    return this.userAccessedmodule.asObservable();
  }
  
  setPageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  getShowNav() {
    return this.showNav$.asObservable();
  }

  setShowNav(showHide: boolean) {
    this.showNav$.next(showHide);
  }

  toggleNavState() {
    this.showNav$.next(!this.showNav$.value);
  }

  getTableHeightOnSideBar(toggle:any){
    this.TableHeightOnSideBar.next(toggle)
  }

  isNavOpen() {
    return this.showNav$.value;
  }

  setMobileNav(showHide: boolean) {
    this.mobileNav$.next(showHide);
  }
  isMobileNavOpen() {
    return this.mobileNav$.asObservable();
  }

  isLoginSet(val: boolean) {
    this.islogedIn$.next(val);
  }
  isLoginGet() {
    return this.islogedIn$.asObservable();
  }
  isLoginGetByValue() {
    return this.islogedIn$.getValue();
  }

  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }

  isNavigationCollapseSet(val:boolean){
    this.isNavigationCollapse$.next(val);
  }

  isNavigationCollapseGet() {
    return this.isNavigationCollapse$.asObservable();
  }

  message: any
  errorOccure(err: any, customClass = '') {
    const code = err.code ? err.code : err.error.status;
    let errorss = err && err.error && err.error.error
    const msg = errorss && err.error.error.description ? err.error.error.description : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`;
    this.refactorError403(err)
    const header = errorss && err.error.error.header ? err.error.error.header : err.statusText;
    switch (code) {
      case 400:
      case 403:
        this.showError(this.message, header, customClass);
        return
      case 406:
      case 409:
      case 422:
      case 309:
        this.showError(msg, header, customClass);
        return;
      case 401:
        this.showError(`Unauthorized Access! You don't have valid access or token is missing.`, '');
        this.authService.logoutRedirect({
          onRedirectNavigate: () => {
            window.location.href = '/';
            return false;
          }
        });
        return;
      default:
        this.showError(msg, header);
        return;
    }
  }


  docDownloadError(err: any, customClass = '') {
    const code = err.code ? err.code : err.error.status;
    let errorss = err && err.error && err.error.error
    const msg = errorss && err.error.error.description ? err.error.error.description : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`;

    const msgdoc = err.error && err.error.description ? err.error.description : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`;
    const header = errorss && err.error.error.header ? err.error.error.header : err.statusText;
    switch (code) {
      case 400:
      case 403:
        this.showError(this.message, header, customClass);
        return
      case 406:
      case 409:  
        this.showError(msgdoc, header, customClass);
        return;
      case 422:
      case 309:
        this.showError(msg, header, customClass);
        return;
      case 401:
        this.showError(`Unauthorized Access! You don't have valid access or token is missing.`, '');
        this.authService.logoutRedirect({
          onRedirectNavigate: () => {
            window.location.href = '/';
            return false;
          }
        });
        return;
      default:
        this.showError(msg, header);
        return;
    }
    
  }

  refactorError403(err: any) {
    if (err.error.error.companyFleets) {
      this.getCompanyFleetError(err)
    } else if (err.error.error.emails) {
      this.getEmailError(err)
    }else if(err.error.error.data){
      this.getdataError(err)
    }else{
      this.message = err.error.error.description ? err.error.error.description : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`
    }
  }
  getdataError(err:any){
    let userObj: any
    let errorss = err && err.error && err.error.error
    userObj = errorss && err.error.error.data.length > 0 ? err.error.error.data : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`;
    let emailObj = userObj ? userObj.map((u: any) => u.email).join(', ') : '';
    let fleet = userObj ? userObj.map((u: any) => u.bookingNo_fleet).join(', ') : ''
    this.message = emailObj + ' already exists for '+fleet
  }
  getEmailError(err:any){
    let userObj: any
    let errorss = err && err.error && err.error.error
    userObj = errorss && err.error.error.emails.length > 0 ? err.error.error.emails : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`;
    let emailObj
    if (err.error.error.emails) {
      emailObj= userObj;
    }else{
      if(userObj){
        emailObj= userObj.join(', ');
      }else{
        emailObj = ''
      }
    }
    this.message = emailObj + ' already exists';
  }
  getCompanyFleetError(err:any)
  {
    let userObj: any
    let errorss = err && err.error && err.error.error
    userObj = errorss && err.error.error.companyFleets.length > 0 ? err.error.error.companyFleets : `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know`;
    let company = userObj ? userObj.map((u: any) => u.company).join(', ') : ''
    let fleet = userObj ? userObj.map((u: any) => u.fleet).join(', ') : ''
    this.message = company + ' with "' + fleet + '" Does not exists'
  }
 
  showError(msg: string, title: string, customClass = '') {
    const toastConfig: any = {
      type: "Error",
      message: msg,
      addTitle: false,
      addProgressBar: true,
      timeout: 3000,
      position: 'top-right',
      multiAlerts:true
    };
    this.toastService.open(toastConfig);
  }

  showErrorAddTitle(msg: string) {
    const toastConfig: any = {
      type: "Error",
      message: msg,
      addTitle: true,
      addProgressBar: true,
      timeout: 3000,
      position: 'top-right',
      multiAlerts:true
    };
    this.toastService.open(toastConfig);
  }

  showSuccess(msg: string, title: string, customClass = '') {
    const toastConfig: any = {
      type: "Success",
      message: msg,
      addTitle: false,
      addProgressBar: true,
      timeout: 3000,
      position: 'top-right',
      multiAlerts:true
    };
    this.toastService.open(toastConfig);
  }

  showSuccessAddTitle(msg: string, title: string, customClass = '') {
    const toastConfig: any = {
      type: "Success",
      message: msg,
      addTitle: true,
      addProgressBar: true,
      timeout: 3000,
      position: 'top-right',
      multiAlerts:true
    };
    this.toastService.open(toastConfig);
  }

  showSuccessDocuments(msg: string, title: string, customClass = '') {
    const toastConfig: any = {
      type: "Information",
      message: msg,
      addTitle: false,
      addProgressBar: true,
      timeout: 10000,
      position: 'top-right',
      multiAlerts: title == 'zip' ? true : false
    };
    this.toastService.open(toastConfig);
  }

  showInformation(msg: string, title: string, customClass = '') {
    const toastConfig: any = {
      type: "Information",
      message: msg,
      addTitle: true,
      addProgressBar: true,
      timeout: 3000,
      position: 'top-right',
      multiAlerts:false
    };
    this.toastService.open(toastConfig);
  }

  getProfile(url: string) {
    return this.http.get(url).pipe(map(data => data));
  }

  getUserInfo() {
    return this.http.get(`${this.baseUrl_auth}/api/getUserInfo`).pipe(map(data => data), retry(2));
  }

  saveUserSearchHistory(req: []) {
    return this.http.post(`${this.baseUrl_auth}/api/postUserSearchHistory`, req, this.getHeaderJson()).pipe(map(data => data));
  }

  getUserSearchHistory() {
    return this.http.get(`${this.baseUrl_auth}/api/getUserSearchHistory`).pipe(map(data => data));
  }

  updateSurveyOpenCount(bookingId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/updateSurveyOpenCount?id_fleet=${bookingId}`, this.getHeaderJson()).pipe(map(data => data), retry(1));
  }
  objectToRequestBody(obj: { [key: string]: any }): string {
    const params = new URLSearchParams();
  
    function appendValue(key: string, value: any) {
      if (value instanceof Date) {
        params.append(key, value.toISOString());
      } else if (value === null) {
        params.append(key, 'null');
      } else if (value === undefined) {
        params.append(key, 'undefined');
      } else {
        params.append(key, value.toString());
      }
    }
  
    function recursiveAppend(obj: { [key: string]: any }, prefix = '') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
            recursiveAppend(value, `${prefix}${key}.`);
          } else {
            appendValue(`${prefix}${key}`, value);
          }
        }
      }
    }
    
    recursiveAppend(obj);
    return params.toString();
  }

  closedAnnouncementByUser(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/closedAnnouncementByUser`, this.getHeaderJson()).pipe(map(data => data), retry(1));
  }

  getActiveAnnouncement(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/getActiveAnnouncement`, this.getHeaderJson()).pipe(map(data => data), retry(1));
  }

  getActiveSurveys(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/getActiveSurveys`, this.getHeaderJson()).pipe(map(data => data), retry(1));
  }

}
