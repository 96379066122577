import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { PublicPagesService } from './public-pages.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class GlobalSearchService {

  private globalSearchData = new BehaviorSubject({});
  searchDataCast = this.globalSearchData.asObservable();

  baseUrl = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;
  baseUrlSustainanility = environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME;
  baseUrlVessel = environment.API_BASE_URL + environment.VESSELTRACKING_SERVICE_BASE_NAME;
  authVessel = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;
  baseUrl_auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;

  constructor(private http: HttpClient, private publicPagesService: PublicPagesService) { }

  getHeader() {
    return this.publicPagesService.getHeader();
  }
  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }

  getGlobalSearchService(searchData: any) {
    this.globalSearchData.next(searchData);
  }

  getBookingGlobalSearchListing(reqBody:any){
    return  this.http.post(`${this.baseUrl}/api/getBookingGlobalSearchListing`,reqBody,this.getHeaderJson()).pipe(map(data =>  data));
  }

}
