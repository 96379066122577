
<!-- Header Section: This section represents the header of the webpage -->
<header>
    <a ng-reflect-router-link="/" href="/">
        <svg-icon name="brand-logo" class="logoDefoult"></svg-icon>
    </a>
</header>


<!-- Common Content Section -->
<section class="Wrapper404Main">
    <div class="Wrapper404 w-100 text-center">
        <h1 class="d-flex justify-content-center align-items-center fw-700">
            <svg-icon class="d-flex" name="WarningCircle"> </svg-icon>
            There seems to be a problem with this page
        </h1>
        <p class="font-16 fw-400 error-otp">Please refer to the latest email with the link to update berth arrivals/ departures and provide the details accordingly.</p>

        <div class="banner-404-hld LargeDeviceshide justify-content-center">
            <img src="assets/img/public/banner-404-m.jpg" alt="404 image">
        </div>
        <!-- Banner 404 -->
        <div class="banner-404-hld M_CommonHidden">
            <img class="w-100" src="assets/img/public/banner-404.jpg" alt="404 banner">
        </div> 
    </div>
</section>