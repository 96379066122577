import { Component } from '@angular/core';

@Component({
  selector: 'app-thank-you-form',
  templateUrl: './thank-you-form.component.html',
  styleUrls: ['./thank-you-form.component.scss']
})
export class ThankYouFormComponent {

}
