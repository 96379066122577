import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, Input, OnChanges, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationService } from 'src/app/services/navigation.service';
import { SettingService } from 'src/app/services/setting.service';
import { InProgressFilter } from 'src/app/enums/inProgressFilter';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';
import { StorageService } from 'src/app/services/storage.service';
import { ShareNotificationComponent } from '../share-notification/share-notification.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-notification-setup',
  templateUrl: './notification-setup.component.html',
  styleUrls: ['./notification-setup.component.scss'],
  providers: [DatePipe, { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class NotificationSetupComponent implements OnInit, OnChanges {
  @Output() emitClaseNoti: EventEmitter<any> = new EventEmitter();
  @Output() emitOverflowClass: EventEmitter<boolean> = new EventEmitter();
  @Input() shareCompData: any[];
  @Input() min: any;
  @Input() max: any;

  @ViewChild('filterSelectModelClass') filterSelectModelClass: ElementRef;
  
  @ViewChild('searchName') searchNameInput: any;
  PortDisplay = 'block'
  tradelaneDisplay = 'block'
  dischargeDisplay = 'block'
  chartererDisplay = 'block'
  portFilterData: any[] = [];
  tradelaneFilterData: any[] = [];
  etaVarianceFilterData: any[] = [];
  etaFilterValueTemp: any[] = [];

  isApplyFilterButton: boolean = true;
  dateRageFiltersFormLoadEta:any;
  dateRageFiltersFormLoadDischarge:any;
  customValidate:any={};
  reactiveValidate: object = {};  
  customValidateDischarge:any={};
  reactiveValidateDishcharge: object = {}; 
  minDate = new Date();
  minDateLoadPort = new Date();
  maxDate = new Date();
  todayDate = new Date();
  dateRangeLoadEta :any = {}
  dateRangeLoadDischarge:any ={}

  portDischargeData: any[] = []
  chartererFilterData: any[] = [];

  portFilterDataMani: any[] = [];
  tradelaneFilterDataMani: any[] = [];
  portDischargeDataMani: any[] = []
  chartererFilterDataMani: any[] = []
  portFilterValueTemp: any[] = [];
  tradelaneFilterValueTemp: any[] = [];
  dportFilterValueTemp: any[] = [];
  chartererFilterValueTemp: any[] = []

  dportFilterValue: any[] = [];
  portFilterValue: any[] = [];
  tradelaneFilterValue: any[] = [];
  hideAllPortSearch = true;
  hideAlltradelaneSearch = true;
  DisabledBtn: boolean = true
  notificationForm: UntypedFormGroup
  @Output() filterCallBack: EventEmitter<any> = new EventEmitter();
  @Output() filterEventCallBack: EventEmitter<any> = new EventEmitter();

  enableFilter: boolean = false;
  saveFilter: any;
  @Input() settingFilterData: any;
  @Input() editRowNotification: any;
  @Input() stoltEmail: any;
  @Input() timezoneList: any
  timezoneListMini: any = [];
  tmzoneValueList: any = [];
  isSelectDay: boolean = false;
  CheckAllBox = false
  isFrequency: boolean;
  notifyTime: Date = new Date()
  carIDvalue: any | null;
  CarHeader: any;
  hstep = 1;
  mstep = 30;
  sstep = 10;
  hour: any = new Date().getHours()
  minutes: any = new Date().getMinutes();
  second: any = new Date().getSeconds()
  pikerTime: any;
  meridians = [];
  timer: any;
  isSaving: boolean = true;
  isDisplayPicker: boolean;
  submitted: boolean = false;
  timeFormate: any;
  changedFormat: any;
  converTime: any;
  converTime_1: any;
  converTime_2: any;
  daysInvalid = false;
  selectedFilter: any = { name: '' }
  dynamicFilterData: any;
  isSetMobileFilterData = false;
  isSetMobileDayData = false;
  isSetMobileTimezoneData = false;
  @ViewChild('filterClass') filterClass: ElementRef;
  @ViewChild('filterModelClass') filterModelClass: ElementRef;
  @ViewChild('timezoneModelClass') timezoneModelClass: ElementRef;
  @ViewChild('timeSlotFirstModelClass') timeSlotFirstModelClass: ElementRef;
  @ViewChild('timeSlotSecondModelClass') timeSlotSecondModelClass: ElementRef;
  @ViewChild('timeSlotThirdModelClass') timeSlotThirdModelClass: ElementRef;
  sortDir = 1;//1= 'ASE' -1= DSC
  frequencyType = 'Immediate';
  @Input() defaulyTimezone: any
  defaultNotificationVal: any;
  changeFrequincy: string;
  changeTimeZone: any;
  isCreated: boolean;

  bottomSheet: any;
  isSubscribe = false

  showClass = 'show';
  @Input() shareType: any
  @Input() booking_fleet: any
  EmailStatus: any;
  @ViewChild('childComponent', { static: false }) childComponent: ShareNotificationComponent;
  stoltDomain: string | null;
  getShareEmails: any;
  daysCheckBooking = true;
  ccAddress:any=[]
  bccAddress:any
  isDUplicateEmail = false;
  isShowDateRange: boolean = false;
  userEmail: any;
  selfEmail:boolean =false;
  isDateFilterValid:boolean=true;
  distributionRemoveModel:boolean = true
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  showHideNotication: boolean = false;
  isFrequencyHideShow:boolean = false
  timeSlotType: number = 0;
  count: number = 0;
  timerSlotLength_1:number = 2
  timerSlotLength_2:number = 3
  activeSecondSlot:boolean = false
  activeThirdSlot:boolean = false
  notifyTime_second: Date = new Date()
  notifyTime_third: Date = new Date()
  timer_second: any;
  timer_third: any;
  timeFormate_second: any;
  timeFormate_third: any;
  changedFormat_second: any;
  changedFormat_third: any;
  isFormatPm: boolean = true;
  isFormateAm = false
  isFormatPm_second: boolean = true;
  isFormateAm_second = false
  isFormatPm_third: boolean = true;
  isFormateAm_third = false
  isNotValidate: boolean = false;
  isNotValidate_1:boolean = false
  isNotValidate_2:boolean = false
  daysCount: number;
  isTimeDuplicate: boolean = false;
  year = new Date().getFullYear();
  month = new Date().getMonth() + 1;
  day = new Date().getDate();
  getSplitHoursSecond: number=1;
  getSplitHoursThird: number=2;
  getSplitMinutesSecond: number=0;
  getSplitMinutesThird: number=0;
  getSplitHours: number=12;
  getSplitMinutes: number=0;
  removeSlotFirst: boolean = false;
  removeSlotSecond: boolean = false;
  notificationTimeString: string | null;
  notificationTimeString_1: string | null;
  notificationTimeString_2: string | null;
  constructor(private navigationService: NavigationService,private datePipe: DatePipe, private settingService: SettingService, private fb: UntypedFormBuilder, private renderer: Renderer2, private _storageService: StorageService) {
    this.subscribeBookingLevelChanges()
    this.navigationService.setShowNav(true);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Setting');
  }

  getProfile() {
    this.navigationService.getUserInfo().subscribe({next:(res: any) => {
      this.userEmail = res.data && res.data.email ? res.data.email : ''
    }, error:(err: any) => {
      this.navigationService.errorOccure(err);
    }});
  }


  ngOnInit() {
    if(this.shareType == 'setting' || this.shareType == 'bookingDetail'){
      this.isFrequencyHideShow = true
      this.showHideNotication = true
    }
    this.isShowDateRange = true;
    this.stoltDomain = localStorage.getItem("stoltDomain")
    this.reactiveValidationFun();
    this.reactiveValidationFunDischarge();
    this.dateReandSubscriber();
    this.dateReandSubscriberDischarge();
    this.minDate.setDate(this.todayDate.getDate());
    this.minDateLoadPort.setDate(this.todayDate.getDate() - 30);
    this.maxDate.setDate(this.todayDate.getDate() + 180);
    this.getETAVarianceValues(this.min,this.max);
    this.getTimezonelist()
    if (!this.editRowNotification) {
      this.notifyTime.setMinutes(0o0);
      this.notifyTime.setHours(12)
      this.createForm()
      if (this.shareType == 'booking' || this.shareType == 'bookingDetail') {
        this.callOnBookingLevel()
      }
    } else {
      this.updateForm()
      if (this.shareType == 'booking' || this.shareType == 'bookingDetail') {
        this.callOnBookingLevel()
      }
      this.getAppledFilterMobile();
    }
    this.applyDaysSelection()
  }

  calanderSubscriber:any;
  calanderSubscriberDischarge:any;
  dateReandSubscriber(){
    this.calanderSubscriber = this.dateRageFiltersFormLoadEta.valueChanges.subscribe((newVal: any) => { 
      this.dateRangeLoadEta = {
        fromDate:this.datePipe.transform(newVal.fromDate, 'YYYY-MM-dd'),
        toDate:this.datePipe.transform(newVal.toDate, 'YYYY-MM-dd')
      }
    })
  }

  dateReandSubscriberDischarge(){
    this.calanderSubscriberDischarge = this.dateRageFiltersFormLoadDischarge.valueChanges.subscribe((newVal: any) => { 
      this.dateRangeLoadDischarge = {
        fromDate:this.datePipe.transform(newVal.fromDate, 'YYYY-MM-dd'),
        toDate:this.datePipe.transform(newVal.toDate, 'YYYY-MM-dd')
      }
    })
  }

  reactiveValidationFun(){
    this.reactiveValidate = this.dateRageFilters();
    this.customValidate = {
    }
    this.dateRageFiltersFormLoadEta = this.fb.group(this.reactiveValidate, this.customValidate);
  }

  reactiveValidationFunDischarge(){
    this.reactiveValidateDishcharge = this.dateRageFilters();
    this.customValidateDischarge = {
    }
    this.dateRageFiltersFormLoadDischarge = this.fb.group(this.reactiveValidateDishcharge, this.customValidateDischarge);
  }

  dateRageFilters(){
    let fromDate = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-10);
    let toDate = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate());
    return {
        fromDate: new UntypedFormControl(fromDate, [Validators.required,]),
        toDate: new UntypedFormControl(toDate, [Validators.required])
    }
   }

  subscribeBookingLevelChanges() {
    this._storageService._shareEmailStatusObservable.subscribe(res => {
      this.EmailStatus = res
    })
  }

  EditFieldsOnBookingDetail() {
    this.notificationForm.addControl('bookingLevelUserEmail', this.fb.control(this.editRowNotification.bookingLevelUserEmail, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]));
    this.notificationForm.addControl('isBookingLevel', this.fb.control(true));
    this.notificationForm.addControl('bookingNo_fleet', this.fb.control(this.booking_fleet));
    this.notificationForm.addControl('unsubscribe', this.fb.control(false));
  }
  addFieldsOnBookingDetail() {

    this.notificationForm.addControl('bookingLevelUserEmail', this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]));
    this.notificationForm.addControl('isBookingLevel', this.fb.control(true));
    this.notificationForm.addControl('bookingNo_fleet', this.fb.control(this.booking_fleet));
    this.notificationForm.addControl('unsubscribe', this.fb.control(false));
  }

  callOnBookingLevel() {
    this.notificationForm.get('notificationTitle')?.clearValidators();
  }

  shareEmailEmit(event: any) {
    this.getShareEmails = event.toAddress
    this.ccAddress = event.ccAddress
    this.bccAddress = event.bccAddress
    this.selfEmail = event.selfemail ? true : false
  }

  selectFilter(selectedFilterName: any, event: Event) {
    this.isSetMobileFilterData = true
    this.selectedFilter.name = selectedFilterName;
    event.stopPropagation();
    this.renderer.addClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.filterSelectModelClass.nativeElement, 'ModelShow');
    this.emitOverflowClass.emit(true);
  }

  mobileFilterRemoveCall(isMobileFilter: boolean) {
    if (isMobileFilter) {
      this.isSetMobileFilterData = false
    }
    this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.removeClass(this.filterSelectModelClass.nativeElement, 'ModelShow');
    this.getAppledFilterMobile();
  }

  getTimezonelist() {
    this.settingService.getTimezonelist().subscribe((res: any) => {
      if (res.data) {
        let tmzoneList = res.data.map((it: any) => ({ timezone: it.country + ", " + it.utctime, value: it.country + ", " + it.utctime + " _" + it.timezones }))
        tmzoneList.sort((a: any, b: any) => {
          a = a['timezone'].toLowerCase();
          b = b['timezone'].toLowerCase();
          return a.localeCompare(b) * this.sortDir;
        })

        this.timezoneList = tmzoneList.map((it: any) => ({ timezone: it.timezone.trim(), value: it.value.trim() }))
        this.timezoneListMini = this.timezoneList;
      }
    },err=>{
      this.timezoneList = []
      this.timezoneListMini = []
    })
  }


  createForm() {
    this.isCreated = true
    this.daysInvalid = true;
    this.isSelectDay = true
    this.defaulyTimezone = this.defaulyTimezone ? this.defaulyTimezone : 'Monrovia, Reykjavik, (GMT+00:00)'
    this.defaultNotificationVal = 'Notification #'
    if (this.stoltEmail == '@STOLT' || this.stoltEmail == '@stolt') {
      this.changeFrequincy = 'custom';
      this.changeTimeZone = this.defaulyTimezone;
      this.frequencyType = 'custom';
    } else {
      this.changeFrequincy = 'immediate';
      this.changeTimeZone = this.defaulyTimezone;
      this.frequencyType = 'Immediate';
    }
    this.dateRageFiltersFormLoadEta.patchValue({
      fromDate: '',
      toDate:''
    });
    this.dateRageFiltersFormLoadDischarge.patchValue({
      fromDate: '',
      toDate:''
    });
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let isdefaultTIme = new Date(year + "-" + month + "-" + day + " " + 24 + ':' + '00')
    this.notificationForm = this.fb.group({
      notificationTitle: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      ChangeShipNom: [false],
      etaUpdates: [false],
      arrIdDelayFilter: [],
      loadPortDateRange: [],
      dischargePortDateRange: [],
      ShipArrivalLaycanPeriod: [false],
      loadPortNumber: [[]],
      tradelaneNumber: [[]],
      dischargePortNumber: [[]],
      chartererFilter: [[]],
      frequencyType: [this.changeFrequincy],
      timezone: [this.changeTimeZone],
      mon: [true],
      tue: [true],
      wed: [true],
      thu: [true],
      fri: [true],
      sat: [true],
      sun: [true],
      notificationTime: [isdefaultTIme],
      notificationTime_1: [''],
      notificationTime_2: [''],
      min:this.min,
      max:this.max,
      allShipmentETAs: [false],
      edit:false
    })

    this.notifyTime.setMinutes(0o0);
      this.notifyTime.setHours(12)
      this.notifyTime_second.setMinutes(0o0);
      this.notifyTime_second.setHours(1)
      this.notifyTime_third.setMinutes(0o0);
      this.notifyTime_third.setHours(2)

      let getTime = '24' + ':' + this.notifyTime.getMinutes() + ':' + this.second;
      let getTime_1 = '01' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      let getTime_2 = '02' + ':' + this.notifyTime_third.getMinutes() + ':' + this.second;
      this.timer = this.formatTime(getTime)
      this.timer_second = this.formatTime(getTime_1)
      this.timer_third = this.formatTime(getTime_2)
      this.timeFormate = this.timer.formate;
      this.timeFormate_second = this.timer_second.formate;
      this.timeFormate_third = this.timer_third.formate;
      this.changedFormat = this.timer.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.changedFormat_third = this.timer_third.formate;

    this.converTime = new Date(year + "-" + month + "-" + day + " " + 24 + ':' + '00')
    this.converTime_1 = new Date(year + "-" + month + "-" + day + " " + 1 + ':' + '00')
    this.converTime_2 = new Date(year + "-" + month + "-" + day + " " + 2 + ':' + '00')
    this.getPmAmformate()
  }

  updateForm() {
    this.getETAVarianceValues(this.editRowNotification.min,this.editRowNotification.max);
    this.isSubscribe = (this.editRowNotification && this.editRowNotification.unsubscribe) ? true : false;
    this.frequencyType = this.editRowNotification.frequencyType;
    this.isCreated = false;
    this.portFilterValueTemp = this.editRowNotification.loadPortNumber;
    this.tradelaneFilterValueTemp = this.editRowNotification.tradelaneNumber;
    this.etaFilterValueTemp = this.editRowNotification.arrIdDelayFilter ? this.editRowNotification.arrIdDelayFilter : [];
    this.dportFilterValueTemp = this.editRowNotification.dischargePortNumber;
    this.chartererFilterValueTemp = this.editRowNotification.charterer;
    this.changeFrequincy = this.frequencyType
    this.dateRageFiltersFormLoadEta.patchValue({
      fromDate: this.editRowNotification.loadPortDateRange?.fromDate,
      toDate:this.editRowNotification.loadPortDateRange?.toDate
    });
    this.dateRageFiltersFormLoadDischarge.patchValue({
      fromDate: this.editRowNotification.dischargePortDateRange?.fromDate,
      toDate:this.editRowNotification.dischargePortDateRange?.toDate
    });
    this.defaultNotificationVal = this.editRowNotification.notificationTitle
    this.isFrequency = this.editRowNotification.frequencyType == 'custom' ? true : false;
    this.notificationForm = this.fb.group({
      id: [this.editRowNotification.id],
      notificationTitle: [this.editRowNotification.notificationTitle, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      ChangeShipNom: [this.editRowNotification.ChangeShipNom],
      etaUpdates: [this.editRowNotification.etaUpdates],
      arrIdDelayFilter: [this.editRowNotification.arrIdDelayFilter],
      ShipArrivalLaycanPeriod: [this.editRowNotification.ShipArrivalLaycanPeriod],
      loadPortNumber: [this.editRowNotification.loadPortNumber],
      tradelaneNumber: [this.editRowNotification.tradelaneNumber],
      loadPortDateRange: this.editRowNotification.loadPortDateRange,
      dischargePortDateRange: this.editRowNotification.dischargePortDateRange,
      dischargePortNumber: [this.editRowNotification.dischargePortNumber],
      chartererFilter: [this.editRowNotification.charterer],
      frequencyType: [this.editRowNotification.frequencyType],
      timezone: [this.editRowNotification.timezone],
      
      mon: [this.editRowNotification.mon],
      tue: [this.editRowNotification.tue],
      wed: [this.editRowNotification.wed],
      thu: [this.editRowNotification.thu],
      fri: [this.editRowNotification.fri],
      sat: [this.editRowNotification.sat],
      sun: [this.editRowNotification.sun],
      notificationTime: [this.editRowNotification.localTime],
      notificationTime_1: [this.editRowNotification.localTime_1],
      notificationTime_2: [this.editRowNotification.localTime_2],
      min:this.editRowNotification.min,
      max:this.editRowNotification.max,
      allShipmentETAs: [this.editRowNotification.allShipmentETAs],
      edit:true
    })
    this.daysInvalid = true;


    this.notifyTime = new Date(this.editRowNotification.localTime)
    this.notifyTime_second = new Date(this.editRowNotification.localTime_1)
    this.notifyTime_third = new Date(this.editRowNotification.localTime_2)

    if(this.editRowNotification.localTime_1){
      this.activeSecondSlot = true
    }
    if(this.editRowNotification.localTime_2){
      this.activeThirdSlot = true
    }
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    this.converTime = this.editRowNotification.localTime ? this.editRowNotification.localTime : new Date(year + "-" + month + "-" + day + " " + 24 + ':' + '00')
    this.converTime_1 =this.editRowNotification.localTime_1 ? this.editRowNotification.localTime_1 : new Date(year + "-" + month + "-" + day + " " + 1 + ':' + '00')
    this.converTime_2 =this.editRowNotification.localTime_2 ? this.editRowNotification.localTime_2 : new Date(year + "-" + month + "-" + day + " " + 2 + ':' + '00')
   
    this.timer = this.getTimeHourMin(1)
    this.timer_second = this.getTimeHourMin(2)
    this.timer_third = this.getTimeHourMin(3)
    this.timeFormate = this.timer.formate
    this.timeFormate_second = this.timer_second.formate
    this.timeFormate_third = this.timer_third.formate
    this.SelectedDays()
    
    this.getPmAmformate()
  }


  SelectedDays() {
    let monday = this.notificationForm.value.mon;
    let tue = this.notificationForm.value.tue;
    let wed = this.notificationForm.value.wed;
    let thur = this.notificationForm.value.thu;
    let fri = this.notificationForm.value.fri;
    let sat = this.notificationForm.value.sat;
    let sun = this.notificationForm.value.sun;
    if ((monday || tue || wed || thur || fri || sat || sun)) {
      this.isSelectDay = true;
    } else {
      this.CheckAllBox = false
      this.isSelectDay = false;
    }
  }

  getPmAmformate() {
    if (this.timer.formate == 'PM') {
      this.isFormatPm = true
      this.isFormateAm = false
    } else {
      this.isFormatPm = false
      this.isFormateAm = true
    }
    if (this.timer_second.formate == 'PM') {
      this.isFormatPm_second = true
      this.isFormateAm_second = false
    } else {
      this.isFormatPm_second = false
      this.isFormateAm_second = true
    }

    if (this.timer_third.formate == 'PM') {
      this.isFormatPm_third = true
      this.isFormateAm_third = false
    } else {
      this.isFormatPm_third = false
      this.isFormateAm_third = true
    }
  }

  ngOnChanges() {
    this.timezoneListMini = this.timezoneList
    if (this.settingFilterData)
      this.getNotificationFilter()
  }


  getNotificationFilter() {
    this.saveFilter = this.settingFilterData.savedFilter;
    this.portFilterValueTemp = (this.saveFilter.loadPortNumber && this.saveFilter.loadPortNumber.length) ? this.saveFilter.loadPortNumber : [];
    this.dportFilterValueTemp = (this.saveFilter.dischargePortNumber && this.saveFilter.dischargePortNumber.length) ? this.saveFilter.dischargePortNumber : [];
    this.chartererFilterValueTemp = (this.saveFilter.charterer && this.saveFilter.charterer.length) ? this.saveFilter.charterer : [];
    this.etaFilterValueTemp = (this.saveFilter.arrIdDelayFilter === null || !this.saveFilter.arrIdDelayFilter) ? [] : this.saveFilter.arrIdDelayFilter;

    let loadPortfilterdata = this.settingFilterData.LoadPortFilter
    this.portFilterData = loadPortfilterdata.map((it: any) => ({ ...it, port: 'All' }))
    this.portFilterDataMani = this.portFilterData;

    let tradelanefilterdata = this.settingFilterData.tradelane
    this.tradelaneFilterData = tradelanefilterdata.map((it: any) => ({ "tradelaneName": it.name,'tradelaneNumber': it.lob, tradelane: 'All' }))
    this.tradelaneFilterDataMani = this.tradelaneFilterData;

    let dischargeFilter = this.settingFilterData.DischargePortsFilter
    this.portDischargeData = dischargeFilter.map((it: any) => ({ ...it, discharge: 'All' }))
    this.portDischargeDataMani = this.portDischargeData

    let chartererData = this.settingFilterData.ChartererFilter;
    this.chartererFilterData = chartererData.map((it: any) => ({ "chartererName": it.charterer, charterer: 'All' }))
    this.chartererFilterDataMani = this.chartererFilterData
  }

  loadPortNameMobileSelected: any=[];
  dischargeNameMobileSelected: any=[];
  charetererNameMobileSelected: any=[];
  tradelaneNameMobileSelected: any = [];
  delayMobileSelected: any = [];
  getAppledFilterMobile(){
     this.loadPortNameMobileSelected = this.portFilterDataMani.filter((it:any) =>this.portFilterValueTemp.some((ele:any) => ele == it.loadPortNumber)).map((obj:any) => obj.loadPortName.charAt(0).toUpperCase() + obj.loadPortName.slice(1).toLowerCase());
     this.dischargeNameMobileSelected = this.portDischargeDataMani.filter((it:any) =>this.dportFilterValueTemp.some((ele:any) => ele == it.dischargePortNumber)).map((obj:any) => obj.dischargePortName.charAt(0).toUpperCase() + obj.dischargePortName.slice(1).toLowerCase());
     this.charetererNameMobileSelected = this.chartererFilterDataMani.filter((it:any) =>this.chartererFilterValueTemp.some((ele:any) => ele == it.chartererName)).map((obj:any) => obj.chartererName.charAt(0).toUpperCase() + obj.chartererName.slice(1).toLowerCase());
     this.tradelaneNameMobileSelected = this.tradelaneFilterDataMani.filter((it:any) =>this.tradelaneFilterValueTemp.some((ele:any) => ele == it.tradelaneName)).map((obj:any) => obj.tradelaneName.charAt(0).toUpperCase() + obj.tradelaneName.slice(1).toLowerCase());
     this.delayMobileSelected = this.etaVarianceFilterData.filter((it:any) =>this.etaFilterValueTemp.some((ele:any) => ele == it.idfilter)).map((obj:any) => obj.displayname);
  }

  customSearch(type: string, event: Event) {
    switch (type) {
      case InProgressFilter.tradelane:
        let wordtradelane = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchtradelane(wordtradelane, event)
        return
      case InProgressFilter.port:
        let wordPort = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchPort(wordPort, event)
        return
      case InProgressFilter.dport:
        let wordDport = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchDport(wordDport)
        return
      case InProgressFilter.charterer:
        let wordCharterer = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchCharterer(wordCharterer)
        return

      case InProgressFilter.timezone:
        let timezoneName = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchTimezone(timezoneName)
        return

      default:
        let wordCharterer1 = (event.target as HTMLInputElement).value.trim();
        this.refactorSearchCharterer(wordCharterer1)
        return
    }
  }


  refactorSearchPort(wordPort: any, event: any) {
    this.hideAllPortSearch = (event.target as HTMLInputElement).value == "" ? true : false;
    if ((wordPort != undefined && wordPort != "undefined" && wordPort != null && wordPort != "null" && wordPort != "") || wordPort.length > 2) {
      this.PortDisplay = "none";
      this.portFilterData = this.portFilterDataMani.filter((it: any) => it.loadPortName.toLowerCase().includes(wordPort.toLowerCase()));
    } else {
      this.PortDisplay = "block";
      this.portFilterData = this.portFilterDataMani
    }
  }

  refactorSearchtradelane(wordPort: any, event: any) {
    this.hideAlltradelaneSearch = (event.target as HTMLInputElement).value == "" ? true : false;
    if ((wordPort != undefined && wordPort != "undefined" && wordPort != null && wordPort != "null" && wordPort != "") || wordPort.length > 2) {
      this.tradelaneDisplay = "none";
      this.tradelaneFilterData = this.tradelaneFilterDataMani.filter((it: any) => it.tradelaneName.toLowerCase().includes(wordPort.toLowerCase()));
    } else {
      this.tradelaneDisplay = "block";
      this.tradelaneFilterData = this.tradelaneFilterDataMani
    }
  }

  refactorSearchDport(wordDport: any) {
    if ((wordDport != undefined && wordDport != "undefined" && wordDport != null && wordDport != "null" && wordDport != "") || wordDport.length > 2) {
      this.dischargeDisplay = "none";
      this.portDischargeData = this.portDischargeDataMani.filter((it: any) => it.dischargePortName.toLowerCase().includes(wordDport.toLowerCase()));
    } else {
      this.dischargeDisplay = "block";
      this.portDischargeData = this.portDischargeDataMani
    }
  }

  refactorSearchCharterer(wordCharterer: any) {
    if ((wordCharterer != undefined && wordCharterer != "undefined" && wordCharterer != null && wordCharterer != "null" && wordCharterer != "") || wordCharterer.length > 2) {
      this.chartererDisplay = "none";
      this.chartererFilterData = this.chartererFilterDataMani.filter((it: any) => it.chartererName.toLowerCase().includes(wordCharterer.toLowerCase()));
    } else {
      this.chartererDisplay = "block";
      this.chartererFilterData = this.chartererFilterDataMani
    }
  }

  refactorSearchTimezone(timezone: any) {
    if ((timezone != undefined && timezone != "undefined" && timezone != null && timezone != "null" && timezone != "" || timezone.length > 2)) {
      this.timezoneList = this.timezoneListMini.filter((it: any) => it.timezone.toLowerCase().includes(timezone.toLowerCase()));
      if (!this.notificationForm.value.timezone) {
        this.notificationForm.get('timezone')?.patchValue(this.defaulyTimezone)
      } 
    } else {
      this.timezoneList = this.timezoneListMini;
      if (!this.notificationForm.value.timezone) {
        this.notificationForm.get('timezone')?.patchValue(this.defaulyTimezone)
      }
    }
  }

  keyUpevent(event:any)
  {
    if(!(event.target as HTMLInputElement).value){
      if (!this.notificationForm.value.timezone) {
        this.notificationForm.get('timezone')?.patchValue(this.defaulyTimezone)
      }
    }
  }

  discard(event?: any) {
    this.emitClaseNoti.emit({ event: false, type: 'close' });
  }



  get f() {
    return this.notificationForm.controls;
  }

  saveNotification() {
    this.submitted = true;
    if (this.notificationForm.invalid) {
      return;
    }
    if (this.frequencyType == 'Immediate') {
      this.patchCustomeForm()
    }
    if (this.shareType == 'booking' || this.shareType == 'bookingDetail') {
      this.getBookingLeveldata()
    }
    this.refactorNotification();
  }

  refactorNotification(){
    let formdata: any
    formdata = this.notificationForm.value;
    formdata['loadPortDateRange'] = this.dateRangeLoadEta;
    formdata['dischargePortDateRange'] = this.dateRangeLoadDischarge;
    formdata['arrIdDelayFilter'] = this.notificationForm.value.arrIdDelayFilter ? this.notificationForm.value.arrIdDelayFilter : [];
    formdata['tradelaneNumber'] = this.notificationForm.value.tradelaneNumber?.includes('All') ? [] : this.notificationForm.value.tradelaneNumber
    formdata['loadPortNumber'] = this.notificationForm.value.loadPortNumber?.includes('All') ? [] : this.notificationForm.value.loadPortNumber
    formdata['dischargePortNumber'] = this.notificationForm.value.dischargePortNumber?.includes('All') ? [] : this.notificationForm.value.dischargePortNumber
    formdata['chartererFilter'] = this.notificationForm.value.chartererFilter?.includes('All') ? [] : this.notificationForm.value.chartererFilter;
    formdata['min'] = this.min;
    formdata['max'] = this.max;
 
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      let isdefaultTIme = new Date(year + "-" + month + "-" + day + " " + 24 + ':' + '00')
      formdata['notificationTime'] =this.converTime ? this.converTime.toString():isdefaultTIme.toString();
      formdata['notificationTime_1'] =this.activeSecondSlot ? this.converTime_1.toString():null;
      formdata['notificationTime_2'] =this.activeThirdSlot ? this.converTime_2.toString():null;
      
    if (this.shareType == 'setting') {
      formdata['isBookingLevel'] = false
    }
      let trigeredEvent = {ChangeShipNom:this.notificationForm.value.ChangeShipNom,etaUpdates:this.notificationForm.value.etaUpdates,ShipArrivalLaycanPeriod:this.notificationForm.value.ShipArrivalLaycanPeriod,allShipmentETAs:this.notificationForm.value.allShipmentETAs} 
      if (this.shareType == 'booking' || this.shareType == 'bookingDetail') {
        this.childComponent.shareDetail(trigeredEvent)
        let bookId = this.shareCompData.map((it: any) => it.booking_fleet)
        formdata['bookingNo_fleet'] = this.booking_fleet ? this.booking_fleet  : bookId;
        this.saveGlobalNotification(formdata);
      }else{
        this.saveGlobalNotification(formdata);
      }
  }

  checkDupleicateEmail(event:any)
  {
    this.isDUplicateEmail = event;
  }

  saveGlobalNotification(formdata: any) {
    if(!this.isDUplicateEmail && (this.notificationForm.value.ChangeShipNom || this.notificationForm.value.etaUpdates || this.notificationForm.value.ShipArrivalLaycanPeriod)){
         this.isSaving = false;
         this.refactorSaveNotification(formdata)
    }
   
  }

  refactorSaveNotification(formdata:any)
  {
    this.settingService.saveNotification(formdata).subscribe({next:res => {
      this.isSaving = true
      let msgSuccess = 'Notification List has been saved successfully.';
      this.navigationService.showSuccess(`${msgSuccess}`, 'Success');
      this.emitClaseNoti.emit({ event: false, type: 'save' });
    }, error:err => {
      this.isSaving = true
      this.navigationService.errorOccure(err);
      this.emitClaseNoti.emit({ event: false, type: 'close' });
    }})
  }

  getBookingLeveldata() {
    let toAddressArray = (this.getShareEmails.trim().split(',').map((it: any) => it.trim()));
    let ccAddressArray = this.ccAddress ? (this.ccAddress.trim().split(',').map((it: any) => it.trim())) : [];
    let bccAddressArray = this.bccAddress ? (this.bccAddress.trim().split(',').map((it: any) => it.trim())) : [];
    let refactToAddress = [ ...new Set(toAddressArray)] 
    let refactCcAddress = [ ...new Set(ccAddressArray)] 
    let refactBccAddress = [ ...new Set(bccAddressArray)]
    let bookId = this.shareCompData.map((it: any) => it.booking_fleet)
    this.notificationForm.addControl('isBookingLevel', this.fb.control(true));
    this.notificationForm.addControl('bookingNo_fleet', this.fb.control(bookId));
    this.notificationForm.addControl('unsubscribe', this.fb.control(false));
    this.notificationForm.addControl('bookingLevelUserEmail', this.fb.control(''));
    this.notificationForm.addControl('cc_email', this.fb.control(''));
    this.notificationForm.addControl('bcc_email', this.fb.control(''));
    this.notificationForm.addControl('selfEmail', this.fb.control(false));
    this.notificationForm.get('selfEmail')?.patchValue(this.selfEmail)
    if(this.selfEmail)
      refactBccAddress.push(this.userEmail)
      
    this.notificationForm.get('bcc_email')?.patchValue(refactBccAddress)
    this.notificationForm.get('cc_email')?.patchValue(refactCcAddress)
    this.notificationForm.get('bookingLevelUserEmail')?.patchValue(refactToAddress)
  }



  SelectAllDays(event: any) {
    if ((event.target as HTMLInputElement).checked) {
      this.daysInvalid = true;
      this.isSelectDay = false;
      this.CheckAllBox = true
      this.daysCheckBooking = true
      this.notificationForm.patchValue({
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
        sun: true
      });
    } else {
      this.daysInvalid = false
      this.isSelectDay = false;
      this.CheckAllBox = false;
      this.daysCheckBooking = false
      this.notificationForm.patchValue({
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      });
    }
    this.applyDaysSelection()
  }

  singleSelectDay(event?: any, type?:string) {
    let monday = this.notificationForm.value.mon;
    let tue = this.notificationForm.value.tue;
    let wed = this.notificationForm.value.wed;
    let thur = this.notificationForm.value.thu;
    let fri = this.notificationForm.value.fri;
    let sat = this.notificationForm.value.sat;
    let sun = this.notificationForm.value.sun;
    if ((monday || tue || wed || thur || fri || sat || sun)) {
      this.isSelectDay = true;
      this.daysInvalid = true;
      this.daysCheckBooking = true
    } else {
      this.daysInvalid = false
      this.CheckAllBox = false
      this.isSelectDay = false;
      this.daysCheckBooking = false
    }

    if ((monday && tue && wed && thur && fri && sat && sun)) {
      this.CheckAllBox = true
      this.isSelectDay = false;
    }
    this.applyDaysSelection()
  }

  frequencyChange(event: any) {
    this.frequencyType = event
    if (event == 'Immediate') {
      this.daysCheckBooking = true;
      this.converTime = '';
      this.notificationForm.get('timezone')?.clearValidators(); // 5.Set Required Validator
      this.notificationForm.get('timezone')?.updateValueAndValidity();
      this.notificationForm.get('frequencyType')?.patchValue('immediate');
      if (this.editRowNotification && !this.editRowNotification.id) {
        this.daysInvalid = true;
      } else {
        this.daysInvalid = true
      }
    } else {
      this.notificationForm.get('frequencyType')?.patchValue('custom');
      this.daysInvalid = (this.editRowNotification && this.editRowNotification.frequencyType) == 'custom' ? true : false;
      this.notificationForm.get('timezone')?.patchValue(this.defaulyTimezone);
      this.menupulateDays()
      this.notificationForm.get('timezone')?.setValidators([Validators.required]); // 5.Set Required Validator
      this.notificationForm.get('timezone')?.updateValueAndValidity();
      if (this.editRowNotification && this.editRowNotification.frequencyType == "immediate") {
        this.resetTiemandFormate()
        this.resetForm()
      }
      if(this.isCreated){
        this.resetForm()
      }
    }
    this.isFrequency = event == 'custom' ? true : false;
  }

  resetForm() {
    this.daysInvalid = true;
    this.isSelectDay = true
    this.notificationForm.get('mon')?.patchValue(true)
    this.notificationForm.get('mon')?.patchValue(true)
    this.notificationForm.get('tue')?.patchValue(true)
    this.notificationForm.get('wed')?.patchValue(true)
    this.notificationForm.get('thu')?.patchValue(true)
    this.notificationForm.get('fri')?.patchValue(true)
    this.notificationForm.get('sat')?.patchValue(true)
    this.notificationForm.get('sun')?.patchValue(true)
  }

  patchCustomeForm() {
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let converTime = new Date(year + "-" + month + "-" + day + " " + 24 + ':' + '00')
    this.notificationForm.get('timezone')?.reset();
    this.notificationForm.get('notificationTime')?.patchValue(converTime);
    if(this.notificationForm.value.ChangeShipNom || this.notificationForm.value.etaUpdates || this.notificationForm.value.ShipArrivalLaycanPeriod || this.notificationForm.value.allShipmentETAs){
      this.notificationForm.get('mon')?.patchValue(false)
      this.notificationForm.get('mon')?.patchValue(false)
      this.notificationForm.get('tue')?.patchValue(false)
      this.notificationForm.get('wed')?.patchValue(false)
      this.notificationForm.get('thu')?.patchValue(false)
      this.notificationForm.get('fri')?.patchValue(false)
      this.notificationForm.get('sat')?.patchValue(false)
      this.notificationForm.get('sun')?.patchValue(false)
      this.isSelectDay = false
    }
    this.resetTiemandFormate()
  }
  resetTiemandFormate() {
    this.notifyTime = new Date()
    this.notifyTime.setMinutes(0o0)
    this.notifyTime.setHours(24)
    let getTime = this.notifyTime.getHours() + ':' + this.notifyTime.getMinutes() + ':' + this.notifyTime.getSeconds();
    this.timer = this.formatTime(getTime)
    this.timeFormate = this.timer.formate;
    this.changedFormat = this.timer.formate;
    this.getPmAmformate()
  }



  menupulateDays() {
    let monday = this.notificationForm.value.mon;
    let tue = this.notificationForm.value.tue;
    let wed = this.notificationForm.value.wed;
    let thur = this.notificationForm.value.thu;
    let fri = this.notificationForm.value.fri;
    let sat = this.notificationForm.value.sat;
    let sun = this.notificationForm.value.sun;
    if ((monday || tue || wed || thur || fri || sat || sun)) {
      this.daysInvalid = true
    } else {
      this.daysInvalid = false
    }
  }



  getTimeHourMin(type?: any) {
    if (type == 1) {
      this.pikerTime = this.notificationForm.value.notificationTime ? new Date(this.notificationForm.value.notificationTime) : new Date();
    } else if (type == 2) {
      this.pikerTime = this.notificationForm.value.notificationTime_1 ? new Date(this.notificationForm.value.notificationTime_1) : this.converTime_1;
    } else {
      this.pikerTime = this.notificationForm.value.notificationTime_2 ? new Date(this.notificationForm.value.notificationTime_2) : this.converTime_2;
    }

    let picMinutes = this.pikerTime.getMinutes()
    this.hour = this.pikerTime.getHours();
    this.minutes = picMinutes

    this.second = this.pikerTime.getSeconds();
    let getTime = this.hour + ':' + this.minutes + ':' + this.second;
    return this.formatTime(getTime);
  }

  formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    let minutes = (minute.toString().length == 1) ? 0 + minute : minute;
    const hour = +hourString % 24;
    let hours = ((hour % 12 || 12).toString().length == 1) ? '0' + (hour % 12 || 12) : (hour % 12 || 12)
    return { HourMin: hours + ":" + minutes, formate: (hour < 12 ? "AM" : "PM") }
  }

  onFormateChange(event: any, type: any) {
    if (event == 1) {
      this.changedFormat = type
      if (type == 'PM') {
        this.isFormatPm = true;
        this.isFormateAm = false;
      } else {
        this.isFormatPm = false;
        this.isFormateAm = true;
      }
    } else if (event == 2) {
      this.changedFormat_second = type
      if (type == 'PM') {
        this.isFormatPm_second = true;
        this.isFormateAm_second = false;
      } else {
        this.isFormatPm_second = false;
        this.isFormateAm_second = true;
      }
    } else {
      this.changedFormat_third = type
      if (type == 'PM') {
        this.isFormatPm_third = true;
        this.isFormateAm_third = false;
      } else {
        this.isFormatPm_third = false;
        this.isFormateAm_third = true;
      }
    }
  }

  displayPicker(event: any) {
    this.isDisplayPicker = !this.isDisplayPicker ? true : false
  }


  mobileFilterData(filterType: string) {
    this.isSetMobileFilterData = true;
    switch (filterType) {
      case InProgressFilter.tradelane:
        this.dynamicFilterData.name = InProgressFilter.tradelane;
        this.tradelaneFilterValueTemp = this.tradelaneFilterValue;
        return;
      case InProgressFilter.port:
        this.dynamicFilterData.name = InProgressFilter.port;
        this.portFilterValueTemp = this.portFilterValue;
        return;

      case InProgressFilter.dport:
        this.dynamicFilterData.name = InProgressFilter.dport;
        this.dportFilterValueTemp = this.dportFilterValue;
        return;

      case InProgressFilter.charterer:
        this.dynamicFilterData.name = InProgressFilter.charterer;
        return;
      default:
        this.dynamicFilterData.name = InProgressFilter.charterer;
    }
  }

  mobileDayCall(event: Event) {
    event.stopPropagation();
    this.renderer.addClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.filterModelClass.nativeElement, 'ModelShow');
    this.emitOverflowClass.emit(true);
    this.bottomSheet.classList.add(this.showClass);
  }

  mobileTimezoneAddCall(event: Event) {
    event.stopPropagation();
    this.renderer.addClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.timezoneModelClass.nativeElement, 'ModelShow');
    this.emitOverflowClass.emit(true);
  }

  mobileDaysRemoveCall(isdayFilter: boolean) {
    if (isdayFilter) {
      this.isSetMobileDayData = false
    } else {
      this.emitOverflowClass.emit(false);
      this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
      this.renderer.removeClass(this.filterModelClass.nativeElement, 'ModelShow');
    }
  }

  mobileTimezoneRemoveCall(isTimezoneFilter: boolean) {
    if (isTimezoneFilter) {
      this.isSetMobileTimezoneData = false
    } else {
      this.emitOverflowClass.emit(false);
      this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
      this.renderer.removeClass(this.timezoneModelClass.nativeElement, 'ModelShow');
    }
  }

  removeFilter(event: Event) {
    this.isSetMobileDayData = false;
    this.isSetMobileTimezoneData = false;
    this.isSetMobileFilterData = false;
    this.mobileDaysRemoveCall(false);
    this.mobileTimezoneRemoveCall(false)
    this.mobileTimeSlotRemoveCall()
   this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
   this.renderer.removeClass(this.filterSelectModelClass.nativeElement, 'ModelShow');
    event.stopPropagation();
  }

  OncheckMoni(event: any) {
      if(this.notificationForm.get('allShipmentETAs')?.value || this.notificationForm.get('ChangeShipNom')?.value){
        this.notificationForm.get('etaUpdates')?.patchValue(true)
      }     
     else {
      this.notificationForm.get('etaUpdates')?.patchValue(false)
    }
  }

  onCheckedEvent(event: any) {
    
    if(this.frequencyType == 'Immediate' && (this.notificationForm.value.ChangeShipNom || this.notificationForm.value.etaUpdates || this.notificationForm.value.ShipArrivalLaycanPeriod || this.notificationForm.value.allShipmentETAs)){
      this.daysCheckBooking = true
    }else
    {
      if (this.notificationForm.value.ChangeShipNom || this.notificationForm.value.etaUpdates || this.notificationForm.value.ShipArrivalLaycanPeriod || this.notificationForm.value.allShipmentETAs) {
        let monday = this.notificationForm.value.mon;
        let tue = this.notificationForm.value.tue; 
        let wed = this.notificationForm.value.wed;
        let thur = this.notificationForm.value.thu;
        let fri = this.notificationForm.value.fri;
        let sat = this.notificationForm.value.sat;
        let sun = this.notificationForm.value.sun;
        if ((monday || tue || wed || thur || fri || sat || sun)) {
          this.daysCheckBooking = true;
        } else {
          this.daysCheckBooking = false;
        }
      } else {
        this.daysCheckBooking = true;
      }
    }
    this.showHidefrequencyOnEvent()
  }

  showHidefrequencyOnEvent(){
    if(this.shareType == 'booking' && (this.notificationForm.value.ChangeShipNom || this.notificationForm.value.etaUpdates || this.notificationForm.value.ShipArrivalLaycanPeriod || this.notificationForm.value.allShipmentETAs)){
      this.isFrequencyHideShow = true
    }else{
      this.isFrequencyHideShow = false
      if(this.shareType == 'setting' || this.shareType == 'bookingDetail'){
        this.isFrequencyHideShow = true
      }
    }
  }

  savingProcessStatus(event:any){
    this.isSaving = event
  }
  isDateFilterValidLoad:boolean=true;
  applyDateRangeLoadFilter(filterType: any){
    if(filterType === "loadPortEta" && this.dateRangeLoadEta['fromDate'] && !this.dateRangeLoadEta['toDate']){
      this.navigationService.showError('Please select end date', '');
      this.isDateFilterValidLoad = false;
    } else {
      this.isDateFilterValidLoad = true;
    }
  }

  applyDateRangeDischargeFilter(filterType: any){
    if(filterType === "dischargePortEta" && this.dateRangeLoadDischarge['fromDate'] && !this.dateRangeLoadDischarge['toDate']){
      this.isDateFilterValid = false;
      this.navigationService.showError('Please select end date', '');
    } else {
      this.isDateFilterValid = true;
    }
  }

  resetDateRangeFilter(filterType: any){
    if(filterType == "loadPortEta"){
      this.dateRageFiltersFormLoadEta.patchValue({
        fromDate: '',
        toDate:''
      });
    } else {
 
    this.dateRageFiltersFormLoadDischarge.patchValue({
      fromDate: '',
      toDate:''
    });
  }
  }

  getETAVarianceValues(min:any , max:any){
    this.etaVarianceFilterData = [{
        "idfilter": 1,
        "mindays": -365,
        "maxdays": min,
        "displayname": `<${min} ${min==1?"Day":"Days"}`
    },
    {
        "idfilter": 2,
        "mindays": min,
        "maxdays": max,
        "displayname": `${min}-${max} Days` // min.max
    },
    {
        "idfilter": 3,
        "mindays": max,
        "maxdays": 365,
        "displayname": `>${max} Days` // max
    }]
  }

  clearInput() {
    this.displayFilterCount()
    this.portFilterData = this.portFilterDataMani;
    this.tradelaneFilterData = this.tradelaneFilterDataMani;
    this.portDischargeData = this.portDischargeDataMani;
    this.chartererFilterData = this.chartererFilterDataMani;
    this.timezoneList = this.timezoneListMini;
    this.chartererFilterData = this.chartererFilterDataMani;
    if(this.searchNameInput){
      this.searchNameInput.nativeElement.value = '';
    }
  }

  displayFilterCount() {
    this.PortDisplay = 'block';
    this.tradelaneDisplay = "block";
    this.dischargeDisplay = "block";
    this.chartererDisplay = "block";
  }

  getHideShowNoticationStatus(event:any){
    this.showHideNotication = event
    if(!this.showHideNotication){
      this.notificationForm.get('allShipmentETAs')?.patchValue(false)
      this.notificationForm.get('etaUpdates')?.patchValue(false)
      this.notificationForm.get('ChangeShipNom')?.patchValue(false)
      this.notificationForm.get('ShipArrivalLaycanPeriod')?.patchValue(false)
    }
  }

  mobileTimeSlotAddCall(event:any,type:any){
    event.stopPropagation();
    this.timeSlotType = type
    this.renderer.addClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.timeSlotFirstModelClass.nativeElement, 'ModelShow');
    this.emitOverflowClass.emit(true);
  }

  mobileTimeSlotRemoveCall() {
      this.emitOverflowClass.emit(false);
      this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
      this.renderer.removeClass(this.timeSlotFirstModelClass.nativeElement, 'ModelShow');
    
  }

  addMoreSlot(){
    this.notificationForm.get('notificationTime')?.patchValue(this.converTime.toString());

    if(this.editRowNotification && this.activeSecondSlot){
      this.count = 1
    }
    this.count++
    if(this.count ==1){
      this.refectorAddMoreFirstSlot()
    }
    if(this.count ==2){
      this.refectorAddMoreSecondSlot()
    }
    if(this.count >=2){
      this.count =0
    }

  this.isTimeDuplicate = (this.isNotValidate || this.isNotValidate_1 || this.isNotValidate_2) ? true : false
  }

  refectorAddMoreFirstSlot(){
    console.log("slot1111:::")
    this.timerSlotLength_1 = 2
     this.activeSecondSlot = true;
     if(this.editRowNotification){

      let getTime_1 = '01' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      this.timer_second = this.formatTime(getTime_1)
      this.timeFormate_second = this.timer_second.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00') 
   }else{
    this.refactorFirstTimeSlot()
   }  
   this.ValidateDuplicateTime_2(2)
  }

  refactorFirstTimeSlot()
  {
    if(this.timer.HourMin == "01:00"){
      this.notifyTime_second.setMinutes(0o0);
      this.notifyTime_second.setHours(2)
      let getTime_1 = '02' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      this.timer_second = this.formatTime(getTime_1)
      this.timeFormate_second = this.timer_second.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
    }else{
      this.notifyTime_second.setMinutes(0o0);
      this.notifyTime_second.setHours(1)
      let getTime_1 = '01' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      this.timer_second = this.formatTime(getTime_1)
      this.timeFormate_second = this.timer_second.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00') 
    }
  }

  refectorAddMoreSecondSlot(){
    console.log("slot222:::")
    this.activeThirdSlot = true;
    this.timerSlotLength_2 = 3
    this.refactorTimeSlot()
    this.ValidateDuplicateTime_3(3)
  }


  refactorTimeSlot(){
    if(this.timer_second.HourMin == "01:00"){
      this.notifyTime_third = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
      let getTime_2 = '02' + ':' + 0 + ':' + 0;
      this.timer_third = this.formatTime(getTime_2)
      this.timeFormate_third = this.timer_third.formate;
      this.changedFormat_third = this.timer_third.formate;
      this.isFormatPm_third = false
      this.isFormateAm_third = true
      this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
      this.notificationForm.get("notificationTime_2")?.patchValue(this.converTime_2)
    }else{
      this.notifyTime_third = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00')
      let getTime_2 = '01' + ':' + 0 + ':' + 0;
      this.timer_third = this.formatTime(getTime_2)
      this.timeFormate_third = this.timer_third.formate;
      this.changedFormat_third = this.timer_third.formate;
      this.isFormatPm_third = false
      this.isFormateAm_third = true
      this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00')
      this.notificationForm.get("notificationTime_2")?.patchValue(this.converTime_2)
    }
  }


  applyTime(type?: any) {
    let jsonObj_1 = this.notificationForm.get('notificationTime')?.value
    let jsonObj_2 = this.notificationForm.get('notificationTime_1')?.value
    let jsonObj_3 = this.notificationForm.get('notificationTime_2')?.value
    this.isCreated = false;
    this.refactorSlotType(type)
    this.refactorTimeFormateChange(type)
     if (this.timeFormate == 'PM'){
      this.changeTimeFormatePmFirst(type,jsonObj_1)
     }else{
      this.changeTimeFormateAmFirst(type)
    }
     if(this.timeFormate_second == 'PM'){
      this.changeTimeFormatePmSecond(type,jsonObj_2)
    }else{
      this.changeTimeFormateAmSecond(type)
    }

    if(this.timeFormate_third == 'PM'){
      this.changeTimeFormatePmThird(type,jsonObj_3,jsonObj_2)
     
    }else{
      this.changeTimeFormateAmThird(type)
    }

    if(this.activeSecondSlot){
      this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_1)
    }
   
    this.mobileTimeSlotRemoveCall()
    this.notificationForm.get('notificationTime')?.patchValue(this.converTime)
    this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_1)
    this.notificationForm.get('notificationTime_2')?.patchValue(this.converTime_2)
    this.isTimeDuplicate = (this.isNotValidate || this.isNotValidate_1 || this.isNotValidate_2) ? true : false;

  }

  refactorTimeFormateChange(type:any){
    if (type == 1) {
      this.timer = this.getTimeHourMin(type)
      this.timeFormate = (!this.changedFormat) ? this.timer.formate : this.changedFormat;
    } else if (type == 2) {
      this.timer_second = this.getTimeHourMin(type)
      this.timeFormate_second = (!this.changedFormat_second) ? this.timer_second.formate : this.changedFormat_second;
    } else {
      this.timer_third = this.getTimeHourMin(type)
      this.timeFormate_third = (!this.changedFormat_third) ? this.timer_third.formate : this.changedFormat_third;
    }
  }

  refactorSlotType(type:any){
    let jsonObj_1 = this.notificationForm.get('notificationTime')?.value
    let jsonObj_2 = this.notificationForm.get('notificationTime_1')?.value
    let jsonObj_3 = this.notificationForm.get('notificationTime_2')?.value
    let controllerTime: any
    if (type == 1) {
      controllerTime =jsonObj_1
    } else if (type == 2) {
      controllerTime = jsonObj_2
    } else {
      controllerTime = jsonObj_3
    }
    let getMinuts = controllerTime.getMinutes()
    this.pikerTime = new Date()
    this.hour = this.pikerTime.getHours();
    this.minutes = getMinuts;
    this.second = this.pikerTime.getSeconds();
    this.isDisplayPicker = false;
  }



  changeTimeFormatePmFirst(type:any,jsonObj_1:any){
    if (type == 1) {
      let newDate = new Date(jsonObj_1)
      const convertTime12to24 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
      let TwentyFOur = convertTime12to24(this.pikerTime.getHours() + ':' + newDate.getMinutes() + ':' + this.pikerTime.getSeconds() + ' ' + 'PM')
      let split = TwentyFOur.split(":")
      this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + split[0] + ':' + split[1] + ':' + split[2])
      this.assignValueOnFormatPM(type)
    }
  }

  changeTimeFormateAmFirst(type:any){
    let splitHour
    if (type == 1) {
      splitHour = this.timer.HourMin.split(':');
      if (splitHour[0] == '12' && splitHour[1] == '00') {
        this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
      } else if (splitHour[0] == '12' && splitHour[1] == '30') {
        this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + '00' + ':' + 30)
      } else {
        this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + splitHour[0] + ':' + splitHour[1])
      }
    }
    this.assignValueOnFormatAM(type)
  }

  changeTimeFormatePmSecond(type:any,jsonObj_2:any){
    if (type == 2) {
      const convertTime12to24 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
      let TwentyFOur_1 = convertTime12to24(jsonObj_2.getHours() + ':' + jsonObj_2.getMinutes() + ':' + this.pikerTime.getSeconds() + ' ' + 'PM')
      let split = TwentyFOur_1.split(":")
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + split[0] + ':' + split[1] + ':' + split[2])
      this.assignValueOnFormatPM(type)
     }
  }

  changeTimeFormateAmSecond(type:any){
    if (type == 2) {
      let splitHour_1:any
      splitHour_1 = this.timer_second.HourMin.split(':');
      if (splitHour_1[0] == '12' && splitHour_1[1] == '00') {
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
      } else if (splitHour_1[0] == '12' && splitHour_1[1] == '30') {
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + '00' + ':' + 30)
      } else {
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + splitHour_1[0] + ':' + splitHour_1[1])
      }
      this.assignValueOnFormatAM(type)
    }
  }

  changeTimeFormatePmThird(type:any,jsonObj_3:any,jsonObj_2:any){
    if (type == 3) {
      if(jsonObj_2){
        if(this.timeFormate_third == 'PM' && this.timeFormate_second == 'PM'){
        let obj_2 = new Date(jsonObj_2)
        const convertTime12to24_1 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
        let TwentyFOur_1 = convertTime12to24_1(obj_2.getHours() + ':' + obj_2.getMinutes() + ':' + 0 + ' ' + 'PM')
        let split_1 = TwentyFOur_1.split(":")
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + split_1[0] + ':' + split_1[1] + ':' + split_1[2])
        }
      }
      const convertTime12to24 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
      let TwentyFOur_2 = convertTime12to24(this.pikerTime.getHours() + ':' + jsonObj_3.getMinutes() + ':' + this.pikerTime.getSeconds() + ' ' + 'PM')
      let split = TwentyFOur_2.split(":")
      this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + split[0] + ':' + split[1] + ':' + split[2])
      this.assignValueOnFormatPM(type)
      }
  }

  changeTimeFormateAmThird(type:any){
    if (type == 3) {
      let splitHour_2:any
      splitHour_2 = this.timer_third.HourMin.split(':');
      if (splitHour_2[0] == '12' && splitHour_2[1] == '00') {
        this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
      } else if (splitHour_2[0] == '12' && splitHour_2[1] == '30') {
        this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + '00' + ':' + 30)
      } else {
        this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + splitHour_2[0] + ':' + splitHour_2[1])
      }
      this.assignValueOnFormatAM(type)
    }
  }
  
  assignValueOnFormatPM(type: any) {
    let formdata = this.notificationForm.value;
    if (type == 1) {
      formdata['notificationTime'] = this.converTime ? this.converTime.toString() : '';
      this.notifyTime = new Date(formdata['notificationTime'])
      this.ValidateDuplicateTime_1(type)
     
    } else if (type == 2) {
      formdata['notificationTime_1'] = this.converTime_1 ? this.converTime_1.toString() : '';
      this.notifyTime_second = new Date(formdata['notificationTime_1'])
      this.ValidateDuplicateTime_2(type)
    } else {
      formdata['notificationTime_2'] = this.converTime_2 ? this.converTime_2.toString() : '';
      this.notifyTime_third = new Date(formdata['notificationTime_2'])
      this.ValidateDuplicateTime_3(type)
     
    }
  }

  assignValueOnFormatAM(type: any) {
    let formdata = this.notificationForm.value;
    if (type == 1) {
      formdata['notificationTime'] = this.converTime.toString();
      this.notifyTime = new Date(formdata['notificationTime'])
      this.ValidateDuplicateTime_1(type)
    } else if (type == 2) {
      formdata['notificationTime_1'] = this.converTime_1.toString();
      this.notifyTime_second = new Date(formdata['notificationTime_1'])
      this.ValidateDuplicateTime_2(type)
    } else {
      formdata['notificationTime_2'] = this.converTime_2.toString();
      this.notifyTime_third = new Date(formdata['notificationTime_2'])
      this.ValidateDuplicateTime_3(type)
    }
  }

  ValidateDuplicateTime_1(type:any){
   
    this.isNotValidate = false
    if(type ==1){
      this.extractTimeFromDate()
      if(this.notificationTimeString == this.notificationTimeString_1){
        this.isNotValidate = true
        this.isNotValidate_1 = false
      }else{
        this.isNotValidate_1 = false
      }

      if(this.activeThirdSlot){
        if(this.notificationTimeString == this.notificationTimeString_2){
          this.isNotValidate = true
          this.isNotValidate_2 = false
        }
      }

      this.refactorValidation_1()
    }
  }

  refactorValidation_1(){
    if(this.activeSecondSlot || this.activeThirdSlot)
      {
        if(this.notificationTimeString_1 == this.notificationTimeString_2){
          this.isNotValidate_1 = true
        }
      }
     
      if(this.isNotValidate_1 && this.isNotValidate_2){
        this.isNotValidate_1 = false
      }

      if(this.notificationTimeString == this.notificationTimeString_1 && this.notificationTimeString_1 == this.notificationTimeString_2 && this.notificationTimeString == this.notificationTimeString_2){
        this.isNotValidate = true
        this.isNotValidate_1 =false
        this.isNotValidate_2 =false
      }

      if (this.notificationTimeString !== this.notificationTimeString_1 && this.notificationTimeString !== this.notificationTimeString_2 && this.notificationTimeString_1 !== this.notificationTimeString_2) {
        this.isNotValidate_2 =false
      }
  }
  

  ValidateDuplicateTime_2(type:any){
    this.isNotValidate_1 = false
    if(type == 2){
      this.extractTimeFromDate()
      if(this.notificationTimeString_1 == this.notificationTimeString){
        this.isNotValidate_1 = true
      }

      if(this.activeThirdSlot){
      if(this.notificationTimeString_1 == this.notificationTimeString_2){
        this.isNotValidate_1 = true
      }
    }
    this.refactorValidation_2()
    }
  }

  refactorValidation_2(){
    
    if(this.notificationTimeString == this.notificationTimeString_2){
      this.isNotValidate_2 = true
    }
    
     if(this.isNotValidate){
      this.isNotValidate = false
    }
    if(this.isNotValidate_1 && this.isNotValidate_2){
      this.isNotValidate_2 = false
    }
  }
  

  ValidateDuplicateTime_3(type:any){
    this.isNotValidate_2 = false
    if(type == 3){
      this.extractTimeFromDate()
      if(this.notificationTimeString_2 == this.notificationTimeString){
        this.isNotValidate_2 = true
      }

      if(this.notificationTimeString_2 == this.notificationTimeString_1){
        this.isNotValidate_2 = true
        this.isNotValidate_1 = false
      }else{
        this.isNotValidate_1 = false
      }

      if(this.notificationTimeString == this.notificationTimeString_1){        
        this.isNotValidate_1 = true
      }
       this.refactorValidation_3()
    }
  }

  refactorValidation_3(){
    if(this.isNotValidate){
      this.isNotValidate = false
    }
    if(this.converTime.toString() == this.converTime_2.toString()){
      this.isNotValidate_2 = true
    }
    if(this.notificationTimeString == this.notificationTimeString_1 && this.notificationTimeString_1 == this.notificationTimeString_2 && this.notificationTimeString == this.notificationTimeString_2){
      this.isNotValidate = false
      this.isNotValidate_1 =false
      this.isNotValidate_2 =true
    }
  }

  extractTimeFromDate(){
    this.notificationForm.get('notificationTime')?.patchValue(this.converTime)
    if(this.activeSecondSlot){
      this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_1)
    }else{
      this.notificationForm.get('notificationTime_1')?.reset()
    }  

    if(this.activeThirdSlot){
      this.notificationForm.get('notificationTime_2')?.patchValue(this.converTime_2)
    }else{
      this.notificationForm.get('notificationTime_2')?.reset()
    }  


    let notificationValue = this.notificationForm.get('notificationTime')?.value
    let notificationValue_1 = this.notificationForm.get('notificationTime_1')?.value
    let notificationValue_2 = this.notificationForm.get('notificationTime_2')?.value
    let notificationdate =notificationValue ? new Date(notificationValue) : null;
    this.notificationTimeString =notificationdate ? notificationdate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // This will use a 24-hour format
}) : null
    

    let notificationdate_1 =notificationValue_1 ? new Date(notificationValue_1) : null;
    this.notificationTimeString_1 =notificationdate_1 ? notificationdate_1.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // This will use a 24-hour format
}) : null



    let notificationdate_2 =notificationValue_2 ? new Date(notificationValue_2) : null;
    this.notificationTimeString_2 =notificationdate_2 ? notificationdate_2.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // This will use a 24-hour format
}) : null
  }
  removeSlot(type:any){
    this.count = 0
    if(type == 1){
      this.removeSlotFirst = true
      this.refactotRemoveSlotFirst()
    }else if(type == 2){
      this.removeSlotSecond = true
      this.refactotRemoveSlotSecond()
    }else{
      console.log("####")
    }
    this.isTimeDuplicate = (this.isNotValidate || this.isNotValidate_1 || this.isNotValidate_2) ? true : false
 }

 refactotRemoveSlotFirst(){
  console.log("remove second ::::")
  this.timerSlotLength_2 = 2
  this.count =1
  if(!this.activeThirdSlot){
    this.activeSecondSlot = false
  }
  this.activeThirdSlot = false

  if(!this.activeSecondSlot && !this.activeThirdSlot){
    this.count = 0
  }
  this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_2)
  this.notificationForm.get("notificationTime_2")?.patchValue(this.converTime_1)

  this.notifyTime_second = this.notifyTime_third
  this.timer_second = this.timer_third
  this.timeFormate_second = this.timeFormate_third
  this.isFormatPm_second = this.isFormatPm_third
  this.isFormateAm_second = this.isFormateAm_third
  this.converTime_1 = this.converTime_2
  this.refactorTimeSlot()  
  if(!this.notificationForm.value.notificationTime_1 && !this.notificationForm.value.notificationTime_2){
    this.isNotValidate = false
  }

  if(!this.notificationForm.value.notificationTime_1 || !this.notificationForm.value.notificationTime_2){
    this.isNotValidate = false
  }
 
  if(this.isNotValidate_1){
    this.isNotValidate_1 = false
  }
  if(this.isNotValidate_2){
    this.isNotValidate_2 = false
  }
  if(this.isNotValidate){
    this.isNotValidate = false
  }
 }
refactorRemoveSlot_2(){
  if(!this.activeSecondSlot && this.activeThirdSlot){
    if(this.timer.HourMin == this.timer_third.HourMin && this.timeFormate == this.timeFormate_third){
      this.isNotValidate = true
    }else{
      this.isNotValidate = false
    }
  }
  this.ValidateDuplicateTime_2(2)
}

refactorRemoveSlot_1(){
  if(this.isNotValidate_1){
    this.isNotValidate_1 = false
  }
  if(this.isNotValidate_2){
    this.isNotValidate_2 = false
  }
  if(this.isNotValidate){
    this.isNotValidate = false
  }
  if(this.activeThirdSlot){
    if(this.timer.HourMin == this.timer_third.HourMin && this.timeFormate == this.timeFormate_third){
      this.isNotValidate = true
    }else{
      this.isNotValidate = false
    }
  }
}

refactotRemoveSlotSecond(){
  console.log("remove third ::::")
  this.count =1
  this.activeThirdSlot = false
  this.refactorTimeSlot()
  if(this.isNotValidate_2){
    this.isNotValidate_2 = false
  }
  if(this.isNotValidate_1){
    this.isNotValidate_1 = false
  }
  if(this.isNotValidate){
    this.isNotValidate = false
  }

  if(this.activeSecondSlot){
    if(this.timer.HourMin == this.timer_second.HourMin && this.timeFormate == this.timeFormate_second){
      this.isNotValidate = true
    }else{
      this.isNotValidate = false
    }
  }
}
  applyDaysSelection(){
    let monday = this.notificationForm.value.mon
    let tuesday = this.notificationForm.value.tue
    let wednesday = this.notificationForm.value.wed
    let thursday = this.notificationForm.value.thu
    let firday = this.notificationForm.value.fri
    let saturday = this.notificationForm.value.sat
    let sunday = this.notificationForm.value.sun
    let Arr = [monday,tuesday,wednesday,thursday,firday,saturday,sunday]
    this.daysCount = Arr.filter(Boolean).length
  }
}



