import { Component } from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
@Component({
  selector: 'app-four-zero-four',
  templateUrl: './four-zero-four.component.html',
  styleUrls: ['./four-zero-four.component.scss']
})
export class FourZeroFourComponent {

  constructor(public navigationService : NavigationService) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt 404');
  }

 

}
