import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PublicPagesService } from './public-pages.service';

@Injectable({
  providedIn: 'root'
})
export class EtbInternalService {
  baseUrl = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;

  private _CountSourceAlert = new BehaviorSubject<any>(0);
  currentAlertCount = this._CountSourceAlert.asObservable();

  MobileFilterChangeValue = new BehaviorSubject<any>(null);
  updatedFilterValue = this.MobileFilterChangeValue.asObservable();

  constructor(private http :HttpClient, private publicPagesService: PublicPagesService) { }

  applyFilterChange(filterval: any) {
    this.MobileFilterChangeValue.next(filterval)
  }

  changeAlertCount(count: any) {
    this._CountSourceAlert.next(count)
  }

  getHeader() {
    return this.publicPagesService.getHeader();
  }

  getHeaderTypeJson() {
    return { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  }

  getEtbSavedFilters(): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}/api/getEtbSavedFilters`, this.getHeader()).pipe(map(data => data), retry(1));
  }

  getEtbCustomerSavedFilters(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/getEtbCustomerSavedFilters`, this.getHeader()).pipe(map(data => data), retry(1));
}

  getEtbList(filterPayload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/getEtbList`, filterPayload).pipe(map(data => data), retry(2));
  }

  getEtbCustomerList(filterPayload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/getEtbCustomerList`, filterPayload).pipe(map(data => data), retry(2));
  }

  validateBerthETdates(payload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/validateBerthETdates`, payload).pipe(map(data => data), retry(2));
  }

  updateEtb(payload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/updateEtb`, payload).pipe(map(data => data), retry(2));
  }

  getCouting(filterPayload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/getEtbPendingCount`, filterPayload).pipe(map(data => data), retry(2));
  }

  getEtbFiltersData(filterPayload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/getEtbFiltersData`, filterPayload).pipe(map(data => data), retry(2));
  }

  getCustomerEtbFilters(filterPayload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/getCustomerEtbFilters`, filterPayload).pipe(map(data => data), retry(2));
  }

  shareEtbCustomerDetails(payload?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/shareEtbCustomerDetails`, payload).pipe(map(data => data), retry(2));
  }  
  getPortAgtEtbData(id: number) {
    return this.http.get<any>(`${this.baseUrl}/api/getPortAgtEtbData?token=${id}`, this.getHeader()).pipe(map(data => data));
  }

  validatePortAgtBerthETdates(payload:any) {
    return this.http.post<any>(`${this.baseUrl}/api/validatePortAgtBerthETdates`,payload, this.getHeaderTypeJson()).pipe(map(data => data));
  }

  updateEtbByPortAgt(payload:any){
    return this.http.post<any>(`${this.baseUrl}/api/submitEtbByPortAgt`,payload).pipe(map(data => data));
  }
}
