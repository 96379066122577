
<div class="StoltWrapper">
    <main class="StoltMainContent">
        <div class="CommonHeroArea ContactTitleArea">
            <div class="LargeWindowWidth">
                <div class="CommonHeroContent">
                    <h1 class="heading01">{{vessel_name}}</h1> 
                </div>
            </div>
        </div>
        
        <section class="CommonContentSec">
            <div class="LargeWindowWidth">
                <!-- download pdf file -->
                <div class="Q88download__pdf"> 
                    <button type="button" [disabled]="isLoading" class="downloadPdf" (click)="downloadpdf()"> <em class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></em> {{button}}</button>
                </div>
              
                <div class="Q88-infodata" id="htmlData"> 
                    <span class="width-scroll" [ngClass]="{'SomethingRequiredMsg' : flag == true}" [hidden]="hiddenFlag" #dataContainer [innerHTML]="Q88Response|safeHtml"></span>
                </div>
            </div>
        </section>  
        <!-- Page Content Stolt Skeleton --> 
        <section class="CommonContentSec Q88--skelton-space" [hidden]="!hiddenFlag">
            <div class="LargeWindowWidth">
                <div class="Q88-infodata">
                    <div class="Q88-infodata-skelton">
                        <div class="table-data CommonTableContainer">
                            <table class="table" aria-describedby="mydesc">
                                <thead class="thead-dark StickyThead">
                                    <tr>
                                        <th scope="col"><span class="Stolt-Skeleton"></span></th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container>
                                        <tr *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,15,17,18,19,20]"> 
                                            <td><span class="Stolt-Skeleton"></span></td> 
                                        </tr>
                                          
                                    </ng-container>
                                </tbody>
                            </table> 
                        </div> 
                    </div>
                </div>
            </div>
        </section> 
        <!-- Page Content Stolt Skeleton End --> 
    </main>
    <app-scroll-to-top></app-scroll-to-top>
</div>