
<div class="StoltWrapper DarkHeaderMain">
    <!-- Header Section: This section represents the header of the webpage -->
    <app-about-header></app-about-header> 

    <!-- Common Page Title Section -->
    <div class="CommonHeroArea Common-bg-lines ContactTitleArea TitleWith-header">
        <div class="LargeWindowWidth">
            <div class="CommonHeroContent ">
                <h1 class="heading01 mb-0">Make an enquiry</h1>
                <p>Please provide us some information so we can give you an accurate quote.</p>
            </div>
        </div>
    </div> 


    <main class="StoltMainContent">
        <!-- Common Content Section -->
        <section class="CommonContentSec OurFleetSec position-relative overflow-hidden">
            <div class="LargeWindowWidth">
                <div class="EnquiryFormMain">
                    <div class="EnquiryFormInner">
                        <div class="HeadingHolder">
                            <h2 class="heading03-front">Make an enquiry</h2>
                        </div>
                        <div class="TextDescription">
                            <p class="CommonPara">Please provide us some information so we can give you an accurate quote.</p>
                        </div>

                        <span class="error" *ngIf="cargoLimitexcd"> 
                            <svg-icon name="Warning"></svg-icon> 
                            Sorry, you've reached your maximum limit (3) of cargo.
                        </span>

                        <div class="FormHeadArea">
                            <div class="tab-slider-holder PublicTabs">
                                <!-- cargo enquiry tabs -->
                                <div class="tab-slider--nav">
                                    <ul class="tab-slider--tabs">
                                        <div *ngFor="let sonderurlaub of enquiryFormsArr.controls; let i=index; first as isFirst; last as isLast">
                                            <li (click)="cargoTabSwitch(i);" class="tab-slider--trigger"
                                                [class.active]="formIndex == i">
                                                <span>Cargo enquiry {{i+1}}</span>
                                                <svg-icon name="Warning" *ngIf="(sonderurlaub.get('cargoName')?.touched && sonderurlaub.get('cargoName')?.status=='INVALID') || (sonderurlaub.get('loadPort')?.touched && sonderurlaub.get('loadPort')?.status=='INVALID') || (sonderurlaub.get('dischargePort')?.touched && sonderurlaub.get('dischargePort')?.status=='INVALID') || (sonderurlaub.get('volume')?.touched && sonderurlaub.get('volume')?.status=='INVALID') || (sonderurlaub.get('laycanDateFrom')?.touched && sonderurlaub.get('laycanDateFrom')?.status=='INVALID') || (sonderurlaub.get('laycanDateTo')?.touched && sonderurlaub.get('laycanDateTo')?.status=='INVALID')"></svg-icon>
                                            </li>
                                        </div>
                                        <li class="tab-slider--trigger active AddMoreBtn" (click)="addCargoFields()">
                                            <div [ngClass]="{'isDisabled':enquiryFormsArr.status=='INVALID'}">
                                                <svg-icon name="plus-icon2"></svg-icon> 
                                                <span (click)="reSettall()">More cargo</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="TabsDataBody">
                                <div class="TitleArea">
                                    <div class="d-flex align-items-center"
                                        *ngFor="let sonderurlaub of enquiryFormsArr.controls; let i=index; first as isFirst; last as isLast">
                                        <h2 class="heading026" *ngIf="formIndex == i">Cargo {{i+1}} info</h2>
                                        <div *ngIf="enquiryFormsArr.controls.length > 1">
                                            <div class="d-flex RemoveCargo" *ngIf="formIndex == i">
                                                <p class="m-0" (click)="deleteReffrel(i)">
                                                    Remove this cargo 
                                                    <svg-icon name="trash-icon-2"></svg-icon> 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Enquiry Form -->
                                <form [formGroup]="enquiryForm">
                                    <div formArrayName="cargoInfo" class="FieldsHolder">
                                        <div *ngFor="let sonderurlaub of enquiryFormsArr.controls; let i=index; first as isFirst; last as isLast"
                                            [formGroupName]="i">
                                            <div class="form-row FieldsHolderinner" *ngIf="formIndex == i">
                                                <div class="FieldsHolderLeft" id="cargo">
                                                    <div class="TitleArea">
                                                        <div class="d-flex RemoveCargo">
                                                            <div class="CargoType">
                                                                <p class="ViewTitle">Cargo type</p>
                                                                <div class="ViewChanger">
                                                                    <div class="RadioHld">
                                                                        <div class="TypeRadio"
                                                                            [class.active]="sonderurlaub.value.vesselType == 'regularParcel'">
                                                                            <input class="InvisibleRadio" type="radio"
                                                                                formControlName="vesselType"
                                                                                value="regularParcel">
                                                                            <label for="html">Regular parcel</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="RadioHld">
                                                                        <div class="TypeRadio"
                                                                            [class.active]="sonderurlaub.value.vesselType == 'floatingStorage'">
                                                                            <input class="InvisibleRadio" type="radio"
                                                                                formControlName="vesselType"
                                                                                value="floatingStorage">
                                                                            <label for="html">Floating storage</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Cargo select -->
                                                    <div class="form-group">
                                                        <fieldset>
                                                            <legend></legend>
                                                            <ng-select *ngIf="cargoEnable" [items]="cargoesOptions"
                                                                bindValue="cargoName" bindLabel="cargoName"
                                                                 formControlName="cargoName"
                                                                [clearable]="false" [searchable]="false"
                                                                [closeOnSelect]="closeDrOther" required
                                                                class="ng-select-2 InnerTxtCntlr pl-0-select-container FloatingLabel E-Cargo customScroll2 OtherInputCstomze"
                                                                [ngClass]="{ 'is-invalid': getReferralsFormArr(i).controls['cargoName'].touched && getReferralsFormArr(i).controls['cargoName'].hasError('required'),'disbeldNoItem':otherEnable}"
                                                                (change)="onOtherChange($event,'cargo',i);"
                                                                (open)="onOpen(i,'cargo')" (close)="closeEvent('cargo' , i)" (keyup.enter)="closeEvent('cargo' , i)">
                                                                
                                                                <label for="floatingInput">Volume (MT)</label> 
                                                                <ng-template ng-label-tmp let-item="item">
                                                                    <span *ngIf="OtherCargoText">Others:</span>  {{f.cargoInfo.value[i].cargoName}}
                                                                </ng-template>

                                                                <div *ngIf="!otherEnable">
                                                                    <ng-template ng-header-tmp >
                                                                        <input   style="width: 100%; line-height: 24px;"
                                                                            type="text" placeholder="Search cargoes"
                                                                            (input)="customSearch('Cargo',$event)" />
                                                                    </ng-template>
                                                                </div>

                                                                <ng-template ng-option-tmp let-item="item"
                                                                let-item$="item$" let-index="index">
                                                                    <span (mouseover)="isHover(item.cargoName,'cargo')">
                                                                        <span *ngIf="item.cargoName == 'Other'" class="flex-row justify-content-between">{{item.cargoName|titlecase}}
                                                                            <svg-icon  src="/assets/svg/chevron-right-thin.svg"></svg-icon>
                                                                        </span> {{item.cargoName|titlecase}}
                                                                    </span>
                                                                </ng-template> 

                                                                <ng-template ng-footer-tmp *ngIf="otherEnable">
                                                                    <div class="ng-option OtherOpt" (click)="backListing(i,'cargo')">
                                                                        <span class="ChevBack">
                                                                            <svg-icon src="/assets/svg/chevron-left-thin.svg"></svg-icon>                                                                                 
                                                                        </span>
                                                                        <span>Other</span>
                                                                    </div>
                                                                    <div class="OtherInputF">
                                                                        <div class="form-floating"> 
                                                                            <input type="text" autocomplete="off" class="form-control form-control-stolt fc-stolt2 floating-control-no-bg" placeholder="Cargo name" formControlName="cargoName"
                                                                            [(ngModel)]="blurCargoName"
                                                                            (keyup)="onKey($event,'cargo')" autofocus> 
                                                                            <label for="floatingInput">Cargo name</label>
                                                                        </div>
                                                                    </div>    
                                                                </ng-template>
                                                            </ng-select>
                                                        </fieldset>

                                                        <div *ngIf="getReferralsFormArr(i).controls['cargoName'].touched && getReferralsFormArr(i).controls['cargoName'].hasError('required')">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Cargo is required
                                                            </div>
                                                        </div>

                                                        <div *ngIf="(getReferralsFormArr(i).controls['cargoName'].touched && getReferralsFormArr(i).controls['cargoName'].hasError('minlength'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Cargo name must be at least 3 characters long.
                                                            </div>
                                                        </div>

                                                        <div *ngIf="(getReferralsFormArr(i).controls['cargoName'].touched && getReferralsFormArr(i).controls['cargoName'].hasError('maxlength'))">
                                                            <div class="error"> <svg-icon name="Warning"></svg-icon>
                                                                Cargo name must not exceed 50 characters.
                                                            </div>
                                                        </div>

                                                        <div class="SubText">
                                                            <span>
                                                                <strong>Note:</strong>
                                                                 ‘Others’ cargo, please upload
                                                                the Material Safety Data Sheet (MSDS) in the
                                                                ‘Attachments’ section below.
                                                            </span>
                                                        </div>
                                                    </div>  
                                                    
                                                    <!-- Volume(MT) -->
                                                    <div class="form-group form-floating form-floating2">
                                                        <input type="number" formControlName="volume"
                                                            placeholder="Volume (MT)"
                                                            class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                            onKeyPress="if(this.value.length==15) return false;"
                                                            [ngClass]="{ 'is-invalid': getReferralsFormArr(i).controls['volume'].touched && getReferralsFormArr(i).controls['volume'].hasError('required')}">
                                                            <label for="floatingInput">Volume (MT)</label>

                                                        <div *ngIf="getReferralsFormArr(i).controls['volume'].touched && getReferralsFormArr(i).controls['volume'].hasError('required')">
                                                            <div class="error d-flex align-items-center"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                    Please fill Volume MT
                                                            </div>
                                                        </div>

                                                        <div *ngIf="getReferralsFormArr(i).controls['volume'].hasError('pattern')">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Volume MT should be numeric
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <!-- Load port -->
                                                    <div class="form-group">
                                                        <fieldset>
                                                            <legend></legend>
                                                            <ng-select *ngIf="portEnable" [items]="loadPortOptions"
                                                                [searchable]="false" bindValue="loadPort"
                                                                bindLabel="loadPort"
                                                                formControlName="loadPort"
                                                                [closeOnSelect]="closeDrOtherPort" required
                                                                class="ng-select-2 InnerTxtCntlr pl-0-select-container FloatingLabel L-Port customScroll2 OtherInputCstomze"
                                                                [ngClass]="{ 'is-invalid': getReferralsFormArr(i).controls['loadPort'].touched && getReferralsFormArr(i).controls['loadPort'].hasError('required'),'disbeldNoItem':otherEnablePort}"
                                                                (change)="onOtherChange($event,'loadport',i);enquiryTerminal($event,'loadport',i)"
                                                                (open)="onOpen(i,'port')"
                                                                (close)="closeEvent('port',i)" (keyup.enter)="closeEvent('port',i)">
                                                                <div *ngIf="!otherEnablePort">
                                                                    <ng-template ng-header-tmp>
                                                                        <input type="text" placeholder="Search load port"
                                                                            (input)="customSearch('LoadPort',$event)" />
                                                                    </ng-template>
                                                                </div>

                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-item$="item$" let-index="index">
                                                                    <span
                                                                        (mouseover)="isHover(item.loadPort,'port')"> <span *ngIf="item.loadPort == 'Other'" class="flex-row justify-content-between">{{item.loadPort|titlecase}}
                                                                            <svg-icon  src="/assets/svg/chevron-right-thin.svg"></svg-icon>
                                                                        </span> {{item.loadPort|titlecase}}</span>
                                                                </ng-template>

                                                                <ng-template ng-label-tmp let-item="item">
                                                                    <span *ngIf="OtherPortText">Others:</span> {{f.cargoInfo.value[i].loadPort}}
                                                                </ng-template>

                                                                <ng-template ng-footer-tmp *ngIf="otherEnablePort">
                                                                    <div class="ng-option OtherOpt" (click)="backListing(i,'port')">
                                                                        <span class="ChevBack">
                                                                            <svg-icon src="/assets/svg/chevron-left-thin.svg"></svg-icon>                                                                       
                                                                        </span>
                                                                        <span>Other</span>
                                                                    </div>
                                                                    <div class="OtherInputF">
                                                                        <div class="form-floating"> 
                                                                            <input type="text" formControlName="loadPort"
                                                                                [(ngModel)]="blurPortName"
                                                                                placeholder="load port name"
                                                                                (keyup)="onKey($event,'loadport')"
                                                                                class="form-control form-control-stolt fc-stolt2 floating-control-no-bg" autofocus>
                                                                                <label for="floatingInput">Load port name</label>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </ng-select>
                                                        </fieldset>
                                                        <div
                                                            *ngIf="getReferralsFormArr(i).controls['loadPort'].touched && getReferralsFormArr(i).controls['loadPort'].hasError('required')">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Load port is required
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['loadPort'].touched && getReferralsFormArr(i).controls['loadPort'].hasError('minlength'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Load port must be at least 3 characters long.
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['loadPort'].touched && getReferralsFormArr(i).controls['loadPort'].hasError('maxlength'))">
                                                            <div class="error">
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Load port must not exceed 50 characters.
                                                            </div>
                                                        </div>

                                                    </div>
                                                     

                                                    <!-- Load port terminal -->
                                                    <div class="form-group" *ngIf="portTermEnb">
                                                        <fieldset>
                                                            <legend></legend>
                                                            <ng-select [items]="loadPortTerminalOptions"
                                                                [searchable]="false" bindValue="terminal"
                                                                bindLabel="terminal"
                                                                formControlName="loadPortTerminal"
                                                                [closeOnSelect]="true"
                                                                class="ng-select-2 InnerTxtCntlr pl-0-select-container FloatingLabel L-Port-terminal customScroll2 OtherInputCstomze">
                                                               
                                                                <ng-template ng-header-tmp>
                                                                    <input type="text" placeholder="Search load port terminal"
                                                                        (input)="customSearch('loadPortTerminal',$event)" />
                                                                </ng-template>

                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-item$="item$" let-index="index">
                                                                    <span> {{item.terminal|titlecase}}</span>
                                                                </ng-template>
                                                            </ng-select>
                                                        </fieldset>
                                                    </div>

                                                    <!-- Load port Transshipment -->
                                                    <div class="restrictions-sec" *ngIf="sonderurlaub.value.loadPort">
                                                        <p>Load port transshipment</p>
                                                        <div class="d-flex radio-opt-main"> 
                                                            <div class="d-flex">
                                                                <span class="checkboxHld RadioBtnCircle">
                                                                    <label class="containerCheckbx">
                                                                        <input type="radio"formControlName="isLoadTranshiment" value="yes">
                                                                        <span class="checkmark checkmark2 bder2"></span>
                                                                    </label>
                                                                </span>
                                                                <span for="html" class="RadioLabel">Yes</span> 
                                                            </div>    

                                                            <div class="d-flex">
                                                                <span class="checkboxHld RadioBtnCircle">
                                                                    <label class="containerCheckbx">
                                                                        <input type="radio"formControlName="isLoadTranshiment" value="no" (click)="onChangeTranship($event,i)">
                                                                        <span class="checkmark checkmark2 bder2"></span>
                                                                    </label>
                                                                </span> 
                                                                <span for="html" class="RadioLabel">No</span>
                                                            </div> 
                                                        </div>

                                                        <div *ngIf="sonderurlaub.value.isLoadTranshiment == 'yes'" class="form-group form-floating form-floating2 lastCargoFld"> 
                                                            <input type="text" formControlName="loadTransshipmentVesselName"
                                                                class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                                placeholder="Vessel Name">
                                                            <label for="floatingInput">Vessel Name</label>

                                                            <div *ngIf="(getReferralsFormArr(i).controls['loadTransshipmentVesselName'].touched && getReferralsFormArr(i).controls['loadTransshipmentVesselName'].hasError('minlength'))">
                                                                <div class="error"> 
                                                                    <svg-icon name="Warning"></svg-icon>
                                                                    Vessel Name must be at least 3 characters long.
                                                                </div>
                                                            </div>
                                                            <div
                                                                *ngIf="(getReferralsFormArr(i).controls['loadTransshipmentVesselName'].touched && getReferralsFormArr(i).controls['loadTransshipmentVesselName'].hasError('maxlength'))">
                                                                <div class="error"> 
                                                                    <svg-icon name="Warning"></svg-icon>
                                                                    Vessel Name must not exceed 100 characters.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  

                                                    <!-- Discharge port -->
                                                    <div class="form-group">
                                                        <fieldset>
                                                            <legend></legend>
                                                            <ng-select *ngIf="dischargeEnable"
                                                                [items]="dischargePortOptions" [searchable]="false"
                                                                bindValue="dischargePort" bindLabel="dischargePort"formControlName="dischargePort"
                                                                [closeOnSelect]="closeDrOtherDischarge" required
                                                                class="ng-select-2 InnerTxtCntlr pl-0-select-container FloatingLabel D-Port customScroll2 OtherInputCstomze"
                                                                [ngClass]="{ 'is-invalid': getReferralsFormArr(i).controls['dischargePort'].touched && getReferralsFormArr(i).controls['dischargePort'].hasError('required'),'disbeldNoItem':otherEnableDischarge}"
                                                                (change)="onOtherChange($event,'discharge',i);enquiryTerminal($event,'discharge',i)"
                                                                (open)="onOpen(i,'discharge')"
                                                                (close)="closeEvent('discharge',i)" (keyup.enter)="closeEvent('discharge',i)">

                                                                <div *ngIf="!otherEnableDischarge">
                                                                    <ng-template ng-header-tmp>
                                                                        <input type="text" 
                                                                            placeholder="Search discharge port"
                                                                            (input)="customSearch('DischargePort',$event)" />
                                                                    </ng-template>
                                                                </div>
                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-item$="item$" let-index="index">
                                                                    <span
                                                                        (mouseover)="isHover(item.dischargePort,'discharge')">
                                                                        <span *ngIf="item.dischargePort == 'Other'" class="flex-row justify-content-between">{{item.dischargePort|titlecase}}
                                                                            <svg-icon  src="/assets/svg/chevron-right-thin.svg"></svg-icon>
                                                                        </span>{{item.dischargePort|titlecase}}</span>
                                                                </ng-template>

                                                                <ng-template ng-label-tmp let-item="item">
                                                                    <span *ngIf="OtherDischargeText">Others:</span> {{f.cargoInfo.value[i].dischargePort}}
                                                                </ng-template>

                                                                <ng-template ng-footer-tmp *ngIf="otherEnableDischarge">
                                                                    <div class="ng-option OtherOpt" (click)="backListing(i,'discharge')">
                                                                        <span class="ChevBack">
                                                                            <svg-icon src="/assets/svg/chevron-left-thin.svg"></svg-icon>                                                                                 
                                                                        </span>
                                                                        <span>Other</span>
                                                                    </div>
                                                                    <div class="OtherInputF">
                                                                        <div class="form-floating"> 
                                                                            <input type="text" formControlName="dischargePort"
                                                                                [(ngModel)]="blurDischargeName"
                                                                                placeholder="Discharge name"
                                                                                (keyup)="onKey($event,'discharge')"
                                                                                class="form-control form-control-stolt fc-stolt2 floating-control-no-bg" autofocus>
                                                                                <label for="floatingInput">Discharge name</label>
                                                                            </div>
                                                                        </div>
                                                                </ng-template>

                                                            </ng-select>
                                                        </fieldset>
                                                        <div
                                                            *ngIf="getReferralsFormArr(i).controls['dischargePort'].touched && getReferralsFormArr(i).controls['dischargePort'].hasError('required')">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Discharge port is required
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['dischargePort'].touched && getReferralsFormArr(i).controls['dischargePort'].hasError('minlength'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Discharge must be at least 3 characters long.
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['dischargePort'].touched && getReferralsFormArr(i).controls['dischargePort'].hasError('maxlength'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Discharge must not exceed 50 characters.
                                                            </div>
                                                        </div>

                                                    </div> 

                                                    <!-- Discharge port terminal -->
                                                    <div class="form-group" *ngIf="dischargeTermEnb">
                                                        <fieldset>
                                                            <legend></legend>
                                                            <ng-select
                                                                [items]="dischargePortTerminalOptions" [searchable]="false"
                                                                bindValue="terminal" bindLabel="terminal"formControlName="dischargePortTerminal"
                                                                [closeOnSelect]="true"
                                                                class="ng-select-2 InnerTxtCntlr pl-0-select-container FloatingLabel D-Port-terminal customScroll2 OtherInputCstomze">                                                               
                                                                <ng-template ng-header-tmp>
                                                                    <input type="text" 
                                                                        placeholder="Search discharge port terminal"
                                                                        (input)="customSearch('dischargePortTerminal',$event)" />
                                                                </ng-template>
                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-item$="item$" let-index="index">
                                                                    <span>
                                                                        {{item.terminal|titlecase}}</span>
                                                                </ng-template>
                                                            </ng-select>
                                                        </fieldset>
                                                    </div> 

                                                    <!-- Discharge port Transshipment -->
                                                    <div class="restrictions-sec" *ngIf="sonderurlaub.value.dischargePort">
                                                        <p>Discharge port transshipment</p>
                                                        <div class="d-flex radio-opt-main"> 
                                                            <div class="d-flex">
                                                                <span class="checkboxHld RadioBtnCircle">
                                                                    <label class="containerCheckbx">
                                                                        <input type="radio"formControlName="isDischargeTranshiment" value="yes">
                                                                        <span class="checkmark checkmark2 bder2"></span>
                                                                    </label>
                                                                </span>
                                                                <span for="html" class="RadioLabel">Yes</span> 
                                                            </div>    

                                                            <div class="d-flex">
                                                                <span class="checkboxHld RadioBtnCircle">
                                                                    <label class="containerCheckbx">
                                                                        <input type="radio"formControlName="isDischargeTranshiment" value="no" (click)="onChangeTranship($event,i)">
                                                                        <span class="checkmark checkmark2 bder2"></span>
                                                                    </label>
                                                                </span> 
                                                                <span for="html" class="RadioLabel">No</span>
                                                            </div> 
                                                        </div>

                                                        <div *ngIf="sonderurlaub.value.isDischargeTranshiment == 'yes'" class="form-group form-floating form-floating2 lastCargoFld"> 
                                                            <input type="text" formControlName="dischargeTransshipmentVesselName"
                                                                class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                                placeholder="Vessel Name">
                                                            <label for="floatingInput">Vessel Name</label>

                                                            <div *ngIf="(getReferralsFormArr(i).controls['dischargeTransshipmentVesselName'].touched && getReferralsFormArr(i).controls['dischargeTransshipmentVesselName'].hasError('minlength'))">
                                                                <div class="error"> 
                                                                    <svg-icon name="Warning"></svg-icon>
                                                                    Vessel Name must be at least 3 characters long.
                                                                </div>
                                                            </div>
                                                            <div
                                                                *ngIf="(getReferralsFormArr(i).controls['dischargeTransshipmentVesselName'].touched && getReferralsFormArr(i).controls['dischargeTransshipmentVesselName'].hasError('maxlength'))">
                                                                <div class="error"> 
                                                                    <svg-icon name="Warning"></svg-icon>
                                                                    Vessel Name must not exceed 100 characters.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <!-- Laycan date start-->
                                                    <div class="form-group">
                                                        <mat-form-field class="mat-form-field-two"
                                                            [ngClass]="{datepickerError:datePickerClass}"
                                                            appearance="fill">
                                                            <mat-label>Laycan date</mat-label>
                                                            <mat-date-range-input [rangePicker]="picker" [min]="minDate"
                                                                [max]="maxDate">
                                                                <input matStartDate #dateRangeStart readonly
                                                                    placeholder="Start date"
                                                                    formControlName="laycanDateFrom"
                                                                    (focus)="picker.open()" (click)="picker.open()">
                                                                <input matEndDate readonly #dateRangeEnd
                                                                    placeholder="End date"
                                                                    formControlName="laycanDateTo"
                                                                    (focus)="picker.open()" (click)="picker.open()">
                                                            </mat-date-range-input>
                                                            <mat-datepicker-toggle matIconSuffix
                                                                [for]="picker"></mat-datepicker-toggle>
                                                            <mat-date-range-picker #picker
                                                                (closed)="datePickerClass=false; onClose(i)"
                                                                (opened)="datePickerClass=true"></mat-date-range-picker>
                                                        </mat-form-field>
                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['laycanDateFrom'].touched && getReferralsFormArr(i).controls['laycanDateFrom'].hasError('required')) || (getReferralsFormArr(i).controls['laycanDateTo'].touched && getReferralsFormArr(i).controls['laycanDateTo'].hasError('required'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Start date and End date is required
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>

                                                <div class="FieldsHolder-right stolt__scrollbar" *ngIf="formIndex == i">
                                                    <div class="restrictions-sec">
                                                        <p>Last cargo restrictions</p>
                                                        <div class="d-flex radio-opt-main"> 
                                                            <div class="d-flex">
                                                                <span class="checkboxHld RadioBtnCircle">
                                                                    <label class="containerCheckbx">
                                                                        <input type="radio"formControlName="lastCargoType" value="yes">
                                                                        <span class="checkmark checkmark2 bder2"></span>
                                                                    </label>
                                                                </span>
                                                                <span for="html" class="RadioLabel">Yes</span> 
                                                            </div>    

                                                            <div class="d-flex">
                                                                <span class="checkboxHld RadioBtnCircle">
                                                                    <label class="containerCheckbx">
                                                                        <input type="radio"formControlName="lastCargoType" value="no">
                                                                        <span class="checkmark checkmark2 bder2"></span>
                                                                    </label>
                                                                </span> 
                                                                <span for="html" class="RadioLabel">No</span>
                                                            </div> 
                                                        </div>

                                                        <div *ngIf="sonderurlaub.value.lastCargoType == 'yes'" class="form-group form-floating form-floating2 lastCargoFld"> 
                                                            <textarea formControlName="lastCargoRestrictions"
                                                                class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                                placeholder="Last cargo restrictions">
                                                            </textarea>
                                                            <label for="floatingInput">Last cargo restrictions</label>

                                                            <div *ngIf="(getReferralsFormArr(i).controls['lastCargoRestrictions'].touched && getReferralsFormArr(i).controls['lastCargoRestrictions'].hasError('minlength'))">
                                                                <div class="error"> 
                                                                    <svg-icon name="Warning"></svg-icon>
                                                                    Last cargo restrictions must be at least 3 characters long.
                                                                </div>
                                                            </div>
                                                            <div
                                                                *ngIf="(getReferralsFormArr(i).controls['lastCargoRestrictions'].touched && getReferralsFormArr(i).controls['lastCargoRestrictions'].hasError('maxlength'))">
                                                                <div class="error"> 
                                                                    <svg-icon name="Warning"></svg-icon>
                                                                    Last cargo restrictions must not exceed 200 characters.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="form-group form-floating form-floating2">
                                                        <textarea formControlName="requirements"
                                                            class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                            placeholder="Requirements (optional)"
                                                            [ngClass]="{ 'is-invalid': getReferralsFormArr(i).controls['requirements'].touched && getReferralsFormArr(i).controls['requirements'].hasError('minlength')}">
                                                        </textarea>
                                                        <label for="floatingInput">Requirements (optional)</label>
                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['requirements'].touched && getReferralsFormArr(i).controls['requirements'].hasError('minlength'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Requirements must be at least 3 characters long.
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="(getReferralsFormArr(i).controls['requirements'].touched && getReferralsFormArr(i).controls['requirements'].hasError('maxlength'))">
                                                            <div class="error"> 
                                                                <svg-icon name="Warning"></svg-icon>
                                                                Requirements must not exceed 500 characters.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="AttachmentsArea">
                                        <div class="AttachmentTitle">
                                            <label>Attachments</label>
                                            <span>(optional)</span>
                                        </div>
                                        <div class="file__choose" fileDragDrop
                                            (filesChangeEmiter)="onFileChange($event,'drag')">
                                            <label for="upload-file" class="StoltBtnIconLarge StoltBtnBorderOnly">
                                                <svg-icon name="upload-icon"></svg-icon> 
                                                <span class="MobileHide">Drag and drop here or</span>
                                                <span class="BrowseFiles">Browse files</span>
                                            </label>
                                            <input (change)="onFileChange($event,'upload')" #uploadFile
                                                class="StoltBtnIconLarge StoltBtnBorderOnly" type="file"
                                                id="upload-file" accept=".csv, .xlsx, .pdf, .doc">
                                        </div>


                                        <a class="FileName" *ngIf="fileNameEnb" (click)="removeFile()">{{fileName}}
                                            <svg-icon name="trash-icon"></svg-icon>
                                        </a>
                                        <span class="error AttachmentError" *ngIf="fileExtenMessg"> 
                                            <svg-icon name="Warning"> </svg-icon>
                                            {{fileExtenMessg}}
                                        </span>
                                    </div>


                                    <div class="FieldsHolder">
                                        <p class="FieldMainTitle">Contact info</p>
                                        <div class="FieldsHolderinner pt-0 border-0 m-pb-0">
                                            <div class="FieldsHolderLeft"> 
                                                <div class="form-group form-floating form-floating2">
                                                    <input type="text" formControlName="name" placeholder="Name"
                                                        class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                        [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors }" />
                                                        <label for="floatingInput">Name</label>
                                                    <div class="error" *ngIf="f.name.touched && f.name.errors">
                                                        <div *ngIf="f.name.errors.required">
                                                            <svg-icon name="Warning"></svg-icon>
                                                             Name is required
                                                        </div>
                                                        <div *ngIf="f.name.errors.minlength">
                                                            <svg-icon name="Warning"></svg-icon> 
                                                            Name must be at least 3 characters long.
                                                        </div>
                                                        <div *ngIf="f.name.errors.maxlength">
                                                            <svg-icon name="Warning"></svg-icon>
                                                             Name must not exceed 100 characters.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group form-floating form-floating2">
                                                    <input type="text" formControlName="organizationName"
                                                        placeholder="Organization name"
                                                        class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                        [ngClass]="{ 'is-invalid': f.organizationName.touched && f.organizationName.errors }" />
                                                        <label for="floatingInput">Organization name</label>
                                                    <div class="error"
                                                        *ngIf="f.organizationName.touched && f.organizationName.errors">
                                                        <div *ngIf="f.organizationName.errors.required">
                                                            <svg-icon name="Warning"></svg-icon>
                                                             Organization name is required
                                                        </div>
                                                        <div *ngIf="f.organizationName.errors.minlength">
                                                            <svg-icon name="Warning"></svg-icon>
                                                             Organization name must be at least 3 characters long.
                                                        </div>
                                                        <div *ngIf="f.organizationName.errors.maxlength">
                                                            <svg-icon name="Warning"></svg-icon>
                                                             Organization name must not exceed 100 characters.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group form-floating form-floating2">
                                                    <input type="email" formControlName="email" placeholder="Email"
                                                        class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                        [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" />
                                                        <label for="floatingInput">Email</label>
                                                    <div class="error" *ngIf="f.email.touched && f.email.errors">
                                                        <div *ngIf="f.email.errors?.required">
                                                            <svg-icon name="Warning"></svg-icon>
                                                             Email is required
                                                        </div>
                                                        <div *ngIf="f.email.errors.pattern">
                                                            <svg-icon name="Warning"></svg-icon> 
                                                            Email must be a valid email address
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ColumnHld">
                                                    <div class="d-flex ColumnInner">
                                                        <div class="form-group form-floating form-floating2">
                                                            <input type="tel" formControlName="phone"
                                                                placeholder="Phone (optional)" (blur)="OnblurEvent($event)"
                                                                class="form-control form-control-stolt fc-stolt2 floating-control-no-bg"
                                                                onKeyPress="if(this.value.length==10) return false;">
                                                                <label for="floatingInput">Phone (optional)</label>

                                                        </div>
                                                        <div class="form-group C-Code">
                                                            <fieldset>
                                                                <legend class="m-0"></legend>
                                                                <ng-select [items]="countryCodeOptions"
                                                                    [searchable]="false" bindValue="szDialCode"
                                                                    bindLabel="szCountryName" placeholder="Country code"
                                                                    formControlName="countryCode"
                                                                    class="ng-select-2 InnerTxtCntlr GreyTextSelect pl-0-select-container MobileOpenDropdownTop customScroll2 HeightController" (change)="onBlurCode($event)">
                                                                    <ng-template ng-header-tmp>
                                                                        <input style="width: 100%; line-height: 24px"
                                                                            type="text"
                                                                            placeholder="Search country code"
                                                                            (input)="customSearch('countrycode',$event)" />
                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-item="item"
                                                                        let-item$="item$" let-index="index">
                                                                        <span>{{item.szCountryName|titlecase}}</span>
                                                                    </ng-template>
                                                                </ng-select>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div class="error" *ngIf="f.phone.errors">
                                                        <div *ngIf="f.phone.errors.pattern"> 
                                                            <svg-icon name="Warning"></svg-icon> 
                                                            Please Enter 10 digit Mobile Number.
                                                        </div>
                                                    </div>
                                                    <div class="error CCodeError" *ngIf="CountryCodeError">
                                                        <div> 
                                                            <svg-icon name="Warning"></svg-icon> 
                                                            Please Enter country code
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey"
                                                        formControlName="recaptcha">
                                                    </ngx-recaptcha2>
                                                    <div class="error"
                                                        *ngIf="f.recaptcha.touched && f.recaptcha.errors">
                                                        <div *ngIf="f.recaptcha.errors.required"> 
                                                            <svg-icon name="Warning"></svg-icon> 
                                                            Captcha is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <a (click)="submitHandler()" class="BtnNoBgArrow position-relative">
                                                    <span *ngIf="!isLoading"> Submit enquiry</span>
                                                    <span *ngIf="isLoading" [ngClass]="{isDisabled:isLoading}">
                                                        <em class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></em>
                                                        Submitting
                                                    </span>
                                                    <img src="assets/svg/login-arrow.svg" alt="img"></a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
 
    <snl-modal (modalClose)="crgoInfoRemoveModel = false" [settings]="snlModalSettings" [type]="'small'" [open]="crgoInfoRemoveModel" class="CenterModelCmn CommonPhoneCstm DeleteModelSnl">
        <div slot="heading"><strong>Delete Cargo info</strong></div>
        <div class="body-b" slot="modalContent">
            Are you sure want to delete this cargo info?
       </div>
        <div slot="modalFooter">
            <snl-button size="large" type="secondary" (click)="crgoInfoRemoveModel = false">Cancel</snl-button>
            <snl-button class="DeleteBtnBG" size="large" (click)="deleteReffrel('yes')">Yes, Delete</snl-button>
        </div>
    </snl-modal>

    <snl-modal (modalClose)="Iswelcome = false" [settings]="snlModalSettings" [type]="'medium'" [open]="Iswelcome" class="CenterModelCmn CommonPhoneCstm EnquiringMdl">
        <div class="body-b" slot="modalContent">
            <div class="enquiringInner">
                <div class="BannerMainEnq">
                    <div class="BannerAreaHld">
                        <div class="BannerArea">
                            <img class="w-100 M_CommonHidden" src="assets/img/welcome-banner.jpg" alt="img">
                            <img class="w-100 m-banner LargeDeviceshide" src="assets/img/feedback-thnaks-m.png" alt="img">
                        </div> 
                    </div>
                    <div class="header-h2">
                        Thank you for enquiring 
                    </div>
                </div>
                <p class="m-0 body-b1">
                    A copy of your request has been sent to your email id for your reference.
                </p>

                <div class="ReferenceSec">
                    <span class="d-flex flex-column section-title">Reference no</span>
                    <p class="m-0 font-24 fw-400" *ngIf="refrenceNumber">{{refrenceNumber}}</p>
                </div>
            </div>
       </div>
        <div slot="modalFooter">
            <snl-button size="large" (click)="closeModel()"  [routerLink]="['/fleetinfo/snits']">OK</snl-button>
        </div>
    </snl-modal>


    <!-- Footer Section: This section represents the footer of the webpage -->
    <app-footer></app-footer>
    <app-scroll-to-top></app-scroll-to-top> 

</div>