import {  ErrorHandler,  Injectable  } from '@angular/core';  
import {   MyMonitoringService  } from './my-monitoring.service';  
@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsErrorHandlerService extends ErrorHandler {
  constructor(private myMonitoringService: MyMonitoringService) {
    super();
  }  
  handleError(error: Error) {
    this.myMonitoringService.logException(error); // Manually log exception
} 
}
