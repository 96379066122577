<div class="form-row">
    <div class="form-group">
        <ng-select #ngSelectControl [items]="filterData"
            [multiple]="true" class="TextSlectGroup {{dynamicTextCss}} " [searchable]="false"
            [clearable]="false" bindLabel="{{bindLabel}}" [closeOnSelect]="false"
            bindValue="{{bindValue}}" groupBy="{{filterField}}" [clearSearchOnAdd]="true"
            [selectableGroup]="true" placeholder="All" (change)="submitFilterMultiple();clearInput()"
            [(ngModel)]="filterValueTemp">
            <ng-template ng-multi-label-tmp let-items="items">
                <span
                    *ngIf="items.length < 2 && !items[0].hasOwnProperty(filterName)">{{items[0][filterField]|titlecase}}</span>
                <span
                    *ngIf="items.length < 2">{{items[0][filterName]|titlecase}}</span>
                <span *ngIf="items.length >= 2">{{items.length}}</span>
            </ng-template>
            <ng-template ng-header-tmp>
                <input style="width: 100%; line-height: 24px" type="text" #searchName
                    placeholder={{placeholder}}
                    (input)="customSearch($event)" />
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$"
                let-index="index">
                <div class="checkboxHld">
                    <label class="containerCheckbx tp15">
                        <input type="checkbox" id="item-{{index}}"
                            [ngModel]="item$.selected" />
                        <span class="checkmark"></span>
                    </label>
                    <span class="OptionTxt">{{item[filterName]|titlecase}}</span>
                </div>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                let-index="index">
                <div class="SelectAllOpt" [style.display]="display">
                    <div class="checkboxHld">
                        <label class="containerCheckbx tp15">
                            <input id="item-{{index}}" type="checkbox"
                                [ngModel]="item$.selected" />
                            <span class="checkmark"></span>
                        </label>
                        <span class="OptionTxt">All
                            ({{filterDataMani.length}})</span>
                    </div>
                </div>
            </ng-template>
        </ng-select>
    </div>
</div>