import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { PublicPagesService } from 'src/app/services/public-pages.service';

@Component({
  selector: 'app-ourfleet-external',
  templateUrl: './ourfleet-external.component.html',
  styleUrls: ['./ourfleet-external.component.scss']
})
export class OurfleetExternalComponent implements OnInit {

  
  istrackIdFormClick = false;
  imo_class = [];
  average_year = 0;
  totalFleets1 = 0;
  totalFleets2 = 0;
  totalFleets3 = 0;
  constructor(public navigationService: NavigationService, private publicPageService: PublicPagesService) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers');
  }

  ngOnInit(): void {
    this.publicPageService.getHomeFleetInfo().subscribe((res: any) => {
      if (res && res.data) {
        this.imo_class = (res.data.imo_class).filter((e: string) => e);
        this.average_year = res.data.average_year;
        this.totalFleets1 = res.data.totalFleets1;
        this.totalFleets2 = res.data.totalFleets2;
        this.totalFleets3 = res.data.totalFleets3;
      }
    });
  }
}
