<snl-modal (modalClose)="closePopup()" [settings]="snlModalSettings" [type]="'medium'" [open]="isPreview" class="CenterModelCmn modelAnnoucement CommonPhoneCstm">
    <div class="HeaderInner" slot="heading">
        <span class="body-b">Announcements</span> 
        <strong class="header-h2 capitalize-first">{{title}}</strong>
    </div>
    <div slot="modalContent">
        <div class="StaticThumbnail">
            <img src="assets/img/thumbnail_image002.jpg" alt="img">
        </div> 
        <div [innerHTML]="safeHtmlContent"></div>
    </div>
    <div slot="modalFooter">
        <snl-button size="large" (click)="closePopup()">Close</snl-button>
    </div>
</snl-modal>