export class SurveyResponsesModel {
    public isSelectedTab: any = 'CFQ';
    public isFilterSkeleton: boolean = true;
    public isStatusSkeleton: boolean = true;
    public ResetFildisable = true;
    public resetAllCalled = false;
    public pageNo = 1;
    public isSkeletonEnable: boolean;

    public companyFilter: any[] = [];
    public categoryFilter: any[] = [];
    public voyageLobFilter: any[] = [];
    public voyageCoordinatorFilter: any[] = [];

    public savedCompanyFilterData: any;
    public savedCategoryFilterData: any;
    public savedVoyageLobFilterData: any;
    public savedVoyageCoordinatorFilterData: any;

    public companyFilterValue: any = [];
    public categoryFilterValue: any = [];
    public voyageLobFilterValue: any = [];
    public voyageCoordinatorFilterValue: any = [];

    public companyFilterDataMani: any = [];
    public categoryFilterDataMani: any = [];
    public voyageLobFilterDataMani: any = [];
    public voyageCoordinatorFilterDataMani: any = [];

    public surveyList: any = [];
    public totalcount: any;
    public statusTotalCount: any;
    public  searchString: any = '';
    public  isSearchSekelton:boolean;
    public  dateRange:any;
    public isDateFilterApplied:boolean;
    public statuses = [
        { key: 'New', label: 'New', count: 0, isChecked: false },
        { key: 'In-progress', label: 'In-progress', count: 0, isChecked: false },
        { key: 'Contact made', label: 'Contact made', count: 0, isChecked: false },
        { key: 'Closed', label: 'Closed', count: 0, isChecked: false }
    ];
    public statusesNps = [
      { key: 'Promoters', label: 'Promoters', count: 0, isChecked: false, tooltip: 'Surveys with the score 9 & 10' },
      { key: 'Passives', label: 'Passives', count: 0, isChecked: false, tooltip: 'Surveys with the score 7 & 8' },
      { key: 'Detractors', label: 'Detractors', count: 0, isChecked: false, tooltip: 'Surveys with the score 6 & below' },
  ];
    public selectedStatuses: string[] = [];
    public selectedStatusesNps: string[] = [];
    public sortOrder = -1;
    public sortBy = 'submittedOn';
    public sortInprogress = {
      nameAndEmail: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
      submittedOn: { 'isActive': true, 'isOpen': false, 'value': 'desc' },
      dtLastUpdatedOn: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
      charterer: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
      npsScore: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
    }
    public sortingFieldType = 'desc';
    public activityDetails: any;
    public backUpActivityDetails: any;
    public isShowActivityPopup: boolean;
    public intervalId: any;
    public statusListMini: any;
    public assignedStatusApply: any; 
    public isUnsavedPopup: boolean;
    public isClickUpdateBtn: boolean;
    public visibleCount: number = 5;
    public showAllComments = false;
    public isOpenDropdown : boolean;
    constructor() { console.log('Model constructor called'); }
}