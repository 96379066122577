import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  shareEmailStatus = new Subject<any>()
  public _shareEmailStatusObservable = this.shareEmailStatus.asObservable();

  bookingLevelChange = new Subject<any>()
  public _bookingLevelChangeObservable = this.bookingLevelChange.asObservable();

  private storageCollection = new Subject<{ key: string, value: any }>();
  public changes = this.storageCollection.asObservable();
  constructor() { 
    this.start();
  }


  ngOnDestroy() {
    this.stop();
  }

  private start(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));

  }

  private stop(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
    this.storageCollection.complete();
  }

  private storageEventListener(event: any) {
    if (event.storageArea == localStorage) {
      let v:any;
      try { v = JSON.parse(event.newValue); }
      catch (e) { v = event.newValue; }
      this.storageCollection.next({ key: event.key, value: v });
    }
  }

  putDataInStorage(storageKey: any, storageData: any) {
    localStorage.setItem(storageKey, storageData);
    this.storageCollection.next({ key: storageKey, value: storageData });
  }

  getDataFromStorage(storageKey: any) {
    return localStorage.getItem(storageKey);
  }

  removeDataFromStorage(storageKey: any) {
    this.storageCollection.next({ key: storageKey, value: null });
    return localStorage.removeItem(storageKey);
  }
}
