import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { GlobalSearchService } from '../../../../services/global-search.service';
import { TitleCasePipe } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [TitleCasePipe]
})
export class SearchBarComponent implements OnInit, OnChanges {

  /**
 * Declaration of Component Variables start
 * cretedAt : 12-02-2023
 * Author : Nisha Saini
 */
  @Input() arrLabel: any;
  @Input() placeholder: any = "Select Tag, Search by booking ID, ship name or port name";
  @Input() submitted: any;
  @Input() selectedValue: any;
  @Input() isSubmit: any;
  @Input() isCustomErr: any;
  @Input() isReadOnly = false;
  @Input() searchTag: string = '';
  @Input() searchString: any = null;
  @Input() isHistory:any=null;
  @Input() isDataStored = false;
  @Output() searchBoxCallBack: EventEmitter<any> = new EventEmitter();
  @Output() clearSearchTag: EventEmitter<any> = new EventEmitter();
  @Output() getSearchValue: EventEmitter<any> = new EventEmitter();
  @Output() callBackTag: EventEmitter<any> = new EventEmitter();
  /**
   * ==================END
   */

  /**
   * Declaration of Component Variables start
   * cretedAt : 12-02-2023
   * Author : Nisha Saini
   */
  reactiveValidate: object = {};
  customValidate: object = {};
  searchBoxForm: any;
  serveLocation: any = [];
  arrLocationList: any = []
  controlSubscribe: any;
  allTags: any;
  AllVessel: boolean = false;
  AllLoadPort: boolean = false;
  AllDischargePort: boolean = false;
  AllCargo: boolean = false;
  AllBookingID: boolean = false;
  arrLocationListBooking: any = [];
  arrLocationListCargo: any = [];
  arrLocationListVessel: any = [];
  arrLocationListLoadPort: any = [];
  arrLocationListDischargePort: any = [];
  searchOptionsSubscribe: any;
  focus:boolean = false;
  isSearchFocus= false;
  stringMatched: boolean=false;
  constructor(
    private _GlobalSearchService: GlobalSearchService,
    private _formBuilder: UntypedFormBuilder,
    private _storageService: StorageService,
    public titleCasePipe: TitleCasePipe
  ) {
 
   }



  ngOnInit() {
    this.searchOptionsSubscriber();
    this.serveLocationFn();
    this.reactiveValidationFun();
    this.formSubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.searchBoxForm){
    this.searchBoxForm.reset()
    if (this.searchString) {
      this.optionSelected = true;
      this.searchBoxForm.patchValue({ szDisplayName: this.searchString.searchKeyword, szTempDisplayName: this.titleCasePipe.transform(this.searchString.searchKeyword) })
    } else {
      this.searchBoxForm.patchValue({ szDisplayName: null, szTempDisplayName: null })
      this.arrLocationListBooking = [];
      this.arrLocationListCargo = [];
      this.arrLocationListVessel = [];
      this.arrLocationListLoadPort = [];
      this.arrLocationListDischargePort = [];
      this.optionSelected = false;
    }

    if (changes?.selectedValue?.currentValue && changes?.selectedValue?.currentValue != changes?.selectedValue?.previousValue) {
      this.resetForm()
    }
    this.isDataStored ?  this.searchBoxForm.controls['szTempDisplayName'].enable() :  this.searchBoxForm.controls['szTempDisplayName'].disable();
  }
  }

  reactiveValidationFun() {
    this.reactiveValidate = {
      szDisplayName: new UntypedFormControl(null, [
        Validators.required,
      ]),
      szTempDisplayName: new UntypedFormControl(null, [
        Validators.required,
      ]),
    }
    this.customValidate = {
    }
    this.searchBoxForm = this._formBuilder.group(this.reactiveValidate, this.customValidate as AbstractControlOptions);
   
  }

  serveLocationFn() {
    let searchOptions: any = this._storageService.getDataFromStorage('searchOptions');
    if (JSON.parse(searchOptions)) {
      let arLocationData = JSON.parse(searchOptions);
      this.serveLocation = arLocationData;
    }
  }


  get f() {
    return this.searchBoxForm.controls
  }

  optionSelected = false;
  onSelect(obj: any) {
    this.isHistory = false;
    this.optionSelected = true;
    let reqHistory: any = {
      searchTag: this.searchTag,
    }
    if (this.searchTag == "Booking ID" || obj?.type == "Booking ID") {
      reqHistory.searchCode = obj.idBooking;
      reqHistory.searchKeyword = obj.idBooking;
      reqHistory.searchTag = obj?.type;

      this.searchBoxForm.patchValue({ szDisplayName: obj.idBooking, szTempDisplayName: obj.idBooking })
      this.callBackTag.emit(reqHistory)
    } else if (this.searchTag == "Cargo Type" || obj?.type == "Cargo Type") {
      reqHistory.searchKeyword = obj.name;
      reqHistory.searchTag = obj?.type;
      reqHistory.searchCode = obj?.idCargo;
      this.searchBoxForm.patchValue({ szDisplayName: obj.name, szTempDisplayName: this.titleCasePipe.transform(obj.name) })
      this.callBackTag.emit(reqHistory)
    } else if (this.searchTag == "Vessel Name" || obj?.type == "Vessel Name") {
      reqHistory.searchKeyword = obj.vesselName;
      reqHistory.searchTag = obj?.type;
      reqHistory.searchCode = obj?.vesselCode;
      this.searchBoxForm.patchValue({ szDisplayName: obj.vesselCode, szTempDisplayName: this.titleCasePipe.transform(obj.vesselName) })
      this.callBackTag.emit(reqHistory)
    } else if (this.searchTag == "Load Port" || obj?.type == "Load Port") {
      reqHistory.searchKeyword = obj.loadPortName;
      reqHistory.searchTag = obj?.type;
      reqHistory.searchCode = obj?.loadPortNumber;
      this.searchBoxForm.patchValue({ szDisplayName: obj.loadPortName, szTempDisplayName: this.titleCasePipe.transform(obj.loadPortName) })
      this.callBackTag.emit(reqHistory)
    } else if (this.searchTag == "Discharge Port" || obj?.type == "Discharge Port") {
      reqHistory.searchKeyword = obj.dischargePortName;
      reqHistory.searchTag = obj?.type;
      reqHistory.searchCode = obj?.dischargePortNumber;
      this.searchBoxForm.patchValue({ szDisplayName: obj.dischargePortName, szTempDisplayName: this.titleCasePipe.transform(obj.dischargePortName) })
      this.callBackTag.emit(reqHistory)
    }
    setTimeout(() => {
      this.searchBoxCallBack.emit(reqHistory)
      this.AllVessel = false;
      this.AllLoadPort = false;
      this.AllDischargePort = false;
      this.AllCargo = false;
      this.AllBookingID = false;
    }, 10);
    this.arrLocationListBooking = [];
    this.arrLocationListCargo = [];
    this.arrLocationListVessel = [];
    this.arrLocationListLoadPort = [];
    this.arrLocationListDischargePort = [];
  }

  onBlur() {
    this.focus = false;
    this.isclearError = false;
    setTimeout(() => {
      this.arrLocationListBooking = [];
      this.arrLocationListCargo = [];
      this.arrLocationListVessel = [];
      this.arrLocationListLoadPort = [];
      this.arrLocationListDischargePort = [];
      this.arrLocationListCargo = [];
      this.AllVessel = false;
      this.AllLoadPort = false;
      this.AllDischargePort = false;
      this.AllCargo = false;
      if (!this.f.szDisplayName.value) {
        this.searchBoxForm.patchValue({ szTempDisplayName: null })
      }
    }, 300)
    this.getSearchValue.emit('')
  }

  onFocus() {
    this.focus = true;
    this.arrLocationListBooking = [];
    this.arrLocationListCargo = [];
    this.arrLocationListVessel = [];
    this.arrLocationListLoadPort = [];
    this.arrLocationListDischargePort = [];
    this.AllVessel = false;
    this.AllLoadPort = false;
    this.AllDischargePort = false;
    this.AllCargo = false;
    this.isHistory = false;
    this.optionSelected = false;
    this.searchString = null;
  }

  formSubscribe() {
    /**
     * ==============================================================================================================
     *                                      FORM CONTROL SUBSCRIBER START
     * ===============================================================================================================
     */
    this.controlSubscribe = this.searchBoxForm.controls.szTempDisplayName.valueChanges.subscribe(async (newVal: any) => {
      if (this.searchTag && newVal && newVal.length > 2) {
        this.AllVessel = false;
        this.AllLoadPort = false;
        this.AllDischargePort = false;
        this.AllCargo = false;
        this.AllBookingID = false;
        let term = newVal.toLocaleLowerCase();
        if (this.searchTag == "Booking ID") {
          this.searchBookingId(term);
        }
        if (this.searchTag == "Cargo Type") {
          this.searchCargoType(term);
        }
        if (this.searchTag == "Vessel Name") {
          this.searchVesselName(term);
        }
        if (this.searchTag == "Load Port") {
          this.searchLoadPort(term);
        }
        if (this.searchTag == "Discharge Port") {
          this.searchDischargePort(term);
        }
      } else {
        this.arrLocationListBooking = [];
        this.arrLocationListCargo = [];
        this.arrLocationListVessel = [];
        this.arrLocationListLoadPort = [];
        this.arrLocationListDischargePort = [];
      }
    });


    /**
     * ==============================================================================================================
     *                                      FORM CONTROL SUBSCRIBER START
     * ===============================================================================================================
     */

  }

  searchBookingId(term:any){
    let filterBooking: any = [];
    for (let dp of this.serveLocation) {
      let myBooking = JSON.stringify(dp.idBooking);
      if (myBooking.includes(term)) {
        filterBooking.push({ idBooking: dp.idBooking, type: "Booking ID" })
        this.AllBookingID = true
      }
    }
    this.arrLocationListBooking = _.uniqBy(filterBooking, (obj: any) => obj.idBooking).slice(0, 50);
    this.arrLocationListBooking.sort((a:any, b:any) =>  {
      if (a.idBooking > b.idBooking) return 1;
      if (b.idBooking > a.idBooking) return -1;
      return 0;
    })
    if(this.arrLocationListBooking.length>0){
      this.stringMatched=true;
    }
    else{
      this.stringMatched=false
    }
  }

  searchCargoType(term:any){
    let filterCargo: any = [];
    this.serveLocation.some((it: any) => {
      it.arVessel.some((el: any) => {
        el.arPorts.some((dp: any) => {
          dp.arCargoes.some((cr: any) => {
            if(cr.name){
              if (cr.name.toLocaleLowerCase().includes(term)) {
                this.AllCargo = true;
              }
            }
          })
        })
      })
    })
    this.serveLocation.filter((it: any) => {
      it.arVessel.filter((el: any) => {
        el.arPorts.filter((dp: any) => {
          dp.arCargoes.filter((cr: any) => {
            if(cr.name){
              if (cr.name.toLocaleLowerCase().indexOf(term) > -1) {
                return filterCargo.push({ idCargo: cr.idCargo, name: cr.name, type: "Cargo Type" })
              }
            }
          })
        })
      })
    })
    this.refactorSearchCargoType(filterCargo);
  }

  refactorSearchCargoType(filterCargo:any){
    this.arrLocationListCargo = _.uniqBy(filterCargo, (obj: any) => obj.name).slice(0, 50);
    this.arrLocationListCargo.sort((a:any, b:any) =>  {
      if (a.name > b.name) return 1;
      if (b.name > a.name) return -1;
      return 0;
    });
    if(this.arrLocationListCargo.length>0){
      this.stringMatched=true;
    }
    else{
      this.stringMatched=false
    }
  }

  searchVesselName(term:any){
    this.serveLocation.some((b: any) => (b.arVessel.some((a: any) => {
      if (a.vesselName.toLocaleLowerCase().includes(term)) {
        this.AllVessel = true;
      }
    })))

    let filterVess: any = [];
    this.serveLocation.filter((it: any) => {
      it.arVessel.filter((el: any) => {
        if (el.vesselName.toLocaleLowerCase().includes(term)) {
          filterVess.push({ vesselName: el.vesselName, vesselCode: el.vesselCode, type: "Vessel Name" })
        }
        return filterVess;
      })
    })
    this.arrLocationListVessel = _.uniqBy(filterVess, (obj: any) => obj.vesselName).slice(0, 50);
    this.arrLocationListVessel.sort((a:any, b:any) =>  {
      if (a.vesselName > b.vesselName) return 1;
      if (b.vesselName > a.vesselName) return -1;
      return 0;
    });
    if(this.arrLocationListVessel.length>0){
      this.stringMatched=true;
    }
    else{
      this.stringMatched=false
    }
  }

  searchLoadPort(term:any){
    let filterLoadport: any = [];
    this.serveLocation.some((it: any, index: number) => {
      it.arVessel.some((el: any) => {
        el.arPorts.some((dp: any) => {
          if(dp.loadPortName){
            if (dp.loadPortName.toLocaleLowerCase().includes(term)) {
              this.AllLoadPort = true;
            }
          }
        })
      })
    })
    this.serveLocation.map((it: any) => {
      it.arVessel.filter((el: any) => {
        el.arPorts.filter((dp: any) => {
          if(dp.loadPortName){
            if (dp.loadPortName.toLocaleLowerCase().indexOf(term) > -1) {
              filterLoadport.push({ loadPortName: dp.loadPortName, loadPortNumber: dp.loadPortNumber, type: "Load Port" });
            }
          }
         
        })
      })
    })
    this.refactorSearchLoadPort(filterLoadport);
  }

  refactorSearchLoadPort(filterLoadport:any){
    this.arrLocationListLoadPort = _.uniqBy(filterLoadport, (obj: any) => obj.loadPortName).slice(0, 50);
    this.arrLocationListLoadPort.sort((a:any, b:any) =>  {
      if (a.loadPortName > b.loadPortName) return 1;
      if (b.loadPortName > a.loadPortName) return -1;
      return 0;
    });
    if(this.arrLocationListLoadPort.length>0){
      this.stringMatched=true;
    }
    else{
      this.stringMatched=false
    }
  }

  searchDischargePort(term:any){
    let filterDischargeport: any = []
    this.serveLocation.some((it: any) => {
      it.arVessel.some((el: any) => {
        el.arPorts.some((dp: any) => {
          if(dp.dischargePortName){
            if (dp.dischargePortName.toLocaleLowerCase().includes(term)) {
              this.AllDischargePort = true;
            }
          }
        })
      })
    })
    this.serveLocation.map((it: any) => {
      it.arVessel.filter((el: any) => {
        el.arPorts.filter((dp: any) => {
          if(dp.dischargePortName){
            if (dp.dischargePortName.toLocaleLowerCase().indexOf(term) > -1) {
              filterDischargeport.push({ dischargePortName: dp.dischargePortName, dischargePortNumber: dp.dischargePortNumber, type: "Discharge Port" });
            }
          }
        })
      })
    })
    this.refactorDearchDischargePort(filterDischargeport);
  }

  refactorDearchDischargePort(filterDischargeport:any){
    this.arrLocationListDischargePort = _.uniqBy(filterDischargeport, (obj: any) => obj.loadPortName).slice(0, 50);
    this.arrLocationListDischargePort.sort((a:any, b:any) =>  {
      if (a.loadPortName > b.loadPortName) return 1;
      if (b.loadPortName > a.loadPortName) return -1;
      return 0;
    });
    if(this.arrLocationListDischargePort.length>0){
      this.stringMatched=true;
    }
    else{
      this.stringMatched=false
    }
  }

  resetForm() {
    this.searchTag = "";
    this.AllVessel = false;
    this.AllLoadPort = false;
    this.AllDischargePort = false;
    this.AllCargo = false;
    this.arrLocationListBooking = [];
    this.arrLocationListCargo = [];
    this.arrLocationListVessel = [];
    this.arrLocationListLoadPort = [];
    this.arrLocationListDischargePort = [];
    this.searchString=null
    this.searchBoxForm.reset();
    this.clearSearchTag.emit(true)
  }

  searchOptionsSubscriber() {
    this.searchOptionsSubscribe = this._GlobalSearchService.searchDataCast.subscribe((updatedValue) => {
      this.serveLocation = updatedValue;
    })
  }

  isclearError = false;
  clearError(event: any) {
    let strlen = event.target.value
    this.isclearError = strlen.length > 1 ? true : false;
    this.getSearchValue.emit(this.searchBoxForm.controls.szTempDisplayName.value)
  }

  focusFunction() {
    this.isSearchFocus = true
  }

  focusOutFunction() {
    this.isSearchFocus = false
  }

  OnClickDiv()
  {
    this.isSearchFocus = true
  }


}

