import { Component, ViewChild,Input, Output, EventEmitter, OnChanges, HostListener } from '@angular/core';
import { Listing, VesselData, LatLong, LatLongHistory } from "../../../models/booking";
import { AgmType } from '../../../enums/agm';
import { BookingService } from '../../../services/booking.service';
import { Base64 } from '../../../helpers/fleetEncodeDecode';
import { CpBlCheck } from '../../../enums/inProgressFilter';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-agm-map',
  templateUrl: './agm-map.component.html',
  styleUrls: ['./agm-map.component.scss']
})
export class AgmMapComponent implements OnChanges {
  enroute = CpBlCheck.enRoute;
  loading = CpBlCheck.loading;
  wordMapRestriction = { latLngBounds: {
    north: 84.7165083856327, // lat
    south: -82.08566814187866, // lat
    east: 177.03434272166055, // lng
    west: -172.19959424563754, // lng
   }  
};
  seaMatrixMultipleApi: any[] = [];
  waypointsArraySeaMatrix: any[] = [];
  previousProtTravelWaypointsySeaMatrix: any[] = [];
  pathCompletedseaMatrixMultipleApi: any[] = [];
  missingLocationHistorySeaMatrix: any[] = [];
  locIcon = "assets/img/map-marker.png";
  locIconCurrent = "assets/img/map-arrow.png";
  mapDot = "assets/img/map-dot.png";
  portDot = "assets/img/port-dot.png";
  passingPort = "assets/icons/Passing-Port.svg";
  priorPort = "assets/icons/Prior-Port.svg";
  activityPort = "assets/icons/Activity-Port-Port.svg";

  vessalLocationIcon = "assets/icons/Vessel-location.svg";
  markerClustererImagePath =
    'assets/img/circle-layer';
  lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 100,
    scale: 2,
  };

  @Input() trackLatLong: { 'lat': any, 'lng': any };
  isDisplayMap: boolean = false;

  latitude = 53.0000;
  longitude = 9.0000;

  defOptionBookingId = { id: 0, vcode: '', vno: 0 };
  changeBookingId = 0;
  @Input() bookingList: Listing[];
  @Input() bookingVariance: string;
  @Input() vsl_name: string;
  @Input() bookingType: boolean;
  agmInfoWindow: Listing[] = [];
  vessalAgmInfo: Listing[] = [];

  arVesselsLocation: LatLong[] = [];
  @Output() agmShareDownload: EventEmitter<object> = new EventEmitter();
  @Input() mapType: any;
  @Input() fleetArray: any[];
  @Input() vessalArray: VesselData[];
  @Input() snitsfleetArray:any[]
  styles: any[];
  infoWindowOpened = null
  previous_info_window = null

  currentIW: any;
  previousIW: any;

  @Input() sItem: any;
  @Input() snitsItem:any

   /* -----------googlemap --------*/
   options: google.maps.MapOptions;
 
 @ViewChild(MapInfoWindow,{ static: false }) infoWindow: MapInfoWindow;
 @ViewChild('googleMap', { static: true }) googleMap: GoogleMap;
 @ViewChild('mapMarker', { static: false }) mapMarker: any[];
 markerCluster?: MarkerClusterer
  TBD_Flag: boolean= false;
  bookingIndex: any;
    constructor(private bookingService: BookingService,private router: Router,private _storageService: StorageService,) {
      let innerWidth = window.innerWidth;
      if (innerWidth > 3500){
        this.zoom = 4.4;
        this.minZoom = 4.4;
      }else if(innerWidth > 2700){
        this.zoom = 4;
        this.minZoom = 4;
      }
      else if(innerWidth > 2048){
        this.zoom = 3.3;
        this.minZoom = 3.3;
      }else if (innerWidth >= 1600){
        this.zoom = 3;
        this.minZoom = 3;
      } else if (innerWidth >= 1440){
        this.zoom = 2.4;
        this.minZoom = 2.4;
      }else if (innerWidth >= 1366){
        this.zoom = 2.3;
        this.minZoom = 2.3;
      }
      else if(innerWidth > 1024){
        this.zoom = 2.2;
        this.minZoom = 2.2;
      } else if (innerWidth >= 745){
        this.zoom = 3;
        this.minZoom = 2;
      } else {
        this.zoom = 2;
        this.minZoom = 1;
      }
   }



  ngOnChanges() {
    this.mapUsedInComp(this.mapType);
  }

  initClusterer(event: any) {
    if (!this.googleMap?.googleMap || this.markerCluster) return
    this.markerCluster = new MarkerClusterer({
      map: this.googleMap.googleMap,
      markers: this.mapMarker,
    })
  }
  agmShareDownloadFunc(fType: string, itm: any) {
    const enfleet = Base64.decode(itm.fleet);
    const data = {
      'id': itm.idBooking,
      'bookingStatus': itm.bookingStatus,
      'fleet': enfleet,
      'eta': this.getEta(itm),
      'vessel': itm.arVessel[0].vesselName,
      'idCargoes': [],
      'id_fleet': `${itm.idBooking}__${enfleet}`,
    }
    this.agmShareDownload.emit({ 'type': fType, 'data': data });
  }

  getEta(itm:any){
    if((this.multiVessalDelay(itm.arVessel) > 0)){
      return 'Delayed';
    }
    if(this.multiVessalDelay(itm.arVessel) < 0 ){
      return 'Early Arrival';
    } else {
      return 'On time';
    }
  }

  multiVessalDelay(it: any[]) {
    if(this.bookingVariance === 'All'){
      it.sort((a: any, b: any) => this.delay(b.totalDelayInDays) - this.delay(a.totalDelayInDays))
    } else {
      it.sort((a: any, b: any) => this.delay(b.delayDaysInLast24Hrs) - this.delay(a.delayDaysInLast24Hrs));
    }
    return this.bookingVariance === 'All' ? this.delay(it[0].totalDelayInDays) : this.delay(it[0].delayDaysInLast24Hrs);
  }
  mapClick() {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }
  openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }
  markerClick(marker: MapMarker, li: any, id: number) {
    this.infoWindow.open(marker);
    this.vessalAgmInfo = this.bookingList.filter((it) => it.arVessel.some((i) => i.vesselCode == li.vesselCode && i.idVoyageNumber == li.idVoyageNumber && it.idBooking === id));
    this.agmInfoWindow = this.bookingList.filter((it) => it.arVessel.some((i) => i.vesselCode == li.vesselCode && i.idVoyageNumber == li.idVoyageNumber));
    this.changeBookingId = id;
    this.defOptionBookingId = { id: this.changeBookingId, vcode: li.vesselCode, vno: li.idVoyageNumber }
  }
  changeAgmInfoData() {
    this.defOptionBookingId.id = this.changeBookingId;
    this.vessalAgmInfo = this.bookingList.filter((it) => it.arVessel.some((i) => i.vesselCode ==
      this.defOptionBookingId.vcode && i.idVoyageNumber == this.defOptionBookingId.vno && it.idBooking === this.defOptionBookingId.id));
    this.vessalAgmInfo = this.vessalAgmInfo.map((it) => {
      return {
        ...it, arVessel: it.arVessel.filter((i: any) => i.vesselCode ==
          this.defOptionBookingId.vcode && i.idVoyageNumber == this.defOptionBookingId.vno && it.idBooking === this.defOptionBookingId.id)
      }
    });
  }
  delay(val: any) {
    return Math.round(val);
  }

  historyLatLong = (data: LatLongHistory[]) => {
    return data.map(it => ({ 'lat': parseFloat(it.latitude), 'lng': parseFloat(it.longitude) }));
  }

  refactorTrack()
  {
    const vsl_arrayTrack = this.vessalArray.map(it => ({ ...it, arPorts: it.arPorts?.filter(e => !e.isPreviousPort) }));
    const imoArrayTrack = vsl_arrayTrack.map((i: any) => (i.imo_no));
    const start_dateTrack = vsl_arrayTrack[0].arPorts && vsl_arrayTrack[0].arPorts.length > 0 && vsl_arrayTrack[0].arPorts[0].dtPlannedETA ? vsl_arrayTrack[0].arPorts[0]?.dtPlannedETA : '';
    const end_dateTrack = vsl_arrayTrack[0].arPorts && vsl_arrayTrack[0].arPorts.length > 0 && vsl_arrayTrack[0].arPorts[vsl_arrayTrack[0].arPorts.length - 1].dtPlannedETA ? vsl_arrayTrack[0].arPorts[vsl_arrayTrack[0].arPorts.length - 1]?.dtPlannedETA : '';
   
    if (imoArrayTrack) {
      this.bookingService.getVesselsLocation(imoArrayTrack, start_dateTrack, end_dateTrack).subscribe((res) => {
        if (res && res.data) {
          const locationHistory = res.data;
          if (locationHistory.length > 0 && locationHistory[0].history && locationHistory[0].history.length > 0) {
            const len = locationHistory[0].history.length - 1;
            this.latitude = Number(locationHistory[0].history[len].latitude);
            this.longitude = Number(locationHistory[0].history[len].longitude);
          }
          this.arVesselsLocation = locationHistory.map((it: LatLong) => ({ ...it, history: this.historyLatLong(it.history) }));
        }
      })
    }
    this.styles = this.bookingCommanStyle();
    this.options = {
      zoom: this.zoom,
      maxZoom: 15,
      minZoom: this.minZoom,
      styles: this.styles,
      streetViewControl: false,
      center: { lat: this.latitude, lng: this.longitude },
      restriction: this.wordMapRestriction
    };
  }

  refactorBookingDetails()
  {
    this.locIcon = "assets/icons/loc-arrow.png";
    const vsl_array = this.vessalArray.map(it => ({ ...it, arPorts: it.arPorts?.filter(e => !e.isPreviousPort) }));
    const imoArray = vsl_array.map((i: any) => (i.imo_no));
    
    const start_date = vsl_array[0].arPorts && vsl_array[0].arPorts.length > 0 && vsl_array[0].arPorts[0].dtPlannedETA_UTC ? 
    new Date(new Date(vsl_array[0].arPorts[0]?.dtPlannedETA_UTC).setHours(new Date(vsl_array[0].arPorts[0]?.dtPlannedETA_UTC).getHours() - 1)) : '';
    const end_date = vsl_array[0].arPorts && vsl_array[0].arPorts.length > 0 && vsl_array[0].arPorts[vsl_array[0].arPorts.length - 1].dtPlannedETA_UTC ? vsl_array[0].arPorts[vsl_array[0].arPorts.length - 1]?.dtPlannedETA_UTC : '';
    const vessalPostion = this.vessalArray.map(it => ({ ...it, arPorts: it.arPorts?.filter(i => !i.isPreviousPort) }));
    if (imoArray && this.bookingType) {
      this.refactorGetVesselLocation(imoArray, start_date, end_date)
     
    } else {
      this.drawRoutes(vessalPostion, []);
    }

    this.options = {
      zoom: this.zoom,
      minZoom: this.minZoom,
      styles: this.styles,
      streetViewControl: false,
      center: { lat: this.latitude, lng: this.longitude },
      restriction: this.wordMapRestriction
    };

  }

  zoom = 3;
  minZoom = 3;
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    let innerWidth = event.target.innerWidth;
    if (innerWidth > 3500){
      this.zoom = 4.4;
      this.minZoom = 4.4;
    }else if(innerWidth > 2700){
      this.zoom = 4;
      this.minZoom = 4;
    }
    else if(innerWidth > 2048){
      this.zoom = 3.3;
      this.minZoom = 3.3;
    }else if (innerWidth >= 1600){
      this.zoom = 3;
      this.minZoom = 3;
    } else if (innerWidth >= 1440){
      this.zoom = 2.4;
      this.minZoom = 2.4;
    }else if (innerWidth >= 1366){
      this.zoom = 2.3;
      this.minZoom = 2.3;
    }
    else if(innerWidth > 1024){
      this.zoom = 2.2;
      this.minZoom = 2.2;
    } else if (innerWidth >= 745){
      this.zoom = 3;
      this.minZoom = 2;
    } else {
      this.zoom = 2;
      this.minZoom = 1;
    }
    this.options = {
      zoom: this.zoom,
      minZoom: this.minZoom,
      streetViewControl: false,
      center: { lat: this.latitude, lng: this.longitude },
      restriction: this.wordMapRestriction
    };
  }

  latLnglocationHistory:any[] = [];
  combinedSeaMatrix: any[] = [];
  combinedAllLatLng:any[] = [];
  isPolygon: boolean = false;
  refactorGetVesselLocation(imoArray:any, start_date:any, end_date:any)
  {
    const vessalPostion = this.vessalArray.map(it => ({ ...it, arPorts: it.arPorts?.filter(i => !i.isPreviousPort) }));
    this.bookingService.getVesselsLocation(imoArray, start_date, end_date).subscribe((res) => {
      if (res?.data) {
        const locationHistory = res.data;
        this.refactorVesselLocation(locationHistory)
        this.arVesselsLocation = locationHistory.map((it: LatLong) => ({ ...it, history: this.historyLatLong(it.history) }));
        this.drawRoutes(vessalPostion, this.arVesselsLocation);
      } else {
        this.drawRoutes(vessalPostion, this.arVesselsLocation);
      }
    });
  }

  
  refactorVesselLocation(locationHistory:any)
  {
    const vsl_array = this.vessalArray.map(it => ({ ...it, arPorts: it.arPorts?.filter(e => !e.isPreviousPort) }));
    if (locationHistory.length > 0 && locationHistory[0].history && locationHistory[0].history.length > 0) {
        this.latLnglocationHistory = locationHistory[0].history;
        let middleIndex = (locationHistory[0].history.length) / 2; 
        const isInteger = Number.isInteger(middleIndex);
        if(!isInteger){
          middleIndex = (locationHistory[0].history.length + 1) / 2;
        }
        this.latitude = Number(locationHistory[0].history[middleIndex].latitude);
        this.longitude = Number(locationHistory[0].history[middleIndex].longitude);
        
      const missingPort = vsl_array.map(it => ({ ...it, arPorts: it.arPorts?.filter(i => i.dtPlannedETA_UTC && new Date(i?.dtPlannedETA_UTC) <= new Date(locationHistory[0].history[0].updatedDate)) }));
      this.options = {
        zoom: this.zoom,
        minZoom: this.minZoom,
        styles: this.styles,
        streetViewControl: false,
        center: { lat: this.latitude, lng: this.longitude },
        restriction: this.wordMapRestriction
      };
      this.refactMissingPort(missingPort)
    
    }
  }

  // Check Polygon
  checkPolygon(lat:any,lng:any){
    type Point = [number, number];
  
    const polygon: Array<Point> = [
      [46.55886, -134.648636],
      [-0.35156, -133.238841],
      [1.406109, -212.715947],
      [40.625073, -213.044049],
    ];
      const withinPoint: Point = [lat, lng];
    
    const isWithinCoordinates = (point: Point, polygon: Array<Point>) => {
      const [lat, lng] = point;
    
      let isInside = false;
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const iLat = polygon[i][0];
        const iLng = polygon[i][1];
    
        const jLat = polygon[j][0];
        const jLng = polygon[j][1];
    
        const isIntersecting =
          iLng > lng != jLng > lng &&
          lat < ((jLat - iLat) * (lng - iLng)) / (jLng - iLng) + iLat;
    
        if (isIntersecting) isInside = !isInside;
      }
    
      return isInside;
    };
    
    const within = isWithinCoordinates(withinPoint, polygon);
    if(within){
      this.isPolygon = true;
    }
  }
  // End Check Polygon

 refactMissingPort(missingPort:any)
  {
    let payloadArr: any[] = [];
    missingPort.forEach((element:any) => {
      element.arPorts?.forEach(async (el:any, index:any, arr:any) => {
        let allowAccess=0;
        if(arr[index].isCanalPort || (arr[index+1] && arr[index+1].isCanalPort)){
          allowAccess = arr[index].portName === "SUEZ CANAL" || (arr[index+1] && arr[index+1].portName === "SUEZ CANAL") ? 7 : 5;
        } 

        if (index < arr.length - 1) {
          payloadArr.push({
            startLon:arr[index].long,
            startLat:arr[index].lat,
            endLon:arr[index + 1].long,
            endLat:arr[index + 1].lat,
            allowedAreas: [allowAccess] 
          })
        } else {
          payloadArr.push({
            startLon:arr[index].long,
            startLat:arr[index].lat,
            endLon:this.longitude,
            endLat:this.latitude,
            allowedAreas: [allowAccess]  
          })
        }
      })
    });
    this.callApi(payloadArr).then((response: any) => {
      this.combinedSeaMatrix = [];
      this.combinedAllLatLng = [];
      this.isPolygon = false;
      if (response.data?.length) {
        this.isDisplayMap = true;
        response.data.forEach((element:any) => {
          this.missingLocationHistorySeaMatrix.push({ waypoints:element.waypoints.flatMap((it: any) => ({ lat: it.lat, lng: it.lon }))});
          this.combinedSeaMatrix.push( element.waypoints.flatMap((it: any) => ({ latitude: it.lat, longitude: it.lon })));
        });
        this.combinedAllLatLng = [...this.latLnglocationHistory, ...this.combinedSeaMatrix.flat()];
        for(let value of this.combinedAllLatLng){
          this.checkPolygon(value.latitude, value.longitude);
        }
        const len = this.combinedAllLatLng.length - 1;
        let lat1 = Number(this.combinedAllLatLng[0].latitude);
        let lat2 = Number(this.combinedAllLatLng[len].latitude);
        let lng1 =  Number(this.combinedAllLatLng[0].longitude);
        let lng2 = Number(this.combinedAllLatLng[len].longitude);  

        if(this.isPolygon){
          this.wordMapRestriction = { latLngBounds: {
            north: 84.76484629569858,
            south: -61.6603100019315,
            east: -21.902206500283246,
            west: -17.859234261307293,
           }  
        };
            this.latitude = 30.244710218847914;
            this.longitude = -173.10034107401796;
        }else {
          this.latitude = (lat1 + lat2)/2;
          this.longitude = (lng1 + lng2)/2;
        }

        this.options = {
            zoom: this.zoom,
            minZoom: this.minZoom,
            styles: this.styles,
            streetViewControl: false,
            center: { lat: this.latitude, lng: this.longitude },
            restriction: this.wordMapRestriction
          };
      }
    });
  }
  mapUsedInComp(val: string) {
    switch (val) {
      case AgmType.booking:
        this.isDisplayMap = true;
        this.styles = this.bookingCommanStyle();
        this.options = {
          zoom: this.zoom,
          maxZoom: 15,
          minZoom: this.minZoom,
          styles: this.styles,
          streetViewControl: false,
          center: { lat: this.latitude, lng: this.longitude },
          restriction: this.wordMapRestriction
        };
        setTimeout(() => {
          this.isDisplayMap = true;
        }, 1000)
        return;
      case AgmType.track:
        this.refactorTrack()
        
        return;
      case AgmType.bookingDetail:
        this.styles = this.bookingDetailStyle();
        this.refactorBookingDetails()
        
        return;
      case AgmType.fleet:
        this.locIcon = 'assets/img/map-marker.png';
        this.styles = this.fleetCommanStyle();
        this.sItem.position = { lat: parseFloat(this.sItem.last_latitude), lng: parseFloat(this.sItem.last_longitude) };
        this.options = {
          zoom: this.zoom,
          maxZoom: 15,
          minZoom: this.minZoom,
          styles: this.styles,
          streetViewControl: false,
          center: { lat: this.sItem.last_latitude, lng: this.sItem.last_longitude},
          restriction: this.wordMapRestriction
        };
        return;
      case AgmType.fleetmultiple:
        this.locIcon = 'assets/img/map-dot.png';
        this.styles = this.fleetCommanStyle();
        this.options = {
          zoom: this.zoom,
          maxZoom: 15,
          minZoom: this.minZoom,
          styles: this.styles,
          streetViewControl: false,
          center: { lat: this.fleetArray.length > 0 ? parseFloat(this.fleetArray[0].last_latitude) : 52.3555, lng: this.fleetArray.length > 0 ? parseFloat(this.fleetArray[0].last_longitude) : 1.1743 },
          restriction: this.wordMapRestriction
        };

        this.fleetArray = this.fleetArray.map((i: any) => ({
          lat: parseFloat(i.last_latitude), lng: parseFloat(i.last_longitude), icon: this.locIcon
        }));

        return;
        case AgmType.snits:
        this.locIcon = 'assets/img/map-marker.png';
        this.styles = this.fleetCommanStyle();
        this.snitsItem.position = { lat: parseFloat(this.snitsItem.last_latitude), lng: parseFloat(this.snitsItem.last_longitude) };
        this.options = {
          zoom: this.zoom,
          maxZoom: 15,
          minZoom: this.minZoom,
          styles: this.styles,
          streetViewControl: false,
          center: { lat: this.snitsItem.last_latitude, lng: this.snitsItem.last_longitude},
          restriction: this.wordMapRestriction
        };
        return;
        case AgmType.snitsfleetmultiple:
        this.locIcon = 'assets/img/map-dot-yallow.png';
        this.styles = this.fleetCommanStyle();
        this.options = {
          zoom: this.zoom,
          maxZoom: 15,
          minZoom: this.minZoom,
          styles: this.styles,
          streetViewControl: false,
          center: { lat: this.snitsfleetArray.length > 0 ? parseFloat(this.snitsfleetArray[0].last_latitude) : 52.3555, lng: this.snitsfleetArray.length > 0 ? parseFloat(this.snitsfleetArray[0].last_longitude) : 1.1743 },
          restriction: this.wordMapRestriction
        };

        this.snitsfleetArray = this.snitsfleetArray.map((i: any) => ({
          lat: parseFloat(i.last_latitude), lng: parseFloat(i.last_longitude), icon: this.locIcon
        }));

        return;
      default:
        this.locIcon = 'assets/img/map-marker.png';
        this.styles = this.fleetCommanStyle();
        this.options = {
          zoom: this.zoom,
          minZoom: this.minZoom,
          styles: this.styles,
          streetViewControl: false,
          center: { lat: this.latitude, lng: this.longitude },
          restriction: this.wordMapRestriction
        };
        return;
    }
  }

  drawRoutes = async (vessalPostion: VesselData[], arVesselsLocation: LatLong[]) => {
    let payloadArr: any[] = [];
    vessalPostion.forEach((element) => {
      element.arPorts?.forEach(async (el, index, arr) => {
        let allowAccess=0;
        if(arr[index-1].isCanalPort || arr[index].isCanalPort){
          allowAccess = arr[index-1].portName === "SUEZ CANAL" || arr[index].portName === "SUEZ CANAL" ? 7 : 5;
        } 
        if (arVesselsLocation.length > 0 && el.isPortCompleted) {
          this.isDisplayMap = true;
          
        }
        else if (index === arr.findIndex(({ isPortCompleted }) => !isPortCompleted) && arVesselsLocation.length > 0 &&
          arVesselsLocation[arVesselsLocation.length - 1].history && arVesselsLocation[arVesselsLocation.length - 1].history.length > 0) {
          const len = arVesselsLocation.length - 1;
          const hislen = arVesselsLocation[len].history.length - 1//481
          const lt = arVesselsLocation[len].history[hislen].lat;
          const ln = arVesselsLocation[len].history[hislen].lng;
          
          payloadArr.push({
            startLon:ln,
            startLat:lt,
            endLon:el.long,
            endLat:el.lat,
            allowedAreas: [allowAccess]  
          })
          this.isDisplayMap = true;
        }
        else {
          this.isDisplayMap = true;
          payloadArr.push({
            startLon:arr[index - 1].long,
            startLat:arr[index - 1].lat,
            endLon:arr[index].long,
            endLat:arr[index].lat,
            allowedAreas: [allowAccess]  
          })
        }
      });

    });
    this.callApi(payloadArr).then((respo: any) => {
      this.refactCallApi(respo)
      
    })

  }
  refactCallApi(respo:any){
    this.combinedSeaMatrix = [];
    this.combinedAllLatLng = [];
    this.isPolygon = false;
    if (respo.data?.length) {
      respo.data.forEach((element:any)=>{
        this.waypointsArraySeaMatrix.push(element.waypoints.flatMap((it: any) => ({ lat: it.lat, lng: it.lon })))

        this.combinedSeaMatrix.push( element.waypoints.flatMap((it: any) => ({ latitude: it.lat, longitude: it.lon })));
        this.combinedAllLatLng = [...this.latLnglocationHistory, ...this.combinedSeaMatrix.flat()];
        for(let value of this.combinedAllLatLng){
          this.checkPolygon(value.latitude, value.longitude);
        }
        const len = this.combinedAllLatLng.length - 1;
        let lat1 = Number(this.combinedAllLatLng[0].latitude);
        let lat2 = Number(this.combinedAllLatLng[len].latitude);
        let lng1 =  Number(this.combinedAllLatLng[0].longitude);
        let lng2 = Number(this.combinedAllLatLng[len].longitude);  

        if(this.isPolygon){
          this.wordMapRestriction = { latLngBounds: {
            north: 84.76484629569858,
            south: -61.6603100019315,
            east: -21.902206500283246,
            west: -17.859234261307293,
           }   
        };
            this.latitude = 30.244710218847914;
            this.longitude = -173.10034107401796;
        }else {
          this.latitude = (lat1 + lat2)/2;
          this.longitude = (lng1 + lng2)/2;
        }

        this.options = {
            zoom: this.zoom,
            minZoom: this.minZoom,
            styles: this.styles,
            streetViewControl: false,
            center: { lat: this.latitude, lng: this.longitude },
            restriction: this.wordMapRestriction
        };

      })
    }
  }
  callApi = (it: any) => {
    return new Promise((resolve, reject) => {
      this.bookingService.wayPointsPreviousPortAPiCallPromise(it).then((res: any) => {
        resolve(res);
      })
    })
  }
  
  goToBooking(el:any) {
    this._storageService.putDataInStorage('isMapActive',true);
    this.router.navigate(['booking', el?.fleet, el?.idBooking]);
  }
  bookingDetailStyle() {
    return [
      { 'elementType': "geometry", 'stylers': [{ 'color': "#565656" }] },
      {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#353535"
          }
        ]
      },
      {
        'featureType': "administrative.country",
        'elementType': "labels",
        'stylers': [
          { 'color': '#D2D2D2' }
        ]
      },
      {
        "featureType": "administrative.country",
        'elementType': "labels.text.fill",
        'stylers': [
          { 'visibility': 'off' }
        ]
      },
      {
        "featureType": "administrative.country",
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'color': '#D2D2D2'
          }
        ]
      },

      {
        "featureType": "administrative.province",
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        "featureType": "administrative.province",
        'elementType': "labels.text.fill",
        'stylers': [
          { 'visibility': 'off' }
        ]
      },
      {
        "featureType": "administrative.province",
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },

      {
        "featureType": "administrative.locality",
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        'elementType': "labels.text.fill",
        'stylers': [
          { 'visibility': 'off' }
        ]
      },
      {
        "featureType": "administrative.locality",
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },

      {
        "featureType": "poi",
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        "featureType": "poi",
        'elementType': "labels.text.fill",
        'stylers': [
          { 'visibility': 'off' }
        ]
      },
      {
        "featureType": "poi",
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },

      {
        "featureType": "road",
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        "featureType": "road",
        'elementType': "labels.text.fill",
        'stylers': [
          { 'visibility': 'off' }
        ]
      },
      {
        "featureType": "road",
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },

      {
        "featureType": "transit",
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        "featureType": "transit",
        'elementType': "labels.text.fill",
        'stylers': [
          { 'visibility': 'off' }
        ]
      },
      {
        "featureType": "transit",
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },

      {
        'featureType': 'water',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'color': '#353535'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [{ 'color': '#D2D2D2' }],
      },
    ];
  }

  bookingCommanStyle() {
    return [
      { 'elementType': "geometry", 'stylers': [{ 'color': "#373850" }] },
      {
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'elementType': "labels.text.fill",
        'stylers': [
          {
            'visibility': "off"
          }
        ]
      },
      {
        'elementType': "labels.text.stroke",
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'color': '#16192C'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [{ 'visibility': 'off' }],
      },
    ];
  }


  fleetCommanStyle() {
    return [
      {
        'elementType': 'geometry',
        'stylers': [{ 'color': 'BCB3E2' }]
      },
      {
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },

      {
        'featureType': 'administrative',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'landscape.natural',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [{ 'visibility': 'off' }],
      },

      {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [
          {
            'color': '#FFFFFF'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [{ 'visibility': 'off' }],
      },
    ];
  }

  OnMouseOver(index:any)
  {
   this.TBD_Flag = true;
   this.bookingIndex = index;
  }
  
  OnMouseleave(index:any)
  {
   this.TBD_Flag = false;
   this.bookingIndex = index;
  }
  
  closePopup(){
    this.infoWindow.close();
  }
}
