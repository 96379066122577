import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nps-survey-monkey',
  templateUrl: './nps-survey-monkey.component.html',
  styleUrls: ['./nps-survey-monkey.component.scss']
})
export class NpsSurveyMonkeyComponent {
 @Input() bookingId:any; 
 @Input() surveyUrl:any; 
 @Input() userId:any;
  url: SafeResourceUrl | null = null; // Safe URL type
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  @Output() surveyClosed = new EventEmitter<string>();
  constructor(private navigationService: NavigationService, private sanitizer: DomSanitizer){
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] && this.userId && this.bookingId && this.surveyUrl) {
      const surveyUrl = `${this.surveyUrl}?custid=${this.userId}&bookingid=${this.bookingId}`;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(surveyUrl); // Mark URL as safe
    }
  }

  closePopup(){
    this.removebodyClass();
    this.surveyClosed.emit('Survey closed successfully!');
    this.navigationService.updateSurveyOpenCount(this.bookingId).subscribe({next:res=>{
     },error:err=>{
     }})
  }

  removebodyClass()
  {
    let ele:any = document.querySelector("body");
    if(ele && ele.style){
      ele.style.overflow = 'auto';
    }
  }
}
