export enum  Sortingfield {
    bookId = 'idBooking',
    vessel = 'vessel',
    status ='status',
    eta = 'latestETA',
    column5 = 'column5',
    vsl_name = 'vsl_name',
    summer_dwt = 'summer_dwt',
    dwt = 'dwt',
    completed='dtCompletedOn',
    susCargoModal='SustainabilityCargoName',
    susTradeModal='SustainabilityTradeName',
    susTradePerModal='SustainabilityTradePer',
    susCargoPerModal='SustainabilityCargoPer',
    loadPortEta='loadPortEta',
    dischargePortEta='dischargePortEta',
    userName='userName',
    userRole='userRole',
    userModule='userModule',
    lastActive='lastActive',
    userStatus='userStatus'
   }