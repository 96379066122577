<form [formGroup]="searchBoxForm" class="CommonSearchMain"
  [ngClass]="{ 'ActiveResults' : ((AllBookingID || AllCargo || AllVessel || AllLoadPort || AllDischargePort))  }">
  <div class="FieldSearch" (click)="OnClickDiv()"  [ngClass]="{'error-flds' : !searchTag && isclearError , 'active' : focus, 'SearchFocus':isSearchFocus}">
    <div class="FieldSearchIn">
      <span class="SelectedTag" *ngIf="searchTag">
        <span>{{searchTag}}  </span>
        <svg class="ResetCross" (click)="resetForm()" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M4.00098 12L12.001 4" stroke="#27364B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M12 12L4 4" stroke="#27364B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <input type="text" class="input-field SearchField body-b1" formControlName="szTempDisplayName" [placeholder]="placeholder"
        (focus)="onFocus()" (keydown)="clearError($event)" (blur)="onBlur();" (focus)="focusFunction()" (focusout)="focusOutFunction()">
    </div>

    <span class="ClearCross" (click)="resetForm()" *ngIf="searchBoxForm.controls.szTempDisplayName.value">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.75 13.7188C15.0312 14.0312 15.0312 14.5 14.75 14.7812C14.4375 15.0938 13.9688 15.0938 13.6875 14.7812L10 11.0625L6.28125 14.7812C5.96875 15.0938 5.5 15.0938 5.21875 14.7812C4.90625 14.5 4.90625 14.0312 5.21875 13.7188L8.9375 10L5.21875 6.28125C4.90625 5.96875 4.90625 5.5 5.21875 5.21875C5.5 4.90625 5.96875 4.90625 6.25 5.21875L10 8.96875L13.7188 5.25C14 4.9375 14.4688 4.9375 14.75 5.25C15.0625 5.53125 15.0625 6 14.75 6.3125L11.0312 10L14.75 13.7188Z" fill="#788496"/>
      </svg>         
    </span>
    <!-- For Mobile-->
    <p class="mobile-label font-16 d-flex m-0">Search by booking ID, ship name or port name</p>
  </div>

  <div class="Combined-Shape DropResults stolt__scrollbar" [ngClass]="{ 'border-hide-blue' : ((!AllBookingID && !AllCargo && !AllVessel && !AllLoadPort && !AllDischargePort) || isHistory)  }">
    <ul>
      <ng-container *ngIf="searchTag == 'Booking ID' && !searchString">
        <ng-container *ngFor="let item of arrLocationListBooking">
          <li (click)="onSelect(item)">
            <div class="pad-nullifier">
              <div class="drop-text">
                <div class="text-flow">
                  {{ item?.idBooking }}
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="searchTag == 'Cargo Type' && !searchString">
        <ng-container *ngFor="let item of arrLocationListCargo">
          <li (click)="onSelect(item)">
            <div class="pad-nullifier">
              <div class="drop-text">
                <div class="text-flow">
                  {{ item?.name | titlecase}}
                </div>
                <div class="sub-text text-flow">
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="searchTag == 'Vessel Name' && !searchString">
        <ng-container *ngFor="let item of arrLocationListVessel">
          <li (click)="onSelect(item)">
            <div class="pad-nullifier">
              <div class="drop-text">
                <div class="text-flow">
                  {{ item?.vesselName | titlecase }}
                </div>
                <div class="sub-text text-flow">
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="searchTag == 'Load Port' && !searchString">
        <ng-container *ngFor="let item of arrLocationListLoadPort">
          <li (click)="onSelect(item)">
            <div class="pad-nullifier">
              <div class="drop-text">
                <div class="text-flow">
                  {{ item?.loadPortName | titlecase}}
                </div>
                <div class="sub-text text-flow">
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="searchTag == 'Discharge Port' && !searchString">
        <ng-container *ngFor="let item of arrLocationListDischargePort">
          <li (click)="onSelect(item)">
            <div class="pad-nullifier">
              <div class="drop-text">
                <div class="text-flow">
                  {{ item?.dischargePortName | titlecase}}
                </div>
                <div class="sub-text text-flow">
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</form>
<ng-container 
  *ngIf="(searchTag && !AllBookingID && !AllCargo && !AllVessel && !AllLoadPort && !AllDischargePort && searchBoxForm.controls.szTempDisplayName.value && !stringMatched)">
  <div class="pad-nullifier error" *ngIf="searchBoxForm.controls.szTempDisplayName.value.length > 2">
    <div class="drop-text">
      <div class="text-flow">
        No match found, please ensure your search criteria is correct.
      </div>
    </div>
  </div>
</ng-container>