<!-- Footer For Public Pages -->
<footer class="st-public-footer overflow-hidden position-relative">
    <div class="container container-custom w-100 mw-100 position-relative layerUp">
      <div class="LargeWindowWidth">
        <div class="row">
          <div class="col-md-6 col-12">
              <div class="StoldBrand">
                <a class="active" [routerLink]="['/']">
                  <img width="56" height="51" src="assets/svg/public/footer_logo.svg" alt="img"> 
                </a>
              </div>
          </div>
          <div class="col-md-3 col-6"> 
            <div class="FooterLinks d-flex flex-column">
                <a [routerLink]="['/about']" [routerLinkActive]="['active']">About</a>
                <a [routerLink]="['/fleetinfo']" [routerLinkActive]="['active']" >Fleet information</a>
                <a [routerLink]="['/contact']" [routerLinkActive]="['active']">Contact</a>
            </div>
          </div>
          <div class="col-md-3 col-6"> 
            <div class="FooterLinks FollowUsLinks">
              <a class="TitleFooterBx CursorDefault d-flex" href="javascript:void(0)">Follow us</a>
              <div class="SocialIcons d-flex justify-content-start">
                <a class="d-inline-flex justify-content-center align-items-center" href="https://www.linkedin.com/company/stolt-nielsen-limited/" target="_blank" rel="noopener"><img width="20" height="20" src="assets/svg/public/linkedin.svg" alt="img"> </a>
                <a class="d-inline-flex justify-content-center align-items-center" href="https://vimeo.com/user85961141" target="_blank" rel="noopener"><img width="20" height="20" src="assets/svg/public/vimeo.svg" alt="img"> </a> 
              </div> 
          </div>
          </div> 
        </div>
        </div>
    </div> 

    <div class="container container-custom w-100 mw-100 FooterLinksLast position-relative layerUp">
      <div class="LargeWindowWidth">
        <div class="FooterLinksLastIneer d-flex">
          <a class="CursorDefault" href="javascript:void(0)">© Stolt Tankers</a>
          <a class="StoltNielsenLink" href="https://www.stolt-nielsen.com/" target="_blank" rel="noopener">Stolt-Nielsen</a> 
          <a [routerLink]="['/terms-of-use']" [routerLinkActive]="['active']">Terms of use</a> 
          <a [routerLink]="['/accessibility']" [routerLinkActive]="['active']">Accessiblity</a> 
          <a [routerLink]="['/privacy-and-cookies']" [routerLinkActive]="['active']">Privacy policy</a> 
        </div>
      </div>
    </div>  
</footer>
<!-- Footer For Public Pages End -->

<app-show-announcement *ngIf="isPreview" [title]="title" [editorData]="editorData" [isPreview]="isPreview" [userEmail]="userEmail" (closePopupEmit)="isPreview = false"></app-show-announcement>
<app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId" [activeSurvey]="activeSurvey" (surveyClosed)="onSurveyClosed($event)"></app-nps-survey-monkey>
