import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-fleet-info-q88-iframe',
  templateUrl: './fleet-info-q88-iframe.component.html',
  styleUrls: ['./fleet-info-q88-iframe.component.scss']
})
export class FleetInfoQ88IframeComponent implements OnInit {

  Q88Response: any = '';
  imo_no: any;
  myResponse: any;
  flag = true;
  hiddenFlag = true;
  vessel_name = '';
  pdfEnb: boolean = false;
  isLoading = false;
  button = 'Download Q88 Details';
  @ViewChild('dataContainer') dataContainer: ElementRef;
  @ViewChild('htmlData') htmlData!: ElementRef;
  constructor(public navigationService: NavigationService, public publicPagesService: PublicPagesService,
    private domSanitizer: DomSanitizer, private route: ActivatedRoute) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Fleet-Info-Q88');
  }

  ngOnInit(): void {
    let Qrstr:any = this.route.snapshot.queryParamMap.get('id')
    let splitstr= Qrstr.split('-')
    this.imo_no = splitstr[1];
    let data = { imo: this.imo_no }
    this.publicPagesService.getAllFleets(data).subscribe({next:(res: any) => {
      console.log(res);
      this.vessel_name = res.data.result[0].column5;
    }, error:err => {
      console.log("get Q88 info by id error ", err)
    }})
    this.getQ88Data(this.imo_no)
  }
  getQ88Data(imo_no: any) {
    this.Q88Response = "Loading..."
    if (imo_no) {
      this.publicPagesService.getQ88Data(imo_no).subscribe({next:(res: any) => {

        this.flag = false;
        this.pdfEnb = true;
        this.Q88Response = res.data;
        setTimeout(() => {
          this.hiddenFlag = false;
          this.flag = false;
          this.Q88Response = this.dataContainer.nativeElement.innerText;
        }, 0)
      }, error:(err) => {
        this.Q88Response = `Something required is missing. Please refresh page and try again. If you're still having issues, contact us and let us know.`;
        this.navigationService.errorOccure(err);
        this.flag = true;
        this.hiddenFlag = false;
        this.pdfEnb = false
      }})
    }
  }

  downloadpdf() {
    /* download pdf files */
    this.isLoading = true;
    this.button = 'Downloading';
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas: any) => {
      let imgWidth = 210;
      let pageHeight = 290;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;


      let doc = new jspdf('p', 'mm');
      let position = 0;
      let pageData = canvas.toDataURL('image/jpeg', 1.0);
      let imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save(this.vessel_name + '.pdf');
      this.isLoading = false;
      this.button = 'Download Q88 Details';
    }, err => {
      this.isLoading = false;
      this.button = 'Download Q88 Details';
    })
  }
}
