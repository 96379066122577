<snl-modal (click)="discard()" [settings]="snlModalSettings" [type]="'medium'" [open]="distributionRemoveModel" class="CenterModelCmn CommonPhoneCstm Share__modal">
    <div slot="heading"> 
        <div class="ShareHeadTitle" *ngIf="(viewMode === 'Poc' || shareType=='docs') ; else showOther">
            <div slot="heading"><strong>Share documents </strong></div>  
            <div class="Sub__heading body-b1">
                <span>Share an email with the selected documents attached.</span>
                <span>Activate distribution lists by using the hash # sign.</span>
            </div>
        </div> 
        <ng-template #showOther>
            <div class="ShareHeadTitle">
                <div slot="heading"><strong>Share booking</strong></div> 
                <div class="Sub__heading body-b1">
                    <span>Send an email with a live link to the booking status.</span>
                    <span>Activate distribution lists by using the hash # sign.</span>
                </div>
            </div> 
        </ng-template> 
    </div>
    <div class="body-b" slot="modalContent">
        <div class="StickyShareBoxInner"> 
            
            <form [formGroup]="shareEmailTemp">
                <div class="ShareInputArea position-relative"> 
                    <div class="input-group form-floating"> 
                        <input type="text" class="form-control w-100 floating-control-no-bg" (keyup)="FetchDistData($event,'to')"  formControlName="toAddress" [(ngModel)]="strEmails" placeholder="" [ngClass]="{'input-error':  (shareEmailTemp.controls.toAddress.touched || shareEmailTemp.controls.toAddress.dirty)&& shareEmailTemp.controls.toAddress.invalid}">
                        <label for="floatingInput">To </label> 
                        <div class="emailError" *ngIf="(shareEmailTemp.controls.toAddress.touched || shareEmailTemp.controls.toAddress.dirty)&& shareEmailTemp.controls.toAddress.invalid && !checkHash">Please enter the correct email id</div>
                    </div> 
                    
                    <!--DropDown List-->
                    <div class="stolt__scrollbar" *ngIf="listEnable">
                        <div class="suggestion__drpdwn"> 
                            <div class="list__item" *ngFor="let Distributiondatas of Distributiondata">
                                <span (click)="getListEmails(Distributiondatas.recipientList,'to')">{{Distributiondatas.listName}}</span>
                            </div>
                        </div>
                    </div> 
                    <div class="input-group form-floating">
                        <input type="text" class="form-control w-100 floating-control-no-bg" formControlName="ccAddress" (keyup)="FetchDistData($event,'cc')" [(ngModel)]="strEmailsCC" placeholder="" [ngClass]="{'input-error':  (shareEmailTemp.controls.ccAddress.touched || shareEmailTemp.controls.ccAddress.dirty) && shareEmailTemp.controls.ccAddress.invalid}" >
                        <label for="floatingInput">CC (Optional)</label>
                        <div class="emailError" *ngIf="(shareEmailTemp.controls.ccAddress.touched || shareEmailTemp.controls.ccAddress.dirty)&& shareEmailTemp.controls.ccAddress.invalid && !checkHash">Please enter the correct email id</div>
                    </div>
                     
                    <!--DropDown List-->
                    <div class="stolt__scrollbar" *ngIf="listEnableCC">
                        <div class="suggestion__drpdwn"> 
                            <div class="list__item" *ngFor="let DistributiondatasCC of DistributiondataCC">
                                <span (click)="getListEmails(DistributiondatasCC.recipientList,'cc')">{{DistributiondatasCC.listName}}</span>
                            </div>
                        </div>
                    </div>
 
                    <div class="input-group form-floating">
                        <input type="text" class="form-control w-100 floating-control-no-bg" (keyup)="FetchDistData($event,'Bcc')" [(ngModel)]="strEmailsBcc" formControlName="bccAddress" placeholder="" [ngClass]="{'input-error': (shareEmailTemp.controls.bccAddress.touched || shareEmailTemp.controls.bccAddress.dirty)  && shareEmailTemp.controls.bccAddress.invalid}">
                        <label for="floatingInput">BCC (Optional)</label>
                        <div class="emailError" *ngIf="(shareEmailTemp.controls.bccAddress.touched || shareEmailTemp.controls.bccAddress.dirty)&& shareEmailTemp.controls.bccAddress.invalid && !checkHash">Please enter the correct email id</div>
                    </div> 
                   
                    <!--DropDown List-->
                    <div class="stolt__scrollbar" *ngIf="listEnableBcc">
                        <div class="suggestion__drpdwn top_direction_open"> 
                            <div class="list__item" *ngFor="let DistributiondatasBcc of DistributiondataBcc">
                                <span (click)="getListEmails(DistributiondatasBcc.recipientList,'Bcc')">{{DistributiondatasBcc.listName}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="SendcheckboxHld body-b">
                        <snl-checkbox
                            formControlName="selfemail" 
                            (change)="getSelfEmail($event)"  
                            [id]="'Tomyself'"
                            [name]="'Checkbox'"
                            [value]="'sample'"
                            >
                            <span class="body-b1" [ngClass]="{'fw-700':shareEmailTemp.value.selfemail}">Send a copy of this email to myself</span>
                        </snl-checkbox>
                    </div>                 
                </div>
            </form>
        </div>
   </div>
    <div slot="modalFooter">
        <snl-button size="large" type="primary" [disabled]="!shareEmailTemp.valid || isDisabledForm" (click)="shareDetail()">Send email</snl-button>
        <snl-button class="DeleteBtnBG" size="large" type="secondary" (click)="discard()">Cancel</snl-button>
    </div>
</snl-modal>


