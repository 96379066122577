
<!-- Header Section: This section represents the header of the webpage -->
<app-about-header></app-about-header>

<!-- Common Hero Area -->
<div class="CommonHeroArea position-relative">
    <div class="LargeWindowWidth">
        <div class="CommonHeroContent">
            <h1 class="heading01">About</h1>
            <div class="HeroImgBx">
                <!-- Image: Represents a ship image -->
                <img class="" src="assets/img/public/ship01.webp" alt="Ship Image">
            </div> 
        </div>
    </div>
</div>
 
<!-- Common Content Section -->
<section class="CommonContentSec">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
           <h2 class="heading02">Stolt Tankers operates the  <span class="dottedBorderBtm">world’s largest and most sophisticated ﬂeet</span> of chemical and parcel tankers, </h2>
        <p class="CommonPara">with over 70 deep-sea ships that are seamlessly integrated with regional ﬂeets in Europe,
             Asia and the Caribbean; with coastal ﬂeets in Asia; and with inland barging services in Europe and the US 
             Gulf. This unrivalled coverage not only gives customers access to the quality and reliability for which Stolt 
             Tankers is well known, but the ﬂexibility to adjust to the often-changing demands of our customers’ dynamic 
             global supply chains.</p>
       </div>
      </div> 
</section>   

<!-- Common Content Section -->
<section class="CommonContentSec GreyBG SpaceTB">
    <div class="LargeWindowWidth">
       <div class="SecInner"> 
            <div class="TextContent right_pd">
                    <h3 class="heading03 heading03-front">Innovation through collaboration</h3>
                    <p class="CommonPara">We work together with our sister companies Stolthaven 
                        Terminals and Stolt Tank Containers to deliver an efficient ship-to-shore 
                        interface with the aim of reducing potential demurrage costs and facilitating 
                        agile and competitive supply chains. And we regularly collaborate with our customers, 
                        many of whom are the world’s premier manufacturers and users of chemicals and other bulk 
                        liquids, to create unique, integrated transportation and storage solutions.</p>
            </div>
            <div class="ImgArea">
                <img class="" src="assets/img/public/tanks01.jpg" alt="Tanks image">
            </div>
       </div>

       <div class="SecInner MobileWindow MobileWindow2">
        <div class="ImgArea CornerPlusEffect TabletViewMode">
            <img class="" src="assets/img/public/tanks02.jpg" alt="Tank image">
            <div class="FloatingIMg">
                <img class="" src="assets/img/public/stoltwerkers.jpg" alt="Stolt workers image">
            </div>
        </div>           
        <div class="TextContent left_pd"> 
                <p class="CommonPara TabletMt_20">Our focus on continuous improvement means that we actively follow new 
                    developments in the industry and are involved in several research projects, particularly
                     in the area of new, lower emission fuels for ships. Our ships frequently serve as test 
                     beds for new equipment and systems and we encourage our people to challenge existing 
                     procedures. Through this process of ongoing innovation we ensure that Stolt Tankers 
                     remains at the forefront of the industry, particularly with respect to safety and 
                     environmental performance.</p>
        </div> 
   </div>  
</div>     
</section>

<!-- Common Content Section -->
<section class="CommonContentSec SpaceTB aboutBGBorder MobileBorderCstm">
    <div class="LargeWindowWidth MobilePosition">
        <div class="SecInner">
            <div class="TextContent TextContent02 right_pd TankerTrading">
                    <h3 class="heading03 heading03-front">Tanker trading</h3>
                    <p class="CommonPara">Tanker Trading consists of our chartering and ship operations experts. 
                        Our chartering team provides global commercial management services for the Stolt Tankers
                        fleet. Recognised throughout the industry for leadership, experience and dedication to
                        customer service, our chartering department is available to customers 24/7 through our 
                        worldwide network of offices.</p>
            </div>
            <div class="ImgArea">
                <img class="" src="assets/img/public/ship02.jpg" alt="Ship Image">
                <div class="circle-effect-hloder">
                    <img src="assets/svg/public/circle-effect.svg" class="circle-effect" alt="Circle Effect">
                </div>
            </div>
        </div>

        <div class="SecInner MobileWindow mb-0">
            <div class="ImgArea TabletViewMode">
                <img class="" src="assets/img/public/stoltworkers02.jpg" alt="Stolt workers image">
            </div>           
            <div class="TextContent TextContent02 left_pd mt-0 smallD-space">  
                <h3 class="heading03 heading03-front M_space TabSpacetp">Ship management</h3>
                <p class="CommonPara">Stolt Tankers’ Ship Owning division provides technical-management services for 
                    all our ships, ensuring that our fleet meets or exceeds the rigorous standards set by our customers, 
                    classification societies, flag authorities and by Stolt Tankers itself. All of the key functions in Ship 
                    Owning—safety and marine services, crewing, fleet management, risk and insurance, vetting, newbuilding and
                        technical projects—work in unison toward this goal.</p>

                    <p class="CommonPara">We manage most of our ships throughout their complete life cycle, from design specification, 
                        through construction, operational life, dry-docking, midlife modernisation, life extensions and, ultimately,
                            recycling. This approach allows us to achieve standards of operation, maintenance and repair well above industry 
                            averages. In addition to managing ships owned by Stolt Tankers, we also manage ships for third-party owners and 
                            provide assistance in quality assurance for third-party ship managers who manage Stolt Tankers-operated ships</p>        
            </div> 
        </div>   
    </div>    
</section>

<!-- Footer Section: This section represents the footer of the webpage -->
 <app-footer></app-footer>
 <app-scroll-to-top></app-scroll-to-top>

 <!-- Comment temporary <app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId"></app-nps-survey-monkey> -->