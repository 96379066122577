<snl-modal (modalClose)="discard()" [settings]="snlModalSettings" [type]="'medium'" [open]="isNotification" class="CommonChnagesMdl ModelNotificationPro MdlNotiProLst" [ngClass]="{'share_inbooking': shareType == 'booking'|| shareType == 'bookingDetail', 'RightColHide':!showHideNotication, 'heading-for-bookingMn':shareType == 'booking', 'CreateNewNotification':shareType == 'bookingDetail'}">
    <div slot="heading" *ngIf="!(shareType == 'booking' || shareType == 'bookingDetail')"><strong>Notification properties</strong></div>
    <div slot="heading" *ngIf="shareType == 'booking'"><strong>Share booking</strong></div> 
    <div slot="heading" *ngIf="shareType == 'bookingDetail'"><strong>Create new notification</strong></div>
    <div slot="modalContent">
        <div class="sub-heading-for-booking" *ngIf="shareType == 'booking'">
            <span class="body-b1">Send an email with a live link to the booking details.</span><br>
            <span class="body-b1">Use distribution lists you created by entering hash #.</span>
        </div>
        <div class="notifi-at-booking">
            <!-- FOR BOOKING LEVEL NOTIFICATIONS -->
            <div class="ShareCol">
                <div class="HeaderTxt" *ngIf="shareType == 'bookingDetail'">  
                    <span class="body-b1">Send an email with a live link to the booking details.</span><br>
                    <span class="body-b1">Use distribution lists you created by entering hash #.</span>
                </div>
                <app-share-notification *ngIf="shareType =='booking' || shareType =='bookingDetail'" #childComponent [shareCompData]='shareCompData' [editRowNotification]="editRowNotification" (shareEmailEmit)="shareEmailEmit($event)" (emitClaseNoti)="discard($event)" (savingProcessStatus)="savingProcessStatus($event)" (checkDupleicateEmail)="checkDupleicateEmail($event)" [shareType]="shareType" (hideShowNoticationStatus)="getHideShowNoticationStatus($event)"></app-share-notification> 
            </div>
            <!-- FOR BOOKING LEVEL NOTIFICATIONS END -->

            <form [ngClass]="{'disabledImmediate':shareType =='bookingDetail' && isSubscribe}" class="stolt__scrollbar NotifactinCol" [formGroup]="notificationForm" *ngIf="showHideNotication">
                <div class="ShareInputArea content-inner-bdy">
                    <div class="InputWdth position-relative" *ngIf="shareType !='booking' && shareType !='bookingDetail'"> 
                    <snl-input
                    [label]="'Notification name'"
                    [errorMessage]="(f?.notificationTitle?.touched) && f?.notificationTitle?.errors?.required ? 'name is required' : f?.notificationTitle?.errors?.minlength ? 'List name must be at least 3 characters long.' : f?.notificationTitle?.errors?.maxlength ? 'List name must
                    not exceed 50 characters.' : '' "
                    [isError]="(f?.notificationTitle?.touched && f?.notificationTitle?.errors?.required) || f.notificationTitle.errors?.minlength"
                    formControlName="notificationTitle"
                    [type]="'text'"
                ></snl-input>
                    </div>

                     
                    <div class="optionhldr">
                        <div class="col-lft"> 
                            <div class="opt-main">
                                <!-- FOR BOOKING LEVEL NOTIFICATIONS -->
                                <p class="sub-heading-s1 Set_notification" *ngIf="shareType == 'booking'">Set notification</p>
                                <p class="body-b1" *ngIf="shareType == 'booking'">Customise content and frequency of ETA update notifications</p>
                                <!-- FOR BOOKING LEVEL NOTIFICATIONS END -->

                                <p class="mb-0 section-title EventTitleMn">Event</p>
                                <ul>
                                    <li>                                        
                                        <snl-checkbox
                                            formControlName="allShipmentETAs"
                                            (onClick)="OncheckMoni($event,'eta');onCheckedEvent($event,'shipment');">
                                            <span class="body-b1" [ngClass]="{'fw-700':notificationForm.value.allShipmentETAs}">All ETAs</span>
                                        </snl-checkbox>
                                    </li>
                                    <li> 
                                        <span class="checkboxHld" [ngClass]="{'disabledImmediate':notificationForm.value.ChangeShipNom || notificationForm.value.allShipmentETAs}">
                                            <snl-checkbox
                                                formControlName="etaUpdates"
                                                (onClick)="onCheckedEvent($event,'eta')">
                                                <span class="body-b1" [ngClass]="{'fw-700':notificationForm.value.etaUpdates}">ETA changes only</span>
                                            </snl-checkbox>
                                        </span> 
                                    </li>

                                    <li> 
                                        <snl-checkbox
                                            formControlName="ChangeShipNom"
                                            (onClick)="OncheckMoni($event,'vessel');onCheckedEvent($event)">
                                            <span class="body-b1" [ngClass]="{'fw-700':notificationForm.value.ChangeShipNom}">Change of vessel nomination</span>
                                        </snl-checkbox>
                                    </li>
                                    
                                    <li> 
                                        <span class="checkboxHld" *ngIf="stoltEmail == '@STOLT' || stoltEmail == '@stolt'">
                                            <snl-checkbox
                                                formControlName="ShipArrivalLaycanPeriod"
                                                (onClick)="onCheckedEvent($event,'ship')">
                                                <span class="body-b1" [ngClass]="{'fw-700':notificationForm.value.ShipArrivalLaycanPeriod}">Vessel arrival outside laycan period</span>
                                            </snl-checkbox>
                                        </span> 
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Filters Column-->
                        <div class="col-rgt" *ngIf="shareType !='booking' && shareType !='bookingDetail'">
                            <div class="opt-main">
                                <p class="mb-1 section-title">Filter</p> 
                                <div class="filtersMain">
                                    <!--Load port  -->
                                    <div class="ng-select-parent">
                                         <ng-select [items]="portFilterData" [multiple]="true"
                                            class="TextSlectGroup _Load no-scroll-drpdwn PlaceholderLft w288" [searchable]="false"
                                            [clearable]="false" bindLabel="loadPortName" [closeOnSelect]="false" bindValue="loadPortNumber"
                                            [(ngModel)]="portFilterValueTemp" (change)="clearInput()" groupBy="port" placeholder="All" [selectableGroup]="true" formControlName="loadPortNumber">
                                            <ng-template ng-multi-label-tmp let-items="items">
                                                <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('loadPortName')">{{
                                                    items[0]?.port|titlecase}}</span>
                                                <span
                                                    *ngIf="items.length < 2 && items[0].hasOwnProperty('loadPortName')">{{items[0]?.loadPortName|titlecase}}</span>
                                                <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                                            </ng-template>
                        
                                            <ng-template ng-header-tmp>
                                                <input style="width: 100%; line-height: 24px" type="text" placeholder="Search port names" #searchName
                                                    (input)="customSearch('port',$event)" />
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="checkboxHld">
                                                    <label class="containerCheckbx tp15">
                                                        <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="OptionTxt">{{item.loadPortName|titlecase}}</span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="SelectAllOpt" [style.display]="PortDisplay">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">All ({{portFilterData.length}})</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                        
                                    </div>

                                    <!-- Discharge port -->
                                    <div class="ng-select-parent">
                                         <ng-select [items]="portDischargeData" [multiple]="true"
                                        class="TextSlectGroup _Discharge OpenDropdownRight no-scroll-drpdwn PlaceholderLft w288" [searchable]="false"
                                        [clearable]="false" bindLabel="dischargePortName" [closeOnSelect]="false"
                                        bindValue="dischargePortNumber" [(ngModel)]="dportFilterValueTemp" [selectableGroup]="true"
                                        placeholder="All" groupBy="discharge" formControlName="dischargePortNumber" (change)="clearInput()">
                                        <ng-template ng-multi-label-tmp let-items="items">
                    
                                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('dischargePortName')">{{
                                                items[0]?.discharge|titlecase}}</span>
                                            <span
                                                *ngIf="items.length < 2 && items[0].hasOwnProperty('dischargePortName')">{{items[0]?.dischargePortName|titlecase}}</span>
                                            <span *ngIf="items.length >= 2">{{items.length}}</span>
                    
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                            <input type="text" placeholder="Search discharge port" #searchName
                                                (input)="customSearch('dport',$event)" />
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="checkboxHld">
                                                <label class="containerCheckbx tp15">
                                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <span class="OptionTxt"> {{item.dischargePortName|titlecase}}</span>
                                            </div>
                    
                                        </ng-template>
                                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="SelectAllOpt" [style.display]="dischargeDisplay">
                                                <div class="checkboxHld">
                                                    <label class="containerCheckbx tp15">
                                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="OptionTxt">All
                                                        ({{portDischargeDataMani.length}})</span>
                                                </div>
                                            </div>
                                        </ng-template>
                    
                                        </ng-select>
                                    </div> 

                                    <!-- Load port ETA -->
                                    <div class="ng-select-parent" *ngIf="isShowDateRange">
                                        <div class="form-row">
                                            <div class="form-group date-range-sustainability LoadPortEta SelectedDate" > 
                                                    <form  [formGroup]="dateRageFiltersFormLoadEta" >              
                                                    <mat-form-field appearance="fill"> 
                                                        <span class="FloatingLbl">Load port ETA</span>      
                                                        <mat-label><span class="AllValue">All</span></mat-label>      
                                                        <mat-date-range-input [rangePicker]="picker" [min]="minDateLoadPort" [max]="maxDate">      
                                                        <input matStartDate readonly placeholder="Start date" formControlName="fromDate" (focus)="picker.open()" (click)="picker.open()">      
                                                        <input matEndDate readonly placeholder="End date" formControlName="toDate" (focus)="picker.open()" (click)="picker.open()">      
                                                        </mat-date-range-input>      
                                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>   
                                                        <mat-date-range-picker #picker>
                                                            <mat-date-range-picker-actions>
                                                                <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="+
                                                                ('loadPortEta')">Apply</button>
                                                                <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('loadPortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                                            </mat-date-range-picker-actions>
                                                        </mat-date-range-picker> 
                                                    </mat-form-field> 
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Discharge port ETA -->
                                    <div class="ng-select-parent" *ngIf="isShowDateRange">
                                        <div class="form-row">
                                            <div class="form-group date-range-sustainability DischargePortEta SelectedDate" > 
                                                    <form  [formGroup]="dateRageFiltersFormLoadDischarge" >              
                                                    <mat-form-field appearance="fill"> 
                                                        <span class="FloatingLbl">Discharge port ETA </span>      
                                                        <mat-label><span class="AllValue">All</span></mat-label>      
                                                        <mat-date-range-input [rangePicker]="pickerDischarge" [min]="minDate" [max]="maxDate">      
                                                        <input matStartDate readonly placeholder="Start date"  formControlName="fromDate" (focus)="pickerDischarge.open()" (click)="pickerDischarge.open()">      
                                                        <input matEndDate readonly placeholder="End date" (focus)="pickerDischarge.open()" (click)="pickerDischarge.open()" formControlName="toDate">      
                                                        </mat-date-range-input>      
                                                        <mat-datepicker-toggle matSuffix [for]="pickerDischarge"></mat-datepicker-toggle>   
                                                        <mat-date-range-picker #pickerDischarge>
                                                            <mat-date-range-picker-actions>
                                                                <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeDischargeFilter('dischargePortEta')">Apply</button>
                                                                <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('dischargePortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                                            </mat-date-range-picker-actions>
                                                        </mat-date-range-picker> 
                                                    </mat-form-field> 
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Charterer -->
                                    <div class="ng-select-parent">
                                        <ng-select [items]="chartererFilterData" [multiple]="true"
                                       class="TextSlectGroup _Charterer no-scroll-drpdwn PlaceholderLft w288" [searchable]="false"
                                       [clearable]="false" bindLabel="chartererName" placeholder="All" [closeOnSelect]="false"
                                       bindValue="chartererName" [(ngModel)]="chartererFilterValueTemp" [selectableGroup]="true"
                                       groupBy="charterer" formControlName="chartererFilter" (change)="clearInput()">
                                       <ng-template ng-multi-label-tmp let-items="items">
                   
                                           <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('chartererName')">{{
                                               items[0]?.charterer|titlecase}}</span>
                                           <span
                                               *ngIf="items.length < 2 && items[0].hasOwnProperty('chartererName')">{{items[0]?.chartererName|titlecase}}</span>
                                           <span *ngIf="items.length >= 2">{{items.length}}</span>
                   
                                       </ng-template>
                                       <ng-template ng-header-tmp>
                                           <input style="width: 100%; line-height: 24px" type="text" placeholder="Search charterer names" #searchName
                                               (input)="customSearch('charterer',$event)" />
                                       </ng-template>
                                       <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                           <div class="checkboxHld">
                                               <label class="containerCheckbx tp15">
                                                   <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                   <span class="checkmark"></span>
                                               </label>
                                               <span class="OptionTxt"> {{item.chartererName|titlecase}}</span>
                                           </div>
                   
                                       </ng-template>
                                       <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                           <div class="SelectAllOpt" [style.display]="chartererDisplay">
                                               <div class="checkboxHld">
                                                   <label class="containerCheckbx tp15">
                                                       <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                       <span class="checkmark"></span>
                                                   </label>
                                                   <span class="OptionTxt">All
                                                       ({{chartererFilterData.length}})</span>
                                               </div>
                                           </div>
                                       </ng-template>
                                       </ng-select>
                                   </div>

                                    <!--Tradelane-->
                                    <div class="ng-select-parent">
                                        <ng-select [items]="tradelaneFilterData" [multiple]="true"
                                            class="TextSlectGroup _Tradelane _Load2 no-scroll-drpdwn PlaceholderLft OpenDropdownRight NoTextTransform w288" [searchable]="false"
                                            [clearable]="false" bindLabel="tradelaneName" [closeOnSelect]="false" bindValue="tradelaneName"
                                            [(ngModel)]="tradelaneFilterValueTemp" groupBy="tradelane" (change)="clearInput()" placeholder="All" [selectableGroup]="true" formControlName="tradelaneNumber">
                                            <ng-template ng-multi-label-tmp let-items="items">
                                                <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('tradelaneName')">{{
                                                    items[0]?.tradelane}}</span>
                                                <span
                                                    *ngIf="items.length < 2 && items[0].hasOwnProperty('tradelaneName')">{{items[0]?.tradelaneName}}</span>
                                                <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                                            </ng-template>
                        
                                            <ng-template ng-header-tmp>
                                                <input style="width: 100%; line-height: 24px" type="text" placeholder="Search tradelane names" #searchName
                                                    (input)="customSearch('tradelane',$event)" />
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="checkboxHld">
                                                    <label class="containerCheckbx tp15">
                                                        <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="OptionTxt">{{item.tradelaneName}}</span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="SelectAllOpt" [style.display]="tradelaneDisplay">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">All ({{tradelaneFilterData.length}})</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>

                                    <!--ETA Variance-->
                                    <div class="ng-select-parent"> 
                                         <ng-select [items]="etaVarianceFilterData" [multiple]="true"
                                            class="TextSlectGroup _ETAVariance _Load2 no-scroll-drpdwn PlaceholderLft w288" [searchable]="false"
                                            [clearable]="false" bindLabel="displayname" [closeOnSelect]="false" bindValue="idfilter"
                                            [(ngModel)]="etaFilterValueTemp"  placeholder="All" [selectableGroup]="true" formControlName="arrIdDelayFilter">
                                            <ng-template ng-multi-label-tmp let-items="items">
                                                <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('displayname')">{{
                                                    items[0]?.displayname}}</span>
                                                <span
                                                    *ngIf="items.length < 2 && items[0].hasOwnProperty('displayname')">{{items[0]?.displayname}}</span>
                                                <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="checkboxHld">
                                                    <label class="containerCheckbx tp15">
                                                        <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="OptionTxt">{{item.displayname}}</span>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div> 
                                </div>

                                <!-- For Mobile Devices-->
                                <div class="MobileDeviceOpt"> 
                                    <ul>	
                                        <li (click)="selectFilter('port',$event)" class="d-flex align-items-center justify-content-between"> 	
                                            <span class="d-flex gap_16">Load port <strong class="fw-700 truncate-text">{{loadPortNameMobileSelected.length == 0 ? 'All' : loadPortNameMobileSelected.length == 1 ? (loadPortNameMobileSelected[0]|titlecase) : loadPortNameMobileSelected.length}} </strong></span>  
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>	
                                        <li (click)="selectFilter('dport',$event)" class="d-flex align-items-center justify-content-between">  	
                                            <span class="d-flex gap_16">Discharge port <strong class="fw-700 truncate-text">{{dischargeNameMobileSelected.length == 0 ? 'All' : dischargeNameMobileSelected.length == 1 ? (dischargeNameMobileSelected[0]|titlecase) : dischargeNameMobileSelected.length}} </strong></span>  
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>	
                                        <li (click)="selectFilter('charterer',$event)" class="d-flex align-items-center justify-content-between"> 	
                                            <span class="d-flex gap_16">Charterer <strong class="fw-700 truncate-text">{{charetererNameMobileSelected.length == 0 ? 'All' : charetererNameMobileSelected.length == 1 ? (charetererNameMobileSelected[0]|titlecase) : charetererNameMobileSelected.length}}</strong></span>  
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>	
                                        <li (click)="selectFilter('tradelane',$event)" class="d-flex align-items-center justify-content-between"> 	
                                            <span class="d-flex gap_16">Tradelane <strong class="fw-700 truncate-text">{{tradelaneNameMobileSelected.length == 0 ? 'All' : tradelaneNameMobileSelected.length == 1 ? (tradelaneNameMobileSelected[0]) : tradelaneNameMobileSelected.length}}</strong></span> 
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>	
                                        <li (click)="selectFilter('etaVariance',$event)" class="d-flex align-items-center justify-content-between"> 	
                                            <span class="d-flex gap_16">ETA Variance <strong class="fw-700 truncate-text">{{delayMobileSelected.length == 0 ? 'All' : delayMobileSelected.length == 1 ? (delayMobileSelected[0]|titlecase) : delayMobileSelected.length}}</strong></span>  
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>	

                                        <li (click)="selectFilter('loadPortEta',$event)" class="d-flex align-items-center justify-content-between"> 	
                                            <span class="d-flex gap_16">
                                                Load port ETA
                                                <strong class="fw-700 truncate-text" *ngIf="dateRangeLoadEta.fromDate || dateRangeLoadEta.toDate">{{dateRangeLoadEta?.fromDate | date:'dd MMM YYYY'}} - {{dateRangeLoadEta?.toDate | date:'dd MMM YYYY'}}</strong>
                                                <strong class="fw-700 truncate-text" *ngIf="!dateRangeLoadEta.fromDate && !dateRangeLoadEta.toDate">All</strong>
                                            </span>  
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>

                                        <li (click)="selectFilter('dischargePortEta',$event)" class="d-flex align-items-center justify-content-between"> 	
                                            <span class="d-flex gap_16">
                                                Discharge port ETA
                                                <strong class="fw-700 truncate-text" *ngIf="dateRangeLoadDischarge.fromDate || dateRangeLoadDischarge.toDate">{{dateRangeLoadDischarge?.fromDate | date:'dd MMM YYYY'}} - {{dateRangeLoadDischarge?.toDate | date:'dd MMM YYYY'}}</strong>
                                                <strong class="fw-700 truncate-text" *ngIf="!dateRangeLoadDischarge.fromDate && !dateRangeLoadDischarge.toDate">All</strong>
                                            </span>  
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                            </svg>   	
                                        </li>
                                      
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
 
                    <div class="DaysTimeMainHolder" *ngIf="isFrequencyHideShow" [ngClass]="{'DaysTimeMainHolder2':shareType=='setting'}">
                        <div class="col-lft"> 
                            <div class="opt-main">
                                <p class="m-0 section-title">Frequency</p>
                                <ul class="FrequencyOpt"> 
                                    <li [ngClass]="{'disabledImmediate': stoltEmail == '@STOLT' || stoltEmail == '@stolt'}"> 
                                        <snl-radio-button
                                            [isDisable]="stoltEmail == '@STOLT' || stoltEmail == '@stolt'"
                                            [value]="'immediate'"
                                            [id]="'ImmediateRadio'"
                                            [checked]="changeFrequincy == 'immediate'"
                                            [name]="'frequencyType'"
                                            (onClick)="frequencyChange('Immediate')"
                                            >
                                            Immediate
                                        </snl-radio-button>
                                    </li>
                                    <li class="border-0"> 
                                        <snl-radio-button
                                            [value]="'custom'"
                                            [name]="'frequencyType'"
                                            [id]="'CustomDayTime'"
                                            [checked]="changeFrequincy == 'custom'"
                                            (onClick)="frequencyChange('custom')"
                                            >
                                            Custom day & time
                                        </snl-radio-button>
                                    </li>
                                </ul>
                            </div>
                        </div> 

                        <!-- ########### Custom Day & Time #################### -->
                        <div class="DaysTimeMain MobileDayTime" *ngIf="notificationForm.value.frequencyType == 'custom'">

                            <div class="DaysCol">
                                <div class="CheckbxMain">
                                    <div class="CheckOpt head">
                                        <span class="section-title">Day</span>
                                    </div>

                                    <div class="CheckOpt">
                                        <span class="checkboxHld" [ngClass]="{'all_checkmng':isSelectDay}">
                                            <snl-checkbox
                                                [checked]="CheckAllBox"
                                                (onClick)="SelectAllDays($event)"
                                                >
                                                <span class="RadioLabel" [ngClass]="{'fw-700':(isSelectDay || CheckAllBox)}">All Days</span> 
                                            </snl-checkbox>
                                        </span> 
                                    </div>
                                    <div class="CheckOpt">
                                        <span class="checkboxHld"> 
                                            <snl-checkbox
                                                (onClick)="singleSelectDay($event,'mon')"
                                                formControlName="mon"
                                                ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.mon}">Monday</span>
                                            </snl-checkbox>
                                        </span> 
                                    </div>
                                    <div class="CheckOpt">
                                        <span class="checkboxHld"> 
                                            <snl-checkbox
                                                (onClick)="singleSelectDay($event,'tue')"
                                                formControlName="tue"
                                                ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.tue}">Tuesday</span>
                                            </snl-checkbox>
                                        </span> 
                                    </div> 
                                    <div class="CheckOpt">
                                        <span class="checkboxHld"> 
                                            <snl-checkbox
                                            (onClick)="singleSelectDay($event,'wed')"
                                            formControlName="wed"
                                            ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.wed}">Wednesday</span>
                                            </snl-checkbox>
                                        </span> 
                                    </div>
                                    <div class="CheckOpt">
                                        <span class="checkboxHld">
                                            <label class="">
                                                <snl-checkbox
                                                (onClick)="singleSelectDay($event,'thu')"
                                                formControlName="thu"
                                                ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.thu}">Thursday</span>
                                                </snl-checkbox>
                                            </label>
                                        </span> 
                                    </div>
                                    <div class="CheckOpt">
                                        <span class="checkboxHld"> 
                                            <snl-checkbox
                                            (onClick)="singleSelectDay($event,'fri')"
                                            formControlName="fri"
                                            ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.fri}">Friday</span>
                                            </snl-checkbox>
                                        </span> 
                                    </div>
                                    <div class="CheckOpt">
                                        <span class="checkboxHld"> 
                                            <snl-checkbox
                                            (onClick)="singleSelectDay($event,'sat')"
                                            formControlName="sat"
                                            ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sat}">Saturday</span>
                                            </snl-checkbox>
                                        </span> 
                                    </div>
                                    <div class="CheckOpt">
                                        <span class="checkboxHld"> 
                                            <snl-checkbox
                                            (onClick)="singleSelectDay($event,'sun')"
                                            formControlName="sun"
                                            ><span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sun}">Sunday</span>
                                            </snl-checkbox>
                                        </span> 
                                    </div>
                                </div>
                            </div>

                            <div class="TimeCol TimeZoneDrodwnMn">
                                <p class="section-title head TimeHeadingMn">Time</p>
                                <ng-select [items]="timezoneList"
                                [searchable]="false" bindValue="timezone"
                                bindLabel="timezone"
                                formControlName="timezone"
                                [closeOnSelect]="true"
                                (change)="clearInput()"
                                (keyup)="onKeyup($event)"
                                class="TextSlectGroup _Timezone no-scroll-drpdwn PlaceholderLft timeTextLimit" [ngClass]="{ 'is-invalid':(submitted || f.timezone.touched) && f.timezone.errors}">
                                    <ng-template ng-header-tmp>
                                        <input type="text" placeholder="Search timezone" #searchName
                                            (input)="customSearch('timezone',$event)" />
                                    </ng-template>                                

                                <ng-template ng-option-tmp let-item="item"
                                    let-item$="item$" let-index="index">
                                    <span [ngClass]="{'SelectedCheck':notificationForm.value.timezone==item.timezone}"> {{item.timezone}}</span>
                                </ng-template>
                            </ng-select>
                                <div class="error" *ngIf="(submitted || f.timezone.touched) && f.timezone.errors">
                                    <div class="CommonErrorMsg" *ngIf="f.timezone.errors.required"> Timezone is
                                        required
                                    </div>
                                </div>
                                
                                <!-- ################## Timer section 1 start here #################### -->
                                <app-notification-time-picker [editRowNotification]="editRowNotification" (notifictionTimeEventCallback)="notifictionTimeEventCallback($event)"></app-notification-time-picker>
                            </div>

                            <!-- For Mobile Devices-->
                            <div class="optionhldr">
                                <div class="opt-main">
                                    <div class="MobileDeviceOpt"> 
                                        <ul>
                                            <li class="d-flex align-items-center justify-content-between" (click)="mobileDayCall($event)"> 
                                                <span>Day</span> 
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>
                                                </svg>  
                                            </li>

                                            <li class="d-flex align-items-center justify-content-between border-0" (click)="mobileTimezoneAddCall($event)">  
                                                <span>Time</span> 
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>
                                                </svg>  
                                            </li> 
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>    
                </div>  
            </form>  
        </div>  
    </div>
    <div slot="modalFooter">
        <snl-button size="large" *ngIf="shareType=='setting' && isSaving" (click)="saveNotification()" [disabled]="(!notificationForm.value.ChangeShipNom && !notificationForm.value.etaUpdates && !notificationForm.value.ShipArrivalLaycanPeriod) || (!daysInvalid) || (!isDateFilterValid) || (!isDateFilterValidLoad) || isTimeDuplicate || (!notificationForm.valid)">Save notification properties</snl-button>

        <snl-button size="large" *ngIf="shareType=='booking' && isSaving"  (click)="saveNotification()" [disabled]="(!daysCheckBooking) || (EmailStatus=='INVALID')">Submit</snl-button>
  
        <snl-button size="large" *ngIf="shareType=='bookingDetail' && isSaving" [ngClass]="{'disabledDetailBtn': shareType =='bookingDetail' && isSubscribe}"  (click)="saveNotification()" [disabled]="(!notificationForm.value.ChangeShipNom && !notificationForm.value.etaUpdates && !notificationForm.value.ShipArrivalLaycanPeriod) || (!daysInvalid) || (EmailStatus=='INVALID')">Save notification properties</snl-button>
  
        <snl-button size="large" *ngIf="!isSaving"  [disabled]="true"> Saving...</snl-button>
        <snl-button size="large" type="secondary" (click)="discard()">Cancel</snl-button>
    </div>
  
  </snl-modal>







<!-- DAYS MODEl -->
<div class="FiltersModelCustom LargeDeviceshide" #filterModelClass> 
    <div class="ModelInnerContent">
        <div class="F_ModelHeader">
            <span class="model-dragger" (click)="mobileDaysRemoveCall(isSetMobileDayData)"></span>
            <span>
                <svg-icon (click)="mobileDaysRemoveCall(isSetMobileDayData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Day
            </span>
        </div> 
        <div class="FilterItemsName pb_80">
            <ng-container *ngIf="!isSetMobileDayData">  
                <form [formGroup]="notificationForm">
                <ul  class="Borderseparator">
                    <li> 
                        <div class="CheckOpt">
                            <span class="checkboxHld" [ngClass]="{'all_checkmng':isSelectDay}">
                                <label class="containerCheckbx">
                                    <input type="checkbox" (change)="SelectAllDays($event)" [checked]="CheckAllBox">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':isSelectDay}"> All Days</span>
                                </label>
                            </span> 
                        </div>
                    </li> 

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="mon" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.mon}">Mon</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="tue" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.tue}">Tue</span>
                                </label>
                            </span> 
                        </div> 
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="wed" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.wed}">Wed</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="thu" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.thu}">Thu</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="fri" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.fri}">Fri</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="sat" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sat}">Sat</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="sun" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sun}">Sun</span>
                                </label>
                            </span> 
                        </div>
                    </li>
                </ul> 
            </form>
                <div class="modal-footer modal-footer-fixed"> 
                    <button class="StoltBtnIconLarge" type="button" (click)="mobileDaysRemoveCall(isSetMobileDayData)">Apply</button>
                    <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileDaysRemoveCall(isSetMobileDayData)"> Cancel </button> 
                </div>
            </ng-container>
        </div>
    </div>  
</div>

<!-- TIME MODEl -->
<div class="FiltersModelCustom LargeDeviceshide" #timezoneModelClass>  
    <div class="ModelInnerContent"> 
        <div class="F_ModelHeader">
            <span class="model-dragger" (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)"></span>
            <span>
                <svg-icon (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Time
            </span>
        </div>

        <div class="FilterItemsName pb_80 TimeModelMobile">
            <ng-container *ngIf="!isSetMobileTimezoneData">  
                <form [formGroup]="notificationForm">
                    <div class="TimeCol">
                        <p class="TitleLabel m-0 d-flex align-items-center fw-700 font-16">Timezone</p>
                        <ng-select [items]="timezoneList"
                            [searchable]="false" bindValue="timezone"
                            bindLabel="timezone"  [isOpen]="true"
                            formControlName="timezone"
                            (change)="clearInput()"
                            [closeOnSelect]="true"
                            class="TimezoneSelectHide">
                                <ng-template ng-header-tmp>
                                    <input type="text" placeholder="Search timezone" #searchName
                                        (input)="customSearch('timezone',$event)" />
                                </ng-template>                                

                            <ng-template ng-option-tmp let-item="item"
                                let-item$="item$" let-index="index">
                                <span [ngClass]="{'SelectedCheck':notificationForm.value.timezone==item.timezone}"> {{item.timezone|titlecase}}</span>
                            </ng-template>
                        </ng-select>

                        <p class="TitleLabel m-0 d-flex align-items-center justify-content-between fw-700 font-16">
                            <span>Time</span>
                            <a href="javascript:void(0);">Reset</a>
                        </p>
                        <div class="CustomSlectTime d-none" (click)="displayPicker(1,'on')" [ngClass]="{'active':isDisplayPicker}"> 
                            <span>
                                <span class="font-16 fw-400">Time:</span>
                                <strong class="fw-700">{{timer.HourMin}} {{timeFormate}}</strong>
                            </span>
                            
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z" fill="#788496"/>
                            </svg>      
                                        
                        </div>
                        
                        <div class="TimePickerMain"> 
                            <div class="BodyTimePic p-0 justify-content-center">
                                <timepicker [hourStep]="hstep" [mousewheel]="false"  [minuteStep]="mstep" formControlName="notificationTime"  [meridians]="meridians" [(ngModel)]="notifyTime"></timepicker>
                                <div class="TimeAmPm">
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormatPm}" type="button" (click)="onFormateChange(1,'PM')"  type="button">PM</button>
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormateAm}" (click)="onFormateChange(2,'AM')" type="button"> AM </button>
                                </div>
                            </div>
                            <div class="modal-footer d-none"> 
                                <button class="StoltBtnIconLarge" type="button" (click)="applyTime(1)">Apply</button>
                                <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="displayPicker(1,'off')"> Cancel </button> 
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer modal-footer-fixed"> 
                        <button class="StoltBtnIconLarge" type="button" (click)="applyTime(2)">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)"> Cancel </button> 
                    </div>
                </form>
            </ng-container>
            <!--FILTER NAME LIST END -->
        </div>
    </div>  
</div>
 
<!--SELECT FILTER MODEl -->
<div class="FiltersModelCustom LargeDeviceshide" #filterSelectModelClass>
    <div class="ModelInnerContent">
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='port'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Load Port
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='dport'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Discharge Port
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='charterer'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Charterer
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='tradelane'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Tradelane
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='etaVariance'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Eta Variance
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='loadPortEta'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Load Port ETA
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='dischargePortEta'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Discharge Port ETA
            </span>
        </div>
            <div class=" "> 
                <div class=""> 
                  <div class="FilterItemsName  MobileMUltiSelct SettingFlrsMobile">
                    <ng-container *ngIf="selectedFilter.name=='port'">
                        <ng-select #ngSelectControlPortMobile [isOpen]="true" [items]="portFilterData" [multiple]="true"
                    class="TextSlectGroup" [searchable]="false"
                    [clearable]="false" bindLabel="loadPortName" [closeOnSelect]="true" bindValue="loadPortNumber"
                    [(ngModel)]="portFilterValueTemp" groupBy="port" placeholder="All" [selectableGroup]="true" >
                    <ng-template ng-multi-label-tmp let-items="items">
                        <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('loadPortName')">{{
                            items[0]?.port|titlecase}}</span>
                        <span
                            *ngIf="items.length < 2 && items[0].hasOwnProperty('loadPortName')">{{items[0]?.loadPortName|titlecase}}</span>
                        <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                    </ng-template>
        
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search port names"
                            (input)="customSearch('port',$event)" />
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="checkboxHld">
                            <label class="containerCheckbx tp15">
                                <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                <span class="checkmark"></span>
                            </label>
                            <span class="OptionTxt SpaceLeftOpt" [ngClass]="{'fw-700':item$.selected }">{{item.loadPortName|titlecase}}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="SelectAllOpt" [style.display]="PortDisplay">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt SpaceLeftOpt">All ({{portFilterData.length}})</span>
                            </div>
                        </div>
                    </ng-template>
                        </ng-select>
                    </ng-container>

                    <ng-container *ngIf="selectedFilter.name=='dport'">
                        <ng-select #ngSelectControlDisPortMobile [isOpen]="true" [items]="portDischargeData" [multiple]="true"
                        class="TextSlectGroup" [searchable]="false"
                        [clearable]="false" bindLabel="dischargePortName" [closeOnSelect]="true"
                        bindValue="dischargePortNumber" [(ngModel)]="dportFilterValueTemp" [selectableGroup]="true"
                        placeholder="All" groupBy="discharge">
                        <ng-template ng-multi-label-tmp let-items="items">

                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('dischargePortName')">{{
                                items[0]?.discharge|titlecase}}</span>
                            <span
                                *ngIf="items.length < 2 && items[0].hasOwnProperty('dischargePortName')">{{items[0]?.dischargePortName|titlecase}}</span>
                            <span *ngIf="items.length >= 2">{{items.length}}</span>

                        </ng-template>
                        <ng-template ng-header-tmp>
                            <input type="text" placeholder="Search discharge port"
                                (input)="customSearch('dport',$event)" />
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt SpaceLeftOpt" [ngClass]="{'fw-700':item$.selected }"> {{item.dischargePortName|titlecase}}</span>
                            </div>

                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="SelectAllOpt" [style.display]="dischargeDisplay">
                                <div class="checkboxHld">
                                    <label class="containerCheckbx tp15">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="OptionTxt SpaceLeftOpt">All
                                        ({{portDischargeDataMani.length}})</span>
                                </div>
                            </div>
                        </ng-template>

                        </ng-select>
                    </ng-container> 
                    <ng-container *ngIf="selectedFilter.name=='charterer'">
                        <ng-select #ngSelectControlChartererMobile [isOpen]="true"  [items]="chartererFilterData" [multiple]="true"
                        class="TextSlectGroup" [searchable]="false"
                        [clearable]="false" bindLabel="chartererName" placeholder="All" [closeOnSelect]="true"
                        bindValue="chartererName" [(ngModel)]="chartererFilterValueTemp" [selectableGroup]="true"
                        groupBy="charterer" >
                        <ng-template ng-multi-label-tmp let-items="items">
            
                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('chartererName')">{{
                                items[0]?.charterer|titlecase}}</span>
                            <span
                                *ngIf="items.length < 2 && items[0].hasOwnProperty('chartererName')">{{items[0]?.chartererName|titlecase}}</span>
                            <span *ngIf="items.length >= 2">{{items.length}}</span>
            
                        </ng-template>
                        <ng-template ng-header-tmp>
                            <input style="width: 100%; line-height: 24px" type="text" placeholder="Search charterer names"
                                (input)="customSearch('charterer',$event)" />
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt SpaceLeftOpt" [ngClass]="{'fw-700':item$.selected }"> {{item.chartererName|titlecase}}</span>
                            </div>
            
                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="SelectAllOpt" [style.display]="chartererDisplay">
                                <div class="checkboxHld">
                                    <label class="containerCheckbx tp15">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="OptionTxt SpaceLeftOpt">All
                                        ({{chartererFilterData.length}})</span>
                                </div>
                            </div>
                        </ng-template>
                        </ng-select> 
                    </ng-container> 
                    <ng-container  *ngIf="selectedFilter.name=='tradelane'">
                        <ng-select  #ngSelectControlTradelaneMobile [isOpen]="true" [items]="tradelaneFilterData" [multiple]="true"
                        class="no-scroll-drpdwn PlaceholderLft OpenDropdownRight NoTextTransform" [searchable]="false"
                        [clearable]="false" bindLabel="tradelaneName" [closeOnSelect]="false" bindValue="tradelaneName"
                        [(ngModel)]="tradelaneFilterValueTemp" groupBy="tradelane" placeholder="All" [selectableGroup]="true" >
                        <ng-template ng-multi-label-tmp let-items="items">
                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('tradelaneName')">{{
                                items[0]?.tradelane}}</span>
                            <span
                                *ngIf="items.length < 2 && items[0].hasOwnProperty('tradelaneName')">{{items[0]?.tradelaneName}}</span>
                            <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                        </ng-template>
    
                        <ng-template ng-header-tmp>
                            <input style="width: 100%; line-height: 24px" type="text" placeholder="Search tradelane names"
                                (input)="customSearch('tradelane',$event)" />
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt">{{item.tradelaneName}}</span>
                            </div>
                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="SelectAllOpt" [style.display]="tradelaneDisplay">
                                <div class="checkboxHld">
                                    <label class="containerCheckbx tp15">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="OptionTxt">All ({{tradelaneFilterData.length}})</span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                    </ng-container>
                    <ng-container  *ngIf="selectedFilter.name=='etaVariance'">
                        <ng-select [isOpen]="true" [items]="etaVarianceFilterData" [multiple]="true"
                        class="TextSlectGroup _ETA _Load2 no-scroll-drpdwn PlaceholderLft OpenDropdownRight" [searchable]="false"
                        [clearable]="false" bindLabel="displayname" [closeOnSelect]="false" bindValue="idfilter"
                        [(ngModel)]="etaFilterValueTemp"  placeholder="All" [selectableGroup]="true" >
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt">{{item?.displayname|titlecase}}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    </ng-container>

                    <ng-container *ngIf="selectedFilter.name=='loadPortEta'">
                        <form class="date-range-sustainability mobile-date-range FullClickable" [formGroup]="dateRageFiltersFormLoadEta" >              
                            <mat-form-field appearance="fill">       
                                <span class="FloatingLbl">Load port ETA</span>  
                                <mat-label><span class="AllValue">All</span></mat-label>     
                                <mat-date-range-input [rangePicker]="picker" [min]="minDateLoadPort" [max]="maxDate">      
                                <input matStartDate placeholder="Start date" formControlName="fromDate">      
                                <input matEndDate placeholder="End date" formControlName="toDate">      
                                </mat-date-range-input>      
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>   
                                <mat-date-range-picker #picker>
                                    <mat-date-range-picker-actions>
                                        <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeLoadFilter('loadPortEta')">Apply</button>
                                        <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('loadPortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker> 
                            </mat-form-field> 
                        </form>
                    </ng-container>
                    <ng-container *ngIf="selectedFilter.name=='dischargePortEta'">
                        <form class="date-range-sustainability mobile-date-range FullClickable" [formGroup]="dateRageFiltersFormLoadDischarge" >              
                            <mat-form-field appearance="fill">       
                                <span class="FloatingLbl">Discharge port ETA </span>  
                                <mat-label><span class="AllValue">All</span></mat-label>     
                                <mat-date-range-input [rangePicker]="pickerDischarge" [min]="minDate" [max]="maxDate">      
                                <input matStartDate placeholder="Start date" formControlName="fromDate">      
                                <input matEndDate placeholder="End date" formControlName="toDate">      
                                </mat-date-range-input>      
                                <mat-datepicker-toggle matSuffix [for]="pickerDischarge"></mat-datepicker-toggle>   
                                <mat-date-range-picker #pickerDischarge>
                                    <mat-date-range-picker-actions>
                                        <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeDischargeFilter('dischargePortEta')">Apply</button>
                                        <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('dischargePortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker> 
                            </mat-form-field> 
                        </form>
                    </ng-container>

                  </div>
                </div>
            </div>
        <!--FILTER INDIVIDUAL ITEMS NAME LIST END -->
    </div>
</div>




