
<snl-button class="AddNewFuelBtn" [type]="'secondary'" [size]="'large'"  (click)="openModel('add')">
    <svg-icon class="df-c" name="plus-icon2"></svg-icon>
    Add new fuel
</snl-button>
 

<!-- Snl Table Code Start Here -->                                        
<div class="SccFactorsTable">
    <snl-table class="snl-table-customized distribution-snl-tbl Notification-snl-tbl">
        <snl-row>
            <snl-th class="body-b1 fw-700">Fuel name</snl-th>
            <snl-th class="body-b1 fw-700">Fuel type</snl-th>
            <snl-th class="body-b1 fw-700 EmissionFactorCol">Emission factor</snl-th>
            <snl-th class="body-b1 fw-700 EffectiveDateCol">Effective date</snl-th>
            <snl-th class="body-b1 fw-700">Status</snl-th>
        </snl-row>
        <ng-container *ngIf="fuelData.length > 0;else noFound">
        <ng-container class="border-0" *ngFor="let fuel of fuelData">
            <snl-row>
                <snl-td class="border-0 fuelNameCol" (click)="openModel('add', fuel)">
                    <span class="truncate-dots-hld">
                        <a href="javascript:void(0)" 
                        class="truncateHeight nowWrpTxt capitalize-first fw-700"
                        [ngClass]="{'truncate-text-dots':fuel.fuelName.length > 30}" >
                            {{ fuel.fuelName }}
                        </a>
                    </span>
                </snl-td>
                <snl-td class="border-0 body-b1 fuelTypeCol">
                    <span class="truncate-dots-hld">
                        <p class="m-0 truncateHeight nowWrpTxt capitalize-first" [ngClass]="{'truncate-text-dots':fuel.fuelType.length > 30}" >
                            {{ fuel.fuelType }}</p> 
                    </span> 
                </snl-td>
                <snl-td class="border-0 body-b1 EmissionFactorCol">
                    <p class="m-0 truncate-text">
                        {{ fuel.displayEmissionFactor?.emissionFactor | number: '1.0-4' }}
                    </p> 
                </snl-td>
                <snl-td class="border-0 body-b1">
                    <p class="m-0 d-flex col_gap_8">
                        {{ fuel.displayEmissionFactor.dateRange.fromDate | date:'dd MMM y'}} - {{fuel.displayEmissionFactor.dateRange.toDate | date:'dd MMM y'}}
                        <span class="df-c InfoToolTip" (click)="openModel('effective',fuel)"> 
                            <svg-icon class="df-c" name="info-icon"></svg-icon> 
                        </span>
                    </p>
                </snl-td>
                <snl-td class="border-0 text-center">
                    <snl-pills [type]="'outline'" [layout]="fuel.status === 'Active' ? 'success' : (fuel.status === 'Upcoming' ? 'progress' : 'neutral')">
                        {{ fuel.status }}
                    </snl-pills>
                </snl-td>
            </snl-row>
        </ng-container> 
    </ng-container>
     <!-- Skeleton table -->
     <ng-container *ngIf="isSkeletonEnable">
        <snl-row *ngFor="let number of [0,1,2,3,4,5]">
            <snl-td>
                <div class="Stolt-Skeleton"></div>
            </snl-td>
            <snl-td>
                <div class="Stolt-Skeleton"></div>
            </snl-td>
            <snl-td>
                <div class="Stolt-Skeleton"></div>
            </snl-td>
            <snl-td>
                <div class="Stolt-Skeleton"></div>
            </snl-td>
            <snl-td>
                <div class="Stolt-Skeleton"></div>
            </snl-td>
        </snl-row>
    </ng-container>

    <!-- No record found -->
    <ng-template #noFound>
        <snl-row class="NohoverBG" *ngIf="!isSkeletonEnable">
            <snl-td class="NoRecordAvl">
                <span class="body-b1">You don’t have any SCC factors added yet</span>
            </snl-td>
        </snl-row>
    </ng-template>
    </snl-table>
</div>

<!-- Mobile Cards Start Here -->
<div class="MobileBoxesListMain">
      <div class="MobileBoxesList MobileBoxesListSccFct" *ngFor="let fuel of fuelData">
           <div class="columntxt flex-row justify-content-between align-items-center col_gap_8" (click)="openModel('add', fuel)">
                <div class="NotifiTxtHead d-flex flex-column"> 
                    <a class="LinkTxt sub-heading-s1 fw-700">{{ fuel.fuelName }}</a>
                    <snl-pills [type]="'outline'" [layout]="fuel.status === 'Active' ? 'success' : (fuel.status === 'Upcoming' ? 'progress' : 'neutral')">
                        {{ fuel.status }}
                    </snl-pills>
                </div>
           </div>

           <div class="columntxt flex-column">
               <span class="title-line body-b m-0">Fuel type</span>
               <p class="body-b1 m-0 fw-700">{{ fuel.fuelType }}</p>
           </div>

            <div class="columntxt flex-column">
                <span class="title-line body-b m-0">Emission factor</span>
                <p class="body-b1 m-0 fw-700">{{ fuel.displayEmissionFactor?.emissionFactor | number: '1.0-4' }}</p>
            </div>

            <div class="columntxt InfoToolTipMbl">
                <div class="d-flex flex-column">
                    <span class="title-line body-b m-0">Effective date</span>
                    <span class="fw-700">{{ fuel.displayEmissionFactor.dateRange.fromDate | date:'dd MMM y'}} - {{fuel.displayEmissionFactor.dateRange.toDate | date:'dd MMM y'}}</span>
                </div>
                <span class="df-c InfoToolTip" (click)="openModel('effective', fuel)" *ngIf="fuel.status === 'Active'"> 
                    <svg-icon class="df-c" name="info-icon"></svg-icon> 
                </span>
            </div>
      </div>
      <ng-container *ngIf="!fuelData.length">
        <div class="DataNotAvailable text-center border-0 my-3 w-100 DataNotAvailableSCC">
            <span class="w-100 justify-content-center">You don’t have any SCC factors added yet</span>
        </div>
    </ng-container> 
</div>