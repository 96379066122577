import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-scc-factors',
  templateUrl: './scc-factors.component.html',
  styleUrls: ['./scc-factors.component.scss']
})
export class SccFactorsComponent {
  @Output() closePopupEmit = new EventEmitter();
  @Input() fuelData: any;
  @Input() isSkeletonEnable:any;

  openModel(event:any, object?:any){
    this.closePopupEmit.emit({type:event, data:object});
  }

}
