<!-- Header Section: This section represents the header of the webpage -->
<app-about-header></app-about-header>  

<!-- Common Page Title Section -->
<div class="CommonHeroArea ContactTitleArea position-relative">
    <div class="LargeWindowWidth">
        <div class="CommonHeroContent">
            <h1 class="heading01">Accessibility</h1> 
        </div>
    </div>
</div> 

 
<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
            <p class="CommonPara">Our website has been designed to be as accessible as possible for all visitors. 
                Information regarding accessibility compliance has been provided below along with 
                clarification of cookies used on the site.</p>
            <p class="CommonPara">Stolt Tankers Limited (STL) recognises the importance of providing a website that is 
                    accessible to everyone and easy to use. On this page, we outline our ongoing 
                    commitment to making our online services accessible.</p>
       </div>
    </div> 
</section> 


<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner"> 
        <p class="CommonPara">Accessibility features of this site include:</p>
             <div class="OptionAccessibility">
                <p class="CommonPara">
                    Pages which can be viewed on a wide range of screen resolutions.
                </p>

                <p class="CommonPara">
                    Facility for the user to enlarge the text size. This can be helpful to users with visual impairments.
                </p>

                <p class="CommonPara">
                    The use of alternative text to describe the content of a picture. This is useful 
                    for text-based browsers and/or for users with visual impairments.
                </p>

                <p class="CommonPara">
                    Increased contrast between background and text for readability purposes.
                </p>

                <p class="CommonPara">
                    Page structure which is easy to navigate through a clear and user-friendly 
                    menu that is visible on each page of the site and uses HTML headings.
                </p>

                <p class="CommonPara">
                    The use of ‘cascading style sheets’ with properly structured markup for 
                    content. If style sheets are not supported or are turned off, information on the 
                    site can still be accessed and read.
                </p>

                <p class="CommonPara">
                    Minimal use of JavaScript. Where it is used then all pages are still accessible 
                    should JavaScript be turned off.
                </p> 

                <p class="CommonPara">
                    Facility to navigate fields in online forms with the keyboard 'tab' key. This is 
                    particularly helpful to users with mobility disabilities or visual impairments.
                </p>       

                <p class="CommonPara">
                    A sitemap to provide information about the layout of the website.
                </p>    
             </div>

             <p class="CommonPara">We believe that this website meets the requirements of the level 1 and 2 criteria of 
                the World Wide Web Consortium Web Accessibility Initiative (WAI) 2.1 guidelines. 
                We strive to meet level 3 whenever possible. We adhere to the accepted standards 
                for accessibility and usability whenever we can, although it is not always possible to 
                do so in all areas of the website, especially where guidelines are still evolving.</p>
                
            <p class="CommonPara"> STL is committed to making our online services inclusive because 
                accessibility has a positive effect on general usability and we believe that websites 
                that are accessible and usable benefit everyone.</p>   

            <p class="CommonPara">We will continue to review, test and modify our website for usability and accessibility 
                issues and aim to ensure maintaining compliance is part of our ongoing commitment.</p>  
                
            <p class="CommonPara">For more information about the guidelines issued by the World Wide Web 
                Consortium (W3C) to remove barriers to accessibility, visit Web Accessibility 
                Initiative at the <a href="javascript:void(0);" class="text-decoration-underline">W3C.</a></p>

            <p class="CommonPara">If you have questions or concerns regarding this website then please 
                <a  [routerLink]="['/contact']" class="text-decoration-underline">contact us.</a>
            </p>       
       </div>
    </div> 
</section> 

<!-- Common Content Section -->
<section class="CommonContentSec TermsUseContent pt-0 Applicablelaw">
    <div class="LargeWindowWidth">
       <div class="ContentAreaInner">
            <h2 class="font-24 fw-700">Applicable law</h2> 
            <p class="CommonPara">This site is created, operated and controlled by STL. STL makes no representation that material on STL is appropriate or available for use in other locations.</p>
            <p class="CommonPara">These Terms and Conditions of Use shall be governed by, construed and enforced in accordance with the laws of, England and Wales, without giving effect to any principles of conflicts of laws. Any disputes arising shall be referred for determination to the High Court of Justice in London, UK.</p>
       </div>
    </div> 
</section> 


<!-- Footer Section: This section represents the footer of the webpage -->
<app-footer></app-footer>
<app-scroll-to-top></app-scroll-to-top>
