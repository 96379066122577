<div class="ModelInnerContent DocumentationMobileMdl" *ngIf="modelType == 'cargo' || modelType == 'vessel'">
    <div class="F_ModelHeader" (click)="mobileModelRemoveTooltip($event)">
        <span class="model-dragger" (click)="mobileModelRemoveTooltip($event)"></span>
        <span>TBD</span> 
    </div>
    <div class="FilterItemsName">
         <div class="HeaderData">
            <ul>
                <li class="d-flex align-items-center justify-content-between col_gap_8">
                    <span>{{cargoTooltipData}}</span>
                </li> 
            </ul>
         </div>
    </div>
</div>


<div class="ModelInnerContent DocumentationMobileMdl" *ngIf="modelType == 'cpblQty'">
    <div class="F_ModelHeader" (click)="mobileModelRemoveTooltip($event)">
        <span class="model-dragger"></span>
        <span>Quantity</span> 
    </div>
    <div class="FilterItemsName">
         <div class="HeaderData">
            <ul>
                <li class="d-flex align-items-center justify-content-between col_gap_8">
                    <span span class="fw-700">Latest PC quantity</span>
                    <span>Original quantity</span>
                </li> 
            </ul>
         </div>
         <ul class="FilterListName">
            <li class="d-flex align-items-center justify-content-between col_gap_8">
                <span class="fw-700">{{dataSource}}</span>
                <span >{{dataSource}}</span>
            </li>
        </ul>
    </div>
</div>


<div class="ModelInnerContent DocumentationMobileMdl" *ngIf="modelType == 'mgoQty'">
    <div class="F_ModelHeader" (click)="mobileModelRemoveTooltip($event)">
        <span class="model-dragger"></span>
        <span>Total MGO</span> 
    </div>

    <div class="FilterItemsName">
         <ul class="FilterListName">
            <li class="d-flex align-items-center justify-content-between col_gap_8" *ngFor="let item of dataSource | keyvalue">
                <span>{{item.key}} </span>
                <span class="fw-700">{{item.value ? item.value : '0' }}</span>
            </li>
        </ul>
    </div>
</div>

<div class="ModelInnerContent DocumentationMobileMdl" *ngIf="modelType == 'laycan'">
    <div class="F_ModelHeader" (click)="mobileModelRemoveTooltip($event)">
        <span class="model-dragger"></span>
        <span>Booking laycan</span> 
    </div>

    <ng-container *ngFor="let ItemLcy of dataSource; let first = first;  let i = index;">
        <ng-container *ngIf="ItemLcy?.history">
        <div class="FilterItemsName LaycanList"> 
             <div class="d-flex justify-content-between ListMain" *ngFor="let items of ItemLcy['history']; let i = index; let first = first">
                <span span class="NameLeft">{{items?.label}}:</span>
                <span>{{items.from | date:'d MMM
                    y':'UTC'}} - {{items.to | date:'d MMM
                    y':'UTC'}}</span>
             </div>
        </div>
</ng-container>
    </ng-container>
</div>


<div class="ModelInnerContent DocumentationMobileMdl" *ngIf="modelType == 'doc'">
    <div class="F_ModelHeader" (click)="mobileModelRemoveTooltip($event)">
        <span class="model-dragger"></span>
        <span *ngIf="documentType == 'loading'">{{dataSource?.documentType}} - {{dataSource?.loadDocs[0].documentStatus|titlecase}}</span> 
        <span *ngIf="documentType == 'discharge'">{{dataSource?.documentType}} - {{dataSource?.dischargingDocs
            [0].documentStatus|titlecase}}</span> 
    </div>

    <div class="FilterItemsName" *ngIf="documentType == 'loading'">
         <div class="HeaderData" *ngFor="let items of dataSource?.loadDocs; let i = index; let first = first">
            <ul>
                <li class="d-flex align-items-center justify-content-between col_gap_8">
                    <span>{{items.name}}</span>
                </li> 
            </ul>
         </div>
    </div>
    <div class="FilterItemsName" *ngIf="documentType == 'discharge'">
        <div class="HeaderData" *ngFor="let items of dataSource?.dischargingDocs
        ; let i = index; let first = first">
           <ul>
               <li class="d-flex align-items-center justify-content-between col_gap_8">
                   <span>{{items.name}}</span>
               </li> 
           </ul>
        </div>
   </div>
</div>

<div class="ModelInnerContent DocumentationMobileMdl" *ngIf="modelType == 'tranship'">
    <div class="F_ModelHeader">
        <span class="model-dragger" (click)="mobileModelRemoveTooltip($event)"></span>
        <span>TransShipment</span> 
    </div>
    <div class="FilterItemsName">
         <div class="HeaderData">
            <ul>
                <li class="d-flex align-items-center justify-content-center">
                    <span>This is a transshipment vessel</span>
                </li> 
            </ul>
         </div>
    </div>
</div>