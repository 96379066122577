import { Component, OnInit } from '@angular/core';
import { ContactData } from "../../../models/publicPages";
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';
import { GoogleAnaylticsService } from 'src/app/services/google-anayltics.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactRegion = 'All Regions';
  contactJob = 'All functions';
  contactList: ContactData[] = [];
  contactListData: ContactData[] = [];
  contactJobData: any[];
  contactRegionData: any[];
  contactRegionDataFilter: any[];
  isSkelton = false;
  pageTrackingData:any = {}
  isLogedin: Observable<boolean>;
  SubscriberContact:any;
  showMonkeySurvey: boolean;
  userId:any;
  constructor(public navigationService: NavigationService, private _googleAnaylticsService: GoogleAnaylticsService,
    public publicPagesService: PublicPagesService) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers Contact');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  ngOnInit(): void {
    this.addDataToGADataLayer();
    this.isSkelton = true;
    this.publicPagesService.getContactList().subscribe({
      next:res=>{
        if (res && res.data) {
          this.isSkelton = false;
          this.contactList = res.data;
          this.contactJobData = ['All functions', ...this.contactList.map(item => item.operation_type)
            .filter((value, index, self) => self.indexOf(value) === index)]
          this.contactRegionData = ['All Regions', ...this.contactList.map(item => item.region)
            .filter((value, index, self) => self.indexOf(value) === index)];
          this.contactListData = this.contactList;
          this.contactRegionDataFilter = this.contactRegionData;
            }
      },
      error:error=>{
      this.isSkelton = false;
      this.navigationService.errorOccure(error);
      }
    })
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          this.navigationService.isSurveyMonkey = true;
          this.showMonkeySurvey = true;
          this.userId = res.data.user_id;
        }
    })
  }

  clearFilter() {
    this.contactRegion = 'All Regions';
    this.contactJob = 'All functions';
    this.jobAndRegionChanged();
  }

  filterChnaged() {
    this.jobAndRegionChanged();
  }

  jobAndRegionChanged() {
    const modifyRegion = this.contactRegion !== 'All Regions' ? this.contactList.filter(it => it.region == this.contactRegion) : this.contactList;
    const modifyJob = this.contactJob !== 'All functions' ? modifyRegion.filter(it => it.operation_type == this.contactJob) : modifyRegion;
    this.contactListData = modifyJob;
    let filteredRegion = ['All Regions', ...this.contactList.filter(it => it.operation_type == this.contactJob || it.region == 'All Regions').map(it => it.region)];
    this.contactRegionDataFilter = this.contactJob !== 'All functions' ? filteredRegion.filter((value: any, index: number, self: any) => self.indexOf(value) === index) : ['All Regions', ...this.contactList.map(item => item.region)
      .filter((value, index, self) => self.indexOf(value) === index)];
  }
  
  addDataToGADataLayer() {
    this.SubscriberContact = this._googleAnaylticsService.castTrackingData.subscribe(pageTrackingData => {
      this.pageTrackingData = pageTrackingData
      if(this.pageTrackingData.userId){
        console.log("this.pageTrackingData",this.pageTrackingData);
        this._googleAnaylticsService.sendPageTrackingLog(this.pageTrackingData)
      }
    });
  }

  ngOnDestroy(){
    this.SubscriberContact.unsubscribe();
  }
}
