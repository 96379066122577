import { Component,EventEmitter,Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Base64 } from '../../../../helpers/fleetEncodeDecode';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-global-search-result',
  templateUrl: './global-search-result.component.html',
  styleUrls: ['./global-search-result.component.scss']
})

export class GlobalSearchResultComponent {
  navType = environment.navType;
  @Input() searchListing:any = [];
  @Input() selectedTag:any
  @Input() isSustainabilityAccess: boolean;
  @Input() isDocumentationAccess: boolean;
  Cargotooltip:any=null
  bookingIndex:any=null;
  Doctooltip:boolean = false

  @Output() emitCargoData: EventEmitter<any> = new EventEmitter();
  @Output() emitDocumentoData: EventEmitter<any> = new EventEmitter();
  @Output() emitOverflowClass: EventEmitter<boolean> = new EventEmitter();
  constructor(private _router: Router,
    public navigationService: NavigationService,
    private _storageService: StorageService,
    private bookingService: BookingService) {
   
  }

  showAllCargos(index:any , bkIndex:any){
    this.Cargotooltip = index;
    this.bookingIndex = bkIndex;
  }

  hideAllCargos(){
    this.Cargotooltip = null;
    this.bookingIndex = null;
  }


  downloadBookingCase2(idBooking:any , fleet:any){
    this.bookingService.downloadScc([{ 'bookingId': idBooking, 'fleet': fleet }]).subscribe({next:(res: any) => {
      if (res && res.data) {
        window.location.href = res.data;
      }
    }, error:(err:any) => {
      this.navigationService.errorOccure(err);
    }})
  }

  showVesselVoyegeRoute(idBooking:any , fleet:any ,vCode:any){
    this._storageService.putDataInStorage("globalSearch",true);
    this._storageService.putDataInStorage("GSearchCode", vCode);
    let fleets = Base64.encode(fleet);
    this._router.navigate([`/booking/${fleets}/${idBooking}`]);
  }

  navigateDetails(idBooking:any,fleet:any)
  {
    this._storageService.removeDataFromStorage("GSearchCode");
    let fleets = Base64.encode(fleet);
    this._storageService.putDataInStorage("globalSearch",true);
    this._router.navigate([`/booking/${fleets}/${idBooking}`]);
  }
  
  mobileCargoesAddCall(event: Event,data:any) {
    this.emitOverflowClass.emit(true);
    this.emitCargoData.emit(({event:event,data:data}));
  }

  mobileDocumentAddCall(event: Event, data:any,type:any) {
    this.emitOverflowClass.emit(true);
    this.emitDocumentoData.emit(({event:event,data:data,docType:type}));
  }

 
}
