import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PublicPagesService } from './public-pages.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(private http: HttpClient, private publicPagesService: PublicPagesService) { }


  baseUrl = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;
  baseUrlSustainanility = environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME;
  baseUrlVessel = environment.API_BASE_URL + environment.VESSELTRACKING_SERVICE_BASE_NAME;
  authVessel = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;
  baseUrl_auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;

  getHeader() {
    return this.publicPagesService.getHeader();
  }
  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }

  // portagentOtpVeificationWithToken(token?: any, otp?:any): Observable<any> {
  //   let body = `token=${token}`;
  //   return this.http.post<any>(`${this.baseUrl}/api/verifyPortAgentWithToken`, body, this.getHeader()).pipe(map(data => data));
  // }

  portagentOtpVeificationWithToken(body: any, otp?:any): Observable<any> {
    let Reqbody = `token=${body.token}&isNewLink=${body.isNewLink}&isResendOtp=${body.isResendOtp}`;
    return this.http.post<any[]>(`${this.baseUrl}/api/verifyPortAgentWithToken`, Reqbody , this.getHeader()).pipe(map(data => data));
  }

  verifyPortAgentOTP(body: any, otp?:any): Observable<any> {
    let Reqbody = `token=${body.token}&isNewLink=${body.isNewLink}&OTP=${encodeURIComponent(body.OTP)}`;
    
    return this.http.post<any[]>(`${this.baseUrl}/api/verifyPortAgentOTP`, Reqbody, this.getHeader()).pipe(map(data => data));
  }

  sendOTPVerification(otp: any): Observable<any> {
    let body = {}
    return this.http.post<any>(`${this.baseUrl}/api/sendotpverification`, body, this.getHeader()).pipe(map(data => data))
  }

  async updateTokenInfo(body: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.baseUrl}/api/updateTokenInfo`, body, this.getHeader()).pipe(map(data => data)).toPromise();
      return response;
    } catch (error) {
      console.error('Error in updateTokenInfo:', error);
      throw error;
    }
  }

}
