import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { BookingService } from 'src/app/services/booking.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  pages: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    public bookingService: BookingService
  ) {
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn()) {
      let pageList: any = localStorage.getItem('pages');
      this.pages = JSON.parse(pageList);
      let path = route.routeConfig && route.routeConfig.path;
        let checkUrl = this.pages.filter((res: any) => res.path.includes(path));
        if (checkUrl.length && checkUrl[0].show) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
    }else {
      this.router.navigate(['/']);
      return false;
    }
  
  }
}
