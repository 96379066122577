<!-- Page Content Holder -->
<script type="text/javascript" src="../../../../assets/js/iframeResizer.contentWindow.min.js"></script>
<section class="CommonContentSec">
    <div class="LargeWindowWidth">
       <div class="SecInner"> 
            <div class="TextContent">
                <div class="BoxTopInfo">
                    <div class="BoxTopInfoInner">
                        <h3 class="heading03"  *ngIf="!isSkelton">Total fleet strength</h3>
                        <h3 class="heading03 Stolt-Skeleton mb-3" *ngIf="isSkelton"></h3>
                        <h4 class="heading04" *ngIf="!isSkelton">{{totalFleets > 0 ? totalFleets : 0}}</h4>
                        <h4 class="heading04 Stolt-Skeleton" *ngIf="isSkelton"></h4>
                    </div>
                    <div class="BoxTopInfoInner InActive">
                        <h3 class="heading03" *ngIf="!isSkelton">Now displaying</h3>
                        <h3 class="heading03 Stolt-Skeleton mb-3" *ngIf="isSkelton"></h3> 
                        <h4 class="heading04" *ngIf="!isSkelton">{{displayFleets > 0 ? displayFleets : 0}}</h4>
                        <h4 class="heading04 Stolt-Skeleton" *ngIf="isSkelton"></h4>
                    </div>                   
                </div>
                <div class="FilterBxLightGrey" *ngIf="!isSkelton">
                    <h3 class="heading03">Filters <a href="javascript:void(0)" (click)="clearFilter()">CLEAR FILTERS</a></h3>
                    <p class="GrapghTitle">Capacity (Dead Weight Tonnes)</p>
                    <div class="CapacityGraphM">
                    <div class="CapacityGraph"> 
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options" (userChangeEnd)="filterData()"></ngx-slider>
                    </div>


                    <div class="FieldsArea">
                        <div class="FieldsInner"> 
                            <div class="form-group"> 
                                <input type="text" [(ngModel)]="minValue" class="form-control form-control-stolt" id="" placeholder="15,000" name="">
                             </div> 
                        </div>
                        <div class="FieldsInner"> 
                            <div class="form-group"> 
                                <input type="text" [(ngModel)]="maxValue" class="form-control form-control-stolt" id="" placeholder="90,000" name="">
                             </div> 
                        </div>                        
                    </div>
                    </div>
                </div> 
                <!-- Column Content Stolt Skeleton Start -->
                <div class="FilterBxLightGrey Stolt-SkeletonBx" *ngIf="isSkelton">
                    <div class="Stolt-Skeleton"></div>
                </div>
                <!-- Column Content Stolt Skeleton End -->

            </div>
            <div class="ImgArea map-fleetinfo">
                <app-agm-map *ngIf="fleetList.length > 0" class="FleetMapFirst" [mapType]="'fleetmultiple'" [fleetArray]="fleetList"></app-agm-map>
                <div class="Stolt-Skeleton" *ngIf="isSkelton"></div>
            </div>
       </div>  
</div>     
</section> 


<section class="CommonContentSec TableFleetData" *ngIf="!isSkelton">
    <div class="LargeWindowWidth">
        <div class="table-data CommonTableContainer" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" (scrolled)="onScroll()"> 
            <table class="table" aria-describedby="mydesc">
                <thead class="thead-dark StickyThead">
                    <tr>
                        <th scope="col">
                            <span class="TheadeContent"> 
                                Vessel name   
                                <span
                                    [ngClass]="{'shortingIcons': true,
                        'active': sortInprogress.column5.isActive,
                        'ascending-icon':sortInprogress.column5.isActive && sortInprogress.column5.value === 'asc','descending-icon':sortInprogress.column5.isActive && sortInprogress.column5.value === 'desc'}"
                                    (click)="toogleSortingOrder('column5')">
                                    <svg-icon name="asc-dsc"></svg-icon>
                                    <app-sorting *ngIf="sortInprogress.column5.isOpen"
                                        [isActive]="sortInprogress.column5.isActive"
                                        [data]="[{ 'name': 'Ascending', 'val': 'asc' }, { 'name': 'Descending', 'val': 'desc' }]"
                                        [sortType]="sortInprogress.column5.value" [colName]="'column5'"
                                        (sortingType)="fetchSortingType($event)"
                                        (popUpMouseOut)="popUpMouseLeaveClose($event)"></app-sorting>
                                </span>
                            </span>
                        </th>
                        <th scope="col">Next destination</th>
                        <th scope="col">Current position</th> 
                        <th  scope="col" class="text_right">
                            <span class="TheadeContent justify-content-end"> 
                                Capacity (DWT) 
                                <span
                                    [ngClass]="{'shortingIcons': true,
                        'active': sortInprogress.summer_dwt.isActive,
                        'ascending-icon':sortInprogress.summer_dwt.isActive && sortInprogress.summer_dwt.value === 'asc','descending-icon':sortInprogress.summer_dwt.isActive && sortInprogress.summer_dwt.value === 'desc'}"
                                    (click)="toogleSortingOrder('summer_dwt')">
                                    <svg-icon name="asc-dsc"></svg-icon>
                                    <app-sorting *ngIf="sortInprogress.summer_dwt.isOpen"
                                        [isActive]="sortInprogress.summer_dwt.isActive"
                                        [data]="[{ 'name': 'Ascending', 'val': 'asc' }, { 'name': 'Descending', 'val': 'desc' }]"
                                        [sortType]="sortInprogress.summer_dwt.value" [colName]="'summer_dwt'"
                                        (sortingType)="fetchSortingType($event)"
                                        (popUpMouseOut)="popUpMouseLeaveClose($event)"></app-sorting>
                                </span>
                            </span>
                        </th>                        
                        <th scope="col">Year built</th>
                        <th scope="col">Compliance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fl of fleetList| sort:sortingFieldType:sortingFieldName;"> 
                        <td><span>{{fl.column5?(fl.column5 | titlecase):'-'}}</span></td>
                        <td><span>{{fl.port? (fl.port) :'-'}}</span></td> 
                        <td><span><a *ngIf="fl.last_latitude != 0;else dash" class="links" href="javascript:void(0);"  (click)="setLocation(fl)">View map</a>
                        <ng-template #dash>-</ng-template></span></td>
                        <td class="text_right"><span>{{fl.summer_dwt ? (fl.summer_dwt | number:'1.0':'en-US'):'-'}}</span></td>
                        <td><span>{{fl.yr_built?(fl.yr_built):'-'}}</span></td>
                        <td><span class="Icon-download newtab-extlink">
                            <a class="links" rel=noopener target="_blank" href="{{'/fleet/vessel?id=Q88-'+ fl.imo_no}}">View Q88 <svg-icon name="newtab-extlink"></svg-icon> </a></span></td>
                          
                    </tr>
                    <tr *ngIf="!fleetList.length">
                        <td colspan="8" class="DataNotAvailable text-center mb-0"><span>No Records Found</span></td>
                    </tr>
                </tbody>
            </table> 
        </div> 
       <!-- Mobile functionality start from here-->
        <div class="MobileTableBoxHolder" *ngFor="let fl of fleetList;">
            <div class="MobileTableBox">
                 <div class="MobileTableBoxHead">
                    <h2 class="heading03 heading03-front">{{fl.column5?(fl.column5 | titlecase):'-'}}</h2>
                    <span>Next destination</span>
                    <p>{{fl.port? (fl.port|titlecase) :'-'}}</p> 
                    <div class="ContentIn">
                        <div class="LeftCnt"> 
                            <span>Year built</span>
                            <p>{{fl.yr_built?(fl.yr_built):'-'}}</p>
                        </div> 
                        <div class="RightCnt"> 
                            <span>Capacity (DWT)</span>
                            <p>{{fl.summer_dwt ? (fl.summer_dwt | number:'1.0':'en-US'):'-'}}</p>
                        </div>  
                    </div> 
                    <div class="FtrBtnSecMobile">
                        <span class="Icon-download"> <svg-icon name="newtab-extlink"></svg-icon><a class="links small-IconSpce" target="_blank" href="{{'/fleet/vessel?id=Q88-'+ fl.imo_no}}" rel="noopener">View Q88</a></span>
                        <span class="Icon-map"> <svg-icon name="Icon-map" *ngIf="fl.last_latitude != 0"></svg-icon><a *ngIf="fl.last_latitude != 0" class="links" (click)="setLocation(fl)" href="javascript:void(0);">View map</a></span>
                    </div> 
                 </div> 
            </div>   
        </div>
        <!-- Mobile functionality end here-->
    </div>     
</section> 


<!-- Table Section Stolt Skeleton Start -->
<section class="CommonContentSec TableFleetData" *ngIf="isSkelton">
    <div class="LargeWindowWidth">
        <div class="table-data CommonTableContainer"> 
            <table class="table" aria-describedby="mydesc">
                <thead class="thead-dark StickyThead">
                    <tr>
                        <th scope="col"><span class="Stolt-Skeleton"></span></th>
                        <th scope="col"><span class="Stolt-Skeleton"></span></th>
                        <th scope="col"><span class="Stolt-Skeleton"></span></th> 
                        <th class="text_right" scope="col"><span class="Stolt-Skeleton"></span></th>                        
                        <th scope="col"><span class="Stolt-Skeleton"></span></th>
                        <th scope="col"><span class="Stolt-Skeleton"></span></th>
                    </tr>
                </thead>
                 <tbody>
                    <tr *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]"> 
                        <td><span class="Stolt-Skeleton"></span></td>
                        <td><span class="Stolt-Skeleton"></span></td> 
                        <td><span class="Stolt-Skeleton"></span></td>
                        <td class="text_right"><span class="Stolt-Skeleton"></span></td>
                        <td><span class="Stolt-Skeleton"></span></td>
                        <td><span class="Stolt-Skeleton"></span></td>
                    </tr>    

                </tbody>
            </table> 
        </div> 

        <!-- Mobile Data Skeleton Start -->
        <div class="MobileTableBoxHolder" *ngFor="let number of [0,1,2,3,4,6,7,8,9]">
            <div class="MobileTableBox">
                 <div class="MobileTableBoxHead BoxHead-Skeleton"> 
                     <div class="Stolt-Skeleton h-100"></div>
                 </div> 
            </div>   
        </div>
        <!-- Mobile Data Skeleton End -->

    </div>     
</section> 
<!-- Table Section Stolt Skeleton End -->
 <app-scroll-to-top></app-scroll-to-top>

 
  <!-- Modal --> 

  <div class="StickyShareBox fleetMapHd" *ngIf="sigleFleetMapPopUp">
    <div class="CustomModelContent modelfleetmap">
        <div class="StickyShareBoxInner">
            <div class="ShareHeadTitle">
                <p class="titleFletMp">{{fleetItem?.column5}}
                    <span class="btn-close" (click)="sigleFleetMapPopUp=false"></span>
                </p>
             </div>
            <div class="mapbdy mapbdyup">
                <!-- map section -->
                <div>
                  <app-agm-map [mapType]="'fleet'" [sItem]="fleetItem"></app-agm-map>
                  </div>
                <!-- map section end -->
            </div>
        </div>
    </div>
</div>