import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from 'src/app/services/navigation.service';
import { PublicPagesService } from 'src/app/services/public-pages.service';

@Component({
  selector: 'app-download-document',
  templateUrl: './download-document.component.html',
  styleUrls: ['./download-document.component.scss']
})
export class DownloadDocumentComponent {
  isLoading:boolean = false
  constructor(public toastr: ToastrService, public navigationService: NavigationService, private publicPageService: PublicPagesService, private authService: MsalService, private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers');
  }
  

  ngOnInit(): void { 
    this.DownloadDocuments()
  }

  DownloadDocuments()
  {
    this.activatedRoute.queryParams.subscribe({next:paramsId => {
      if (paramsId.share) {
        this.navigationService.showSuccess("Download is in progress. Don't close the window", '');
        setTimeout(() => {
          this.refactorDownloadDocument(paramsId.share)
        }, 3000);
      
      } else {
        this.navigationService.showError("Something went wrong. Please try again after sometime", '');
        this.SetTimeOut()
        this.isLoading = false
      }
    },error:err=>{
      this.navigationService.showError("Something went wrong. Please try again after sometime", '');
        this.SetTimeOut()
        this.isLoading = false
    }})
  }


refactorDownloadDocument(key:any)
{
  this.publicPageService.downloadDocument(key.replace(/ /g, '+')).subscribe({next:res => {
    if (res.data && res.data.url) {
      this.isLoading = true
      let elem = window.document.createElement('a');
      elem.href = res.data.url
      elem.download = res.data.fileName;        
      document.body.appendChild(elem);
      elem.click();        
      document.body.removeChild(elem);
      this.navigationService.showSuccess("Documents downloaded successfully", '');
      setTimeout(() => {
        window.close();
      }, 1000);
    } else if (res.data && res.data.message) {      // this is for expire URL message
      this.isLoading = false
      this.navigationService.showError(res.data.message, '');
      this.SetTimeOut()
    }
    else {
      this.navigationService.showError("Something went wrong. Please try again after sometime", '');
      this.SetTimeOut()
      this.isLoading = false
    }
  }, error:err => {
    console.log(err)
    this.navigationService.errorOccure(err);
    this.SetTimeOut()
    this.isLoading = false
  }})
}

  SetTimeOut() {
    setTimeout(() => {
      window.close();
    }, 2000);
  }
}
