import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {PublicPagesService} from './public-pages.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  baseUrl= environment.API_BASE_URL+environment.AUTH_SERVICE_BASE_NAME;
  constructor(private http :HttpClient,private publicPagesService :PublicPagesService) { }

  getHeader() {
   return this.publicPagesService.getHeader();
  }
  getHeaderJson(){
    return this.publicPagesService.getHeaderTypeJson();
  }
  saveNotification(notificationData:any): Observable<any> {
    let body= notificationData;
  return  this.http.post(`${this.baseUrl}/api/saveNotifications`,body,this.getHeaderJson()).pipe(map(data => data));
  }
  getNotification(): Observable<any>{
    return  this.http.get(`${this.baseUrl}/api/getNotifications`,this.getHeaderJson()).pipe(map(data => data));
  }

  
}
