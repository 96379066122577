import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-effective-date-model',
  templateUrl: './effective-date-model.component.html',
  styleUrls: ['./effective-date-model.component.scss']
})
export class EffectiveDateModelComponent {
  @Output() closePopupEmit = new EventEmitter();
  @Input() isPreview: any;
  @Input() editData: any;
  snlModalSettings = { showCloseIcon: true, backdropClick: false }

  closePopup() {
    this.removebodyClass();
    this.closePopupEmit.emit();
  }

  removebodyClass() {
    let ele: any = document.querySelector("body");
    if (ele && ele.style) {
      ele.style.overflow = 'auto';
    }
  }


}
