<script type="text/javascript" src="../../../../assets/js/iframeResizer.contentWindow.min.js"></script>

 

<!-- Page Content Holder -->
<div class="StoltWrapper">
    <main class="StoltMainContent">
        <section class="CommonContentSec">
            <div class="LargeWindowWidth MobilePosition">
            <div class="SecInner"> 
                    <div class="TextContent MobileWindow">
                        <div class="BoxTopInfo">
                            <div class="BoxTopInfoInner">
                                <h3 class="heading03" *ngIf="!isSkelton">Total contacts</h3>
                                <h3 class="heading03 Stolt-Skeleton" *ngIf="isSkelton"></h3> 
                                <h4 class="heading04" *ngIf="!isSkelton">{{contactList?.length}}</h4>
                                <h4 class="heading04 Stolt-Skeleton" *ngIf="isSkelton"></h4>
                            </div>
                            <div class="BoxTopInfoInner InActive">
                                <h3 class="heading03" *ngIf="!isSkelton">Now displaying</h3>
                                <h3 class="heading03 Stolt-Skeleton" *ngIf="isSkelton"></h3>  
                                <h4 class="heading04" *ngIf="!isSkelton">{{contactListData?.length}}</h4>
                                <h4 class="heading04 Stolt-Skeleton" *ngIf="isSkelton"></h4>
                            </div>
                        </div>
                        
                        <div class="FilterBxLightGrey" *ngIf="!isSkelton">
                            <h3 class="heading03" (click)="clearFilter()">Filters <a href="javascript:void(0)">CLEAR FILTERS</a></h3>
                            <div class="FieldsArea">
                                <div class="FieldsInner">
                                    <label>Function</label>
                                    <ng-select class="ng-select-fade ng-select-large ng-select-md" [searchable]="true" [clearable]="true"
                                        [(ngModel)]="contactJob" (change)="filterChnaged()">
                                        <ng-option *ngFor="let el of contactJobData" [value]="el">{{el|titlecase}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="FieldsInner">
                                    <label>Region</label>
                                    <ng-select class="ng-select-fade ng-select-large" [searchable]="true" [clearable]="true"
                                        [(ngModel)]="contactRegion" (change)="filterChnaged()">
                                        <ng-option *ngFor="let el of contactRegionDataFilter" [value]="el">{{el|titlecase}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        

                        <!-- Column Content Stolt Skeleton Start -->
                        <div class="FilterBxLightGrey Stolt-SkeletonBx" *ngIf="isSkelton">
                            <div class="Stolt-Skeleton"></div>
                        </div>
                        <!-- Column Content Stolt Skeleton End -->
                    </div>
                </div>
            </div>
        </section>
        <section class="CommonContentSec AdressInfoBxMain" *ngIf="!isSkelton">
            <div >
                <div class="LargeWindowWidth"> 
                    <div class="dataBxHld"> 
                        <div class="AdressInfoBx" *ngFor="let item of contactListDataSource;index as i;">
                            <h4 class="heading04">
                                {{item[0] | titlecase}}
                            </h4> 

                            <!-- Name & Contact Details Start Here --> 
                            <div class="InnerBxAdressMain">
                                <div class="InnerBxAdress" *ngFor="let itemss of item[1];index as i;">
                                    <div class="InnerLeftCnt">
                                        <h5 class="heading05">{{itemss.employeeName|titlecase}}</h5>
                                        <p>{{itemss.operation_type|titlecase}}<em>|</em><a class="CursorDefault" *ngIf="itemss.office_address">{{itemss.office_address|titlecase}}</a></p>
                                    </div>
                                    <div class="InnerRightCnt">
                                        <p *ngIf="itemss.phone"> <a class="linkstg" href="mailto:{{itemss.email}}?subject=Email received from ST Customer Portal"><svg-icon class="mailIcn" name="mail-icon"></svg-icon>{{itemss.email}}</a></p>
                                        <p *ngIf="itemss.phone"> <a href="tel:{{itemss.phone}}"><svg-icon name="phone-icon"></svg-icon>{{itemss.phone}}</a></p>
                                        <p *ngIf="itemss.mobile"><a href="tel:{{itemss.mobile}}"><svg-icon name="mob-icon"></svg-icon>{{itemss.mobile}}</a></p>
                                    </div>
                                </div>  
                            </div>  
                        </div> 
                    </div> 
                </div>
                <div class="DataNotAvailable" *ngIf="contactListData.length ===0"> 
                    <div class="LargeWindowWidth"><span>No Item Found!</span></div> 
                </div>
            </div>
        </section>



        <!-- Section Content Stolt Skeleton Start -->
        <section class="CommonContentSec AdressInfoBxMain" *ngIf="isSkelton">
            <div>
                <div class="LargeWindowWidth">
                    <div class="AdressInfoBx">
                        <h4 class="heading04 Stolt-Skeleton Custom-w-50" ></h4>

                        <div class="InnerBxAdress" *ngFor="let number of [0,1,2,3,4,5]">
                            <div class="InnerLeftCnt">
                                <h5 class="heading05 Stolt-Skeleton Custom-w-50"></h5>
                                <p class="Stolt-Skeleton Custom-w-70"></p> 
                                <p class="Stolt-Skeleton Custom-w-80"></p> 
                            </div>
                            <div class="InnerRightCnt">
                                <p class="Stolt-Skeleton"></p>
                                <p class="Stolt-Skeleton"></p>
                                <p class="Stolt-Skeleton"></p>
                            </div>
                        </div>

        
                    </div>
                </div>
            </div>
        </section>
        <!-- Section Content Stolt Skeleton End -->
    </main>
    <app-scroll-to-top></app-scroll-to-top>
</div>