import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-effective-date-filter',
  templateUrl: './effective-date-filter.component.html',
  styleUrls: ['./effective-date-filter.component.scss']
})
export class EffectiveDateFilterComponent {
  @Input() status:any;
  @Input() errorDateRange: any;
  @Input() savedDateRange: any
  @Input() dateRange: any;
  @Input() resetAllCalled: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  todayDate = new Date();
  fromDate: any;
  toDate: any;
  dateRageFiltersForm: any;
  customValidate: any = {};
  reactiveValidate: object = {};
  isDateFilterApplied = false;
  calanderSubscriber: any;
  @Output() submitFilter: EventEmitter<any> = new EventEmitter();

  constructor(public navigationService: NavigationService, private _formBuilder: UntypedFormBuilder,
    public fb: UntypedFormBuilder) {
  }
  ngOnInit(): void {
    this.reactiveValidationFun();
    this.dateReandSubscriber();

    if (this.savedDateRange) {
      this.resetDateRangeFilter(this.savedDateRange)
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.resetAllCalled?.currentValue && changes?.resetAllCalled?.currentValue != changes?.resetAllCalled?.previousValue) {
      this.resetDateRangeFilter();
    }

  }
  get f() {
    return this.dateRageFiltersForm.controls;
  }


  dateReandSubscriber() {
    this.calanderSubscriber = this.dateRageFiltersForm.valueChanges.subscribe((newVal: any) => {
      this.dateRange = {
        fromDate: moment(newVal.fromDate).format('YYYY-MM-DD'),
        toDate: moment(newVal.toDate).format('YYYY-MM-DD')
      }
    })
  }
  reactiveValidationFun() {
    this.reactiveValidate = this.dateRangeFilters();
    this.customValidate = {
    }

    this.dateRageFiltersForm = this._formBuilder.group(this.reactiveValidate, this.customValidate);

  }
  dateRangeFilters() {
    let fromDate = '';
    let toDate = '';
    return {
      fromDate: new UntypedFormControl(fromDate, [Validators.required,]),
      toDate: new UntypedFormControl(toDate, [Validators.required])
    }
  }
  applyDateRangeFilter() {
    this.isDateFilterApplied = true;
    this.submitFilter.emit({ type: 'dateRange', data: this.dateRange })
  }

  resetDateRangeFilter(args?: any) {
    this.isDateFilterApplied = false
    let fromDate = args ? args.fromDate : '';
    let toDate = args ? args.toDate : '';
    if (this.dateRageFiltersForm) {
      this.dateRageFiltersForm.patchValue({
        fromDate: fromDate,
        toDate: toDate
      });
    }
    if (!args) {
      this.submitFilter.emit({ type: 'dateRange', data: this.dateRange })
    }
  }

}
