<snl-modal (modalClose)="closePopup()" [settings]="snlModalSettings" [type]="'medium'" [open]="isPreview"
    class="CenterModelCmn modelAnnoucement CommonPhoneCstm EffectiveDateMdl ">
    <div class="HeaderInner" slot="heading">
        <strong class="header-h2 capitalize-first">History</strong>
    </div>
    <div slot="modalContent">
        <div class="HeaderFactorLog">
            <p class="sub-heading-s1 m-0">{{editData.fuelName}}</p>
            <span class="body-b1">{{editData.fuelType}}</span>
        </div>

        <div class="EffectiveDateColIn" *ngFor="let item of editData.emissionFactors; let i = index">
            <div class="InnerInfoEffective">
                <span class="body-b">Effective date <span class="cargo-delay GreyValue fw-700" *ngIf="item.original">Original</span></span>
                <p class="section-title m-0">{{ item.dateRange.fromDate | date:'dd MMM y'}} - {{item.dateRange.toDate | date:'dd MMM y'}}</p>
             </div>
             <div class="InnerInfoEffective">
                <span class="body-b">Emission factor</span>
                <p class="section-title m-0">{{item.emissionFactor | number: '1.0-4'}}</p>
             </div>
             <div class="UpdatedByCol">
                <span class="caption">Updated on {{item.updatedAt | date:'dd MMM y'}}</span>
                <span class="caption">By {{item.updatedByName}}</span>
             </div>
        </div>
    </div>
    <div slot="modalFooter">
        <snl-button size="large" (click)="closePopup()">Close</snl-button>
    </div>
</snl-modal>