import { Component , OnInit} from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
import { GoogleAnaylticsService } from 'src/app/services/google-anayltics.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  pageTrackingData:any = {}
  SubscriberAbout:any ;
  showMonkeySurvey: boolean;
  userId:any;
  isPreview:boolean;
  title:any;
  editorData:any;
  constructor(public navigationService : NavigationService , private _googleAnaylticsService: GoogleAnaylticsService) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers About');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  ngOnInit(): void {
    this.addDataToGADataLayer();
  }

  addDataToGADataLayer() {
    this.SubscriberAbout = this._googleAnaylticsService.castTrackingData.subscribe((pageTrackingData:any) => {
      this.pageTrackingData = pageTrackingData
      if(this.pageTrackingData.userId){
        console.log("this.pageTrackingData abbout",this.pageTrackingData);
        this._googleAnaylticsService.sendPageTrackingLog(this.pageTrackingData)
      }
    });
  }

  ngOnDestroy(){
    this.SubscriberAbout.unsubscribe();
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
      let isShowAncmt = localStorage.getItem('show_announcement');
      if (isShowAncmt || (!isShowAncmt && !res.data.isAnnouncementClosed)) {
        this.getActiveAnnouncement();
      }
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          this.navigationService.isSurveyMonkey = true;
          this.showMonkeySurvey = true;
          this.userId = res.data.user_id;
        }
    })
  }

  getActiveAnnouncement(){
    this.navigationService.getActiveAnnouncement().subscribe((res: any) => {
       if(res.status == 200){
        this.title = res.data.title;
        this.editorData = res.data.content;
        this.isPreview = true;
       }
    })
  }
}
