import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublicPagesService } from './public-pages.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { map, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  baseUrl = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;
  baseUrl_auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;

  MobileFilterChangeValue = new BehaviorSubject<any>(null);
  updatedFilterValue = this.MobileFilterChangeValue.asObservable();

  constructor(public toastr: ToastrService,
    private http: HttpClient,
    private publicPagesService: PublicPagesService) { }

    applyFilterChange(filterval: any) {
      this.MobileFilterChangeValue.next(filterval)
    }


  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }
  getHeader() {
    return this.publicPagesService.getHeader();
  }
  getDocuments(filters: any, filterPayload?:any): Observable<any> {
    let body = { vesselCode: filters.vessArra, loadPortNumber: filters.portArr, dischargePortNumber: filters.dportArr, pageNo: filters.pageNo, id_fleet: filters.bookingIdArr, dateRange: filters.dateRange, id_fleetSortingFlag: filters.sortBookindId, etaSortingFlag: filters.sortEta, chartererFilter: filterPayload.chartererFilter, brokerFilter: filterPayload.brokerFilter, tradeLOB: filterPayload.tradeLOB, tradelanes: filterPayload.tradelanes,charterer: filterPayload.charterer, broker: filterPayload.broker }
    return this.http.post(`${this.baseUrl}/api/getDocumentationBookingList`, body).pipe(map(data => data), retry(2));
  }
  getDocumentationSavedFilter(): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/getDocumentationSavedFilter`, {}).pipe(map(data => data), retry(2));
  }
  getDocumentsFilter(payload: any): Observable<any> {

    return this.http.post(`${this.baseUrl}/api/getDocumentationfilter`, payload).pipe(map(data => data), retry(2));
  }
  markAsReadBookings(fleetIdArray: any, isRead: any, isSelected:boolean): Observable<any> {
    return this.http.post(`${this.baseUrl_auth}/api/readBookingsByUser`, { 'readBookingIds': fleetIdArray, 'isRead': isRead, isDoc: true,isSelected:isSelected }).pipe(map(data => data), retry(2));
  }

  saveBookingVarient(tradelanes: boolean, charterer: boolean, broker: boolean): Observable<any> {
    let body = `tradelanes=${tradelanes}&charterer=${charterer}&broker=${broker}`;
    return this.http.post<any>(`${this.baseUrl}/api/addDocumentVariance`, body, this.getHeader()).pipe(map(data => data));
  }

}
