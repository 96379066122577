import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  @Input() sidenavTemplateRef: any;
  @Output() emitSibeBar: EventEmitter<boolean> = new EventEmitter();
  @Input() isSideBarActive: boolean = false;
  isPrivateMode = false;
  toggleBar : boolean= false
  showMobileNav: Observable<boolean>;
  isCollaps:boolean = false
  constructor(private navService: NavigationService, private router: Router) {
    this.showMobileNav = this.navService.isMobileNavOpen();
    this.navService.isNavigationCollapseGet().subscribe({
      next: res => {
        this.isCollaps = res
      }, error: err => {
        this.isCollaps = false
      }
    })
  }


  checkPrivateMode = async () => {
    const browserName = this.getBrowserName();
    if ("storage" in navigator && "estimate" in navigator.storage) {
      const { quota } = await navigator.storage.estimate();
      if (quota && quota < 120000000) {
        if (browserName === 'Safari') {
          this.isPrivateMode = true;
        }
      }
    }
  }

  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    if(agent.indexOf('edge') > -1){
      return 'Microsoft Edge';
    } else if(agent.indexOf('edg') > -1){
      return 'Chromium-based Edge';
    }else if(agent.indexOf('opr') > -1){
      return 'Opera';
    }else if(agent.indexOf('chrome') > -1){
      return 'Chrome';
    }else if(agent.indexOf('trident') > -1){
      return 'Internet Explorer';
    }else if(agent.indexOf('firefox') > -1){
      return 'Firefox';
    }else if(agent.indexOf('safari') > -1){
      return 'Safari';
    } else {
      return 'other';
    }

  }

  toggleSideBar() {
    this.toggleBar = !this.toggleBar
    this.navService.getTableHeightOnSideBar(this.toggleBar)
    this.emitSibeBar.emit(!this.isSideBarActive);
  }
  showMobileSidebar() {
    this.navService.setMobileNav(true);
  }
  hideMobileSidebar() {
    this.navService.setMobileNav(false);
  }
}
