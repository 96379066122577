
<!-- Header Section: This section represents the header of the webpage -->
<header>
    <a ng-reflect-router-link="/" href="/">
        <svg-icon name="brand-logo" class="logoDefoult"></svg-icon>
    </a>
</header>


<!-- Common Content Section -->
<section class="Wrapper404Main">
    <div class="Wrapper404 w-100 text-center">
        <h1 class="d-flex justify-content-center align-items-center fw-700 m-0">
            <svg-icon class="d-flex" name="WarningCircle"> </svg-icon>
            There seems to be a problem with this page
        </h1>
        <p class="font-16 fw-400">Our developers have been informed and error info will be collected</p>

        <div class="banner-404-hld LargeDeviceshide justify-content-center">
            <img src="assets/img/public/banner-404-m.jpg" alt="404 image">
        </div>
        <div class="ButtonHolder404 d-flex justify-content-center align-items-center flex-column">
            <button class="StoltBtnIconLarge position-relative overflow-hidden" [routerLink]="'/booking'" routerLinkActive="active">
                Go to my bookings 
                <svg-icon name="right-arrow"> </svg-icon> 
            </button>
            <a href="javascript:void(0);" class="d-flex font-16 fw-500 text-decoration-underline">
                You can also provide additional feedback (optional)
            </a>
        </div>

        <!-- Banner 404 -->
        <div class="banner-404-hld M_CommonHidden">
            <img class="w-100" src="assets/img/public/banner-404.jpg" alt="404 banner">
        </div> 
    </div>
</section>