import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PublicPagesService } from './public-pages.service';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class SurveysResponsesService {
  baseUrl_Auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;

  private _CountSourceAlert = new BehaviorSubject<any>(0);
  currentAlertCount = this._CountSourceAlert.asObservable();

  MobileFilterChangeValue = new BehaviorSubject<any>(null);
  updatedFilterValue = this.MobileFilterChangeValue.asObservable();

  assignedStatus:any;
  apiStatus:any;
  notes:string = '';
  constructor(private http: HttpClient, private publicPagesService: PublicPagesService) { }

  applyFilterChange(filterval: any) {
    this.MobileFilterChangeValue.next(filterval)
  }

  changeAlertCount(count: any) {
    this._CountSourceAlert.next(count)
  }

  getHeader() {
    return this.publicPagesService.getHeader();
  }

  getHeaderTypeJson() {
    return { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  }

  getSavedFilterForSM(type: string): Observable<any> {
    let local = new Date();
    let formattedDate = moment(local).format('YYYY-MM-DD');
    return this.http.get<any>(`${this.baseUrl_Auth}/api/getSavedFilterForSM?type=${type}&local=${formattedDate}`, this.getHeader()).pipe(map(data => data), retry(1));
  }

  getSurveyResponseList(filterPayload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl_Auth}/api/getSurveyResponseList`, filterPayload).pipe(map(data => data), retry(2));
  }

  getSurveyResponseFitlers(filterPayload?: any): Observable<any> {
    let local = new Date();
    let formattedDate = moment(local).format('YYYY-MM-DD');
    filterPayload.local = formattedDate;
    return this.http.post(`${this.baseUrl_Auth}/api/getSurveyResponseFitlers`, filterPayload).pipe(map(data => data), retry(2));
  }

  updateStatusForVC(filterPayload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl_Auth}/api/updateStatusForVC`, filterPayload).pipe(map(data => data), retry(2));
  }

}

