import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContactData } from '../models/publicPages';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PublicPagesService {

  baseUrl_master = environment.API_BASE_URL + environment.MASTER_SERVICE_BASE_NAME;
  baseUrl_booking = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;
  baseUrl_vessel = environment.API_BASE_URL + environment.VESSELTRACKING_SERVICE_BASE_NAME;
  baseUrl_auth = environment.API_BASE_URL+environment.AUTH_SERVICE_BASE_NAME;
  constructor(private http: HttpClient) { }

  getHeader() {
    return { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
  }
  getHeaderTypeJson() {
    return { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  }
  getContactList(): Observable<any> {
    return this.http.get<ContactData>(`${this.baseUrl_master}/api/getContacts`, this.getHeader()).pipe(map(data => data));
  }
  trackId(id: number) {
    let body = `trackId=${id}`;
    return this.http.post<any>(`${this.baseUrl_booking}/api/trackBookings`, body, this.getHeader()).pipe(map(data => data));
  }
  getAllFleets(datas: any) {
    let body = {};
    if (datas && datas.range1 || datas.range2) {
      body = { range1: datas.range1, range2: datas.range2 };
    }
    else if (datas && datas.imo) {
      body = { imo: datas.imo }
    }
    return this.http.post<any>(`${this.baseUrl_vessel}/api/getAllFleets`, body, this.getHeaderTypeJson()).pipe(map(data => data));
  }
  getQ88Data(imo_no: any): Observable<any> {
    return this.http.get(`${this.baseUrl_vessel}/api/getQ88Response?VesselName=${imo_no}`, this.getHeader()).pipe(map(data => data));
  }
  getHomeFleetInfo(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl_vessel}/api/getHomeFleets`, {}, this.getHeaderTypeJson()).pipe(map(data => data));
  }


  getEnquiryDropDownObj() {
    let body = {};
    return this.http.post<any>(`${this.baseUrl_master}/api/getEnquiryDropDownObj`, body, this.getHeader()).pipe(map(data => data));
  }

  getFrquentlyPortsCargoes() {
    let body = {};
    return this.http.post<any>(`${this.baseUrl_master}/api/getFrquentlyPortsCargoes`, body, this.getHeader()).pipe(map(data => data));
  }

  getFrquentlyPortsCargoesSearch() {
    let body = {};
    return this.http.post<any>(`${this.baseUrl_master}/api/getUniqueCargoesAndPorts`, body, this.getHeader()).pipe(map(data => data));
  }

  getGlobalFleets():Observable<any>{
    let body = {};
    return this.http.post<any>(`${this.baseUrl_vessel}/api/getsnitsLists`, body).pipe(map(data => data));
  }

  saveEnquiryForm(datas:any) {
    let body = datas;
    return this.http.post<any>(`${this.baseUrl_auth}/api/postEnquiryForm`, body).pipe(map(data => data));
  }

  getCountryCode():Observable<any>{
    let body = {};
    return this.http.get<any>(`${this.baseUrl_master}/api/getCountryData`, this.getHeader()).pipe(map(data => data));
  }

  updateNotifications(token:any):Observable<any>{
    let body = {};
    return this.http.get<any>(`${this.baseUrl_auth}/api/updateNotificationSettings?notificationLevelUserId=`+token, this.getHeader()).pipe(map(data => data));
  }

  downloadDocument(token:any):Observable<any>{
    let body = {token:token};
    return this.http.post<any>(`${this.baseUrl_booking}/api/downloadShareDocument`, body).pipe(map(data => data));
  }

  getShareEtbCustomer(token: any): Observable<any> {
    return this.http.get(`${this.baseUrl_booking}/api/getShareEtbCustomer?token=${token}`, this.getHeader()).pipe(map(data => data));
  }

}
