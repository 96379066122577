import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tbd',
  templateUrl: './tbd.component.html',
  styleUrls: ['./tbd.component.scss']
})
export class TbdComponent {
  @Output() responsiveTbdTooltip =new EventEmitter();

  mobileAddTooltip(event:any)
  {
    this.responsiveTbdTooltip.emit(event);
  }
}
