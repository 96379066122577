import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref.service';
import {BehaviorSubject} from 'rxjs';

declare let ga:any; 
declare let gtag:any; 
@Injectable({
  providedIn: 'root'
})

export class GoogleAnaylticsService {
    pageTrackingData:any = {} 
    private pageTrackingDataObs = new BehaviorSubject<string>(this.pageTrackingData);
    castTrackingData = this.pageTrackingDataObs.asObservable();
  constructor(private _windowRef: WindowRef) { }

   
   public sendPageTrackingLog(arData: any) {
    let arPageTracing = {
        eventCategory: arData.eventCategory || '',
        userLogin: arData.user_login || '',
        Domain: arData.user_charterer || '',
        userEmail: arData.user_email || '',
        userName:  '',
        userId: arData.user_id || '',
        eventAction:arData.eventAction || '',
        eventLabel:arData.eventLabel || '',
        eventValue:arData.eventValue || '',
        userType:arData.userType || ''
    };
    this.pageTrackingData = arPageTracing;
    this.addGoogleTagsLog(arPageTracing);
    this.pageTrackingDataObs.next(this.pageTrackingData); 
}

public addGoogleTagsLog(arData: any) {
    if (arData.eventCategory) {
        let arDataLayer = arData;
        arDataLayer.event = arData.eventCategory;
        this._windowRef.nativeWindow.dataLayer.push(arDataLayer);
    }
}
}





