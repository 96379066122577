import { Component , OnInit} from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
import { GoogleAnaylticsService } from 'src/app/services/google-anayltics.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  pageTrackingData:any = {}
  SubscriberAbout:any ;
 
  constructor(public navigationService : NavigationService, private _googleAnaylticsService: GoogleAnaylticsService) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers About');
  }

  ngOnInit(): void {
    this.addDataToGADataLayer();
  }

  addDataToGADataLayer() {
    this.SubscriberAbout = this._googleAnaylticsService.castTrackingData.subscribe((pageTrackingData:any) => {
      this.pageTrackingData = pageTrackingData
      if(this.pageTrackingData.userId){
        console.log("this.pageTrackingData abbout",this.pageTrackingData);
        this._googleAnaylticsService.sendPageTrackingLog(this.pageTrackingData)
      }
    });
  }

  ngOnDestroy(){
    this.SubscriberAbout.unsubscribe();
  }

}
