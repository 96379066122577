
<!-- This section represents the Mobile Menu Header -->
<div class="align-items-center justify-content-between position-relative" [ngClass]="{ 'MobileHeader' : true,'LargeDeviceshide':true,'active_overlay': (showMobileNav|async)}">
    <div (click)="showMobileSidebar()">
        <svg-icon name="menu-opener"></svg-icon>
    </div>

    <div class="logo">
        <a [routerLink]="['/']">
           <svg-icon class="logoDefoult" name="brand-logo"></svg-icon> 
        </a>                      
    </div> 
    
    <div class="LargeDeviceshide" [routerLink]="['/search']">
        <svg-icon name="search-icon"></svg-icon>
    </div>   
</div> 
 

 <!-- This section represents the Desktop Menu Header -->
 <div class="side-nav-bar d-inline-flex flex-column justify-content-between align-items-start vh-100 position-fixed stolt__scrollbar thincrollbar" [ngClass]="{ 'StoltSideBarMini' : isSideBarActive,'side-nav-bar':true,'OpenedMobileBar': (showMobileNav|async),'SidebarChanger':isCollaps}">


    <!-- This section represents the Desktop Menu items -->
    <div class="w-100" [ngClass]="{'side-nav-bar-content-container':true}">
        <ng-container *ngTemplateOutlet="sidenavTemplateRef" ></ng-container>
    </div>  
</div>


<!-- Mobile Sidebar On Off cross button -->
<span class="close-nav-cross LargeDeviceshide position-fixed" *ngIf="(showMobileNav|async)" (click)="hideMobileSidebar()">
    <svg-icon name="close-nav-cross"></svg-icon>                                
</span> 


<!-- Safari Private Mode does not Support Modal -->
<div class="StickyShareBox ModelSafariBrwer" *ngIf="isPrivateMode">
    <div class="CustomModelContent">
        <span class="btn-close"></span>
        <div class="StickyShareBoxInner"> 
            <div class="BodySafariModel">
                <div class="BodySafariModelInner">
                    <span class="error-icon" (click)="isPrivateMode = false"><img src="assets/img/error-icon.png" alt="img"></span>
                    <h1 class="heading01">Safari Private Mode doesn't Support!</h1>
                    <p class="CommonPara">Safari Private Mode doesn't Support. When it becomes available, we will notify you via email. Thanks for your patience as we continue to expand our footprint and improve your user experience.</p>
                </div> 
            </div>  
        </div>
    </div>
</div>