<!-- Page Content Holder -->

<app-about-header class="d-none"></app-about-header>
<div id="content" class="StoltBGDefault" *ngIf="trackPageData" [ngClass]="{'overFlowHidden-0':overFlowClass}" #filterClass (click)="removeFilter($event)">
    <div class="StoltCommonContentArea trackCommonContentArea">
        <div class="booking-heading">
            <div class="booking-heading-left"> 
                <snl-page-title [list]="bookingDetail">
                    <div title-content class="header-heading__cstm"> 
                        <snl-icon 
                            [icon]="'fa-solid fa-chevron-left'">
                        </snl-icon>
                        <span class="header-h1" *ngIf="trackPageData && trackPageData.idBooking">{{trackPageData?.idBooking}}-
                            {{arVesselTrack && arVesselTrack.length > 0 ?
                            (arVesselTrack[0].vesselName|titlecase): ''}} 
                        </span>
                    </div>
                    <div last-update-content class="Lastupdt">
                        <span class="body-b1">Last update:</span>
                        <span class="section-title" *ngIf="trackPageData && trackPageData.dtLastUpdatedOn">  {{trackPageData.dtLastUpdatedOn|timeAgo}} </span>
                        <span class="disclaimer-msg">*Information displayed is subject to changes.</span>
                    </div>
                </snl-page-title>
            </div>


            <ng-container>
                <div class="DataContain">
                    <div class="DataItemsIn">
                        <div class="d-flex DataItmOpt">
                            <svg-icon class="d-flex" src="assets/svg/bar-chart.svg"></svg-icon>
                            <div class="d-flex flex-column font-16 TitleMn">
                                <span class="d-flex TitleIn" [ngClass]="{'arrowMobile':trackPageData.bookingStatus != 'Completed'}">Booking status:</span>
                                <span class="fw-700">{{trackPageData?.bookingStatus}}</span>
                            </div>

                            <div class="d-flex flex-column font-16 TitleMn CompletedLaycn" *ngIf="trackPageData.bookingStatus == 'Completed'">
                                <span class="ToolTipCustomHover LaycanLableHld">
                                    <svg-icon class="d-flex" src="assets/svg/calendar-new.svg"></svg-icon> 
                                    Laycan date:  
                                </span>
                                <div class="BlankBG BlankBGstatic"></div>
                            </div>
                            
                        </div>
                        <ng-container *ngIf="trackPageData && trackPageData.bookingStatus !='Completed'">
                            <div class="Columnwrp" *ngFor="let v of arVesselTrack">
                                <!-- Vessel section -->
                                <div class="d-flex DataItmOpt VesselColumn">
                                    <span class="IconSvg">
                                        <svg-icon class="d-flex" src="assets/svg/ship-icon.svg"></svg-icon>
                                    </span>
                                    <div class="d-flex flex-column font-16 TitleMn">
                                        <span class="TitleIn">{{arVesselTrack && arVesselTrack.length > 1 ? 'Vessels:' : 'Vessel:'}}</span>
                                        <div class="VesseldataCol">
                                            <div class="Vesseldatain">
                                                <div class="d-flex head">
                                                    <span class="fw-700">{{v.vesselName|titlecase}}</span>
                                                    <span class="font-14" *ngIf="trackPageData?.bookingStatus == 'Upcoming'">En-route to Load</span>
                                                <span class="font-14" *ngIf="(trackPageData?.bookingStatus == 'Ongoing' && v?.filterPort)">
                                                    <span *ngIf="v.filterPort.isPortCompleted == true && v.filterPort.isDepartured == false && v.filterPort.isComLoad == true && v.filterPort.isComDischarge == false">In port-load</span>
                                                    <span *ngIf="v.filterPort.isPortCompleted == true && v.filterPort.isDepartured == false && v.filterPort.isComLoad == true && v.filterPort.isComDischarge == true">In port-load/discharge</span>
                                                    <span *ngIf="v.filterPort.isPortCompleted == true && v.filterPort.isDepartured == false && v.filterPort.isComLoad == false && v.filterPort.isComDischarge == true">In port-discharge</span>
                                                    <span *ngIf="v.filterPort.isPortCompleted == false && v.filterPort.isDepartured == false && v.filterPort.isComLoad == false && v.filterPort.isComDischarge == true">En-route to discharge</span>
                                                    <span *ngIf="v.filterPort.isPortCompleted == false && v.filterPort.isDepartured == false && v.filterPort.isComLoad == true && v.filterPort.isComDischarge == false">En-route to load</span>
                                                    <span *ngIf="v.filterPort.isPortCompleted == true && v.filterPort.isDepartured == true">Completed</span>
                                                </span> 
                                                </div>
                                                <div class="d-flex flex-column delayed-on-time font-16">
                                                    <div class="d-flex w-100">
                                                        <span class="d-flex col_gap_8"> 
                                                            {{v.totalDelayInDays|delayMessage}} vs vessel nom
                                                            <ng-container *ngIf="v.totalDelayInDays">  
                                                                <ng-container *ngFor="let item of delayObj">
                                                                    <snl-pills *ngIf="item.min <= v.totalDelayInDays && item.max > v.totalDelayInDays"   [type]="'outline'" [layout]="item.cls">{{(v.totalDelayInDays>0 && v.totalDelayInDays<1) ?(v.totalDelayInDays > 0.5 ? '1 day':(v.totalDelayInDays*24|number:'1.0-0'|hours)):((v.totalDelayInDays < 0 && v.totalDelayInDays > -1)? (v.totalDelayInDays < -0.5) ?'-1 day':(v.totalDelayInDays*24|number:'1.0-0'|hours):v.totalDelayInDays|number:'1.0-0'|days)}}</snl-pills>
                                                                </ng-container>  
                                                            </ng-container>
                                                        </span>
                                                    </div>
                                                    <div class="d-flex w-100">
                                                        <span class="d-flex col_gap_8 flex-wrap"> 
                                                            {{(((v.totalDelayInDays|delayMessage) !== 'On time') &&
                                                    ((v.delayDaysInLast24Hrs|delayMessage) == 'On time'))? 'No Change'
                                                    :v.delayDaysInLast24Hrs|delayMessage}} vs last update within 24 hrs
                                                            <ng-container *ngIf="v.delayDaysInLast24Hrs">  
                                                                <ng-container *ngFor="let item of delayObj">
                                                                    <snl-pills *ngIf="item.min <= v.delayDaysInLast24Hrs && item.max > v.delayDaysInLast24Hrs"   [type]="'outline'" [layout]="item.cls">{{(v.delayDaysInLast24Hrs>0 && v.delayDaysInLast24Hrs<1) ?(v.delayDaysInLast24Hrs > 0.5 ? '1 day':(v.delayDaysInLast24Hrs*24|number:'1.0-0'|hours)):((v.delayDaysInLast24Hrs < 0 && v.delayDaysInLast24Hrs > -1)? (v.delayDaysInLast24Hrs < -0.5) ?'-1 day':(v.delayDaysInLast24Hrs*24|number:'1.0-0'|hours):v.delayDaysInLast24Hrs|number:'1.0-0'|days)}}</snl-pills>
                                                                </ng-container>  
                                                            </ng-container>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                
                            
                                <ng-container> 
                                    <!-- Laycan date -->
                                    <div class="d-flex DataItmOpt LaycanDateColumn">
                                        <div class="BookingLaycanCol">
                                            <span class="d-flex ToolTipCustomHover LaycanLableHld">
                                                <span class="IconSvg">
                                                    <svg-icon class="d-flex" src="assets/svg/calendar-new.svg"></svg-icon>
                                                </span>
                                                <div class="d-flex flex-column font-16 TitleMn">
                                                    <span class="TitleIn">Laycan date:</span>
                                                    <div class="position-relative">
                                                        <div class="BlankBG"></div>
                                                    </div>
                                                </div>
                                            </span> 
                                        </div>
                                    </div>

                                    <!-- Cargo laycan date --> 
                                    <div class="d-flex DataItmOpt CargoLaycanColumn">
                                        <span class="IconSvg">
                                                <svg-icon class="d-flex" src="assets/svg/calendar-new.svg"></svg-icon>
                                        </span>
                                        <div class="d-flex flex-column font-16 TitleMn ToolTipCustomHover MobileBorder">
                                            <span class="TitleIn">Cargo laycan:</span>
                                            <div class="position-relative">
                                                <div class="BlankBG"></div>
                                            </div>
                                        </div>
                                    </div>  
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>   
        </div>

        <div class="tab-slider-holder">
            <div class="tab-slider--nav">
                <ul class="tab-slider--tabs">
                    <snl-page-tab [isSelected]="true">
                        <span>Vessel</span> 
                        <span> 
                            ({{(arVesselTrack && arVesselTrack.length > 0 ) ? arVesselTrack.length:0}})
                        </span>
                      </snl-page-tab>
                </ul>
            </div>
            <div class="tab-slider--container IpadProScroller">
                <div id="tab2"
                    class="tab-slider--body IpadProScrollerIneer table-data CommonTableContainer padding-bottom-0 VesselTabHld">
                    <div class="CustomAcordion accordion" id="vesselAccordian">
                        <div class="BorderBtmClass VesselBorderBtmClass" *ngFor="let it of arVesselTrack;index as i">
                            <div class="accordion-item">
                                <div class="vessel-sec cargo-sec accordion-header StickyArea tranship"
                                    id="panelsStayOpen-headingOne">
                                    <button class="accordion-button" [class.collapsed]="i!=0" type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#panelsStayOpen-collapseOne'+i" aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne">
                                        <h4 class="tabelheadingtop ps-0 d-flex col_gap_8">
                                            <span class="brand-lg">
                                                <svg-icon name="brand-logo-mini" class="d-flex"></svg-icon>
                                            </span>
                                            <span class="vesselNameLimitPhone">
                                               {{it.vesselName|titlecase}}
                                            </span> 
                                        </h4>
                                        <span *ngIf="it?.isTransShipmentVessel"
                                            class="TransshipmentHd" (click)="mobileModelAddSource($event,'tranship')"> 
                                            <snl-tooltip class="width-fc df-c fw-700" [position]="'bottom'">
                                                <svg-icon src="assets/svg/ship-icon.svg"></svg-icon>
                                                <span class="body-b1 TranshipmentSpan">Transshipment</span>
                                                <div class="snl-tooltip-custmzd" tooltipContent>  
                                                    <ul>
                                                        <li>
                                                            <span>This is a transshipment vessel</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </snl-tooltip>
                                        </span>
                                    </button> 
                                    <div class="CurrentPositionMn" *ngIf="trackPageData.bookingStatus != 'Completed'">
                                        <span class="CurrentPositionHd">
                                            <em>Current position </em>
                                            <span class="latlongMain">
                                                <span class="m-0">
                                                    <ng-container *ngFor="let lo of it.arVesselsLocation;let last = last">
                                                        <span *ngIf="last" class="mt-0">{{latlongtoRadian(lo.latitude,lo.longitude)}}</span>
                                                    </ng-container>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="CollapseArea accordion-collapse collapse" [class.show]="!i"
                                    [attr.id]="'panelsStayOpen-collapseOne'+i" data-parent="#vesselAccordian"
                                    aria-labelledby="panelsStayOpen-headingOne"> 
                                    <div class="VesselTableViewHld">
                                        <div class="CustmTableStructure">
                                            <div class="CustmTableHead">
                                                <ul>
                                                    <li class="VoyageNum VoyageNumberVsl">Voyage No.</li>
                                                    <li>Port</li>
                                                    <li>Port arrival & departure</li> 
                                                    <li>Cargoes</li>
                                                    <li><span class="d-flex justify-content-end me-5">Qty</span></li>
                                                </ul>
                                            </div>

                                            <div class="CustmTableBody MobileTrackingLines">

                                                <ul *ngFor="let b of it?.arPorts; let last = last;index as i">
                                                    <li class="border-muted VoyageNumberVsl">
                                                        <span class="fw-700 font-14 VoyageNumb">{{b.idVoyageNumber}}</span>
                                                    </li>
                                                    <li [ngClass]="{'straightLine VesselTrcSpace':true,
                                            'heght-straightLine':delay(bookingVariance ==='All' ?b.totalDelayInDays:b.delayDaysInLast24Hrs),
                                            'BorderActive':b.isPortAdded && !b.isPreviousPort,
                                            'dashed-line':!b.isPreviousPort,
                                            'solid-line': b.isPortCompleted && !b.isPreviousPort && !b.isCurrentPort|| (!b.isPreviousPort && trackPageData.bookingStatus =='Completed'),
                                            'CircleActive':b.isPortCompleted&& !b.isPortAdded && !b.isPreviousPort,
                                            'map_pin map_pinSpcae' : last}">

                                                        <div class="SmallDevicesBx">
                                                            <span
                                                                [ngClass]="{
                                                    'portArrow':b.isCurrentPort && !b.isDepartured && trackPageData.bookingStatus !=='Completed'  && !last,
                                                    'reachedArrow':b.isCurrentPort &&  b.isDepartured && trackPageData.bookingStatus !=='Completed' && !last}"></span>
                                                            <span class="PortCalls">
                                                                <span class="loadPortNameHld">
                                                                    {{b.portName|titlecase|removeCountry}} 
                                                                </span>
                                                                <em>{{b.country|titlecase}}</em> 
                                                            </span>

                                                            <!-- Mobile View Start Here -->
                                                            <div class="SmallViewInfo">
                                                                <div class="TopCnt">
                                                                    <p> 
                                                                        <span>
                                                                            <p>Port arrival & departure:</p>
                                                                            <span class="mb-1">
                                                                                {{b.dtPlannedETA|date:'d
                                                                                MMM
                                                                                y, a':'UTC'
                                                                                }}
                                                                                {{b.isPortCompleted
                                                                                ||b.isCurrentPort ?''
                                                                                :b.dtPlannedETA?'(Est.)':''}} 
                                                                            </span>
                                                                            <span>
                                                                                {{b.dtPlannedETD|date:'d
                                                                                MMM
                                                                                y, a':'UTC'
                                                                                }}
                                                                                {{b.isPortCompleted
                                                                                ||b.isCurrentPort ?''
                                                                                :b.dtPlannedETD?'(Est.)':''}}
                                                                            </span> 
                                                                        </span>   
                                                                    </p>      
                                                                    <p class="PortTimeSMoblie">
                                                                        <span class="ArrivalTxt">{{b.etaDelay|delayMessage}}</span>
                                                                        <span class="PillsHolder LargeDeviceshide m-0"> 
                                                                            <ng-container *ngIf="b.etaDelay">
                                                                                <ng-container *ngFor="let item of delayObj">
                                                                                        <snl-pills *ngIf="item.min <= b.etaDelay && item.max > b.etaDelay"   [type]="'outline'" [layout]="item.cls">{{(b.etaDelay>0 && b.etaDelay<1) ?(b.etaDelay> 0.5 ? '1
                                                                                            day':(b.etaDelay*24|number:'1.0-0'|hours)):((b.etaDelay < 0 && b.etaDelay> -1)? (b.etaDelay < -0.5) ?'-1
                                                                                                    day':(b.etaDelay*24|number:'1.0-0'|hours):b.etaDelay|number:'1.0-0'|days)}}</snl-pills>
                                                                                </ng-container>
                                                                            </ng-container> 
                                                                        </span>
                                                                    </p> 
                                                                </div>
                                                                <div class="TopCnt BtmCnt mt-2">
                                                                    <div class="CargosBerth">
                                                                        <div class="CargosInr">
                                                                            <span class="ArrivalTxt">Cargoes:</span>
                                                                            <div class="CargosInrData"> 
                                                                                <span class="BlankBG BlankBGstatic"></span>
                                                                                <span class="BlankBG BlankBGstatic"></span>
                                                                                <span class="BlankBG BlankBGstatic"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="TopCnt BtmCnt mt-2">
                                                                    <div class="CargosBerth">
                                                                        <div class="CargosInr">
                                                                            <span class="ArrivalTxt">Qty:</span>
                                                                            <div class="CargosInrData"> 
                                                                                <span class="BlankBG BlankBGstatic"></span> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- Mobile View End Here --> 
                                                        </div>

                                                    </li>

                                                    <li class="SmallDeviceshide"
                                                        [ngClass]="{TextSpaceBtm:delay(bookingVariance === 'All'?b.totalDelayInDaysETA:b.delayDaysInLast24HrsETA)}">
                                                       
                                                        <span class="SpaceUtili flex-row Smallfont">
                                                            {{b.dtPlannedETA|date:'d
                                                            MMM y, a':'UTC'
                                                            }}
                                                            {{b.isPortCompleted ||b.isCurrentPort ?''
                                                            :b.dtPlannedETA?'(Est.)':''}}
                                                        </span>
                                                        <span class="SpaceUtili">{{b.dtPlannedETD|date:'d
                                                            MMM
                                                            y, a':'UTC'
                                                            }} {{b.isPortCompleted ||b.isCurrentPort ?''
                                                            :b.dtPlannedETD?'(Est.)':''}}
                                                        </span>
                                                        <div class="PortTimeS"> 
                                                            <span class="ArrivalTxt font-16">{{b.etaDelay|delayMessage}}</span> 
                                                            <ng-container *ngIf="b.etaDelay">
                                                                <ng-container *ngFor="let item of delayObj">
                                                                    <snl-pills *ngIf="item.min <= b.etaDelay && item.max > b.etaDelay"  [type]="'outline'" [layout]="item.cls">{{(b.etaDelay>0 && b.etaDelay<1) ?(b.etaDelay> 0.5 ? '1
                                                                        day':(b.etaDelay*24|number:'1.0-0'|hours)):((b.etaDelay < 0 && b.etaDelay> -1)? (b.etaDelay < -0.5) ?'-1
                                                                                day':(b.etaDelay*24|number:'1.0-0'|hours):b.etaDelay|number:'1.0-0'|days)}}</snl-pills>
                                                                </ng-container>
                                                            </ng-container> 
                                                        </div> 
                                                    </li> 
                                                    <li class="SmallDeviceshide">
                                                        <div class="CargoesVesselTb"> 
                                                            <span class="BlankBG BlankBGstatic"></span>
                                                            <span class="BlankBG BlankBGstatic"></span>
                                                            <span class="BlankBG BlankBGstatic"></span>
                                                        </div>
                                                    </li>
                                                    <li></li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Track Footer Start Here" -->
    
    <app-footer></app-footer>
    <!-- Track Footer End Here -->
</div>

<div class="FiltersModelCustom LargeDeviceshide" #filterTooltipModelClass >
    <app-mobile-model [modelType]="modelType"
        (mobileModelClassClose)="mobileModelClassClose($event)"></app-mobile-model>
</div>