<snl-modal (modalClose)="closeModel('close')" [settings]="snlModalSettings" [type]="'small'" [open]="isUnsavedPopup" class="CenterModelCmn CommonPhoneCstm DeleteModelSnl">
    <div slot="heading"><strong>Unsaved changes</strong></div>
    <div class="body-b" slot="modalContent">
        Are you sure you want to leave this page? 
        <br>
        Changes you made will not be saved.
   </div>
    <div slot="modalFooter">
        <snl-button size="large" type="primary" (click)="closeModel('leave')">Leave</snl-button>
        <snl-button size="large" [type]="'secondary'" (click)="closeModel('close')">Cancel</snl-button>
    </div>
</snl-modal>