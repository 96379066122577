<div class="StickyShareBox backdrop__Mngr" #filterClass (click)="removeFilter($event)">
    <div class="CustomModelContent ShareBooking__Mdl Notification_properties MobileNotiProMdl" [ngClass]="{'notifi-at-booking-main':shareType =='booking' || shareType =='bookingDetail'}">
        <div class="StickyShareBoxInner stolt__scrollbar">
            <div class="ShareHeadTitle" >
                <p *ngIf="!(shareType == 'booking' || shareType == 'bookingDetail')">
                    Notification properties
                    <span class="btn-close" (click)="discard()"></span>
                </p>  
                <p *ngIf="shareType == 'bookingDetail'">
                    Create new notification
                    <span class="btn-close" (click)="discard()"></span>
                </p>  
                <p *ngIf="shareType == 'booking'">
                    Share booking
                    <span class="btn-close" (click)="discard()"></span>
                </p>
            </div>
            <div class="notifi-at-booking">
                <!-- FOR BOOKING LEVEL NOTIFICATIONS -->
                <div class="ShareCol mobileSharedCol">
                    <div class="HeaderTxt">  
                        <span class="font-16 fw-400">Send an email with a live link to the booking details.</span><br>
                        <span class="font-16 fw-400">Use distribution lists you created by entering hash #.</span>
                    </div>
                    <app-share-notification *ngIf="shareType =='booking' || shareType =='bookingDetail'" #childComponent [shareCompData]='shareCompData' [editRowNotification]="editRowNotification" (shareEmailEmit)="shareEmailEmit($event)" (emitClaseNoti)="discard($event)" (savingProcessStatus)="savingProcessStatus($event)" (checkDupleicateEmail)="checkDupleicateEmail($event)" [shareType]="shareType" (hideShowNoticationStatus)="getHideShowNoticationStatus($event)"></app-share-notification> 
                </div>
                <!-- FOR BOOKING LEVEL NOTIFICATIONS END -->

                <form [ngClass]="{'disabledImmediate':shareType =='bookingDetail' && isSubscribe}" class="stolt__scrollbar NotifactinCol" [formGroup]="notificationForm" *ngIf="showHideNotication">
                    <div class="ShareInputArea content-inner-bdy">
                        <div class="InputWdth position-relative" *ngIf="shareType !='booking' && shareType !='bookingDetail'">
                                <snl-input
                                [label]="'Notification name'"
                                [errorMessage]="(f?.notificationTitle?.touched) && f?.notificationTitle?.errors?.required ? 'name is required' : f?.notificationTitle?.errors?.minlength ? 'List name must be at least 3 characters long.' : f?.notificationTitle?.errors?.maxlength ? 'List name must
                                not exceed 50 characters.' : '' "
                                [isError]="(f?.notificationTitle?.touched && f?.notificationTitle?.errors?.required) || f.notificationTitle.errors?.minlength"
                                formControlName="notificationTitle"
                                [type]="'text'"
                            ></snl-input>
                        </div>
 
                        <div class="optionhldr">
                            <div class="col-lft"> 
                                <div class="opt-main">
                                    <!-- FOR BOOKING LEVEL NOTIFICATIONS -->
                                    <p class="font-24 fw-700 Set_notification" *ngIf="shareType == 'booking'">Set notification</p>
                                    <p class="body-b1 Customise_content" *ngIf="shareType == 'booking'">Customise content and frequency of ETA update notifications</p>
                                    <!-- FOR BOOKING LEVEL NOTIFICATIONS END -->

                                    <p class="m-0 font-20 fw-700 TitleMobileDevice">Event</p>
                                    <ul>
                                       
                                        <li> 
                                            <span class="checkboxHld" >
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="allShipmentETAs" (change)="OncheckMoni($event);onCheckedEvent($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel font-16 fw-400" [ngClass]="{'fw-700':notificationForm.value.allShipmentETAs}">All ETAs</span>
                                                </label>
                                            </span> 
                                        </li>
                                        <li> 
                                            <span class="checkboxHld" [ngClass]="{'disabledImmediate':notificationForm.value.ChangeShipNom || notificationForm.value.allShipmentETAs}">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="etaUpdates" (change)="onCheckedEvent($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel font-16 fw-400" [ngClass]="{'fw-700':notificationForm.value.etaUpdates}">ETA changes only</span>
                                                </label>
                                            </span> 
                                        </li>
                                        <li> 
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="ChangeShipNom" 
                                                    (change)="OncheckMoni($event);onCheckedEvent($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel font-16 fw-400" [ngClass]="{'fw-700':notificationForm.value.ChangeShipNom}">Change of vessel nomination</span>
                                                </label>
                                            </span> 
                                        </li>
                                        
                                        <li> 
                                            <span class="checkboxHld" *ngIf="stoltEmail == '@STOLT' || stoltEmail == '@stolt'">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="ShipArrivalLaycanPeriod" (change)="onCheckedEvent($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel font-16 fw-400" [ngClass]="{'fw-700':notificationForm.value.ShipArrivalLaycanPeriod}">Vessel arrival outside laycan period</span>
                                                </label>
                                            </span> 
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Filters Column-->
                            <div class="col-rgt" *ngIf="shareType !='booking' && shareType !='bookingDetail'">
                                <div class="opt-main">
                                    <p class="m-0 font-20 fw-700 TitleMobileDevice">Filter</p> 
                                    <div class="filtersMain">
                                        <div class="ng-select-parent">
                                            <ng-select [items]="portFilterData" [multiple]="true"
                                                class="TextSlectGroup _Load no-scroll-drpdwn PlaceholderLft OpenDropdownRight" [searchable]="false"
                                                [clearable]="false" bindLabel="loadPortName" [closeOnSelect]="false" bindValue="loadPortNumber"
                                                [(ngModel)]="portFilterValueTemp" (change)="clearInput()" groupBy="port" placeholder="All" [selectableGroup]="true" formControlName="loadPortNumber">
                                                <ng-template ng-multi-label-tmp let-items="items">
                                                    <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('loadPortName')">{{
                                                        items[0]?.port|titlecase}}</span>
                                                    <span
                                                        *ngIf="items.length < 2 && items[0].hasOwnProperty('loadPortName')">{{items[0]?.loadPortName|titlecase}}</span>
                                                    <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                                                </ng-template>
                            
                                                <ng-template ng-header-tmp>
                                                    <input style="width: 100%; line-height: 24px" type="text" placeholder="Search port names" #searchName
                                                        (input)="customSearch('port',$event)" />
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">{{item.loadPortName|titlecase}}</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <div class="SelectAllOpt" [style.display]="PortDisplay">
                                                        <div class="checkboxHld">
                                                            <label class="containerCheckbx tp15">
                                                                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <span class="OptionTxt">All ({{portFilterData.length}})</span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="ng-select-parent">
                                            <ng-select [items]="portDischargeData" [multiple]="true"
                                            class="TextSlectGroup _Discharge OpenDropdownRight no-scroll-drpdwn PlaceholderLft" [searchable]="false"
                                            [clearable]="false" bindLabel="dischargePortName" [closeOnSelect]="false"
                                            bindValue="dischargePortNumber" [(ngModel)]="dportFilterValueTemp" [selectableGroup]="true"
                                            placeholder="All" groupBy="discharge" formControlName="dischargePortNumber" (change)="clearInput()">
                                            <ng-template ng-multi-label-tmp let-items="items">
                        
                                                <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('dischargePortName')">{{
                                                    items[0]?.discharge|titlecase}}</span>
                                                <span
                                                    *ngIf="items.length < 2 && items[0].hasOwnProperty('dischargePortName')">{{items[0]?.dischargePortName|titlecase}}</span>
                                                <span *ngIf="items.length >= 2">{{items.length}}</span>
                        
                                            </ng-template>
                                            <ng-template ng-header-tmp>
                                                <input type="text" placeholder="Search discharge port" #searchName
                                                    (input)="customSearch('dport',$event)" />
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="checkboxHld">
                                                    <label class="containerCheckbx tp15">
                                                        <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="OptionTxt"> {{item.dischargePortName|titlecase}}</span>
                                                </div>
                        
                                            </ng-template>
                                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="SelectAllOpt" [style.display]="dischargeDisplay">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">All
                                                            ({{portDischargeDataMani.length}})</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                        
                                            </ng-select>
                                        </div>
                                        <div class="ng-select-parent">
                                            <ng-select [items]="chartererFilterData" [multiple]="true"
                                            class="TextSlectGroup _Charterer OpenDropdownRight no-scroll-drpdwn PlaceholderLft" [searchable]="false"
                                            [clearable]="false" bindLabel="chartererName" placeholder="All" [closeOnSelect]="false"
                                            bindValue="chartererName" [(ngModel)]="chartererFilterValueTemp" [selectableGroup]="true"
                                            groupBy="charterer" formControlName="chartererFilter" (change)="clearInput()">
                                            <ng-template ng-multi-label-tmp let-items="items">
                        
                                                <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('chartererName')">{{
                                                    items[0]?.charterer|titlecase}}</span>
                                                <span
                                                    *ngIf="items.length < 2 && items[0].hasOwnProperty('chartererName')">{{items[0]?.chartererName|titlecase}}</span>
                                                <span *ngIf="items.length >= 2">{{items.length}}</span>
                        
                                            </ng-template>
                                            <ng-template ng-header-tmp>
                                                <input style="width: 100%; line-height: 24px" type="text" placeholder="Search charterer names" #searchName
                                                    (input)="customSearch('charterer',$event)" />
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="checkboxHld">
                                                    <label class="containerCheckbx tp15">
                                                        <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="OptionTxt"> {{item.chartererName|titlecase}}</span>
                                                </div>
                        
                                            </ng-template>
                                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="SelectAllOpt" [style.display]="chartererDisplay">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">All
                                                            ({{chartererFilterData.length}})</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            </ng-select>  
                                        </div>
                                        <!--Tradelane-->
                                        <div class="ng-select-parent">
                                            <ng-select [items]="tradelaneFilterData" [multiple]="true"
                                                class="TextSlectGroup _Tradelane _Load2 no-scroll-drpdwn PlaceholderLft OpenDropdownRight NoTextTransform" [searchable]="false"
                                                [clearable]="false" bindLabel="tradelaneName" [closeOnSelect]="false" bindValue="tradelaneName"
                                                [(ngModel)]="tradelaneFilterValueTemp" groupBy="tradelane" (change)="clearInput()" placeholder="All" [selectableGroup]="true" formControlName="tradelaneNumber">
                                                <ng-template ng-multi-label-tmp let-items="items">
                                                    <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('tradelaneName')">{{
                                                        items[0]?.tradelane}}</span>
                                                    <span
                                                        *ngIf="items.length < 2 && items[0].hasOwnProperty('tradelaneName')">{{items[0]?.tradelaneName}}</span>
                                                    <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                                                </ng-template>
                            
                                                <ng-template ng-header-tmp>
                                                    <input style="width: 100%; line-height: 24px" type="text" placeholder="Search tradelane names" #searchName
                                                        (input)="customSearch('tradelane',$event)" />
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">{{item.tradelaneName}}</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <div class="SelectAllOpt" [style.display]="tradelaneDisplay">
                                                        <div class="checkboxHld">
                                                            <label class="containerCheckbx tp15">
                                                                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <span class="OptionTxt">All ({{tradelaneFilterData.length}})</span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <!--ETA Variance-->
                                        <div class="ng-select-parent">
                                            <ng-select [items]="etaVarianceFilterData" [multiple]="true"
                                                class="TextSlectGroup _ETAVariance _Load2 no-scroll-drpdwn PlaceholderLft OpenDropdownRight" [searchable]="false"
                                                [clearable]="false" bindLabel="displayname" [closeOnSelect]="false" bindValue="idfilter"
                                                [(ngModel)]="etaFilterValueTemp"  placeholder="All" [selectableGroup]="true" formControlName="arrIdDelayFilter">
                                                <ng-template ng-multi-label-tmp let-items="items">
                                                    <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('displayname')">{{
                                                        items[0]?.displayname}}</span>
                                                    <span
                                                        *ngIf="items.length < 2 && items[0].hasOwnProperty('displayname')">{{items[0]?.displayname}}</span>
                                                    <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <div class="checkboxHld">
                                                        <label class="containerCheckbx tp15">
                                                            <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <span class="OptionTxt">{{item.displayname}}</span>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <!--Load port ETA -->
                                        <div class="ng-select-parent" *ngIf="isShowDateRange">
                                            <div class="form-row">
                                                <div class="form-group date-range-sustainability LoadPortEta SelectedDate" > 
                                                    <form  [formGroup]="dateRageFiltersFormLoadEta" >              
                                                        <mat-form-field appearance="fill"> 
                                                            <span class="FloatingLbl">Load port ETA</span>      
                                                            <mat-label><span class="AllValue">All</span></mat-label>      
                                                            <mat-date-range-input [rangePicker]="picker" [min]="minDateLoadPort" [max]="maxDate">      
                                                            <input matStartDate readonly placeholder="Start date" formControlName="fromDate" (focus)="picker.open()" (click)="picker.open()">      
                                                            <input matEndDate readonly placeholder="End date" formControlName="toDate" (focus)="picker.open()" (click)="picker.open()">      
                                                            </mat-date-range-input>      
                                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>   
                                                            <mat-date-range-picker #picker>
                                                                <mat-date-range-picker-actions>
                                                                    <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeLoadFilter('loadPortEta')">Apply</button>
                                                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('loadPortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                                                </mat-date-range-picker-actions>
                                                            </mat-date-range-picker> 
                                                        </mat-form-field> 
                                                    </form>
                                                </div>
                                           </div>
                                        </div>
                                        <!--Discharge port ETA-->
                                        <div class="ng-select-parent" *ngIf="isShowDateRange">
                                            <div class="form-row">
                                                <div class="form-group date-range-sustainability DischargePortEta SelectedDate" > 
                                                    <form  [formGroup]="dateRageFiltersFormLoadDischarge" >              
                                                        <mat-form-field appearance="fill"> 
                                                            <span class="FloatingLbl">Discharge port ETA </span>      
                                                            <mat-label><span class="AllValue">All</span></mat-label>      
                                                            <mat-date-range-input [rangePicker]="pickerDischarge" [min]="minDate" [max]="maxDate">      
                                                            <input matStartDate readonly placeholder="Start date"  formControlName="fromDate" (focus)="pickerDischarge.open()" (click)="pickerDischarge.open()">      
                                                            <input matEndDate readonly placeholder="End date" (focus)="pickerDischarge.open()" (click)="pickerDischarge.open()" formControlName="toDate">      
                                                            </mat-date-range-input>      
                                                            <mat-datepicker-toggle matSuffix [for]="pickerDischarge"></mat-datepicker-toggle>   
                                                            <mat-date-range-picker #pickerDischarge>
                                                                <mat-date-range-picker-actions>
                                                                    <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeDischargeFilter('dischargePortEta')">Apply</button>
                                                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('dischargePortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                                                </mat-date-range-picker-actions>
                                                            </mat-date-range-picker> 
                                                        </mat-form-field> 
                                                    </form>
                                                </div>
                                           </div>
                                        </div>
                                    </div>

                                    <!-- For Mobile Devices-->
                                    <div class="MobileDeviceOpt"> 
                                        <ul>	
                                            <li (click)="selectFilter('port',$event)" class="d-flex align-items-center justify-content-between"> 	
                                                <span class="d-flex gap_16">Load port <strong class="fw-700 truncate-text">{{loadPortNameMobileSelected.length == 0 ? 'All' : loadPortNameMobileSelected.length == 1 ? (loadPortNameMobileSelected[0]|titlecase) : loadPortNameMobileSelected.length}} </strong></span>  
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                                </svg>   	
                                            </li>	
                                            <li (click)="selectFilter('dport',$event)" class="d-flex align-items-center justify-content-between">  	
                                                <span class="d-flex gap_16">Discharge port <strong class="fw-700 truncate-text">{{dischargeNameMobileSelected.length == 0 ? 'All' : dischargeNameMobileSelected.length == 1 ? (dischargeNameMobileSelected[0]|titlecase) : dischargeNameMobileSelected.length}} </strong></span>  
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                                </svg>   	
                                            </li>
                                            <li (click)="selectFilter('loadPortEta',$event)" class="d-flex align-items-center justify-content-between"> 	
                                                <span class="d-flex gap_16">
                                                    Load port ETA
                                                    <strong class="fw-700 truncate-text mw180" *ngIf="dateRangeLoadEta.fromDate || dateRangeLoadEta.toDate">{{dateRangeLoadEta?.fromDate | date:'dd MMM YYYY'}} - {{dateRangeLoadEta?.toDate | date:'dd MMM YYYY'}}</strong>
                                                    <strong class="fw-700 truncate-text" *ngIf="!dateRangeLoadEta.fromDate && !dateRangeLoadEta.toDate">All</strong>
                                                </span>  
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.75 2H10.25V0.75C10.25 0.34375 10.5625 0 11 0C11.4062 0 11.75 0.34375 11.75 0.75V2H13C14.0938 2 15 2.90625 15 4V14C15 15.125 14.0938 16 13 16H3C1.875 16 1 15.125 1 14V4C1 2.90625 1.875 2 3 2H4.25V0.75C4.25 0.34375 4.5625 0 5 0C5.40625 0 5.75 0.34375 5.75 0.75V2ZM2.5 14C2.5 14.2812 2.71875 14.5 3 14.5H13C13.25 14.5 13.5 14.2812 13.5 14V6H2.5V14Z" fill="#788496"/>
                                                </svg>       
                                            </li>

                                            <li (click)="selectFilter('dischargePortEta',$event)" class="d-flex align-items-center justify-content-between"> 	
                                                <span class="d-flex gap_16">
                                                    Discharge port ETA
                                                    <strong class="fw-700 truncate-text mw180" *ngIf="dateRangeLoadDischarge.fromDate || dateRangeLoadDischarge.toDate">{{dateRangeLoadDischarge?.fromDate | date:'dd MMM YYYY'}} - {{dateRangeLoadDischarge?.toDate | date:'dd MMM YYYY'}}</strong>
                                                    <strong class="fw-700 truncate-text" *ngIf="!dateRangeLoadDischarge.fromDate && !dateRangeLoadDischarge.toDate">All</strong>
                                                </span>  
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.75 2H10.25V0.75C10.25 0.34375 10.5625 0 11 0C11.4062 0 11.75 0.34375 11.75 0.75V2H13C14.0938 2 15 2.90625 15 4V14C15 15.125 14.0938 16 13 16H3C1.875 16 1 15.125 1 14V4C1 2.90625 1.875 2 3 2H4.25V0.75C4.25 0.34375 4.5625 0 5 0C5.40625 0 5.75 0.34375 5.75 0.75V2ZM2.5 14C2.5 14.2812 2.71875 14.5 3 14.5H13C13.25 14.5 13.5 14.2812 13.5 14V6H2.5V14Z" fill="#788496"/>
                                                </svg>                                                          
                                            </li>	
                                            <li (click)="selectFilter('charterer',$event)" class="d-flex align-items-center justify-content-between"> 	
                                                <span class="d-flex gap_16">Charterer <strong class="fw-700 truncate-text">{{charetererNameMobileSelected.length == 0 ? 'All' : charetererNameMobileSelected.length == 1 ? (charetererNameMobileSelected[0]|titlecase) : charetererNameMobileSelected.length}}</strong></span>  
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                                </svg>   	
                                            </li>	
                                            <li (click)="selectFilter('tradelane',$event)" class="d-flex align-items-center justify-content-between"> 	
                                                <span class="d-flex gap_16">Tradelane <strong class="fw-700 truncate-text">{{tradelaneNameMobileSelected.length == 0 ? 'All' : tradelaneNameMobileSelected.length == 1 ? (tradelaneNameMobileSelected[0]) : tradelaneNameMobileSelected.length}}</strong></span> 
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                                </svg>   	
                                            </li>	
                                            <li (click)="selectFilter('etaVariance',$event)" class="d-flex align-items-center justify-content-between"> 	
                                                <span class="d-flex gap_16">ETA Variance <strong class="fw-700 truncate-text">{{delayMobileSelected.length == 0 ? 'All' : delayMobileSelected.length == 1 ? (delayMobileSelected[0]|titlecase) : delayMobileSelected.length}}</strong></span>  
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">	
                                                    <path d="M6.53125 1.5L12.25 7.46875C12.375 7.625 12.4688 7.8125 12.4688 8C12.4688 8.1875 12.375 8.375 12.25 8.5L6.53125 14.4688C6.25 14.7812 5.75 14.7812 5.46875 14.5C5.15625 14.2188 5.15625 13.75 5.4375 13.4375L10.6875 7.96875L5.4375 2.53125C5.15625 2.25 5.15625 1.75 5.46875 1.46875C5.75 1.1875 6.25 1.1875 6.53125 1.5Z" fill="#788496"/>	
                                                </svg>   	
                                            </li>	 
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="SeparatorLine M_CommonHidden"></div>
                        <div class="DaysTimeMainHolder" *ngIf="isFrequencyHideShow">
                            <div class="optionhldr pt-0">
                                <div class="col-lft"> 
                                    <div class="opt-main">
                                        <p class="m-0 fw-700 TitleMobileDevice">Frequency</p>
                                        <ul class="FrequencyOpt"> 
                                            <li [ngClass]="{'disabledImmediate': stoltEmail == '@STOLT' || stoltEmail == '@stolt'}"> 
                                                <span class="checkboxHld RadioBtnCircle">
                                                    <label class="containerCheckbx">
                                                        <input type="radio" formControlName="frequencyType" value="immediate" (click)="frequencyChange('Immediate')">
                                                        <span class="checkmark tp02"></span>
                                                        <span class="RadioLabel font-16 fw-400" [ngClass]="{'fw-700':notificationForm.value.frequencyType}">Immediate</span>
                                                    </label>
                                                </span> 
                                            </li>
                                            <li class="border-0"> 
                                                <span class="checkboxHld RadioBtnCircle">
                                                    <label class="containerCheckbx">
                                                        <input type="radio" formControlName="frequencyType" value="custom" (click)="frequencyChange('custom')">
                                                        <span class="checkmark tp02"></span>
                                                        <span class="RadioLabel font-16 fw-400" [ngClass]="{'fw-700':notificationForm.value.frequencyType}">Custom day & time</span>
                                                    </label>
                                                </span> 
                                            </li>
                                        </ul>
                                        <p class="m-0 fw-700 TitleMobileDevice DayTimeTitle">Day and time</p>
                                    </div>
                                </div> 
                            </div>

                            <!-- ########### Custom Day & Time #################### -->
                            <div class="DaysTimeMain MobileDayTime" *ngIf="notificationForm.value.frequencyType == 'custom'">

                                <div class="DaysCol">
                                    <div class="CheckbxMain">
                                        <div class="CheckOpt head">
                                            <span class="RadioLabel fw-700">Day</span>
                                        </div>

                                        <div class="CheckOpt">
                                            <span class="checkboxHld" [ngClass]="{'all_checkmng':isSelectDay}">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" (change)="SelectAllDays($event)" [checked]="CheckAllBox">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel"> All Days</span>
                                                </label>
                                            </span> 
                                        </div>
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="mon" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.mon}">Mon</span>
                                                </label>
                                            </span> 
                                        </div>
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="tue" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.tue}">Tue</span>
                                                </label>
                                            </span> 
                                        </div> 
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="wed" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.wed}">Wed</span>
                                                </label>
                                            </span> 
                                        </div>
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="thu" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.thu}">Thu</span>
                                                </label>
                                            </span> 
                                        </div>
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="fri" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.fri}">Fri</span>
                                                </label>
                                            </span> 
                                        </div>
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="sat" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sat}">Sat</span>
                                                </label>
                                            </span> 
                                        </div>
                                        <div class="CheckOpt">
                                            <span class="checkboxHld">
                                                <label class="containerCheckbx">
                                                    <input type="checkbox" formControlName="sun" (change)="singleSelectDay($event)">
                                                    <span class="checkmark tp02"></span>
                                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sun}">Sun</span>
                                                </label>
                                            </span> 
                                        </div>
                                    </div>
                                </div>

                                <div class="TimeCol">
                                    <p class="m-0 fw-700 head">Time</p>
                                    <ng-select [items]="timezoneList"
                                        [searchable]="false" bindValue="timezone"
                                        bindLabel="timezone"
                                        formControlName="timezone"
                                        [closeOnSelect]="true"
                                        (change)="clearInput()"
                                        class="TextSlectGroup _Timezone no-scroll-drpdwn PlaceholderLft timeTextLimit" [ngClass]="{ 'is-invalid':(submitted || f.timezone.touched) && f.timezone.errors}">
                                            <ng-template ng-header-tmp>
                                                <input type="text" placeholder="Search timezone" #searchName
                                                    (input)="customSearch('timezone',$event)" />
                                            </ng-template>                                

                                        <ng-template ng-option-tmp let-item="item"
                                            let-item$="item$" let-index="index">
                                            <span [ngClass]="{'SelectedCheck':notificationForm.value.timezone==item.timezone}"> {{item.timezone}}</span>
                                        </ng-template>
                                    </ng-select>
                                    <div class="error" *ngIf="(submitted || f.timezone.touched) && f.timezone.errors">
                                        <div class="CommonErrorMsg" *ngIf="f.timezone.errors.required"> Timezone is
                                            required
                                        </div>
                                    </div>
                                    
                                    <div class="CustomSlectTime" (click)="displayPicker('on')" [ngClass]="{'active':isDisplayPicker}"> 
                                    
                                        <!-- {{hour}}:{{minutes}} {{hour > 12 ? 'PM' : 'AM'}} --> 
                                        <span>
                                            <span class="FloatingLbl">Time</span>
                                            <strong class="fw-700">{{timer.HourMin}} {{timeFormate}}</strong>
                                        </span>
                                        
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75L8.75 7.625L11.4062 9.375C11.75 9.625 11.8437 10.0938 11.5937 10.4375C11.375 10.7813 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z" fill="#788496"/>
                                        </svg>      
                                                    
                                    </div>
                                    
                                    <div class="TimePickerMain" *ngIf="isDisplayPicker"> 
                                        <div class="BodyTimePic">
                                            <timepicker [hourStep]="hstep" [mousewheel]="false"  [minuteStep]="mstep" formControlName="notificationTime"  [meridians]="meridians" [(ngModel)]="notifyTime"></timepicker>
                                            <div class="TimeAmPm">
                                                <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormatPm}" type="button" (click)="onFormateChange(1,'PM')"  type="button">PM</button>
                                                <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormateAm}" (click)="onFormateChange(1,'AM')" type="button"> AM </button>
                                            </div>
                                        </div>
                                        <div class="modal-footer"> 
                                            <button class="StoltBtnIconLarge" type="button" (click)="applyTime()">Apply</button>
                                            <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="displayPicker('off')"> Cancel </button> 
                                        </div>
                                    </div>
                                </div>

                                <!-- For Mobile Devices-->
                                <div class="optionhldr">
                                    <div class="opt-main">
                                        <div class="MobileDeviceOpt MobileDayTimeCstm"> 
                                            <ul>
                                                <li class="d-flex align-items-center justify-content-between position-relative" (click)="mobileDayCall($event)"> 
                                                    <label class="FloatingLbl">Day</label>
                                                    <span class="fw-700">{{daysCount}}</span> 
                                                    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.5 6.5625L12.5312 12.2812C12.3438 12.4375 12.1562 12.5 12 12.5C11.8125 12.5 11.625 12.4375 11.4688 12.3125L5.46875 6.5625C5.15625 6.28125 5.15625 5.78125 5.4375 5.5C5.71875 5.1875 6.21875 5.1875 6.5 5.46875L12 10.7188L17.4688 5.46875C17.75 5.1875 18.25 5.1875 18.5312 5.5C18.8125 5.78125 18.8125 6.28125 18.5 6.5625Z" fill="#788496"/>
                                                    </svg>                                                        
                                                </li>

                                                <li class="d-flex align-items-center justify-content-between border-0 position-relative" (click)="mobileTimezoneAddCall($event)"> 
                                                    <label class="FloatingLbl">Time zone </label> 
                                                    <span class="fw-700 truncate-text Custom-w-90">{{notificationForm.value.timezone}}</span> 
                                                    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.5 6.5625L12.5312 12.2812C12.3438 12.4375 12.1562 12.5 12 12.5C11.8125 12.5 11.625 12.4375 11.4688 12.3125L5.46875 6.5625C5.15625 6.28125 5.15625 5.78125 5.4375 5.5C5.71875 5.1875 6.21875 5.1875 6.5 5.46875L12 10.7188L17.4688 5.46875C17.75 5.1875 18.25 5.1875 18.5312 5.5C18.8125 5.78125 18.8125 6.28125 18.5 6.5625Z" fill="#788496"/>
                                                    </svg> 
                                                </li> 

                                                <li class="d-flex border-0 CustomSlectTimeMbl"> 
                                                    <div class="CustomSlectTimeHolder">
                                                        <div class="CustomSlectTimeIn" (click)="mobileTimeSlotAddCall($event,1)" >
                                                            <div class="CustomSlectTime" [ngClass]="{'inp__error': isNotValidate}">
                                                                <span>
                                                                    <span class="FloatingLbl">Time #1</span>
                                                                    <strong>{{timer.HourMin}} {{timeFormate}}</strong>
                                                                </span>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.25 7.75C11.25 7.34375 11.5625 7 12 7C12.4062 7 12.75 7.34375 12.75 7.75V11.625L15.4062 13.375C15.75 13.625 15.8438 14.0938 15.5938 14.4375C15.375 14.7812 14.9062 14.875 14.5625 14.625L11.5625 12.625C11.375 12.5 11.25 12.25 11.25 12V7.75ZM12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12Z" fill="#788496"/>
                                                                </svg>
                                                            </div>
                                                        </div> 
                                                        <span class="mt-1 error caption d-flex" *ngIf="isNotValidate">
                                                            This selection overlaps with another timeslot selected. Please choose another timeslot.
                                                        </span> 
                                                    </div>    
                                                    
                                                    <div class="CustomSlectTimeHolder" *ngIf="activeSecondSlot">
                                                        <div class="CustomSlectTimeIn">
                                                            <div class="CustomSlectTime" (click)="mobileTimeSlotAddCall($event,2)" [ngClass]="{'inp__error': isNotValidate_1}">
                                                                <span>
                                                                    <span class="FloatingLbl">Time #{{timerSlotLength_1}}</span>
                                                                    <strong>{{timer_second.HourMin}} {{timeFormate_second}}</strong>
                                                                </span>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.25 7.75C11.25 7.34375 11.5625 7 12 7C12.4062 7 12.75 7.34375 12.75 7.75V11.625L15.4062 13.375C15.75 13.625 15.8438 14.0938 15.5938 14.4375C15.375 14.7812 14.9062 14.875 14.5625 14.625L11.5625 12.625C11.375 12.5 11.25 12.25 11.25 12V7.75ZM12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12Z" fill="#788496"/>
                                                                </svg>
                                                            </div>
                                                            <svg width="16" (click)="removeSlot(1)" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_18448_35530)">
                                                                <path d="M13.8516 2.16406C14.2578 2.16406 14.6016 2.50781 14.6016 2.91406C14.6016 3.35156 14.2578 3.66406 13.8516 3.66406H13.4766L12.7266 13.8203C12.6328 14.8828 11.7891 15.6641 10.7266 15.6641H4.44531C3.38281 15.6641 2.53906 14.8828 2.44531 13.8203L1.69531 3.66406H1.35156C0.914062 3.66406 0.601562 3.35156 0.601562 2.91406C0.601562 2.50781 0.914062 2.16406 1.35156 2.16406H3.50781L4.66406 0.445312C4.97656 -0.0234375 5.53906 -0.335938 6.13281 -0.335938H9.03906C9.63281 -0.335938 10.1953 -0.0234375 10.5078 0.445312L11.6641 2.16406H13.8516ZM6.13281 1.16406C6.03906 1.16406 5.94531 1.22656 5.91406 1.28906L5.32031 2.16406H9.85156L9.25781 1.28906C9.22656 1.22656 9.13281 1.16406 9.03906 1.16406H6.13281ZM11.9766 3.66406H3.19531L3.94531 13.7266C3.97656 13.9766 4.19531 14.1641 4.44531 14.1641H10.7266C10.9766 14.1641 11.1953 13.9766 11.2266 13.7266L11.9766 3.66406Z" fill="#008ECF"/>
                                                                </g>
                                                                <defs>
                                                                <clipPath id="clip0_18448_35530">
                                                                <rect width="16" height="16" fill="white"/>
                                                                </clipPath>
                                                                </defs>
                                                            </svg>  
                                                        </div>
                                                        <span class="mt-1 error caption d-flex" *ngIf="isNotValidate_1">
                                                            This selection overlaps with another timeslot selected. Please choose another timeslot.
                                                        </span>
                                                    </div>

                                                    <div class="CustomSlectTimeHolder" *ngIf="activeThirdSlot"> 
                                                        <div class="CustomSlectTimeIn">
                                                            <div class="CustomSlectTime" (click)="mobileTimeSlotAddCall($event,3)" [ngClass]="{'inp__error': isNotValidate_2}">
                                                                <span>
                                                                    <span class="FloatingLbl">Time #{{timerSlotLength_2}}</span>
                                                                    <strong>{{timer_third.HourMin}} {{timeFormate_third}}</strong>
                                                                </span>
                                                                <svg width="24" height="24" viewBox="0 0 24 24"  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.25 7.75C11.25 7.34375 11.5625 7 12 7C12.4062 7 12.75 7.34375 12.75 7.75V11.625L15.4062 13.375C15.75 13.625 15.8438 14.0938 15.5938 14.4375C15.375 14.7812 14.9062 14.875 14.5625 14.625L11.5625 12.625C11.375 12.5 11.25 12.25 11.25 12V7.75ZM12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12Z" fill="#788496"/>
                                                                </svg>
                                                            </div>
                                                            <svg width="16" (click)="removeSlot(2)" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_18448_35530)">
                                                                <path d="M13.8516 2.16406C14.2578 2.16406 14.6016 2.50781 14.6016 2.91406C14.6016 3.35156 14.2578 3.66406 13.8516 3.66406H13.4766L12.7266 13.8203C12.6328 14.8828 11.7891 15.6641 10.7266 15.6641H4.44531C3.38281 15.6641 2.53906 14.8828 2.44531 13.8203L1.69531 3.66406H1.35156C0.914062 3.66406 0.601562 3.35156 0.601562 2.91406C0.601562 2.50781 0.914062 2.16406 1.35156 2.16406H3.50781L4.66406 0.445312C4.97656 -0.0234375 5.53906 -0.335938 6.13281 -0.335938H9.03906C9.63281 -0.335938 10.1953 -0.0234375 10.5078 0.445312L11.6641 2.16406H13.8516ZM6.13281 1.16406C6.03906 1.16406 5.94531 1.22656 5.91406 1.28906L5.32031 2.16406H9.85156L9.25781 1.28906C9.22656 1.22656 9.13281 1.16406 9.03906 1.16406H6.13281ZM11.9766 3.66406H3.19531L3.94531 13.7266C3.97656 13.9766 4.19531 14.1641 4.44531 14.1641H10.7266C10.9766 14.1641 11.1953 13.9766 11.2266 13.7266L11.9766 3.66406Z" fill="#008ECF"/>
                                                                </g>
                                                                <defs>
                                                                <clipPath id="clip0_18448_35530">
                                                                <rect width="16" height="16" fill="white"/>
                                                                </clipPath>
                                                                </defs>
                                                            </svg>  
                                                        </div>
                                                        <span class="mt-1 error caption d-flex" *ngIf="isNotValidate_2">
                                                            This selection overlaps with another timeslot selected. Please choose another timeslot.
                                                        </span> 
                                                    </div>
                                                    <div class="AddMoreDateMobile" [ngClass]="{'resetdisabled' :(activeSecondSlot && activeThirdSlot) || (isNotValidate || isNotValidate_1 || isNotValidate_2)}">
                                                        <span class="AddMoreDate" (click)="addMoreSlot()">
                                                            <a class="section-title text-end w-100 text-decoration-none">
                                                                <svg-icon name="plus-icon2"></svg-icon>
                                                                Add another time
                                                            </a>
                                                        </span>
                                                    </div>
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>    
                    </div>  
                </form>  
            </div>  
            <div class="modal-footer"> 
                <button *ngIf="shareType=='setting' && isSaving" class="StoltBtnIconLarge w-auto" type="button" (click)="saveNotification()" [disabled]="(!notificationForm.value.ChangeShipNom && !notificationForm.value.etaUpdates && !notificationForm.value.ShipArrivalLaycanPeriod) || (!daysInvalid) || (!isDateFilterValid) || (!isDateFilterValidLoad) || isTimeDuplicate || (!notificationForm.valid)">
                    Save notification properties
                    </button>
                    
                <button *ngIf="shareType=='booking' && isSaving"  class="StoltBtnIconLarge w-auto" type="button" (click)="saveNotification()" [disabled]="(!daysCheckBooking) || (EmailStatus=='INVALID')">Submit
                    </button>

                <button *ngIf="shareType=='bookingDetail' && isSaving" [ngClass]="{'disabledDetailBtn': shareType =='bookingDetail' && isSubscribe}" class="StoltBtnIconLarge w-auto detailbtn" type="button" (click)="saveNotification()" [disabled]="(!notificationForm.value.ChangeShipNom && !notificationForm.value.etaUpdates && !notificationForm.value.ShipArrivalLaycanPeriod) || (!daysInvalid) || (EmailStatus=='INVALID')">Save notification properties
                </button>

                <button *ngIf="!isSaving" class="StoltBtnIconLarge w-auto" type="button"  [disabled]="true"> Saving..</button>
                <button class="StoltBtnIconLarge StoltBtnBorderOnly w-auto" type="button" (click)="discard()"> Cancel </button> 
            </div>
        </div>
    </div>
</div> 




<!-- DAYS MODEl -->
<div class="FiltersModelCustom LargeDeviceshide" #filterModelClass> 
    <div class="ModelInnerContent">
        <div class="F_ModelHeader">
            <span class="model-dragger" (click)="mobileDaysRemoveCall(isSetMobileDayData)"></span>
            <span>
                <svg-icon (click)="mobileDaysRemoveCall(isSetMobileDayData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Day
            </span>
        </div> 
        <div class="FilterItemsName pb_80">
            <ng-container *ngIf="!isSetMobileDayData">  
                <form [formGroup]="notificationForm">
                <ul  class="Borderseparator">
                    <li> 
                        <div class="CheckOpt">
                            <span class="checkboxHld" [ngClass]="{'all_checkmng':isSelectDay}">
                                <label class="containerCheckbx">
                                    <input type="checkbox" (change)="SelectAllDays($event)" [checked]="CheckAllBox">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel"> All Days</span>
                                </label>
                            </span> 
                        </div>
                    </li> 

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="mon" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.mon}">Monday</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="tue" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.tue}">Tuesday</span>
                                </label>
                            </span> 
                        </div> 
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="wed" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.wed}">Wednesday</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="thu" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.thu}">Thursday</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="fri" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.fri}">Friday</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="sat" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sat}">Saturday</span>
                                </label>
                            </span> 
                        </div>
                    </li>

                    <li>
                        <div class="CheckOpt">
                            <span class="checkboxHld">
                                <label class="containerCheckbx">
                                    <input type="checkbox" formControlName="sun" (change)="singleSelectDay($event)">
                                    <span class="checkmark tp02"></span>
                                    <span class="RadioLabel" [ngClass]="{'fw-700':notificationForm.value.sun}">Sunday</span>
                                </label>
                            </span> 
                        </div>
                    </li>
                </ul> 
            </form>
                <div class="modal-footer modal-footer-fixed"> 
                    <button class="StoltBtnIconLarge" [disabled]="daysCount == 0" type="button" (click)="applyDaysSelection();mobileDaysRemoveCall(isSetMobileDayData)">Apply</button>
                    <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileDaysRemoveCall(isSetMobileDayData)"> Cancel </button> 
                </div>
            </ng-container>
        </div>
    </div>  
</div>

<!-- TIME MODEl -->
<div class="FiltersModelCustom LargeDeviceshide" #timezoneModelClass>  
    <div class="ModelInnerContent"> 
        <div class="F_ModelHeader">
            <span class="model-dragger" (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)"></span>
            <span>
                <svg-icon (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Timezone
            </span>
        </div>

        <div class="FilterItemsName pb_80 TimeModelMobile">
            <ng-container *ngIf="!isSetMobileTimezoneData">  
                <form [formGroup]="notificationForm">
                    <div class="TimeCol">
                        <ng-select [items]="timezoneList"
                            [searchable]="false" bindValue="timezone"
                            bindLabel="timezone"  [isOpen]="true"
                            formControlName="timezone"
                            [closeOnSelect]="true"
                            (change)="clearInput()"
                            class="TimezoneSelectHide">
                                <ng-template ng-header-tmp>
                                    <input type="text" placeholder="Search timezone" #searchName
                                        (input)="customSearch('timezone',$event)" />
                                </ng-template>                                

                            <ng-template ng-option-tmp let-item="item"
                                let-item$="item$" let-index="index">
                                <span [ngClass]="{'SelectedCheck':notificationForm.value.timezone==item.timezone}"> {{item.timezone|titlecase}}</span>
                            </ng-template>
                        </ng-select>

                    </div>
                    <div class="modal-footer modal-footer-fixed"> 
                        <button class="StoltBtnIconLarge" type="button" (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileTimezoneRemoveCall(isSetMobileTimezoneData)"> Cancel </button> 
                    </div>
                </form>
            </ng-container>
            <!--FILTER NAME LIST END -->
        </div>
    </div>  
</div>
 
<!--SELECT FILTER MODEl -->
<div class="FiltersModelCustom LargeDeviceshide" #filterSelectModelClass>
    <div class="ModelInnerContent">
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='port'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Load Port
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='dport'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Discharge Port
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='charterer'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Charterer
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='tradelane'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Tradelane
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='etaVariance'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Eta Variance
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='loadPortEta'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Load Port ETA
            </span>
        </div>
        <div class="F_ModelHeader" *ngIf="selectedFilter.name=='dischargePortEta'">
            <span class="model-dragger" (click)="mobileFilterRemoveCall(isSetMobileFilterData)"></span>
            <span>
                <svg-icon (click)="mobileFilterRemoveCall(isSetMobileFilterData)" class="angle-arrow" name="angle-arrow"></svg-icon>
                Discharge Port ETA
            </span>
        </div>
            <div class=" "> 
                <div class=""> 
                  <div class="FilterItemsName  MobileMUltiSelct SettingFlrsMobile">
                    <ng-container *ngIf="selectedFilter.name=='port'">
                        <ng-select #ngSelectControlPortMobile [isOpen]="true" [items]="portFilterData" [multiple]="true"
                    class="TextSlectGroup" [searchable]="false"
                    [clearable]="false" bindLabel="loadPortName" [closeOnSelect]="true" bindValue="loadPortNumber"
                    [(ngModel)]="portFilterValueTemp"  groupBy="port" placeholder="All" [selectableGroup]="true" >
                    <ng-template ng-multi-label-tmp let-items="items">
                        <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('loadPortName')">{{
                            items[0]?.port|titlecase}}</span>
                        <span
                            *ngIf="items.length < 2 && items[0].hasOwnProperty('loadPortName')">{{items[0]?.loadPortName|titlecase}}</span>
                        <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                    </ng-template>
        
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search port names"
                            (input)="customSearch('port',$event)" />
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="checkboxHld">
                            <label class="containerCheckbx tp15">
                                <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                <span class="checkmark"></span>
                            </label>
                            <span class="OptionTxt SpaceLeftOpt" [ngClass]="{'fw-700':item$.selected }">{{item.loadPortName|titlecase}}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="SelectAllOpt" [style.display]="PortDisplay">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt SpaceLeftOpt">All ({{portFilterData.length}})</span>
                            </div>
                        </div>
                    </ng-template>
                   
                    </ng-select>
                    </ng-container>

                    <ng-container *ngIf="selectedFilter.name=='dport'">
                        <ng-select #ngSelectControlDisPortMobile [isOpen]="true" [items]="portDischargeData" [multiple]="true"
                        class="TextSlectGroup" [searchable]="false"
                        [clearable]="false" bindLabel="dischargePortName" [closeOnSelect]="true"
                        bindValue="dischargePortNumber" [(ngModel)]="dportFilterValueTemp" [selectableGroup]="true"
                        placeholder="All" groupBy="discharge" >
                        <ng-template ng-multi-label-tmp let-items="items">

                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('dischargePortName')">{{
                                items[0]?.discharge|titlecase}}</span>
                            <span
                                *ngIf="items.length < 2 && items[0].hasOwnProperty('dischargePortName')">{{items[0]?.dischargePortName|titlecase}}</span>
                            <span *ngIf="items.length >= 2">{{items.length}}</span>

                        </ng-template>
                        <ng-template ng-header-tmp>
                            <input type="text" placeholder="Search discharge port"
                                (input)="customSearch('dport',$event)" />
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt SpaceLeftOpt" [ngClass]="{'fw-700':item$.selected }"> {{item.dischargePortName|titlecase}}</span>
                            </div>

                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="SelectAllOpt" [style.display]="dischargeDisplay">
                                <div class="checkboxHld">
                                    <label class="containerCheckbx tp15">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="OptionTxt SpaceLeftOpt">All
                                        ({{portDischargeDataMani.length}})</span>
                                </div>
                            </div>
                        </ng-template>

                        </ng-select>
                    </ng-container> 
                    <ng-container *ngIf="selectedFilter.name=='charterer'">
                        <ng-select #ngSelectControlChartererMobile [isOpen]="true"  [items]="chartererFilterData" [multiple]="true"
                        class="TextSlectGroup" [searchable]="false"
                        [clearable]="false" bindLabel="chartererName" placeholder="All" [closeOnSelect]="true"
                        bindValue="chartererName" [(ngModel)]="chartererFilterValueTemp" [selectableGroup]="true"
                        groupBy="charterer" >
                        <ng-template ng-multi-label-tmp let-items="items">
            
                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('chartererName')">{{
                                items[0]?.charterer|titlecase}}</span>
                            <span
                                *ngIf="items.length < 2 && items[0].hasOwnProperty('chartererName')">{{items[0]?.chartererName|titlecase}}</span>
                            <span *ngIf="items.length >= 2">{{items.length}}</span>
            
                        </ng-template>
                        <ng-template ng-header-tmp>
                            <input style="width: 100%; line-height: 24px" type="text" placeholder="Search charterer names"
                                (input)="customSearch('charterer',$event)" />
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt SpaceLeftOpt" [ngClass]="{'fw-700':item$.selected }"> {{item.chartererName|titlecase}}</span>
                            </div>
            
                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="SelectAllOpt" [style.display]="chartererDisplay">
                                <div class="checkboxHld">
                                    <label class="containerCheckbx tp15">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="OptionTxt SpaceLeftOpt">All
                                        ({{chartererFilterData.length}})</span>
                                </div>
                            </div>
                        </ng-template>
                       
                        </ng-select> 
                    </ng-container> 
                    <ng-container  *ngIf="selectedFilter.name=='tradelane'">
                        <ng-select  #ngSelectControlTradelaneMobile [isOpen]="true" [items]="tradelaneFilterData" [multiple]="true"
                        class="no-scroll-drpdwn PlaceholderLft OpenDropdownRight NoTextTransform" [searchable]="false"
                        [clearable]="false" bindLabel="tradelaneName" [closeOnSelect]="false" bindValue="tradelaneName"
                        [(ngModel)]="tradelaneFilterValueTemp" groupBy="tradelane"  placeholder="All" [selectableGroup]="true" >
                        <ng-template ng-multi-label-tmp let-items="items">
                            <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('tradelaneName')">{{
                                items[0]?.tradelane}}</span>
                            <span
                                *ngIf="items.length < 2 && items[0].hasOwnProperty('tradelaneName')">{{items[0]?.tradelaneName}}</span>
                            <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                        </ng-template>
    
                        <ng-template ng-header-tmp>
                            <input style="width: 100%; line-height: 24px" type="text" placeholder="Search tradelane names"
                                (input)="customSearch('tradelane',$event)" />
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt textTinitial">{{item.tradelaneName}}</span>
                            </div>
                        </ng-template>
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="SelectAllOpt" [style.display]="tradelaneDisplay">
                                <div class="checkboxHld">
                                    <label class="containerCheckbx tp15">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="OptionTxt">All ({{tradelaneFilterData.length}})</span>
                                </div>
                            </div>
                        </ng-template>
                       
                    </ng-select>
                    </ng-container>
                    <ng-container  *ngIf="selectedFilter.name=='etaVariance'">
                        <ng-select [isOpen]="true" [items]="etaVarianceFilterData" [multiple]="true"
                        class="TextSlectGroup _ETA _Load2 no-scroll-drpdwn PlaceholderLft OpenDropdownRight" [searchable]="false"
                        [clearable]="false" bindLabel="displayname" [closeOnSelect]="false" bindValue="idfilter"
                        [(ngModel)]="etaFilterValueTemp"  placeholder="All" [selectableGroup]="true" >
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input type="checkbox" id="item-{{index}}" [checked]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt">{{item?.displayname|titlecase}}</span>
                            </div>
                        </ng-template>
                       
                    </ng-select>
                    </ng-container>

                    <ng-container *ngIf="selectedFilter.name=='loadPortEta'">
                        <form class="date-range-sustainability mobile-date-range FullClickable" [formGroup]="dateRageFiltersFormLoadEta" >              
                            <mat-form-field appearance="fill">       
                                <span class="FloatingLbl">Load port ETA</span>  
                                <mat-label><span class="AllValue">All</span></mat-label>     
                                <mat-date-range-input [rangePicker]="picker" [min]="minDateLoadPort" [max]="maxDate">      
                                <input matStartDate placeholder="Start date" formControlName="fromDate">      
                                <input matEndDate placeholder="End date" formControlName="toDate">      
                                </mat-date-range-input>      
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>   
                                <mat-date-range-picker #picker>
                                    <mat-date-range-picker-actions>
                                        <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeLoadFilter('loadPortEta')">Apply</button>
                                        <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('loadPortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker> 
                            </mat-form-field> 
                        </form>
                    </ng-container>
                    <ng-container *ngIf="selectedFilter.name=='dischargePortEta'">
                        <form class="date-range-sustainability mobile-date-range FullClickable" [formGroup]="dateRageFiltersFormLoadDischarge" >              
                            <mat-form-field appearance="fill">       
                                <span class="FloatingLbl">Discharge port ETA </span>  
                                <mat-label><span class="AllValue">All</span></mat-label>     
                                <mat-date-range-input [rangePicker]="pickerDischarge" [min]="minDate" [max]="maxDate">      
                                <input matStartDate placeholder="Start date" formControlName="fromDate">      
                                <input matEndDate placeholder="End date" formControlName="toDate">      
                                </mat-date-range-input>      
                                <mat-datepicker-toggle matSuffix [for]="pickerDischarge"></mat-datepicker-toggle>   
                                <mat-date-range-picker #pickerDischarge>
                                    <mat-date-range-picker-actions>
                                        <button class="StoltBtnIconLarge position-relative overflow-hidden DATEfilterapplybtn" matDateRangePickerApply (click)="applyDateRangeDischargeFilter('dischargePortEta')">Apply</button>
                                        <button class="StoltBtnIconLarge StoltBtnBorderOnly DATErestebtn" (click)="resetDateRangeFilter('dischargePortEta');isApplyFilterButton = false" matDateRangePickerCancel>Reset</button>                                                       
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker> 
                            </mat-form-field> 
                        </form>
                    </ng-container>

                  </div>
                </div>
            </div>
        <!--FILTER INDIVIDUAL ITEMS NAME LIST END -->
    </div>
</div>

<!-- ##################  Custom Timer Section ###################### -->
<div class="FiltersModelCustom LargeDeviceshide" #timeSlotFirstModelClass>  
    <div class="ModelInnerContent"> 
        <div class="F_ModelHeader">
            <span class="model-dragger" (click)="mobileTimeSlotRemoveCall()"></span>
            <span>
                <svg-icon (click)="mobileTimeSlotRemoveCall()" class="angle-arrow" name="angle-arrow"></svg-icon>
                Time
            </span>
        </div>

        <div class="FilterItemsName pb_80 TimeModelMobile">
                <form [formGroup]="notificationForm">
                    <ng-container *ngIf="timeSlotType == 1">
                    <div class="TimeCol">
                        <div class="TimePickerMain"> 
                            <div class="BodyTimePic p-0 justify-content-center">
                                <timepicker [hourStep]="hstep" [mousewheel]="false"  [minuteStep]="mstep" formControlName="notificationTime"  [meridians]="meridians" [(ngModel)]="notifyTime"></timepicker>
                                <div class="TimeAmPm">
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormatPm}" type="button" (click)="onFormateChange(1,'PM')"  type="button">PM</button>
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormateAm}" (click)="onFormateChange(1,'AM')" type="button"> AM </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer modal-footer-fixed"> 
                        <button class="StoltBtnIconLarge" type="button" (click)="applyTime(1)">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileTimeSlotRemoveCall()"> Cancel </button> 
                    </div>
                </ng-container>

                <ng-container *ngIf="timeSlotType == 2">
                    <div class="TimeCol">
                        <div class="TimePickerMain"> 
                            <div class="BodyTimePic p-0 justify-content-center">
                                <timepicker [hourStep]="hstep" [mousewheel]="false"  [minuteStep]="mstep" formControlName="notificationTime_1"  [meridians]="meridians" [(ngModel)]="notifyTime_second"></timepicker>
                                <div class="TimeAmPm">
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormatPm_second}" type="button" (click)="onFormateChange(2,'PM')"  type="button">PM</button>
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormateAm_second}" (click)="onFormateChange(2,'AM')" type="button"> AM </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer modal-footer-fixed"> 
                        <button class="StoltBtnIconLarge" type="button" (click)="applyTime(2)">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileTimeSlotRemoveCall()"> Cancel </button> 
                    </div>
                </ng-container>

                <ng-container *ngIf="timeSlotType == 3">
                    <div class="TimeCol">
                        <div class="TimePickerMain"> 
                            <div class="BodyTimePic p-0 justify-content-center">
                                <timepicker [hourStep]="hstep" [mousewheel]="false"  [minuteStep]="mstep" formControlName="notificationTime_2"  [meridians]="meridians" [(ngModel)]="notifyTime_third"></timepicker>
                                <div class="TimeAmPm">
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormatPm_third}" type="button" (click)="onFormateChange(3,'PM')"  type="button">PM</button>
                                    <button class="StoltBtnIconLarge StoltBtnBorderOnly font-14 fw-700" [ngClass]="{'active':isFormateAm_third}" (click)="onFormateChange(3,'AM')" type="button"> AM </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer modal-footer-fixed"> 
                        <button class="StoltBtnIconLarge" type="button" (click)="applyTime(3)">Apply</button>
                        <button class="StoltBtnIconLarge StoltBtnBorderOnly" type="button" (click)="mobileTimeSlotRemoveCall()"> Cancel </button> 
                    </div>
                </ng-container>

                </form>
            <!--FILTER NAME LIST END -->
        </div>
    </div>  
</div>




