import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { Sortingfield } from '../../../enums/sortingfield';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fleet-info-iframe',
  templateUrl: './fleet-info-iframe.component.html',
  styleUrls: ['./fleet-info-iframe.component.scss']
})
export class FleetInfoIframeComponent implements OnInit {

fleetList: any = [];
totalFleets: any = '';
displayFleets: any = '';
minValue: number = 0;
maxValue: number = 50000;
sigleFleetMapPopUp = false;
options: Options = {
  floor: 0,
  ceil: 50000,
  translate: (value: number, label: LabelType): string => {
    return "";
  }
};
fleetItem: any;
pageNo = 1;
skip = 0;
isSkelton = false;
sortingFieldName = '';
sortingFieldType = '';
sortInprogress = {
  column5: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
  summer_dwt: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
}
public areaChart: GoogleChartInterface = {
  chartType: GoogleChartType.AreaChart,
  dataTable: [
    ['Year', 'Capicity']
  ],
  //firstRowIsData: true,
  options: { 'title': 'Capicity' },
};

constructor(public navigationService: NavigationService, public publicPagesService: PublicPagesService,private router: Router) {
  this.navigationService.setShowNav(false);
  this.navigationService.setMobileNav(false);
  this.navigationService.setPageTitle('Stolt Tankers Fleet information');
}


ngOnInit(): void {
  this.getAllFleets(this.minValue, this.maxValue)
}
filterData() {
  this.pageNo = 1;
  this.getAllFleets(this.minValue, this.maxValue)
}
clearFilter() {
  this.minValue = 0;
  this.maxValue = 100000;
  this.getAllFleets(this.minValue, this.maxValue)
}
onScroll() {
  if (this.totalFleets >= ((this.pageNo * 10) - 10)) {
    this.pageNo = this.pageNo + 1;
  }
}
setLocation(it: any) {
  this.fleetItem = {};
  this.fleetItem = it;
  this.fleetItem.last_latitude = parseFloat(it.last_latitude);
  this.fleetItem.last_longitude = parseFloat(it.last_longitude);
  this.sigleFleetMapPopUp = true;
}


getAllFleets(range1 = 0, range2 = 0) {
  this.isSkelton = true;
  let filterData = (range1 || range2) ? { range1, range2 } : { range1: this.minValue, range2: this.maxValue };
  this.publicPagesService.getAllFleets(filterData).subscribe({next:(res: any) => {
    this.isSkelton = false;
    this.fleetList = (res && res.data && res.data.result) ? (res.data.result) : [];

    this.isSkelton = false;
    this.fleetList = (res && res.data && res.data.result) ? (res.data.result) : [];

    let sum = 0;
    this.sortList(this.fleetList, 'port', 'asc');
    let i:number
    for (let ele of this.fleetList) {
      i = this.fleetList.indexOf(ele)
      this.areaChart.dataTable.push([sum, this.fleetList[i].summer_dwt])
      sum += 500;
    }
    this.displayFleets = this.fleetList.length > 0 ? this.fleetList.length : 0;
    this.totalFleets = (res && res.data && res.data.totalFleets) ? res.data.totalFleets : 0;
  }, error:(err) => {
    this.isSkelton = false;
    this.navigationService.errorOccure(err);
  }})
}
getMininumValue(fleetList: any, key: any) {
  return Math.min.apply(null, fleetList.map((item: any) => {
    return item[key];
  }))
}
getMaximumValue(fleetList: any, key: any) {
  return Math.max.apply(null, fleetList.map((item: any) => {
    return item[key];
  }))
}
setSortingFieldName(name: string, type: string) {
  if(name == 'column5')
  {
    this.sortingFieldName = Sortingfield.column5;
    this.sortingFieldType = type;
  }else
  {
    this.sortingFieldName = Sortingfield.summer_dwt;
    this.sortingFieldType = type;
  }

}
toogleSortingOrder(fieldname: string) {
  if(fieldname == 'column5'){
    this.sortInprogress.column5.isOpen = !this.sortInprogress.column5.isOpen;
    this.sortInprogress.summer_dwt.isOpen = false;
  }else{
    this.sortInprogress.summer_dwt.isOpen = !this.sortInprogress.summer_dwt.isOpen
    this.sortInprogress.column5.isOpen = false;
  }
}
popUpMouseLeaveClose(val: any) {
  if(val == 'column5')
  {
    this.sortInprogress.column5.isOpen = false;
  }else{
    this.sortInprogress.summer_dwt.isOpen = false;
  }
}
fetchSortingType(obj: any) {
  this.sortingFieldType = obj.value;
  if(obj.col == 'column5'){
    this.sortInprogress.column5.isActive = true;
    this.sortInprogress.summer_dwt.isActive = false;
    this.sortInprogress.column5.value = this.sortInprogress.column5.isActive ? obj.value : '';
    this.sortingFieldName = Sortingfield.column5;
  }else{
    this.sortInprogress.summer_dwt.isActive = true;
    this.sortInprogress.column5.isActive = false;
    this.sortInprogress.summer_dwt.value = this.sortInprogress.summer_dwt.isActive ? obj.value : '';
    this.sortingFieldName = Sortingfield.summer_dwt;
  }
}

sortList(fleetList: any, key: any, ascDescFlag: any = '') {
  fleetList.sort(function (a: any, b: any) {
    if (a[key] === null || (a[key] > b[key] && a[key] != '' && b[key] != '')) return ascDescFlag == 'asc' ? 1 : -1;
    else if (b[key] === null || (a[key] < b[key] && a[key] != '' && b[key] != '')) return ascDescFlag == 'desc' ? -1 : 1;
    else return 0;
  });
}

}
