<div class="side-nav-bar-content h-100 m-0">

    <!-- This block of code is currently hidden but may prove useful in the future.
    It is retained here for potential future reference or implementation. -->
    <div class="sidebar-user flex-column align-items-center justify-content-center"
        style="display: none !important;visibility: hidden;opacity: 0;">
        <ng-container *ngIf="(profileData.name!='' ||profileData.charterer !='');else noSkelton">
            <svg-icon name="account-icon"></svg-icon>
            <p>{{this.profileData.name}}</p>
            <small>({{this.profileData.charterer}})</small>
        </ng-container>
        <ng-template #noSkelton>
            <span class="icon-skelton Stolt-Skeleton"></span>
            <p class="Stolt-Skeleton"></p>
            <small class="Stolt-Skeleton"></small>
        </ng-template>
    </div>


    <!-- Menu items skeleton -->
    <div class="SidebarSkeltonMain h-100" *ngIf="skelton">
        <div class="vh-100 justify-content-between d-flex flex-column">
            <ul class="side-nav-bar-menu skelton-item-main sec1">
                <li class="LogoSkeleton"><div class="Stolt-Skeleton"></div></li>
                <li *ngFor="let number of [0,1,2,3]">
                    <a class="Stolt-Skeleton" href="javascript:void(0);"></a>
                </li>
            </ul>

            <ul class="side-nav-bar-menu skelton-item-main sec2">
                <li *ngFor="let number of [0,1,2]">
                    <a class="Stolt-Skeleton" href="javascript:void(0);"></a>
                </li>
            </ul>
        </div>
    </div>

    <div class="h-100" *ngIf="!skelton">
        <div class="h-100">
            <snl-navigation
             [menuOptions]="pages"
              [bottomOptions]="bottomOptions" 
              [userOption]="userOption"
              [signOutOption]="signOutOption"
              [isCollapsed]="false"
              (selectedOption)='selectedOption($event)'
              (navigationCollapse) = 'navigationCollapse($event)'>
                <div expanded [routerLink]="['/']">
                    <img src="assets/icons/brand-logo.svg" alt="brand-logo" />
                </div>
                <div collapsed>
                    <img src="assets/icons/brand-logo-mini.svg" alt="brand-logo" />
                </div>
                <div top-container></div>
            </snl-navigation>
        </div>
    </div>
</div>
<!-- Comment temporary <app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId"></app-nps-survey-monkey>  -->
