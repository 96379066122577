import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { ToastrService } from 'ngx-toastr';
import { PublicPagesService } from './public-pages.service';
import { environment } from 'src/environments/environment';
import { map, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  baseUrl_auth = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;
  userRole: string;
  constructor(public toastr: ToastrService,
    private http: HttpClient,
    private authService: MsalService,
    private titleService: Title,
    private publicPagesService: PublicPagesService) { }
  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }
  getUsers(filters: any) {
    return this.http.post(`${this.baseUrl_auth}/api/getUsersList`, filters).pipe(map(data => data), retry(2));
  }
  getUserManagementFilters(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/userListFilters`, this.getHeaderJson()).pipe(map(data => data))
  }
  updateUsers(payload: any) {
    return this.http.post<any>(`${this.baseUrl_auth}/api/updateUsers`, payload, this.getHeaderJson()).pipe(map(data => data))
  }
  deleteUsers(payload: any) {
    return this.http.post<any>(`${this.baseUrl_auth}/api/deleteUsers`, payload, this.getHeaderJson()).pipe(map(data => data))
  }

  saveUser(body: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl_auth}/api/addUsers`, body, this.getHeaderJson()).pipe(map(data => data))
  }


  getCompanylist(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/charterers`, this.getHeaderJson()).pipe(map(data => data))
  }

  getBrokerlist(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl_auth}/api/getBrokers`, this.getHeaderJson()).pipe(map(data => data))
  }


  getUserRole(){
    return this.userRole;
  }
}
