import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-show-announcement',
  templateUrl: './show-announcement.component.html',
  styleUrls: ['./show-announcement.component.scss']
})
export class ShowAnnouncementComponent {
  @Output() closePopupEmit = new EventEmitter(); 
  @Input() isPreview: any;
  @Input() editorData:any;
  @Input() title:any;
  public safeHtmlContent: SafeHtml;
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  constructor(private sanitizer: DomSanitizer, private navigationService: NavigationService){
  }

  ngOnChanges(){
    if(this.editorData){
      this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.editorData);
    }
  }

  closePopup(){
    localStorage.removeItem('show_announcement');
    this.removebodyClass();
    this.closedAnnouncementByUser();
    this.closePopupEmit.emit();
  }

  closedAnnouncementByUser(){
    this.navigationService.closedAnnouncementByUser().subscribe((res: any) => {
      console.log(res);
    })
  }

  removebodyClass()
  {
    let ele:any = document.querySelector("body");
    if(ele && ele.style){
      ele.style.overflow = 'auto';
    }
  }


}