<!-- User Favourites Setup Start -->
<div class="UserFavouritesFilters d-flex flex-column">
    <div class="FavouritesFiltersInner d-flex align-items-start justify-content-start flex-column" *ngIf="isFilter && !isLoading">

        <!-- Port filter Start -->
        <div class="form-row">
            <div class="form-group">
                <ng-select #ngSelectControlPort [items]="portFilterData" [multiple]="true"
                    class="TextSlectGroup _Load OpenDropdownTop SetingFltr no-scroll-drpdwn PlaceholderLft" [searchable]="false"
                    [clearable]="false" bindLabel="loadPortName" [closeOnSelect]="false" bindValue="loadPortNumber"
                    [(ngModel)]="portFilterValueTemp" groupBy="port" placeholder="All" [selectableGroup]="true"
                    (change)="clickEvent($event,'fevorite');clearInput()"
                    (open)="emitDinamicAddClass()"
                    (close)="emitDinamicRemoveClass()">
                    <ng-template ng-multi-label-tmp let-items="items">
                        <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('loadPortName')">{{
                            items[0]?.port|titlecase}}</span>
                        <span
                            *ngIf="items.length < 2 && items[0].hasOwnProperty('loadPortName')">{{items[0]?.loadPortName|titlecase}}</span>
                        <span *ngIf="items.length >= 2 ">{{items.length}}</span>
                    </ng-template>

                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search port names" #searchName
                            (input)="customSearch('port',$event)" />
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="checkboxHld">
                            <label class="containerCheckbx tp15">
                                <input type="checkbox" id="item-{{index}}" [ngModel]="item$.selected" />
                                <span class="checkmark"></span>
                            </label>
                            <span class="OptionTxt">{{item.loadPortName|titlecase}}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="SelectAllOpt" [style.display]="PortDisplay">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt">All ({{portFilterData.length}})</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <!-- Port filter end -->

        <!-- Discharge Port filter Start -->
        <div class="form-row">
            <div class="form-group">
                <ng-select #ngSelectControlDisPort [items]="portDischargeData" [multiple]="true"
                    class="TextSlectGroup _Discharge OpenDropdownTop SetingFltr no-scroll-drpdwn PlaceholderLft" [searchable]="false"
                    [clearable]="false" bindLabel="dischargePortName" [closeOnSelect]="false"
                    bindValue="dischargePortNumber" [(ngModel)]="dportFilterValueTemp" [selectableGroup]="true"
                    placeholder="All" (change)="clickEvent($event,'fevorite');clearInput()" groupBy="discharge"
                    (open)="emitDinamicAddClass()"
                    (close)="emitDinamicRemoveClass()">
                    <ng-template ng-multi-label-tmp let-items="items">

                        <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('dischargePortName')">{{
                            items[0]?.discharge|titlecase}}</span>
                        <span
                            *ngIf="items.length < 2 && items[0].hasOwnProperty('dischargePortName')">{{items[0]?.dischargePortName|titlecase}}</span>
                        <span *ngIf="items.length >= 2">{{items.length}}</span>

                    </ng-template>
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search discharge port" #searchName
                            (input)="customSearch('dport',$event)" />
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="checkboxHld">
                            <label class="containerCheckbx tp15">
                                <input type="checkbox" id="item-{{index}}" [ngModel]="item$.selected" />
                                <span class="checkmark"></span>
                            </label>
                            <span class="OptionTxt"> {{item.dischargePortName|titlecase}}</span>
                        </div>

                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="SelectAllOpt" [style.display]="dischargeDisplay">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt">All
                                    ({{portDischargeDataMani.length}})</span>
                            </div>
                        </div>
                    </ng-template>

                </ng-select>
            </div>
        </div>
        <!-- Discharge Port filter end -->

        <!-- Charterer filter Start -->
        <div class="form-row">
            <div class="form-group">
                <ng-select #ngSelectControlCharterer [items]="chartererFilterData" [multiple]="true"
                    class="TextSlectGroup _Charterer OpenDropdownTop SetingFltr no-scroll-drpdwn PlaceholderLft" [searchable]="false"
                    [clearable]="false" bindLabel="chartererName" placeholder="All" [closeOnSelect]="false"
                    bindValue="chartererName" [(ngModel)]="chartererFilterValueTemp" [selectableGroup]="true"
                    groupBy="charterer" (change)="clickEvent($event,'fevorite');clearInput()"
                    (open)="emitDinamicAddClass()"
                    (close)="emitDinamicRemoveClass()">
                    <ng-template ng-multi-label-tmp let-items="items">

                        <span *ngIf="items.length < 2 && !items[0].hasOwnProperty('chartererName')">{{
                            items[0]?.charterer|titlecase}}</span>
                        <span
                            *ngIf="items.length < 2 && items[0].hasOwnProperty('chartererName')">{{items[0]?.chartererName|titlecase}}</span>
                        <span *ngIf="items.length >= 2">{{items.length}}</span>

                    </ng-template>
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 24px" type="text" placeholder="Search charterer names" #searchName
                            (input)="customSearch('charterer',$event)" />
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="checkboxHld">
                            <label class="containerCheckbx tp15">
                                <input type="checkbox" id="item-{{index}}" [ngModel]="item$.selected" />
                                <span class="checkmark"></span>
                            </label>
                            <span class="OptionTxt"> {{item.chartererName|titlecase}}</span>
                        </div>

                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="SelectAllOpt" [style.display]="chartererDisplay">
                            <div class="checkboxHld">
                                <label class="containerCheckbx tp15">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="OptionTxt">All
                                    ({{chartererFilterData.length}})</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>

    </div>

    <!-- Filter Favourites Skelton --> 
    <div class="Stolt-Skeleton-Holder" *ngIf="isLoading">
        <div class="inProgress-sec">
            <div class="inProgress-filter">
                <div class="FilterDropDownArea d-flex align-items-start justify-content-start flex-column">
                    <div class="form-row">
                        <div class="form-group">
                            <ng-select class="Stolt-Skeleton"></ng-select>
                        </div>
                    </div> 

                    <div class="form-row">
                        <div class="form-group">
                            <ng-select class="Stolt-Skeleton Stolt-Skeleton-large"></ng-select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <ng-select class="Stolt-Skeleton"></ng-select>
                        </div>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
    <!-- Filter Favourites Skelton End --> 
        
</div>
<!-- User Favourites Setup End -->