import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {
  
  transform(value: string,  limit: number): string {
    let trail = '...';
    let val = value.trim();
    return val.length > limit ? val.substring(0, limit) + trail : val;
  }

}

@Pipe({
  name: 'removeCountry'
})
export class RemoveCountryToPipe implements PipeTransform {
  bookingFilterRegex = environment.bookingFilterRegex
  transform(value: string): string {
    return value.replace(this.bookingFilterRegex, "");
  }

}

@Pipe({
  name: 'documentName'
})
export class DocumentNamePipe implements PipeTransform {
  transform(val: string): string {
    let trail = '...';
    let value=val.trim();
    if (value.length > 20) {
      let splitedValues = value.split(".");
      if(splitedValues[0] && splitedValues[1]){
      return splitedValues[0].substring(0,13) + trail + '(' + splitedValues[1] + ')';
    }
    else{
      return val;
    }

    } else {
      return val;
    }
  }

}
