<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}"> 
    <div class="scroll-to-top show-scrollTop">
        <button type="button" class="scrollTop-btn" (click)="scrollToTop()">
            <img class="ArrowRotate" src="assets/svg/login-arrow.svg" alt="img">
        </button>
      </div>
  </div>

  
 
  
