import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value :any) {
    // NOSONAR - The URL is sanitized using DOMPurify, and trusted domains are validated
    return this.sanitizer.bypassSecurityTrustHtml(value); // NOSONAR
  }

}
