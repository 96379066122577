import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingService } from 'src/app/services/setting.service';
import { NavigationService } from '../../../services/navigation.service';
import { NotificationService } from '../../../services/notification.service';
import { Papa } from "ngx-papaparse";
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { BookingService } from "../../../services/booking.service";
import { SustainabilityService } from 'src/app/services/sustainability.service';
import moment from 'moment';
import { SettingfilterPocComponent } from './settingfilter-poc/settingfilter-poc.component';
import { TitleCasePipe } from '@angular/common';
import { DocumentationService } from 'src/app/services/documentation.service';

@Component({
  selector: 'app-setting-poc',
  templateUrl: './setting-poc.component.html',
  styleUrls: ['./setting-poc.component.scss'],
  providers: [TitleCasePipe]

})
export class SettingPocComponent implements OnInit {
  @ViewChild(SettingfilterPocComponent) SettingfilterPocComponent: SettingfilterPocComponent;
  obj = {
    min_delay_days: 1, max_delay_days: 3, frequency_per_day: 1, mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, sun: 0,
    change_of_vessel_nom: 0, eta_updates: 0, laycan: 0, loadPortNumber: [], dischargePortNumber: [], charterer: []
  };
  minVal = '1';
  maxVal = '3';
  isDisabled = true;
  isFilterOpenClose=false
  viewMode = 'Manual';
  sortDir = 1;//1= 'ASE' -1= DSC
  @ViewChild('min') min: ElementRef;
  @ViewChild('max') max: ElementRef;
  SlpitStr: any;
  NotifyEnable: boolean;
  hideUploadBtn: boolean = true
  isOpenModel:boolean = false
  listName: string = '';
  DistSourceData: any = []
  ResipiantData: Array<any> = [{ name: '', email: '' }]
  model: any = { contactemail: '' }
  userForm: UntypedFormGroup;
  submitted = false;
  Fremail: any;
  emailArr: any = [];
  Is_add: boolean = true
  errMessage: string;
  Is_List: any;
  Is_Csv: boolean;
  recipientList: any[];
  is_csven: boolean = false;
  manulaEnb: boolean = true;
  UploadEnb: boolean = false;
  is_upload: boolean = true;
  is_csvError: boolean;
  listnameExist: any = [];
  disableOnEdit: boolean = true;
  emialError_validate: string;
  errorMessage: any[] = [];
  existError: boolean;
  Is_Delete: boolean = true;
  email_duplicte: any = [];
  onCreateForm: boolean;
  textLenMsg: string;
  errMessagelimit: string;
  reSetData: any
  saveChnageType: string = 'Save';
  reSettEnb: boolean = false;
  list_id: any = '';
  Is_frmDelete: boolean = false;
  IstoggleAcordian: boolean = false;
  delVal: string = 'notDelete';
  csvFileName: any = '';
  onUploaddsbl: boolean = true;
  is_upload_csv: boolean = true;
  nameValidError: string;
  checkError: boolean = false
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  findIndex: any;
  totalRecipients: any;
  listNameLenght: boolean;
  openppop: boolean = false;
  UploadfilesData: any;
  csvlistnameExist: any = [];
  plusButton: boolean = true;
  checkIndex: any;
  csvFileData: any;
  fileExtenMessg: string;
  countrecipients: number;
  respLimit: boolean;
  respintLenght: number = 0;
  totalRecored: void;
  resplenght: number;
  listnamForm: UntypedFormGroup;
  settingFilterData: any;
  settingtype: any;
  getEmitFilter: any;
  isLoading: boolean = false
  isUpdate: boolean = false
  isNotification = false
  isMobileNotification=false
  NotificationListData: any = []
  notifiactionSkelton: boolean = true;
  SavedFilter: { loadPortNumber: any; dischargePortNumber: any; charterer: any; };
  updateNotification: any = ''
  rowId: any;
  stoltDomain: any;
  timer: any;
  localtime: Date;
  localTime: any;
  defaulyTimezone: string = 'Monrovia, Reykjavik, (GMT+00:00)';
  overFlowClass: boolean;
  isDistributionLoading:boolean = true;
  btnDisabled: boolean = true;
  checkhashName: any;
  distributionRemoveModel:boolean = false
  notificationRemoveModel:boolean = false
  timezoneList: any = [];
  @ViewChild('fullName') fullNameInput: any;
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  isDelete: boolean = false;
  findHashNameCharIndex: any = [];
  findBlankNameIndex: any=[];
  findNameMaxLenIndex: any=[];
  findDuplicateEmailIndex: any =[];
  findInvalidEmailIndex: any=[];
  settings: any={
    "isDisable": false,
    "showHover": true,
    "showCursorNotAllowed": false,
  }
  constructor(private navigationService: NavigationService, private notificationService: NotificationService, private settingService: SettingService, private fb: UntypedFormBuilder,private papa: Papa, private router: Router, private _storageService: StorageService,private _bookingService:BookingService , private sustainabilityService: SustainabilityService,public titleCasePipe: TitleCasePipe, private docService: DocumentationService) {
    this.navigationService.setShowNav(true);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Setting');

    this.listnamForm = this.fb.group({
      'listname': ["", Validators.compose([
        Validators.required])],
    })

    this.userForm = this.fb.group({
      name: ['',[Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z]{2,9}$')]],
      Referrals: this.fb.array([]),

    });
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getUserFilter()
    this.getNotificationData()
    this.getTimezonelist()
  }

  getTimezonelist() {
    this.settingService.getTimezonelist().subscribe((res: any) => {
      if (res.data) {
        let tmzoneList = res.data.map((it: any) => ({ timezone: it.country + ", " + it.utctime, value: it.country + ", " + it.utctime + " _" + it.timezones }))
        tmzoneList.sort((a: any, b: any) => {
          a = a['timezone'].toLowerCase();
          b = b['timezone'].toLowerCase();
          return a.localeCompare(b) * this.sortDir;
        })

        this.timezoneList = tmzoneList.map((it: any) => ({ name: it.timezone.trim(), id: it.value.trim(),timezone: it.timezone.trim(), value: it.value.trim() }))
      }

    })

  }

  getNotificationData() {
    this.notifiactionSkelton = true;
    this.settingService.getNotificationList().subscribe((res: any) => {
      if (res) {
        this.manepulatenotificationData(res)
      }
    })
  }

  manepulatenotificationData(res: any) {
    this.settingService.getUserFilterData().subscribe((filres:any) => {
      this.refactorNotification(filres, res)
    })
  }

  refactorNotification(filres: any, res: any) {
    let filterList = filres.data
    let result = res.data ? res.data : '';

    this.NotificationListData = result ? result.map((it: any) => ({ ...it, events: this.manupulateEvent(it), ConverTime: this.converTIme(it), dateFilters: this.convertFilterDates(it), mergeFilter: this.mergeFilter(filterList, it.loadPortNumber, it.dischargePortNumber, it.charterer, it.tradelaneNumber) })) : [];

    this.notifiactionSkelton = false;
    if (res.data && res.data.length > 0) {
      let filtetimezone = res.data.filter((it: any) => it.timezone)
      if(filtetimezone.length){
        let lastArr = filtetimezone[filtetimezone.length - 1]
        this.defaulyTimezone = lastArr.timezone ? lastArr.timezone : this.defaulyTimezone;
      }
    }
  }


  loadport = ''
  disport = ''
  charterobj = ''
  tradelanobj = ''
  mergeFilter(filter: any, port: any, dport: any, chartere: any, tradelane: any) {
    let fil = filter.LoadPortFilter.filter((ele: any) => port ? port.includes(ele.loadPortNumber) : [])
    this.loadport = fil.map((u: any) => this.titleCasePipe.transform(u.loadPortName)).join(', ')

    let dpobj = filter.DischargePortsFilter.filter((ele: any) => dport ? dport.includes(ele.dischargePortNumber) : [])
    this.disport = dpobj.map((u: any) => this.titleCasePipe.transform(u.dischargePortName)).join(', ')

    let charter = filter.ChartererFilter.filter((ele: any) => chartere ? chartere.includes(ele.charterer) : [])
    this.charterobj = charter.map((u: any) => this.titleCasePipe.transform(u.charterer)).join(', ')

    let tradelan = filter.tradelane.filter((ele: any) => tradelane ? tradelane.includes(ele.name) : [])
    this.tradelanobj = tradelan.map((u: any) => u.name).join(', ')

    let concPort = this.loadport ? (this.loadport + ", ") : ''
    let contdPort = this.disport ? (this.disport + ", ") : ''
    let conChart = this.charterobj ? (this.charterobj) : ''
    let conTrad = this.tradelanobj ? (this.tradelanobj) : ''
    let mergeAll = concPort + contdPort + conChart + conTrad;
    let newobj = mergeAll.length > 15 ? mergeAll.slice(0, 15 - 1) + "…" : mergeAll;
    return newobj.replace(/,\s*$/, "")

  }

  loadDate: any = ''
  dischargeDate: any = ''
  convertFilterDates(event: any) {
    let str = '';
    if (event?.loadPortDateRange?.fromDate && event?.loadPortDateRange?.toDate) {
      str = str + moment(event?.loadPortDateRange?.fromDate).format('DD MMM YYYY') + " - " + moment(event?.loadPortDateRange?.toDate).format('DD MMM YYYY')
    }
    if (event?.dischargePortDateRange?.fromDate && event?.dischargePortDateRange?.toDate) {
      str = str + " , " + moment(event?.dischargePortDateRange?.fromDate).format('DD MMM YYYY') + " - " + moment(event?.dischargePortDateRange?.toDate).format('DD MMM YYYY')
    }
    let etaVArianve = this.getETAVarianceValues(event.min, event.max);

    etaVArianve.forEach((item: any) => {
      if (event?.arrIdDelayFilter.includes(item.idfilter)) {
        str = str + "," + item.displayname
      }
    })
    let newFil = str.length > 15 ? str.slice(0, 23 - 1) + "…" : str;
    newFil = newFil.trim();
    if(newFil[0] === ","){
      newFil = newFil.substring(1);
    }
    return newFil.replace(/,\s*$/, "")
  }


  converTIme(event: any) {

    if (event.frequencyType == 'custom') {
      this.localTime = new Date(event.localTime)
      let days = event.Days
      this.timer = this.getTimeHourMin(this.localTime, event.timezone, days)
      return this.timer
    }
  }

  getTimeHourMin(lTime: any, tzone: any, days: any) {
    this.localtime = lTime;
    let hours = this.localtime.getHours();
    let minutes = this.localtime.getMinutes();
    let second = this.localtime.getSeconds();
    let getTime = hours + ':' + minutes + ':' + second;
    return this.formatTime(getTime, tzone, days);
  }

  formatTime(timeString: any, tzone: any, days: any) {
    let timezone = tzone ? tzone.substring(tzone.indexOf('GMT') + 3, tzone.indexOf(':')) : '';
    let refectDay: any
    if (days.length <= 2) {
      refectDay = days.slice(0, 2)
    } else if (days.length > 2 && days.length <= 6) {
      refectDay = days.slice(0, 2) + "…"
    } else {
      refectDay = days[0] + '-' + days[days.length - 1];
    }
    const [hourString, minute] = timeString ? timeString.split(":") : '';
    let minutes = (minute.toString().length == 1) ? 0 + minute : minute
    const hour = +hourString % 24;
    let hours = ((hour % 12 || 12).toString().length == 1) ? '0' + (hour % 12 || 12) : (hour % 12 || 12)
    return refectDay + "," + " " + hours + ":" + minutes + " " + (hour < 12 ? "AM" : "PM") + ", " + '(' + timezone + ')'
  }

  manupulateEvent(event: any) {
    let evntstr = '';
    evntstr += event.ChangeShipNom ? 'Vessel nom, ' : '';
    evntstr += event.etaUpdates ? 'ETA updates, ' : '';
    evntstr += event.ShipArrivalLaycanPeriod ? 'Outside laycan' : '';
    evntstr += event.allShipmentETAs ? 'All ETAs' : '';
    return evntstr.replace(/,\s*$/, "");
  }




  getUserInfo() {
    this.isDistributionLoading = true
    this.saveChnageType = "Save";
    this.getUserData()
    this.loadNotificationData();
    this.getDistributionaData()

    this.reSettEnb = (this.obj.frequency_per_day == 0) && (this.obj.mon == 0) && (this.obj.wed == 0) && (this.obj.thu == 0) && (this.obj.fri == 0) && (this.obj.sat == 0) && (this.obj.sun == 0) && (this.obj.eta_updates == 0) && (this.obj.change_of_vessel_nom == 0) ? true : false;
  }

  getUserData(){
    this.navigationService.getUserInfo().subscribe({next:(res: any) => {
      if (res.data) {
        let email = res.data.email;
        if (!res?.data?.company_no?.length) {
          this._storageService.putDataInStorage("isNotCompanyAssigned", true);
          this.router.navigate(['/'])
        } else {
          this._storageService.putDataInStorage("isNotCompanyAssigned", false);
        }
        this.SlpitStr = email.substring(email.lastIndexOf("@") + 1)
        this.stoltDomain = email.substring(email.lastIndexOf("@")).substring(0, 6);
        this.NotifyEnable = (this.SlpitStr == 'STOLTDEV.COM' || this.SlpitStr == 'STOLT.COM') ? true : false;
      }
    }, error:err => {
      this.NotifyEnable = false;
    }})
  }

  getDistributionaData(){
    this.settingService.getEmailDistribution().subscribe({next:(res: any) => {
      this.DistSourceData = res.data;
      this.isDistributionLoading = false
      if(res.data && res.data.length){
        this.DistSourceData.sort((a: any, b: any) => {
          a = a['listName'].toLowerCase();
          b = b['listName'].toLowerCase();
          return a.localeCompare(b) * this.sortDir;
        })
      }
    }, error:err => {
      this.DistSourceData = []
      this.isDistributionLoading = false
    }})
  }
  preventKeyEnter(e: any, typVal: string): any {
    const val = typVal == 'min' ? this.min.nativeElement.value : this.max.nativeElement.value;
    if (val > 0 && val < 100 || e.which == 46 || e.which == 8) {
      return true;
    } else {
      if (typVal == 'min') {
        this.obj.min_delay_days = Math.trunc(val / 10);
      } else {
        this.obj.max_delay_days = Math.trunc(val / 10);
      }
      e.preventDefault();
      return false;
    }
  }
  clickedEvent(event: any) {
    this.isFilterOpenClose = event ? true : false
    if(event.type){
      if (event.type == "fevorite") {
        this.settingtype = event.type
        if (event.event.length > 0) {
          this.saveChnageType = 'Save';
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      } else {
        this.settingtype = ''
        if (event.target && event.target.value) {
          this.saveChnageType = 'Save';
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    }
  }
  setBetweenData(min: any, max: any) {
    if (min < 1 || min >= max) {
      this.navigationService.showError(`less than field value is incorrect, must be equal or greater than 1 and less than ${max}`, '');
      this.isDisabled = true;
    } else if (max <= min) {
      this.navigationService.showError(`more than field vlaue is incorrect, must be greater than ${min} and less than 99`, ``);
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
      this.minVal = min;
      this.maxVal = max;
    }
  }

  loadNotificationData() {
    this.notificationService.getNotification().subscribe({next:(res: any) => {
      this.SavedFilter = {
        loadPortNumber: res.data?.loadPortNumber ? res.data?.loadPortNumber : [],
        dischargePortNumber: res.data?.dischargePortNumber ? res.data?.dischargePortNumber : [],
        charterer: res.data?.charterer ? res.data?.charterer : []
      }
      if (res && res.data) {
        this.setNotificationObject(res.data);
      }
    }, error:(err) => {
      this.navigationService.errorOccure(err);
    }})
  }

  onClickSubmit(data: any) {
    let body: any
    body = (this.saveChnageType == "Reset") ? this.reSetData : this.getNotificationObject(data);
    body['DischargePortNo'] = (!this.getEmitFilter) ? this.SavedFilter.dischargePortNumber : this.getEmitFilter.dport;
    body['LoadingPortNo'] = (!this.getEmitFilter) ? this.SavedFilter.loadPortNumber : this.getEmitFilter.port;
    body['chartererFilter'] = (!this.getEmitFilter) ? this.SavedFilter.charterer : this.getEmitFilter.charterer
    if (body.min_delay_days < 1 || body.min_delay_days >= body.max_delay_days) {
      this.navigationService.showError(`less than field value is incorrect, must be equal or greater than 1 and less than ${body.max_delay_days}`, '');
      this.isDisabled = true;
    } else if (body.max_delay_days <= body.min_delay_days) {
      this.navigationService.showError(`more than field vlaue is incorrect, must be greater than ${body.min_delay_days} and less than 99`, ``);
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
      this.isLoading = true
      this.notificationService.saveNotification(body).subscribe({next:(res: any) => {
        this.isLoading = false
        if (res && res.data) {
          this._bookingService.changeBookingCount({bookingType:0,isBooking:false})
          this.resetBookingFilters();
          this.resetSustanabilityFilters();
          this.resetDocumentationFilter();
          localStorage.removeItem('data');
          localStorage.removeItem('receivedAt');
          this.navigationService.showSuccess("Settings has been updated successfully", '');
          this.isDisabled = true;
          this.ngOnInit()
        }
      }, error:(err) => {
        this.isLoading = false
        this.navigationService.errorOccure(err);
      }})
    }
  }



  resetBookingFilters() {
    let reqbody = {
      bookingType: 1,
      LoadingPortNo: [],
      vesselCode: [],
      DischargePortNo: [],
      companyId: [],
      idfilter: [],
      chartererFilter: [],
      tradeLOB: [],
      id_fleet: [],
      bkType: "All"
    }

    this._bookingService.saveFilter(reqbody).subscribe((res: any) => {
    })
  }

  resetDocumentationFilter(){
  this.docService.getDocumentationSavedFilter().subscribe((res: any) => {
    if (res && res.data.length > 0) {
      let savedFilter = res.data[0]
      let obj={
        vesselCode: [],
        loadPortNumber: [],
        dischargePortNumber: [],
        id_fleet: [],
        dateRange: {
            fromDate: "2023-05-30",
            toDate: "2024-05-30"
        },
        tradeLOB: [],
        chartererFilter: [],
        brokerFilter: [],
        broker:savedFilter.broker,
        charterer:savedFilter.charterer,
        tradelanes:savedFilter.tradelanes
    }
      this.docService.getDocumentsFilter(obj).subscribe((res: any) => {})
    }
  })

  }

  resetSustanabilityFilters() {
    let cargoArr: any = [];
    let tradeArr: any = [];
    let vesselArr: any = [];
    let chartArr: any = [];
    let bookIdArr: any = [];
    let dateRangeObj = {};
    let obj = {
      'pageNo':1,
      'isSetting': true
    }
    this.sustainabilityService.getSustainabilityBookingListData(obj, cargoArr, tradeArr, vesselArr, chartArr, bookIdArr, dateRangeObj).subscribe((res: any) => console.log(res))
  }
  getNotificationObject(data: any) {
    return {
      "min_delay_days": data['obj.min_delay_days'], "max_delay_days": data['obj.max_delay_days'],
      "frequency_per_day": data['obj.frequency_per_day'],
      "laycan": data['obj.laycan'],
      "weekdays": {
        "mon": data['obj.mon'], "tue": data['obj.tue'], "wed": data['obj.wed'], "thu": data['obj.thu'], "fri": data['obj.fri'],
        "sat": data['obj.sat'], "sun": data['obj.sun']
      },
      "events": { "change_of_vessel_nom": data['obj.change_of_vessel_nom'], "eta_updates": data['obj.eta_updates'] },
    };
  }
  setNotificationObject(data: any) {
    this.obj.min_delay_days = data?.min_delay_days;
    this.obj.max_delay_days = data?.max_delay_days;
    this.obj.frequency_per_day = data?.frequency_per_day;
    this.obj.mon = data.weekdays.mon;
    this.obj.tue = data.weekdays.tue;
    this.obj.wed = data.weekdays.wed;
    this.obj.thu = data.weekdays.thu;
    this.obj.fri = data.weekdays.fri;
    this.obj.sat = data.weekdays.sat;
    this.obj.sun = data.weekdays.sun;
    this.obj.change_of_vessel_nom = data.events.change_of_vessel_nom;
    this.obj.eta_updates = data.events.eta_updates;
    this.minVal = data.min_delay_days;
    this.maxVal = data.max_delay_days;
    this.obj.laycan = data.laycan;
  }

  ResetAll() {
    this.reSettEnb = true;
    this.saveChnageType = "Reset";
    this.isDisabled = false;
    this.obj.frequency_per_day = 0;
    this.obj.mon = 0;
    this.obj.tue = 0;
    this.obj.wed = 0;
    this.obj.thu = 0;
    this.obj.fri = 0;
    this.obj.sat = 0;
    this.obj.sun = 0;
    this.obj.eta_updates = 0;
    this.obj.change_of_vessel_nom = 0;

    this.reSetData = {
      events: { change_of_vessel_nom: false, eta_updates: false },
      frequency_per_day: 0,
      laycan: this.obj.laycan,
      max_delay_days: this.maxVal,
      min_delay_days: this.minVal,
      weekdays: { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false }
    }
  }

  addMorFileds() {
    this.ResipiantData.push({ name: '', email: '' });
  }

  onKeyBlur(event: any, index: number, type: any) {
    if (type == 1)
      this.ResipiantData[index].name = event.target.value
    else
      this.ResipiantData[index].email = event.target.value
  }


  removevalue(i: any) {
    this.ResipiantData.splice(i, 1);
  }

  DeleteList(event: any) {
    this.isUpdate = false;
    this.fileExtenMessg = '';
    let is_Deleted = true;
    if (event == "del") {
      this.isDelete = true;
      this.settingService.saveEmailDistribution(this.listnamForm.get("listname")?.value, this.list_id, is_Deleted).subscribe({next:() => {
        let msgSuccess = 'Distribution List has been successfully deleted.';
        this.navigationService.showSuccess(`${msgSuccess}`, 'Success');
        this.distributionRemoveModel = false
        this.isDelete = false;
        this.getUserInfo();
      }, error:err => {
        this.navigationService.errorOccure(err);
        this.distributionRemoveModel = false
        this.isDelete = false;
      }})
    } else {
      this.totalRecipients = event.recipientList.length
      this.listName = event.listName;
      this.listnamForm.get("listname")?.patchValue(event.listName)
      this.list_id = event.id;
    }
  }

  resetForm() {
    this.isUpdate = false;
    this.respintLenght = 0;
    this.respLimit = false;
    this.fileExtenMessg = ''
    this.listName = ''
    this.list_id = '';
    this.manulaEnb = true;
    this.UploadEnb = false;
    this.is_csvError = false;
    this.recipientList = [];
    this.is_upload = true;
    this.errorMessage = [];
    this.existError = false;
    this.listnameExist = [];
    this.hideUploadBtn = true;
    this.Is_Delete = true;
    this.viewMode = 'Manual'
    this.disableOnEdit = true;
    this.onCreateForm = true;
    this.emailArr = [];
    this.Is_add = true;
    this.textLenMsg = '';
    this.Is_frmDelete = false;
    this.onUploaddsbl = true;
    this.is_upload_csv = true;
    this.is_csven = false;
    this.delVal = 'notDelete';
    this.errMessage = '';

    this.listnamForm = this.fb.group({
      'listname': ["", Validators.compose([
        Validators.required])],
    })
    this.userForm = this.fb.group({
      name: ['',[Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]],
      Referrals: this.fb.array([]),
    });
    if (this.ReferralsFormArr.length < 0) {
      this.ReferralsFormArr.push(
        this.fb.group({
          name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern('^[a-zA-Z0-9!@$%^&*()-_\\s]+$')]],
          email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]],
        })
      );
    }
  }


  OnkeyUp(event: any) {
    let lenth = event.target.value;
    if (lenth.length > 0)
      this.Is_add = true;
    else
      this.errMessage = '';

  }

  UpdateList(event: any) {
    this.isUpdate = true
    this.respintLenght = event.recipientList.length
    this.respLimit = false;
    this.fileExtenMessg = '';
    this.ResipiantData = [];
    this.Is_Delete = true;
    this.Is_add = true;
    this.disableOnEdit = false;
    this.manulaEnb = true;
    this.UploadEnb = false;
    this.is_upload = false;
    this.hideUploadBtn = false;
    this.onCreateForm = false;
    this.textLenMsg = ''
    this.listName = event.listName;
    this.listnamForm.get("listname")?.patchValue(event.listName)
    this.list_id = event.id;
    this.ResipiantData = event.recipientList;
    this.delVal = "updatedel";
    if (this.ResipiantData.length == 1)
      this.Is_Delete = false;
    else
      this.Is_Delete = true;

    this.emailArr = [];
    Object.values(this.ResipiantData).forEach((val: any) => {
      this.emailArr.push(val.email)
    });
    let coremail = (this.ResipiantData[0].name == null || this.ResipiantData[0].name == undefined) ? '' : this.ResipiantData[0].name;
    this.userForm = this.fb.group({
      name: [coremail,[Validators.required]],
      email: [this.ResipiantData[0].email, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]],
      Referrals: this.fb.array([]),

    });

    for (const index of this.ResipiantData.keys()) {
      let recorrect = (this.ResipiantData[index].name == null || this.ResipiantData[index].name == undefined) ? '' : this.ResipiantData[index].name
      this.ReferralsFormArr.push(
        this.fb.group({
          name: [recorrect, [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern('^[a-zA-Z0-9!@$%^&*()-_\\s]+$')]],

          email: [this.ResipiantData[index].email, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]],
        })
      );
    }
  }


  public checkErrorUserForm = (controlName: string, errorName: string) => {
    return (this.userForm.controls[controlName].hasError(errorName))
  };


  get ReferralsFormArr(): UntypedFormArray {
    return (this.userForm.get('Referrals') as UntypedFormArray)
  }

  getReferralsFormArr(index: any): UntypedFormGroup {
    if (this.delVal == "updatedel")
      this.resplenght = this.ReferralsFormArr.controls.length;
    else
      this.resplenght = this.ReferralsFormArr.controls.length + 1;
    return (this.ReferralsFormArr.controls[index] as UntypedFormGroup);
  }

  functionName(event: any) {
    if(!this.userForm.invalid){
      if (event == undefined)
      this.respintLenght = 1;
    else
      this.respintLenght = event;
    }
  }
  addReferral(): void {
    this.Is_Delete = true;
    this.Is_frmDelete = true;
    if (this.ReferralsFormArr.length < 24) {
      this.ReferralsFormArr.push(
        this.fb.group({
          name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern('^[a-zA-Z0-9!@$%^&*()-_\\s]+$')]],
          email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]],
        })
      );
    } else {
      this.Is_add = true;
      this.respLimit = true;
      this.errMessagelimit = "Sorry, you've reached your maximum limit (25) of emails as per list."
    }
  }

  removeReferral(index: any) {
    this.email_duplicte =  this.email_duplicte.length == 1 ? [] :  this.email_duplicte
    this.respintLenght = document.getElementsByClassName("trash-field").length - 1;
    if (this.ReferralsFormArr.length == 1) {
      this.Is_frmDelete = false;
    }
    if (this.ReferralsFormArr.length == 2 && this.delVal == "deleted") {
      this.Is_Delete = false;
    }
    if (this.delVal == "updatedel" && this.ReferralsFormArr.length == 2)
      this.Is_Delete = false;

    if (this.onCreateForm)
      this.emailArr.splice(index + 1, 1)
    else
      this.emailArr.splice(index, 1);
    this.Is_add = true
    let refflen = this.ReferralsFormArr.length;
    let delCount = this.onCreateForm ? 1 : 2
    if (refflen == delCount && this.disableOnEdit) {
      this.Is_Delete = false;
      this.ReferralsFormArr.removeAt(index);
    } else {
      this.Is_add = true
      this.ReferralsFormArr.removeAt(index);
    }

  }


  removeForm() {
    this.delVal = "deleted";
    if (this.ReferralsFormArr.length > 0) {
      this.emailArr.splice(0, 1)
      this.disableOnEdit = false;
      if (this.ReferralsFormArr.length == 1)
        this.Is_Delete = false
    } else {
      this.disableOnEdit = true;
    }
  }

  onSubmit(values: any) {
    if (this.email_duplicte.length > 0) {
      this.Is_add = false;
      this.errMessage = "You have enter Duplicate Email"
    } else {
      this.Is_add = true;
      let formData = values.Referrals;
      let newarr: any = [];
      for (const index of formData.keys()) {
        newarr.push({ name: formData[index].name, email: formData[index].email })
      }
      if (this.delVal != "deleted")
        newarr.push({ name: values.name, email: values.email.trim() })
      else
        newarr.push({ name: '', email: '' })

      if (this.delVal == "updatedel")
        newarr = values.Referrals;

      let removeDuplicate = newarr.filter((arr: any, index: any, self: any) =>
        index === self.findIndex((t: any) => (t.email === arr.email)))
      this.recipientList = removeDuplicate.filter((oneObj: any) => Object.values(oneObj.email.trim()).length > 1);
      this.SaveScVlist();
    }
  }



  onDuplicateEmail1(event: any, index: any) {
    this.emailArr[index] = event.target.value;
    let lowercase = this.emailArr.map((element: any) => element.toLowerCase());
    this.email_duplicte = lowercase.filter((item: any, indexs: any) => lowercase.indexOf(item) !== indexs);
    if (this.email_duplicte.length > 0) {
      this.Is_add = false
      this.errMessage = "You have enter Duplicate Email"
    } else {
      this.Is_add = true;
    }
  }

  onDuplicateEmail(event: any, i: any) {
    let index = this.isUpdate ? i - 1 : i;
    let evEmail = event.target.value
    this.emailArr[index] = evEmail;
    let lowercase = this.emailArr.map((element: any) => element.toLowerCase());
    this.email_duplicte = lowercase.filter((item: any, index: any) => lowercase.indexOf(item) !== index)
    if (this.email_duplicte.length > 0) {
      this.Is_add = false
      this.errMessage = "You have enter Duplicate Email"
    } else {
      this.Is_add = true;

    }

  }

  handleFileSelect(evt: any) {
    let files = evt.target.files;
    this.UploadfilesData = files[0];
    this.csvFileName = this.UploadfilesData.name;
    let allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(this.csvFileName)) {
      this.myInputVariable.nativeElement.value = "";
      this.fileExtenMessg = "Invalid file format. Only .csv files are allowed.";
    }
    else {
      this.openppop = true;
      this.fileExtenMessg = ''
    }
    this.errorMessage = []
  }

  closePopup() {
    this.openppop = false
  }

  uploadcsvdata(event: any) {
    this.csvlistnameExist = [];
    this.openppop = false;
    let file = event;
    this.csvFileName = file.name;
    this.onUploaddsbl = false;
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (events: any) => {
      let csv = events.target.result;
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results: any) => {
          let dataLen = results.data.length;
          this.refactorCsvFileData(results);
          let emialError_validate: any = []
          let mailformat = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,9})$/;
          let emialError_column: any = Object.keys(Object.assign({}, ...results.data));
          let listnameArr = results.data.filter((result: any) => result["List Name"])
          let listName = this.checkListNameArr(listnameArr);
          let lisnameError = this.checkListNameError(listnameArr);
         
          let nameNotBlankError = results.data.filter((result: any) => result.Name !== '')
          let emialNotError_blnk = results.data.filter((result: any) => result.Email !== '')
          let getValid_email = this.checkGetValidEmail(results, mailformat);
          this.csvlistnameExist = this.DistSourceData.filter((result: any) => result.listName == listName);
         
          this.refactorCsvlistnameExist(listnameArr)
          /* Name COlumn section */
          let nameError_column_find = this.checkNameErrorColumnFind(emialError_column);
          let findBlankName = results.data.filter((result: any) => result.Name)
          let nameError_spChr: any[] = results.data.filter((rss: any) => rss.Name && rss.Name.includes('#'))
       
         
          let nameError = results.data.filter((result: any) => result.Name && result.Name.length > 30)
          let nameBlankError = results.data.filter((result: any) => result.Name == '')
          
          let nameLengthError: any[] = results.data.filter((result: any) => result.Name && result.Name.length < 3)
          this.refactorfindBlankNameIndex(nameBlankError,results,nameLengthError,nameError_spChr)
          /* Start Email Column section */
          let emialError_column_find = emialError_column.filter((item: any) => item == 'Email') ? emialError_column.filter((item: any) => item == 'Email') : [];
          if (nameLengthError.length > 0)
            this.findIndex = results.data.findIndex((x: any) => x.Email == nameLengthError[0].Email)
          let emialError = results.data.filter((result: any) => result.Email && result.Email.length > 50)
          let emialError_blnk = results.data.filter((result: any) => result.Email == '')
          let listNameError_column_find = emialError_column.filter((item: any) => item == 'List Name')
          if (emialError_column_find[0] == 'Email')
            emialError_validate = results.data.filter((result: any) => result.Email && !mailformat.test(result.Email))
          else
            emialError_validate = results.data;

          let valueArray: any = [];
          Object.values(results.data).forEach((val: any) => {
            valueArray.push(val.Email)
          });
          let emailError_duplicte: any = []
          if (valueArray[0] !== undefined) {
            emailError_duplicte = valueArray.filter((item: any, index: any) => valueArray.indexOf(item) !== index)
          }

          this.refactorfindDuplicateEmailIndex(results,emailError_duplicte,emialError_validate)
          this.refactorNameError(nameError,nameLengthError,this.findNameMaxLenIndex,findBlankName,dataLen,this.findBlankNameIndex,nameError_spChr)
          this.refactorEmailErrorColumn(emialError_column_find,nameError_column_find, listNameError_column_find);
          this.refactorEmailError(emialError,emialError_validate,emailError_duplicte,dataLen,this.findInvalidEmailIndex,this.findDuplicateEmailIndex,emialError_blnk)
          this.refactorNamelistError(listnameArr,lisnameError)
          /* check when error and some emails are vailid */
          this.refactorCheckValidEmail(getValid_email, nameNotBlankError, emialNotError_blnk, results, listName);
          this.refactorErrorMessage(results,listName)
        }
      });
    }
  }

  checkNameErrorColumnFind(emialError_column:any){
    return emialError_column.filter((item: any) => item == 'Name') ? emialError_column.filter((item: any) => item == 'Name') : [];
  }

  checkGetValidEmail(results:any, mailformat: any){
    return results.data.filter((result: any) => result.Email && mailformat.test(result.Email)) 
  }

  checkListNameError(listnameArr:any){
    return listnameArr.length == 0 ? false : listnameArr[0]["List Name"].includes('#'); 
  }

  checkListNameArr(listnameArr:any){
    return listnameArr.length > 0 ? listnameArr[0]["List Name"] : [];
  }

  refactorCsvFileData(results:any){
    this.csvFileData = results.data.filter((result: any) => result.Name && result.Name !== '' && result.Email && result.Email !== '')
  }

  refactorEmailErrorColumn(emialError_column_find:any,nameError_column_find:any, listNameError_column_find:any){
    if (emialError_column_find[0] != 'Email' || nameError_column_find[0] != 'Name' || listNameError_column_find[0] != 'List Name') {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "CSV must contain 'List Name','Name' and 'Email' in columns." })
    }
  }

  refactorCheckValidEmail(getValid_email:any, nameNotBlankError:any, emialNotError_blnk:any, results:any, listName:any){
    if (getValid_email.length > 0 && nameNotBlankError.length > 0 && emialNotError_blnk.length > 0 && this.errorMessage.length > 0) {
      this.checkError = true
      getValid_email = getValid_email.filter((li: any, idx: any, self: any) => self.map((itm: any) => itm.Email).indexOf(li.Email) === idx)
      let filternameEmail = getValid_email.filter((result: any) => result.Name !== '' && result.Email !== '' && result.Name.length > 3);
      this.totalRecored = results.data.length;
      this.recipientList = filternameEmail.map(function (obj: any) {
        return { name: obj.Name, email: obj.Email };
      });
      this.SaveDistributionList(nameNotBlankError,listName)
    }
  }

  refactorNameError(nameError:any,nameLengthError:any,findNameMaxLenIndex:any,findBlankName:any,dataLen:any,findBlankNameIndex:any,nameError_spChr:any){

    if (dataLen > 25 || dataLen < 1) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "Email upload limit is 25. Please re-upload the CSV after making the changes." })
    }

    if (nameError.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "Name Should be maximum thirty characters." })
    }

    if (nameLengthError.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: findNameMaxLenIndex.toString(), message: "Name should atleast contain 3 characters" })
    }

    if (findBlankName.length != dataLen) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: findBlankNameIndex.toString(), message: "Name cannot be blank" })

    }
    if (nameError_spChr.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: this.findHashNameCharIndex.toString(), message: "Name  can't contain hash(#) special characters" })

    }
  }

  refactorfindDuplicateEmailIndex(results:any,emailError_duplicte:any,emialError_validate:any){
    if (emailError_duplicte.length > 0) {
      results.data.forEach((element: any, index: any) => {
        if (emailError_duplicte.includes(element.Email)) {
          this.findDuplicateEmailIndex.push(index + 2)
        }
      });
    }

    if (emialError_validate.length > 0) {
      results.data.forEach((element: any, index: any) => {
        if (emialError_validate.includes(element)) {
          this.findInvalidEmailIndex.push(index + 2)
        }
      });
    }
  }

  refactorErrorMessage(results:any,listName:any){
    if (this.errorMessage.length == 0) {
      this.is_csvError = false;
      this.is_csven = true;
      this.recipientList = results.data.filter((result: any) => result.Name && result.Name !== '') && results.data.filter((result: any) => result.Email && result.Email !== '')
      this.listName = listName;
      this.listnamForm.get("listname")?.patchValue(this.listName)
      this.recipientList = this.recipientList.map(function (obj) {
        return { name: obj.Name, email: obj.Email };
      });
      if (this.recipientList.length > 0 && this.errorMessage.length == 0) {
        this.myInputVariable.nativeElement.value = "";
        this.openppop = false;
        this.checkError = false;
        this.csvFileName = ''
        this.settingService.saveEmailDistribution(this.listnamForm.get("listname")?.value, this.list_id, this.recipientList).subscribe(
          {
            next:res=>{
              let msgSuccess = this.recipientList.length + ' out of ' + this.recipientList.length + ' records have been added successfully to the list.';
              this.navigationService.showSuccess(`${msgSuccess}`, 'Success');
              this.getUserInfo();
            },
           error:err=>{
            this.navigationService.errorOccure(err);
           }
          }
        )
      }
    }
  }

  SaveDistributionList(nameNotBlankError:any,listName:any){
    if (this.recipientList.length > 0 && nameNotBlankError.length > 0) {
      this.myInputVariable.nativeElement.value = "";
      this.openppop = false;
      this.csvFileName = ''
      this.listName = listName;
      this.listnamForm.get("listname")?.patchValue(this.listName)
      let recpintlen = this.recipientList.length;
      
      this.settingService.saveEmailDistribution(this.listnamForm.get("listname")?.value, this.list_id, this.recipientList).subscribe(
        {
          next:res=>{
            let msgSuccess = recpintlen + ' out of ' + this.totalRecored + ' records have been added successfully to the list.';
            this.navigationService.showError(`${msgSuccess}`, 'Failure');
            this.getUserInfo();
          },
         error:err=>{
          this.navigationService.errorOccure(err);
         }
        }
      )
    }
  }

  refactorCsvlistnameExist(listnameArr:any){
    if (listnameArr.length > 0) {
      this.listNameLenght = listnameArr[0]["List Name"] && listnameArr[0]["List Name"].length > 40 ? true : false;
    }

    if (this.csvlistnameExist.length > 0) {
      this.list_id = this.csvlistnameExist[0].id
    }
  }

  refactorNamelistError(listnameArr:any,lisnameError:any){
    if (listnameArr.length > 1) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "Per list only one List Name is allowed. " })
    }
    if (listnameArr.length == 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "The list Name cannot be blank." })
    }
    if (lisnameError) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "Hash(#) special characters are not allowed in List name" })

    }
    if (this.listNameLenght) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "List name Should be maximum forty character" })
    }
  }

  refactorEmailError(emialError:any,emialError_validate:any,emailError_duplicte:any,dataLen:any,findInvalidEmailIndex:any,findDuplicateEmailIndex:any,emialError_blnk:any){
    if (emialError.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "Email Should be maximum fifty characters. Email should not be blank." })

    }

    if (emialError_validate.length !== dataLen && emialError_validate.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: findInvalidEmailIndex.toString(), message: "The email format is wrong. Please edit and re-upload." })

    }
    if (emailError_duplicte.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: findDuplicateEmailIndex.toString(), message: "You have a duplicate email in the csv file! Please check it." })

    }
    if (emialError_blnk.length > 0) {
      this.checkError = true;
      this.myInputVariable.nativeElement.value = "";
      this.errorMessage.push({ rownum: [], message: "Somthing went wrong! Email should not be blank." })

    }
  }

  refactorfindBlankNameIndex(nameBlankError:any,results:any,nameLengthError:any,nameError_spChr:any){
    if (nameError_spChr.length > 0) {
      results.data.forEach((element: any, index: any) => {
        if (nameError_spChr.includes(element)) {
          this.findHashNameCharIndex.push(index + 2)
        }
      });
    }

    if (nameBlankError.length > 0) {
      results.data.forEach((element: any, index: any) => {
        if (nameBlankError.includes(element)) {
          this.findBlankNameIndex.push(index + 2)
        }
      });
    }

    if (nameLengthError.length > 0) {
      results.data.forEach((element: any, index: any) => {
        if (nameLengthError.includes(element)) {
          this.findNameMaxLenIndex.push(index + 2)
        }
      });
    }
  }


  SaveScVlist() {
    this.isDelete = true;
    this.removebodyClass()
    this.settingService.saveEmailDistribution(this.listnamForm.get("listname")?.value, this.list_id, this.recipientList).subscribe({next:() => {
      let msgSuccess = 'Distribution List has been successfully added.';
      this.navigationService.showSuccess(`${msgSuccess}`, 'Success');
      this.resplenght = 1;
      this.isOpenModel = false
      this.isDelete = false;
      this.getUserInfo();
    }, error:err => {
      this.isDelete = false;
      this.navigationService.errorOccure(err);
      this.isOpenModel = true
    }})
  }


  OnchangeList(type: any, mode: any) {
    this.viewMode = mode;
    if (type == 1) {
      this.manulaEnb = true;
      this.UploadEnb = false
    } else {
      this.manulaEnb = false;
      this.UploadEnb = true
    }
  }


  OnlistNameChange(event: any) {
    let listVal = event.target.value;
    if (listVal.length > 40 || listVal.length == 40) {
      this.textLenMsg = "List name should be max 40 character"
    }
    else if (listVal.includes('#')) {
      this.Is_add = false;
      this.textLenMsg = 'Hash(#) special character are not allowed'
    }
    else {
      this.Is_add = true;
      this.textLenMsg = ''
    }
    this.is_upload = listVal.length > 0 ? false : true;
    this.is_upload_csv = listVal.length > 0 ? false : true;
    this.listnameExist = this.DistSourceData.filter((result: any) => result.listName == listVal);
    this.existError = this.listnameExist.length > 0 ? true : false;

    if (this.existError) {
      this.is_upload_csv = true;
      this.Is_add = false;
    } else {
      this.is_upload_csv = false;
      this.Is_add = true;
    }
  }

  OnKeyupName(event: any) {
    this.checkhashName = event.target.value;
    if (this.checkhashName.includes('#')) {
      this.Is_add = false;
      this.nameValidError = "Hash(#) special character are not allowed";
    } else if (this.checkhashName.length < 3) {
      this.Is_add = false;
      this.nameValidError = 'Name should atleast contain 3 character';
    } else if (this.checkhashName.length > 100) {
      this.Is_add = false;
      this.nameValidError = 'Name should max contain 100 character';
    }
    else {
      this.Is_add = true;
      this.nameValidError = '';
      this.checkhashName = false

    }
  }


  downloadCSV() {
    this.fileExtenMessg = '';
    let options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["Row Number", "Error Message"]
    };
    this.checkError = false;
    return new ngxCsv(this.errorMessage, 'Error', options);

  }

  downloadTemplate() {
    this.fileExtenMessg = '';
    let data = [{}];
    let options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["List Name", "Name", "Email"]
    };

    return new ngxCsv(data, 'Template', options);
  }


  getUserfilterByEmit(event: any) {
    this.getEmitFilter = event;
    this.isDisabled = false;
  }

  getUserFilter() {
    this.settingService.getUserFilterData().subscribe({next:res => {
      if (res.data) {
        this.settingFilterData = res.data;
        this.settingFilterData['savedFilter'] = this.SavedFilter;
        this.btnDisabled = (this.SavedFilter.charterer.length > 0 || this.SavedFilter.dischargePortNumber.length > 0 || this.SavedFilter.loadPortNumber.length > 0) ? false : true
      }
    }, error:err => {
      this.btnDisabled = true
      this.navigationService.errorOccure(err);
    }})
  }


  CreateNotificaton() {
    this.isNotification = true
    this.updateNotification = ''
  }

  CreateMobileNotificaton(){
    this.isMobileNotification = true;
    this.updateNotification = '';
  }

  UpdateNotificaton(rowdata: any) {
    this.isNotification = true;
    this.updateNotification = rowdata

  }

  UpdateMobileNotificaton(rowdata: any){
    this.isMobileNotification = true;
    this.updateNotification = rowdata
  }



  closePopupNoti(event: any) {
    this.removebodyClass()
    if (event.type == 'save') {
      this.isNotification = event.event
      this.isMobileNotification = event.event
      this.NotificationListData = []
      this.getNotificationData()
    } else {
      this.isNotification = event.event
      this.isMobileNotification = event.event
    }

  }

  DeleteNotificationList(event: any) {
    if (event == "del") {
      this.isDelete = true;
      this.settingService.removeNotification(this.rowId).subscribe((res:any) => {
        this.NotificationListData = [];
        this.getNotificationData();
        let msgSuccess = 'Notification List has been successfully deleted.';
        this.navigationService.showSuccess(`${msgSuccess}`, 'Success');
        this.isDelete = false;
        this.notificationRemoveModel = false;
      })
    } else {
      this.rowId = event;
    }
  }

  emitOverflowClass(event: any) {
    this.overFlowClass = event ? true : false

  }

  getETAVarianceValues(min: any, max: any) {
    return [{
      "idfilter": 1,
      "mindays": -365,
      "maxdays": min,
      "displayname": `<${min} ${min==1?"Day":"Days"}`
    },
    {
      "idfilter": 2,
      "mindays": min,
      "maxdays": max,
      "displayname": `${min}-${max} Days` // min.max
    },
    {
      "idfilter": 3,
      "mindays": max,
      "maxdays": 365,
      "displayname": `>${max} Days` // max
    }]
  }

  closeDistribution(){
    this.resplenght = 1;
    this.isOpenModel = false
    this.removebodyClass()
  }
removebodyClass()
{
  let ele:any = document.querySelector("body");
  if(ele && ele.style){
    ele.style.overflow = 'auto';
  }
}

  ClickShowFullFilter(){ 
    this.IstoggleAcordian = this.IstoggleAcordian ? false : true
  }
}
