import { Component, OnInit } from '@angular/core';
import { ContactData } from "../../../models/publicPages";
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';

@Component({
  selector: 'app-contact-iframe',
  templateUrl: './contact-iframe.component.html',
  styleUrls: ['./contact-iframe.component.scss']
})
export class ContactIframeComponent implements OnInit {

  contactRegion = 'All Regions';
  contactJob = 'All functions';
  contactList: ContactData[] = [];
  contactListData: any[] = [];
  contactListDataSource:any[] = []
  contactJobData: any[];
  contactRegionData: any[];
  contactRegionDataFilter: any[];
  isSkelton = false;
  ContactIframeComponent:any
  constructor(public navigationService: NavigationService, public publicPagesService: PublicPagesService) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers Contact');
  }

  ngOnInit(): void {
    this.isSkelton = true;
    this.publicPagesService.getContactList().subscribe({next:res => {
      if (res && res.data) {
        this.isSkelton = false;
        this.contactList = res.data;
        this.contactJobData = ['All functions', ...this.contactList.map(item => item.operation_type)
          .filter((value, index, self) => self.indexOf(value) === index)]
        this.contactRegionData = ['All Regions', ...this.contactList.map(item => item.region)
          .filter((value, index, self) => self.indexOf(value) === index)];
          this.contactListData = this.contactList
        let conTdata = this.groupBy(this.contactList, 'region');
        this.contactListDataSource = Object.entries(conTdata);
        this.contactRegionDataFilter = this.contactRegionData;
        
      }
    }, error:err => {
      this.isSkelton = false;
      this.navigationService.errorOccure(err);
    }})
  }



   groupBy(objectArray:any, property:any) {
    return objectArray.reduce(function (acc:any, obj:any) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  
 

  clearFilter() {
    this.contactRegion = 'All Regions';
    this.contactJob = 'All functions';
    this.jobAndRegionChanged();
  }

  filterChnaged() {
    this.jobAndRegionChanged();
  }

  jobAndRegionChanged() {
    const modifyRegion = this.contactRegion !== 'All Regions' ? this.contactList.filter(it => it.region == this.contactRegion) : this.contactList;
    const modifyJob = this.contactJob !== 'All functions' ? modifyRegion.filter(it => it.operation_type == this.contactJob) : modifyRegion;
    this.contactListData = modifyJob;
    let conTdata = this.groupBy(this.contactListData, 'region');
    this.contactListDataSource = Object.entries(conTdata);
    let filteredRegion = ['All Regions', ...this.contactList.filter(it => it.operation_type == this.contactJob || it.region == 'All Regions').map(it => it.region)];
    this.contactRegionDataFilter = this.contactJob !== 'All functions' ? filteredRegion.filter((value: any, index: number, self: any) => self.indexOf(value) === index) : ['All Regions', ...this.contactList.map(item => item.region)
      .filter((value, index, self) => self.indexOf(value) === index)];
  }

}
