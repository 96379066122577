/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export const msalConfig: Configuration = {
  auth: {
    clientId: environment.B2C_CLIENTID,
    authority: environment.AUTHORITY,
    redirectUri: environment.REDIRECT_URI,
    knownAuthorities: environment.KNOWNAUTHORITIES,
    postLogoutRedirectUri: environment.POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        // loggerCallback
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}
