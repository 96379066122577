import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PublicPagesService } from './public-pages.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  baseUrlVessel = environment.API_BASE_URL + environment.VESSELTRACKING_SERVICE_BASE_NAME;
  authVessel = environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME;
  baseUrl = environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME;

  constructor(private http: HttpClient, private publicPagesService: PublicPagesService) { }
  getHeader() {
    return this.publicPagesService.getHeader();
  }
  getHeaderJson() {
    return this.publicPagesService.getHeaderTypeJson();
  }

  getEmailDistribution(): Observable<any> {
    return this.http.get(`${this.authVessel}/api/viewDistributionList`, this.getHeaderJson()).pipe(map(data => data));
  }

 

  saveEmailDistribution(listname: string, id: any, recipientList: any): Observable<any> {
    let body = {}
    if (recipientList.length > 0)
      body = { listName: listname, id: id, recipientList: recipientList }
    else
      body = { listName: listname, id: id, is_deleted: true }
    return this.http.post<any>(`${this.authVessel}/api/addDistributionList`, body, this.getHeaderJson()).pipe(map((data: any) => data))
  }

  getUserFilterData(): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/fetchUserSettingsFilters`, this.getHeaderJson()).pipe(map(data => data));
  }

  saveUserFilter(body:any)
  {
    return this.http.post<any>(`${this.authVessel}/api/addsettignfilter`, body, this.getHeaderJson()).pipe(map((data: any) => data))
  }

  getNotificationList()
  {
    return this.http.get(`${this.authVessel}/api/getNotificationsSettings`, this.getHeaderJson()).pipe(map(data => data));
  }

  saveNotification(body:any):Observable<any>
  {
    return this.http.post<any>(`${this.authVessel}/api/saveNotificationsSettings`, body, this.getHeaderJson()).pipe(map((data: any) => data))
  }

  removeNotification(id:any)
  {
    let body = {id:id}
    return this.http.post<any>(`${this.authVessel}/api/deleteNotificationsSettings`, body, this.getHeaderJson()).pipe(map((data: any) => data))
  }

  getTimezonelist()
  {
    return this.http.get(`${this.authVessel}/api/getTimeZones`, this.getHeaderJson()).pipe(map(data => data));
  }
}
