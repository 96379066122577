import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-share-filter',
  templateUrl: './share-filter.component.html',
  styleUrls: ['./share-filter.component.scss']
})
export class ShareFilterComponent {
  @ViewChild('ngSelectControl') ngSelectControl: NgSelectComponent;
  @ViewChild('searchName') searchNameInput: any;
  @Input() filterDataInput: any;
  @Input() savedfilterData: any;
  @Input() resetAllCalled:any;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() display: string;
  @Input() filterName: string;
  @Input() filterField: string;
  @Input() dynamicTextCss:string;
  @Input() placeholder :string;
  filterValueTemp: any;
  filterValue: any;

  filterData: any;
  filterDataMani: any;
  filte: any

  @Output() submitFilter: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.manupulateFIlterRes(this.filterDataInput)
  }

  manupulateFIlterRes(data: any[]) {
    this.filterDataMani = data.map((it: any) => ({ ...it,[this.filterName]:it[this.filterName]+"", [this.filterField]: 'All' }));
    this.refactorUserFilter(data)
    this.mappedSavedFilters(this.savedfilterData)
  }
  mappedSavedFilters(savedfilter: any) {
    this.filterValue = savedfilter
    this.filterValueTemp = this.filterValue;
  }
  refactorUserFilter(data: any) {
    let filterdata = this.filterDataMani;
    this.filterData = filterdata.map((it: any) => ({ ...it, [this.filterField]: 'All' }));
    this.filte = this.filterData
  }
  customSearch(event: Event) {
    let word = (event.target as HTMLInputElement).value.trim();
    this.refactorSearch(word, event)
  }
  refactorSearch(word: any, event: any) {
    if ((word != undefined && word != "undefined" && word != null && word != "null" && word != "") || word.length > 2) {
      this.display = "none";
      this.filterData = this.filterDataMani.filter((it: any) => it[this.filterName].toLowerCase().includes(word.toLowerCase()));
    } else {
      this.display = "block";
      let alterArray = this.filterDataMani;
      this.filterData = alterArray.map((it: any) => ({ ...it, [this.filterField]: 'All' }));
    }
  }
  clearInput() {
    this.display = "block";
    this.filterData = this.filte;
    if(this.searchNameInput){
      this.searchNameInput.nativeElement.value = '';
    }
  }
  submitFilterMultiple() {
    if (this.filterValueTemp.includes('All')) {
      this.filterValueTemp = this.filterDataMani.map((it: any) => it[this.bindValue]);
      this.filterValue = this.filterValueTemp
      this.submitFilter.emit({type:this.filterField,data:this.filterValueTemp})
    } else {
      this.filterValue = this.filterValueTemp;
      this.submitFilter.emit({type:this.filterField,data:this.filterValueTemp})

    }
  }
  resetFilterMultiple() {
    this.display = "block"
     this.ngSelectControl.handleClearClick();
    this.filterValueTemp = [];
    let alterArray = this.filterDataMani;
    let resetAll = alterArray.map((it: any) => ({ ...it, [this.filterField]: 'All' }));
     this.filterData = resetAll;
    this.filterValue = this.filterValueTemp;
     this.ngSelectControl.close();
    this.submitFilter.emit({type:this.filterField,data:this.filterValueTemp})
  }

  getSelectedValue(){
    console.log("filterValueTemp",this.filterValueTemp)
  }

}
