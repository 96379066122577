import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  siteUrl = '';
  hostName='';
  constructor(public navigationService: NavigationService) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Privacy-policy');
  }

  ngOnInit(): void {
    this.siteUrl= environment.POST_LOGOUT_REDIRECT_URI;
    console.log(this.siteUrl);
    this.hostName= (environment.POST_LOGOUT_REDIRECT_URI).replace(/^https?:\/\//, '');
  }


}
