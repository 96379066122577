<snl-tooltip class="width-fc df-c" [position]="'bottom'">
    <span  href="javascript:void(0);" class="text__primary border-dash-b" (click)="mobileAddTooltip($event)"> 
        TBD
    </span>
    <div class="snl-tooltip-custmzd static-tooltip" tooltipContent>                                                     
        <div class="body-b1 d-flex justify-content-between c-gap-24 __CargoList">
            <span>Please contact
                your local Stolt representative for
                further information.</span>
        </div>
    </div>
</snl-tooltip>